/* eslint-disable max-classes-per-file */
/* eslint-disable arrow-body-style */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  MpQuestionContentMcaEntry,
  MpQuestionContentNumericEntry,
  MpQuestionContentScaEntry,
  MpQuestionContentShortTextEntry,
  MpQuestionEntryType,
  MpQuestionEvaluationKeyMcaEntry,
  MpQuestionEvaluationKeyNumericEntry,
  MpQuestionEvaluationKeyScaEntry,
  MpQuestionEvaluationKeyShortTextEntry,
  MpSittingQuestionDto,
  MpSittingQuestionResponseMcaEntry,
  MpSittingQuestionResponseNumericEntry,
  MpSittingQuestionResponseScaEntry,
  MpSittingQuestionResponseShortTextEntry,
  SittingQuestionDto,
} from '../../model';
import { shortId } from '../../util/random';
import OverlayButton from '../util/OverlayButton';
import { RichTeX, RichTeXEditor } from '../util/RichTeXEditor';
import AnswerEditor from './AnswerEditor';
import { QuestionEditorMode, QuestionEditorModelProps, QuestionEditorProps } from './QuestionEditorModel';

/**
 * Extended model props
 */

type AbstractMpEntryEditorModel = {
  token: string;
  entryKey: string;
  type: MpQuestionEntryType;
  weight: number;
};

type MpShortTextEntryEditorModel = AbstractMpEntryEditorModel & {
  type: 'shorttext';
  acceptedAnswers: {
    [key: string]: string;
  };
  caseSensitive: boolean;
  response?: string;
};

type MpNumericEntryEditorModel = AbstractMpEntryEditorModel & {
  type: 'numeric';
  min: number;
  max: number;
  response?: number;
};

type MpScaEntryEditorModel = AbstractMpEntryEditorModel & {
  type: 'singlecorrect';
  shuffleAnswers: boolean;
  answers: {
    [key: string]: {
      dynamic: boolean;
      text: RichTeX | string;
    };
  };
  correctKey: string;
  selectedKey: string;
};

type MpMcaEntryEditorModel = AbstractMpEntryEditorModel & {
  type: 'multiplecorrect';
  shuffleAnswers: boolean;
  answers: {
    [key: string]: {
      dynamic: boolean;
      correct: boolean;
      selected: boolean;
      text: RichTeX | string;
    };
  };
};

type MpEntryEditorModel = MpShortTextEntryEditorModel | MpNumericEntryEditorModel | MpScaEntryEditorModel | MpMcaEntryEditorModel;

export class MpQuestionEditorModelProps extends QuestionEditorModelProps<MpSittingQuestionDto> {
  questionText: RichTeX;
  entries: { [token: string]: MpEntryEditorModel };

  constructor(question: MpSittingQuestionDto, template?: SittingQuestionDto) {
    super(question, template);

    this.questionText = new RichTeX(this.question.questionContent.text);
    this.entries = {};

    const tokens = Object.keys(this.question.questionContent.entries || {});

    tokens.forEach((token) => {
      const type = this.question.questionContent.entries?.[token]?.type;

      if (type === 'shorttext') {
        const contentEntry = this.question.questionContent.entries?.[token] as MpQuestionContentShortTextEntry;
        const evaluationKeyEntry = this.question.evaluationKey?.[token] as MpQuestionEvaluationKeyShortTextEntry;
        const responseEntry = this.question.response?.[token] as MpSittingQuestionResponseShortTextEntry;

        this.entries[token] = {
          type,
          token,
          entryKey: contentEntry.entryKey,
          weight: contentEntry.weight,
          caseSensitive: contentEntry.caseSensitive,
          acceptedAnswers: Object.fromEntries(evaluationKeyEntry?.acceptedAnswers?.map((a) => [shortId(), a]) || []),
          response: responseEntry?.response,
        };
      } else if (type === 'numeric') {
        const contentEntry = this.question.questionContent.entries?.[token] as MpQuestionContentNumericEntry;
        const evaluationKeyEntry = this.question.evaluationKey?.[token] as MpQuestionEvaluationKeyNumericEntry;
        const responseEntry = this.question.response?.[token] as MpSittingQuestionResponseNumericEntry;

        this.entries[token] = {
          type,
          token,
          entryKey: contentEntry.entryKey,
          weight: contentEntry.weight,
          min: evaluationKeyEntry?.min,
          max: evaluationKeyEntry?.max,
          response: responseEntry?.response,
        };
      } else if (type === 'singlecorrect') {
        const contentEntry = this.question.questionContent.entries?.[token] as MpQuestionContentScaEntry;
        const evaluationKeyEntry = this.question.evaluationKey?.[token] as MpQuestionEvaluationKeyScaEntry;
        const responseEntry = this.question.response?.[token] as MpSittingQuestionResponseScaEntry;

        this.entries[token] = {
          type,
          token,
          entryKey: contentEntry.entryKey,
          weight: contentEntry.weight,
          shuffleAnswers: contentEntry.shuffleAnswers,
          correctKey: evaluationKeyEntry?.correctKey,
          selectedKey: responseEntry?.selectedKey,
          answers: Object.fromEntries(
            Object.entries(contentEntry.answers).map(([answerId, answer]) => {
              return [
                answerId,
                {
                  dynamic: answer.dynamic,
                  text: answer.dynamic ? new RichTeX(answer.text) : answer.text,
                },
              ];
            }),
          ),
        };
      } else if (type === 'multiplecorrect') {
        const contentEntry = this.question.questionContent.entries?.[token] as MpQuestionContentMcaEntry;
        const evaluationKeyEntry = this.question.evaluationKey?.[token] as MpQuestionEvaluationKeyMcaEntry;
        const responseEntry = this.question.response?.[token] as MpSittingQuestionResponseMcaEntry;

        this.entries[token] = {
          type,
          token,
          entryKey: contentEntry.entryKey,
          weight: contentEntry.weight,
          shuffleAnswers: contentEntry.shuffleAnswers,
          answers: Object.fromEntries(
            Object.entries(contentEntry.answers).map(([answerId, answer]) => {
              return [
                answerId,
                {
                  dynamic: answer.dynamic,
                  correct: evaluationKeyEntry?.correctKeys?.[answerId],
                  selected: responseEntry?.selectedKeys?.[answerId],
                  text: answer.dynamic ? new RichTeX(answer.text) : answer.text,
                },
              ];
            }),
          ),
        };
      }
    });
  }

  refreshQuestion(): void {
    this.question.questionContent.text = this.questionText.getMarkdown();

    this.question.questionContent.entries = {};
    this.question.evaluationKey = {};
    this.question.response = {};

    // re-weight weights to sum to 1
    let sumWeights = 0;
    Object.values(this.entries).forEach((entry) => {
      sumWeights += entry.weight;
    });

    if (sumWeights === 0) {
      const numEntries = Object.keys(this.entries).length;
      // eslint-disable-next-line no-param-reassign
      Object.values(this.entries).forEach((entry) => {
        entry.weight = 1.0 / numEntries;
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      Object.values(this.entries).forEach((entry) => {
        entry.weight /= sumWeights;
      });
    }

    const tokens = Object.keys(this.entries);

    tokens.forEach((token) => {
      const type = this.entries[token]?.type;

      if (type === 'shorttext') {
        const entry = this.entries[token] as MpShortTextEntryEditorModel;

        this.question.questionContent.entries[token] = {
          entryKey: entry.entryKey,
          type: entry.type,
          weight: entry.weight,
          caseSensitive: entry.caseSensitive,
        };
        this.question.evaluationKey[entry.token] = {
          type: entry.type,
          acceptedAnswers: [...Object.values(entry.acceptedAnswers)],
        };
        this.question.response[entry.token] = {
          type: entry.type,
          response: entry.response,
        };
      } else if (type === 'numeric') {
        const entry = this.entries[token] as MpNumericEntryEditorModel;

        this.question.questionContent.entries[token] = {
          entryKey: entry.entryKey,
          type: entry.type,
          weight: entry.weight,
        };
        this.question.evaluationKey[entry.token] = {
          type: entry.type,
          min: entry.min,
          max: entry.max,
        };
        this.question.response[entry.token] = {
          type: entry.type,
          response: entry.response,
        };
      } else if (type === 'singlecorrect') {
        const entry = this.entries[token] as MpScaEntryEditorModel;

        this.question.questionContent.entries[token] = {
          entryKey: entry.entryKey,
          type: entry.type,
          weight: entry.weight,
          shuffleAnswers: entry.shuffleAnswers,
          answers: Object.fromEntries(
            Object.entries(entry.answers).map(([answerId, answer]) => {
              return [
                answerId,
                {
                  dynamic: answer.dynamic,
                  text: answer.dynamic ? (answer.text as RichTeX).getMarkdown() : answer.text,
                },
              ];
            }),
          ),
        };
        this.question.evaluationKey[entry.token] = {
          type: entry.type,
          correctKey: entry.correctKey,
        };
        this.question.response[entry.token] = {
          type: entry.type,
          selectedKey: entry.selectedKey,
        };
      } else if (type === 'multiplecorrect') {
        const entry = this.entries[token] as MpMcaEntryEditorModel;

        this.question.questionContent.entries[token] = {
          entryKey: entry.entryKey,
          type: entry.type,
          weight: entry.weight,
          shuffleAnswers: entry.shuffleAnswers,
          answers: Object.fromEntries(
            Object.entries(entry.answers).map(([answerId, answer]) => {
              return [
                answerId,
                {
                  dynamic: answer.dynamic,
                  text: answer.dynamic ? (answer.text as RichTeX).getMarkdown() : answer.text,
                },
              ];
            }),
          ),
        };
        this.question.evaluationKey[entry.token] = {
          type: entry.type,
          correctKeys: Object.fromEntries(Object.entries(entry.answers).map(([answerId, answer]) => [answerId, answer.correct])),
        };
        this.question.response[entry.token] = {
          type: entry.type,
          selectedKeys: Object.fromEntries(Object.entries(entry.answers).map(([answerId, answer]) => [answerId, answer.selected])),
        };
      }
    });
  }

  fromQuestionTemplate(template: SittingQuestionDto): MpSittingQuestionDto {
    // general case
    return {
      id: template.id,
      questionType: 'multipart',
      questionContent: {
        text: (template.questionContent as { text: string }).text || (template.questionContent as string),
        entries: {},
      },
      evaluationKey: {},
      response: {},
    };
  }

  setQuestionText(questionText: RichTeX): void {
    this.questionText = questionText;
    this.questionUpToDate = false;
  }

  addNewShortTextEntry(): void {
    const token = shortId();
    this.entries[token] = {
      type: 'shorttext',
      token,
      entryKey: 'New text key',
      weight: 1,
      acceptedAnswers: {},
      caseSensitive: true,
    };
    this.questionUpToDate = false;
  }

  addNewNumericEntry(): void {
    const token = shortId();
    this.entries[token] = {
      token,
      entryKey: 'New number key',
      weight: 1,
      type: 'numeric',
      min: 0,
      max: 1,
    };
    this.questionUpToDate = false;
  }

  addNewScaEntry(): void {
    const token = shortId();
    this.entries[token] = {
      token,
      entryKey: 'New number key',
      weight: 1,
      type: 'singlecorrect',
      shuffleAnswers: true,
      answers: {},
      correctKey: null,
      selectedKey: null,
    };
    this.questionUpToDate = false;
  }

  addNewMcaEntry(): void {
    const token = shortId();
    this.entries[token] = {
      token,
      entryKey: 'New number key',
      weight: 1,
      type: 'multiplecorrect',
      shuffleAnswers: true,
      answers: {},
    };
    this.questionUpToDate = false;
  }

  deleteEntry(token: string): void {
    delete this.entries[token];
    this.questionUpToDate = false;
  }

  changeWeight(token: string, weight: number): void {
    this.entries[token].weight = Math.max(weight, 0);
    this.questionUpToDate = false;
  }

  setEntryKey(token: string, entryKey: string): void {
    this.entries[token].entryKey = entryKey;
    this.questionUpToDate = false;
  }

  //
  // shorttext
  //

  toggleEntryCaseSensitive(token: string): void {
    const entry = this.entries[token];
    if (entry.type === 'shorttext') {
      entry.caseSensitive = !entry.caseSensitive;
      this.questionUpToDate = false;
    }
  }

  setAcceptedAnswerText(token: string, answerId: string, answerText: string): void {
    const entry = this.entries[token];
    if (entry.type === 'shorttext') {
      entry.acceptedAnswers[answerId] = answerText;
      this.questionUpToDate = false;
    }
  }

  addNewAcceptedAnswer(token: string, answerText: string): void {
    const entry = this.entries[token];
    if (entry.type === 'shorttext') {
      entry.acceptedAnswers[shortId()] = answerText;
      this.questionUpToDate = false;
    }
  }

  deleteAcceptedAnswer(token: string, answerId: string): void {
    const entry = this.entries[token];
    if (entry.type === 'shorttext') {
      delete entry.acceptedAnswers[answerId];
      this.questionUpToDate = false;
    }
  }

  setShortTextResponse(token: string, response: string): void {
    const entry = this.entries[token];
    if (entry.type === 'shorttext') {
      entry.response = response;
      this.questionUpToDate = false;
    }
  }

  //
  // numeric
  //

  setAcceptedMin(token: string, newMin: number): void {
    const entry = this.entries[token];
    if (entry.type === 'numeric') {
      entry.min = newMin;
      this.questionUpToDate = false;
    }
  }

  setAcceptedMax(token: string, newMax: number): void {
    const entry = this.entries[token];
    if (entry.type === 'numeric') {
      entry.max = newMax;
      this.questionUpToDate = false;
    }
  }

  setNumericResponse(token: string, response: number): void {
    const entry = this.entries[token];
    if (entry.type === 'numeric') {
      entry.response = response;
      this.questionUpToDate = false;
    }
  }

  //
  // singlecorrect && multiplecorrect
  //

  toggleQuestionShuffleAnswers(token: string): void {
    const entry = this.entries[token];
    if (entry.type === 'singlecorrect' || entry.type === 'multiplecorrect') {
      entry.shuffleAnswers = !entry.shuffleAnswers;
      this.questionUpToDate = false;
    }
  }

  setAnswerText(token: string, answerId: string, answerText: RichTeX | string, dynamic: boolean): void {
    const entry = this.entries[token];
    if (entry.type === 'singlecorrect' || entry.type === 'multiplecorrect') {
      entry.answers[answerId].text = answerText;
      entry.answers[answerId].dynamic = dynamic;
      this.questionUpToDate = false;
    }
  }

  //
  // singlecorrect
  //

  addNewScaAnswer(token: string, answerText: RichTeX | string, dynamic: boolean): void {
    const entry = this.entries[token];
    if (entry.type === 'singlecorrect') {
      const answerId = shortId();
      entry.answers[answerId] = {
        text: answerText,
        dynamic,
      };
      // if one answer left, it is automatically correct
      if (Object.keys(entry.answers).length === 1) {
        [entry.correctKey] = Object.keys(entry.answers);
      }
      this.questionUpToDate = false;
    }
  }

  setScaCorrectAnswer(token: string, answerId: string): void {
    const entry = this.entries[token];
    if (entry.type === 'singlecorrect') {
      entry.correctKey = answerId;
      this.questionUpToDate = false;
    }
  }

  setScaSelectedAnswer(token: string, answerId: string): void {
    const entry = this.entries[token];
    if (entry.type === 'singlecorrect') {
      entry.selectedKey = answerId;
      this.questionUpToDate = false;
    }
  }

  deleteScaAnswer(token: string, answerId: string): void {
    const entry = this.entries[token];
    if (entry.type === 'singlecorrect') {
      delete entry.answers[answerId];
      // if one answer left, it is automatically correct
      if (Object.keys(entry.answers).length === 1) {
        [entry.correctKey] = Object.keys(entry.answers);
      }
      this.questionUpToDate = false;
    }
  }

  //
  // multiplecorrect
  //

  addNewMcaAnswer(token: string, answerText: RichTeX | string, dynamic: boolean): void {
    const entry = this.entries[token];
    if (entry.type === 'multiplecorrect') {
      const answerId = shortId();
      entry.answers[answerId] = {
        text: answerText,
        dynamic,
        correct: false,
        selected: false,
      };
      this.questionUpToDate = false;
    }
  }

  toggleMcaAnswerCorrect(token: string, answerId: string): void {
    const entry = this.entries[token];
    if (entry.type === 'multiplecorrect') {
      entry.answers[answerId].correct = !entry.answers[answerId].correct;
      this.questionUpToDate = false;
    }
  }

  toggleMcaAnswerSelected(token: string, answerId: string): void {
    const entry = this.entries[token];
    if (entry.type === 'multiplecorrect') {
      entry.answers[answerId].selected = !entry.answers[answerId].selected;
      this.questionUpToDate = false;
    }
  }

  deleteMcaAnswer(token: string, answerId: string): void {
    const entry = this.entries[token];
    if (entry.type === 'multiplecorrect') {
      delete entry.answers[answerId];
      this.questionUpToDate = false;
    }
  }
}

/**
 * Associated components
 */

type MpQuestionShortTextEntryEditorProps = {
  mode: QuestionEditorMode;
  entry?: MpShortTextEntryEditorModel;
  new?: boolean;

  onEntryToggleCaseSensitive: () => void;
  onAcceptedAnswerTextChange: (answerId: string, answerText: string) => void;
  onDeleteAcceptedAnswer: (answerId: string) => void;
  onNewAcceptedAnswer: (answerText: string) => void;
  onResponseChange: (response: string) => void;
};

class MpQuestionShortTextEntryEditor extends Component<MpQuestionShortTextEntryEditorProps> {
  token = shortId();

  constructor(props: MpQuestionShortTextEntryEditorProps) {
    super(props);
    autoBind(this);
  }

  render(): JSX.Element {
    const { mode, entry } = this.props;
    const { caseSensitive, acceptedAnswers } = entry;

    const isAnswerSelected = (answer: string): boolean => {
      if (!entry.response || !answer) {
        return false;
      }
      if (caseSensitive) {
        return answer.trim() === entry.response.trim();
      }
      return answer.trim().toLowerCase() === entry.response.trim().toLowerCase();
    };

    return (
      <div>
        {/* case sensitivity */}
        {mode === 'edit' && (
          <>
            <div className="float-right">
              <input
                type="checkbox"
                id={`caseSensitive-${entry.token}`}
                checked={caseSensitive}
                onChange={this.props.onEntryToggleCaseSensitive}
              />
              &nbsp;
              <label htmlFor={`caseSensitive-${entry.token}`} className="uncolored">
                Case sensitive
              </label>
            </div>
          </>
        )}

        {mode !== 'edit' && (
          <>
            <div className="float-right">
              This question <b>{caseSensitive ? 'is' : 'is not'}</b> case sensitive
            </div>
          </>
        )}

        <div style={{ paddingLeft: 8 }}>
          {mode === 'edit' && !entry.weight && (
            <>
              <span className="errormsg">Warning: Entry will not count towards final points</span>
            </>
          )}

          {/* response */}

          {(mode === 'response' || mode === 'evaluate' || mode === 'take') && (
            <>
              <b className="eventmsg">Response:</b>&nbsp;
              <input
                type="text"
                placeholder={mode === 'take' ? 'Your response goes here' : 'No response'}
                value={entry.response}
                onChange={(event) => this.props.onResponseChange(event.target.value)}
                style={{ width: '100%' }}
                disabled={mode !== 'take'}
                required
              />
              <br />
            </>
          )}

          {/* shorttext subquestions - accepted answers */}
          {(mode === 'edit' || mode === 'view' || mode === 'evaluate') && (
            <>
              <div className="eventmsg" style={{ paddingTop: 4, paddingBottom: 2 }}>
                <b>Accepted answers:</b>
              </div>

              {/* list of accepted answers */}
              {Object.entries(acceptedAnswers).map(([answerId, answer]) => (
                <AnswerEditor
                  key={answerId}
                  mode={mode}
                  variant="simple"
                  allowDynamic={false}
                  selected={isAnswerSelected(answer)}
                  correct={true}
                  token={this.token}
                  answerId={answerId}
                  text={answer}
                  onTextChange={(answerText: string) => this.props.onAcceptedAnswerTextChange(answerId, answerText)}
                  onDelete={() => this.props.onDeleteAcceptedAnswer(answerId)}
                />
              ))}

              {mode === 'edit' && (
                <>
                  <AnswerEditor
                    mode={mode}
                    variant="simple"
                    allowDynamic={false}
                    token={this.token}
                    new={true}
                    onTextChange={(answerText: string) => this.props.onNewAcceptedAnswer(answerText)}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

type MpQuestionNumericEntryEditorProps = {
  mode: QuestionEditorMode;
  entry?: MpNumericEntryEditorModel;
  new?: boolean;

  onAcceptedMinChange: (newMin: number) => void;
  onAcceptedMaxChange: (newMax: number) => void;
  onResponseChange: (response: number) => void;
};

class MpQuestionNumericEntryEditor extends Component<MpQuestionNumericEntryEditorProps> {
  token = shortId();

  constructor(props: MpQuestionNumericEntryEditorProps) {
    super(props);
    autoBind(this);
  }

  render(): JSX.Element {
    const { mode, entry } = this.props;
    const { min, max } = entry;

    return (
      <div style={{ paddingLeft: 8 }}>
        {mode === 'edit' && !entry.weight && (
          <>
            <span className="errormsg">Warning: Entry will not count towards final points</span>
          </>
        )}

        {/* response */}

        {(mode === 'response' || mode === 'evaluate' || mode === 'take') && (
          <>
            <b className="eventmsg">Response:</b>&nbsp;
            <input
              type="number"
              placeholder={mode === 'take' ? 'Your response goes here' : 'No response'}
              value={entry.response}
              onChange={(event) => this.props.onResponseChange(Number(event.target.value))}
              style={{ width: '100%' }}
              disabled={mode !== 'take'}
              required
            />
            <br />
          </>
        )}

        {/* numeric subquestions - min & max */}

        {/* edit accepted min/max */}
        {mode === 'edit' && (
          <>
            <div className="eventmsg" style={{ paddingBottom: 4 }}>
              <b>Correct answers are between&nbsp;</b>
              <input
                type="number"
                placeholder="Min"
                value={min}
                onChange={(event) => this.props.onAcceptedMinChange(Number(event.target.value))}
                style={{ flexGrow: 1, width: 100 }}
                required
              />
              <b>&nbsp;and&nbsp;</b>
              <input
                type="number"
                placeholder="Max"
                value={max}
                onChange={(event) => this.props.onAcceptedMaxChange(Number(event.target.value))}
                style={{ flexGrow: 1, width: 100 }}
                required
              />

              {min > max && (
                <>
                  <span className="errormsg">&nbsp;Warning: max value is smaller than min, no answers can be correct</span>
                </>
              )}
            </div>
          </>
        )}

        {/* show accepted min/max */}
        {(mode === 'view' || mode === 'evaluate') && (
          <>
            <div className="eventmsg" style={{ paddingBottom: 4 }}>
              <b>
                Correct answers are between {min} and {max}
              </b>
            </div>
          </>
        )}
      </div>
    );
  }
}

type MpQuestionScaEntryEditorProps = {
  mode: QuestionEditorMode;
  entry?: MpScaEntryEditorModel;
  new?: boolean;

  onToggleShuffleAnswers: () => void;
  onAddNewAnswer: (answerText: RichTeX | string, dynamic: boolean) => void;
  onAnswerTextChange: (answerId: string, answerText: RichTeX | string, dynamic: boolean) => void;
  onSetCorrectAnswer: (answerId: string) => void;
  onSetSelectedAnswer: (answerId: string) => void;
  onDeleteAnswer: (answerId: string) => void;
};

type MpQuestionScaEntryEditorState = {
  answerEntries: [
    string,
    {
      dynamic: boolean;
      text: RichTeX | string;
    },
  ][];
};

class MpQuestionScaEntryEditor extends Component<MpQuestionScaEntryEditorProps, MpQuestionScaEntryEditorState> {
  token = shortId();

  constructor(props: MpQuestionScaEntryEditorProps) {
    super(props);
    autoBind(this);

    if (props.mode === 'take') {
      const { shuffleAnswers } = props.entry;
      let answerEntries = Object.entries(props.entry.answers);
      if (shuffleAnswers && props.mode === 'take') {
        answerEntries = answerEntries.sort(() => Math.random() - 0.5);
      }

      this.state = {
        answerEntries,
      };
    }
  }

  render(): JSX.Element {
    const { mode, entry } = this.props;
    const { shuffleAnswers } = entry;

    const answerEntries = mode === 'take' ? this.state.answerEntries : Object.entries(entry.answers);

    return (
      <div>
        {/* shuffle answers */}
        {(mode === 'edit' || mode === 'view') && (
          <>
            <div className="float-right">
              <input
                type="checkbox"
                id={`shuffleAnswers-${this.token}`}
                checked={shuffleAnswers}
                onChange={this.props.onToggleShuffleAnswers}
                disabled={mode !== 'edit'}
              />
              &nbsp;
              <FontAwesomeIcon icon="random" />
              &nbsp;
              <label htmlFor={`shuffleAnswers-${this.token}`} className="uncolored">
                Answers shuffled
              </label>
            </div>
          </>
        )}

        <div style={{ paddingLeft: 8 }}>
          {mode === 'edit' && !entry.weight && (
            <>
              <span className="errormsg">Warning: Entry will not count towards final points</span>
            </>
          )}

          {/* answers */}
          {(mode === 'edit' || mode === 'view') && (
            <>
              <div className="eventmsg" style={{ paddingTop: 4, paddingBottom: 2 }}>
                <b>Options:</b>
              </div>
            </>
          )}

          {answerEntries.map(([answerId, answer]) => (
            <AnswerEditor
              key={answerId}
              mode={mode}
              variant="singlecorrect"
              allowDynamic={true}
              token={this.token}
              answerId={answerId}
              correct={entry.correctKey === answerId}
              selected={entry.selectedKey === answerId}
              {...answer}
              onTextChange={(answerText, dynamic) => this.props.onAnswerTextChange(answerId, answerText, dynamic)}
              onToggleCorrect={() => this.props.onSetCorrectAnswer(answerId)}
              onToggleSelected={() => this.props.onSetSelectedAnswer(answerId)}
              onDelete={() => this.props.onDeleteAnswer(answerId)}
            />
          ))}

          {mode === 'edit' && (
            <>
              <AnswerEditor
                mode={mode}
                variant="singlecorrect"
                allowDynamic={true}
                token={this.token}
                new={true}
                onTextChange={this.props.onAddNewAnswer}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

type MpQuestionMcaEntryEditorProps = {
  mode: QuestionEditorMode;
  entry?: MpMcaEntryEditorModel;
  new?: boolean;

  onToggleShuffleAnswers: () => void;
  onAddNewAnswer: (answerText: RichTeX | string, dynamic: boolean) => void;
  onAnswerTextChange: (answerId: string, answerText: RichTeX | string, dynamic: boolean) => void;
  onSetCorrectAnswer: (answerId: string) => void;
  onSetSelectedAnswer: (answerId: string) => void;
  onDeleteAnswer: (answerId: string) => void;
};

type MpQuestionMcaEntryEditorState = {
  answerEntries: [
    string,
    {
      dynamic: boolean;
      correct: boolean;
      selected: boolean;
      text: RichTeX | string;
    },
  ][];
};

class MpQuestionMcaEntryEditor extends Component<MpQuestionMcaEntryEditorProps, MpQuestionMcaEntryEditorState> {
  token = shortId();

  constructor(props: MpQuestionMcaEntryEditorProps) {
    super(props);
    autoBind(this);

    if (props.mode === 'take') {
      const { shuffleAnswers } = props.entry;
      let answerEntries = Object.entries(props.entry.answers);
      if (shuffleAnswers) {
        answerEntries = answerEntries.sort(() => Math.random() - 0.5);
      }

      this.state = {
        answerEntries,
      };
    }
  }

  render(): JSX.Element {
    const { mode, entry } = this.props;
    const { shuffleAnswers } = entry;

    const answerEntries = mode === 'take' ? this.state.answerEntries : Object.entries(entry.answers);

    return (
      <div>
        {/* shuffle answers */}
        {(mode === 'edit' || mode === 'view') && (
          <>
            <div className="float-right">
              <input
                type="checkbox"
                id={`shuffleAnswers-${this.token}`}
                checked={shuffleAnswers}
                onChange={this.props.onToggleShuffleAnswers}
                disabled={mode !== 'edit'}
              />
              &nbsp;
              <FontAwesomeIcon icon="random" />
              &nbsp;
              <label htmlFor={`shuffleAnswers-${this.token}`} className="uncolored">
                Answers shuffled
              </label>
            </div>
          </>
        )}

        <div style={{ paddingLeft: 8 }}>
          {mode === 'edit' && !entry.weight && (
            <>
              <span className="errormsg">Warning: Entry will not count towards final points</span>
            </>
          )}

          {/* answers */}
          {(mode === 'edit' || mode === 'view') && (
            <>
              <div className="eventmsg" style={{ paddingTop: 4, paddingBottom: 2 }}>
                <b>Options:</b>
              </div>
            </>
          )}

          {answerEntries.map(([answerId, answer]) => (
            <AnswerEditor
              key={answerId}
              mode={mode}
              variant="multicorrect"
              allowDynamic={true}
              token={this.token}
              answerId={answerId}
              {...answer}
              onTextChange={(answerText, dynamic) => this.props.onAnswerTextChange(answerId, answerText, dynamic)}
              onToggleCorrect={() => this.props.onSetCorrectAnswer(answerId)}
              onToggleSelected={() => this.props.onSetSelectedAnswer(answerId)}
              onDelete={() => this.props.onDeleteAnswer(answerId)}
            />
          ))}

          {mode === 'edit' && (
            <>
              <AnswerEditor
                mode={mode}
                variant="multicorrect"
                allowDynamic={true}
                token={this.token}
                new={true}
                onTextChange={this.props.onAddNewAnswer}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

//
// Main entry editor
//

type MpQuestionEntryEditorProps = {
  mode: QuestionEditorMode;
  entry?: MpEntryEditorModel;
  new?: boolean;

  onDeleteEntry: () => void;
  onEntryKeyChange: (entryKey: string) => void;
  onWeightChange: (weight: number) => void;
  onEntryToggleCaseSensitive: () => void;
  onAcceptedAnswerTextChange: (answerId: string, answerText: string) => void;
  onDeleteAcceptedAnswer: (answerId: string) => void;
  onNewAcceptedAnswer: (answerText: string) => void;
  onAcceptedMinChange: (newMin: number) => void;
  onAcceptedMaxChange: (newMax: number) => void;
  onShortTextResponseChange: (response: string) => void;
  onNumericResponseChange: (response: number) => void;
  onToggleShuffleAnswers: () => void;
  onAnswerTextChange: (answerId: string, answerText: RichTeX | string, dynamic: boolean) => void;
  onAddNewScaAnswer: (answerText: RichTeX | string, dynamic: boolean) => void;
  onSetCorrectScaAnswer: (answerId: string) => void;
  onSetSelectedScaAnswer: (answerId: string) => void;
  onDeleteScaAnswer: (answerId: string) => void;
  onAddNewMcaAnswer: (answerText: RichTeX | string, dynamic: boolean) => void;
  onToggleCorrectMcaAnswer: (answerId: string) => void;
  onToggleSelectedMcaAnswer: (answerId: string) => void;
  onDeleteMcaAnswer: (answerId: string) => void;
};

class MpQuestionEntryEditor extends Component<MpQuestionEntryEditorProps> {
  token = shortId();

  constructor(props: MpQuestionEntryEditorProps) {
    super(props);
    autoBind(this);
  }

  render(): JSX.Element {
    const { mode, entry } = this.props;

    return (
      <div style={{ marginTop: 8 }}>
        <div>
          {/* entry ID */}

          {mode === 'edit' && (
            <>
              <hr style={{ marginBottom: 10 }} />
              <div style={{ display: 'flex' }}>
                <FontAwesomeIcon icon="caret-right" />
                &nbsp;
                <label htmlFor={`entryKey-${entry.token}`}>Key:&nbsp;</label>
                <input
                  type="text"
                  id={`entryKey-${entry.token}`}
                  value={entry.entryKey}
                  style={{ flexGrow: 1, width: '100%' }}
                  onChange={(e) => this.props.onEntryKeyChange(e.target.value)}
                />
                &nbsp;
                <FontAwesomeIcon icon="weight-hanging" />
                &nbsp;
                <input
                  type="number"
                  id={`weight-${entry.token}`}
                  value={entry.weight}
                  style={{ flexGrow: 1 }}
                  onChange={(e) => this.props.onWeightChange(Number(e.target.value))}
                />
                &nbsp;
                <OverlayButton className="close" visible={!this.props.new} tooltip="Delete entry" onClick={this.props.onDeleteEntry}>
                  <FontAwesomeIcon icon="trash" size="xs" />
                </OverlayButton>
              </div>
            </>
          )}

          {mode !== 'edit' && (
            <div>
              <FontAwesomeIcon icon="caret-right" />
              &nbsp;
              <b>{entry.entryKey}</b>
              <OverlayTrigger placement="top" overlay={<Tooltip id={`weight-${entry.token}`}>Weight in total question score</Tooltip>}>
                <span className="objectstreams">
                  &nbsp;·&nbsp;
                  <FontAwesomeIcon icon="weight-hanging" />
                  &nbsp;
                  {(entry.weight * 100.0).toFixed()}%
                </span>
              </OverlayTrigger>
            </div>
          )}

          {/* sub-component by type */}

          {entry.type === 'shorttext' && (
            <>
              <MpQuestionShortTextEntryEditor
                entry={entry}
                mode={mode}
                onEntryToggleCaseSensitive={this.props.onEntryToggleCaseSensitive}
                onAcceptedAnswerTextChange={this.props.onAcceptedAnswerTextChange}
                onDeleteAcceptedAnswer={this.props.onDeleteAcceptedAnswer}
                onNewAcceptedAnswer={this.props.onNewAcceptedAnswer}
                onResponseChange={this.props.onShortTextResponseChange}
              />
            </>
          )}

          {entry.type === 'numeric' && (
            <>
              <MpQuestionNumericEntryEditor
                entry={entry}
                mode={mode}
                onAcceptedMinChange={this.props.onAcceptedMinChange}
                onAcceptedMaxChange={this.props.onAcceptedMaxChange}
                onResponseChange={this.props.onNumericResponseChange}
              />
            </>
          )}

          {entry.type === 'singlecorrect' && (
            <>
              <MpQuestionScaEntryEditor
                entry={entry}
                mode={mode}
                onToggleShuffleAnswers={this.props.onToggleShuffleAnswers}
                onAnswerTextChange={this.props.onAnswerTextChange}
                onAddNewAnswer={this.props.onAddNewScaAnswer}
                onSetCorrectAnswer={this.props.onSetCorrectScaAnswer}
                onSetSelectedAnswer={this.props.onSetSelectedScaAnswer}
                onDeleteAnswer={this.props.onDeleteScaAnswer}
              />
            </>
          )}

          {entry.type === 'multiplecorrect' && (
            <>
              <MpQuestionMcaEntryEditor
                entry={entry}
                mode={mode}
                onToggleShuffleAnswers={this.props.onToggleShuffleAnswers}
                onAnswerTextChange={this.props.onAnswerTextChange}
                onAddNewAnswer={this.props.onAddNewMcaAnswer}
                onSetCorrectAnswer={this.props.onToggleCorrectMcaAnswer}
                onSetSelectedAnswer={this.props.onToggleSelectedMcaAnswer}
                onDeleteAnswer={this.props.onDeleteMcaAnswer}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

type MpQuestionEditorProps = QuestionEditorProps<MpSittingQuestionDto, MpQuestionEditorModelProps>;

export default class MpQuestionEditor extends Component<MpQuestionEditorProps> {
  constructor(props: MpQuestionEditorProps) {
    super(props);
    autoBind(this);
  }

  onQuestionTextChange(questionText: RichTeX): void {
    const { model, onChange } = this.props;
    model.setQuestionText(questionText);
    onChange(model);
  }

  onDeleteEntry(token: string): void {
    const { model, onChange } = this.props;
    model.deleteEntry(token);
    onChange(model);
  }

  onWeightChange(token: string, weight: number): void {
    const { model, onChange } = this.props;
    model.changeWeight(token, weight);
    onChange(model);
  }

  onEntryKeyChange(token: string, entryKey: string): void {
    const { model, onChange } = this.props;
    model.setEntryKey(token, entryKey);
    onChange(model);
  }

  //
  // shorttext
  //

  onNewShortTextEntry(): void {
    const { model, onChange } = this.props;
    model.addNewShortTextEntry();
    onChange(model);
  }

  onEntryToggleCaseSensitive(token: string): void {
    const { model, onChange } = this.props;
    model.toggleEntryCaseSensitive(token);
    onChange(model);
  }

  onAcceptedAnswerTextChange(token: string, answerId: string, answerText: string): void {
    const { model, onChange } = this.props;
    model.setAcceptedAnswerText(token, answerId, answerText);
    onChange(model);
  }

  onDeleteAcceptedAnswer(token: string, answerId: string): void {
    const { model, onChange } = this.props;
    model.deleteAcceptedAnswer(token, answerId);
    onChange(model);
  }

  onNewAcceptedAnswer(token: string, answerText: string): void {
    const { model, onChange } = this.props;
    model.addNewAcceptedAnswer(token, answerText);
    onChange(model);
  }

  onShortTextResponseChange(token: string, response: string): void {
    const { model, onChange } = this.props;
    model.setShortTextResponse(token, response);
    onChange(model);
  }

  //
  // numeric
  //

  onNewNumericEntry(): void {
    const { model, onChange } = this.props;
    model.addNewNumericEntry();
    onChange(model);
  }

  onAcceptedMinChange(token: string, newMin: number): void {
    const { model, onChange } = this.props;
    model.setAcceptedMin(token, newMin);
    onChange(model);
  }

  onAcceptedMaxChange(token: string, newMax: number): void {
    const { model, onChange } = this.props;
    model.setAcceptedMax(token, newMax);
    onChange(model);
  }

  onNumericResponseChange(token: string, response: number): void {
    const { model, onChange } = this.props;
    model.setNumericResponse(token, response);
    onChange(model);
  }

  //
  // singlecorrect
  //

  onNewScaEntry(): void {
    const { model, onChange } = this.props;
    model.addNewScaEntry();
    onChange(model);
  }

  onToggleShuffleAnswers(token: string): void {
    const { model, onChange } = this.props;
    model.toggleQuestionShuffleAnswers(token);
    onChange(model);
  }

  onAddNewScaAnswer(token: string, answerText: RichTeX | string, dynamic: boolean): void {
    const { model, onChange } = this.props;
    model.addNewScaAnswer(token, answerText, dynamic);
    onChange(model);
  }

  onAnswerTextChange(token: string, answerId: string, answerText: RichTeX | string, dynamic: boolean): void {
    const { model, onChange } = this.props;
    model.setAnswerText(token, answerId, answerText, dynamic);
    onChange(model);
  }

  onSetScaCorrectAnswer(token: string, answerId: string): void {
    const { model, onChange } = this.props;
    model.setScaCorrectAnswer(token, answerId);
    onChange(model);
  }

  onSetScaSelectedAnswer(token: string, answerId: string): void {
    const { model, onChange } = this.props;
    model.setScaSelectedAnswer(token, answerId);
    onChange(model);
  }

  onDeleteScaAnswer(token: string, answerId: string): void {
    const { model, onChange } = this.props;
    model.deleteScaAnswer(token, answerId);
    onChange(model);
  }

  //
  // multiplecorrect
  //

  onNewMcaEntry(): void {
    const { model, onChange } = this.props;
    model.addNewMcaEntry();
    onChange(model);
  }

  onAddNewMcaAnswer(token: string, answerText: RichTeX | string, dynamic: boolean): void {
    const { model, onChange } = this.props;
    model.addNewMcaAnswer(token, answerText, dynamic);
    onChange(model);
  }

  onToggleMcaAnswerCorrect(token: string, answerId: string): void {
    const { model, onChange } = this.props;
    model.toggleMcaAnswerCorrect(token, answerId);
    onChange(model);
  }

  onToggleMcaAnswerSelected(token: string, answerId: string): void {
    const { model, onChange } = this.props;
    model.toggleMcaAnswerSelected(token, answerId);
    onChange(model);
  }

  onDeleteMcaAnswer(token: string, answerId: string): void {
    const { model, onChange } = this.props;
    model.deleteMcaAnswer(token, answerId);
    onChange(model);
  }

  render(): JSX.Element {
    const { mode, model, allowFileUpload, onUpload } = this.props;

    return (
      <>
        {/* question editor */}
        <span className="question-text">
          <RichTeXEditor
            className="static-editor"
            size="medium"
            value={model.questionText}
            readOnly={mode !== 'edit'}
            onChange={this.onQuestionTextChange}
            allowFileUpload={allowFileUpload}
            onUpload={onUpload}
          />
        </span>
        <br />

        <div style={{ marginLeft: 8 }}>
          <div>
            <b style={{ fontSize: '110%' }}>Entries</b>
            {mode === 'edit' && (
              <>
                &nbsp;
                <OverlayButton variant="info" size="sm" tooltip="Add new text entry" onClick={this.onNewShortTextEntry}>
                  <FontAwesomeIcon icon="plus" size="xs" />
                  &nbsp;abc
                </OverlayButton>
                &nbsp;
                <OverlayButton variant="info" size="sm" tooltip="Add new number entry" onClick={this.onNewNumericEntry}>
                  <FontAwesomeIcon icon="plus" size="xs" />
                  &nbsp;123
                </OverlayButton>
                &nbsp;
                <OverlayButton
                  variant="info"
                  size="sm"
                  tooltip="Add new quiz question with single correct answer"
                  onClick={this.onNewScaEntry}
                >
                  <FontAwesomeIcon icon="plus" size="xs" />
                  &nbsp;
                  <FontAwesomeIcon icon="dot-circle" size="xs" />
                </OverlayButton>
                &nbsp;
                <OverlayButton
                  variant="info"
                  size="sm"
                  tooltip="Add new quiz question with multiple correct answers"
                  onClick={this.onNewMcaEntry}
                >
                  <FontAwesomeIcon icon="plus" size="xs" />
                  &nbsp;
                  <FontAwesomeIcon icon="check-square" size="xs" />
                </OverlayButton>
              </>
            )}
          </div>

          {Object.entries(model.entries).map(([token, entry]) => (
            <MpQuestionEntryEditor
              key={token}
              entry={entry}
              mode={mode}
              onDeleteEntry={() => this.onDeleteEntry(token)}
              onEntryKeyChange={(entryKey) => this.onEntryKeyChange(token, entryKey)}
              onWeightChange={(weight) => this.onWeightChange(token, weight)}
              onEntryToggleCaseSensitive={() => this.onEntryToggleCaseSensitive(token)}
              onAcceptedAnswerTextChange={(answerId, answerText) => this.onAcceptedAnswerTextChange(token, answerId, answerText)}
              onDeleteAcceptedAnswer={(answerId) => this.onDeleteAcceptedAnswer(token, answerId)}
              onNewAcceptedAnswer={(answerText) => this.onNewAcceptedAnswer(token, answerText)}
              onShortTextResponseChange={(response) => this.onShortTextResponseChange(token, response)}
              onNumericResponseChange={(response) => this.onNumericResponseChange(token, response)}
              onAcceptedMinChange={(newMin) => this.onAcceptedMinChange(token, newMin)}
              onAcceptedMaxChange={(newMax) => this.onAcceptedMaxChange(token, newMax)}
              onToggleShuffleAnswers={() => this.onToggleShuffleAnswers(token)}
              onAnswerTextChange={(answerId, answerText, dynamic) => this.onAnswerTextChange(token, answerId, answerText, dynamic)}
              onAddNewScaAnswer={(answerText, dynamic) => this.onAddNewScaAnswer(token, answerText, dynamic)}
              onSetCorrectScaAnswer={(answerId) => this.onSetScaCorrectAnswer(token, answerId)}
              onSetSelectedScaAnswer={(answerId) => this.onSetScaSelectedAnswer(token, answerId)}
              onDeleteScaAnswer={(answerId) => this.onDeleteScaAnswer(token, answerId)}
              onAddNewMcaAnswer={(answerText, dynamic) => this.onAddNewMcaAnswer(token, answerText, dynamic)}
              onToggleCorrectMcaAnswer={(answerId) => this.onToggleMcaAnswerCorrect(token, answerId)}
              onToggleSelectedMcaAnswer={(answerId) => this.onToggleMcaAnswerSelected(token, answerId)}
              onDeleteMcaAnswer={(answerId) => this.onDeleteMcaAnswer(token, answerId)}
            />
          ))}
        </div>
      </>
    );
  }
}
