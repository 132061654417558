import React, { Component } from 'react';
import { GroupReducedDto } from '../../model';
import GroupListEntry from './GroupListEntry';

type GroupListProps = {
  groups: GroupReducedDto[];
};

export default class GroupList extends Component<GroupListProps> {
  render(): JSX.Element {
    if (!this.props.groups.length) {
      return <div className="infomsg">No groups to show</div>;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <table className="table table-hover">
            <tbody>
              {this.props.groups.map((group) => (
                <tr key={group.id}>
                  <td>
                    <GroupListEntry group={group} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
