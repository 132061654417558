import ms from 'ms';

/**
 * Aux methods
 */

const boolEnv = (envVarName, defaultValue = false) => {
  if (!(envVarName in window.cvqenvironment)) {
    return defaultValue;
  }

  const value = window.cvqenvironment[envVarName];
  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(value);
  }
};

export const apiServerUrl = window.cvqenvironment.API_SERVER_URL || 'http://localhost:8000';
export const authServerUrl = window.cvqenvironment.AUTH_SERVER_URL || 'http://localhost:8080';
export const fileStorageWsUrl = window.cvqenvironment.FILE_STORAGE_WS_URL || 'ws://localhost:8998/filestoragews';
export const fileStorageHttpUrl = window.cvqenvironment.FILE_STORAGE_HTTP_URL || 'http://localhost:8999/filestorage';
export const brokerRelayUrl = window.cvqenvironment.BROKER_RELAY_URL || 'ws://localhost:5670/brokerrelay';
export const wsReconnectInterval = window.cvqenvironment.WS_RECONNECT_INTERVAL || '5s';
export const wsReconnectNumTries = Number(window.cvqenvironment.WS_RECONNECT_NUM_TRIES || 12);
export const accessTokenExpirationTime = window.cvqenvironment.ACCESS_TOKEN_EXPIRATION_TIME || '15m';
export const videoSendInterval = window.cvqenvironment.VIDEO_SEND_INTERVAL || '10s';
export const streamTurnoverMBytes = Number(window.cvqenvironment.STREAM_TURNOVER_MBYTES || 4096);
export const logFetchRequests = boolEnv('LOG_FETCH_REQUESTS', false);
export const forceH264Codec = boolEnv('FORCE_H264_CODEC', true);

// derived props
export const wsReconnectIntervalMillis = ms(`${wsReconnectInterval}`);
export const streamTurnoverBytes = streamTurnoverMBytes * 1024 * 1024;
