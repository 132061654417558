import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Button, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RichTeX, RichTeXEditor } from './RichTeXEditor';

class TextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: new RichTeX(this.props.text),
      editing: false,
    };

    this.startEditing = this.startEditing.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onTextChanged = this.onTextChanged.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
  }

  startEditing() {
    this.setState({ editing: true });
  }

  onTextChanged(editor) {
    this.setState({ text: editor });
  }

  onSave() {
    this.props.onSave(this.state.text.getMarkdown());
    this.setState({ editing: false });
  }

  cancelEditing() {
    this.setState({
      text: new RichTeX(this.props.text),
      editing: false,
    });
  }

  render() {
    return (
      <div>
        {this.props.text ? this.props.header || '' : this.props.emptyHeader || 'No content provided...'}&nbsp;
        {this.props.editable && !this.state.editing && (
          <OverlayTrigger placement="top" overlay={<Tooltip>{this.props.editLabel || 'Edit'}</Tooltip>}>
            <Button className="close" onClick={this.startEditing}>
              <FontAwesomeIcon icon="edit" size="xs" />
            </Button>
          </OverlayTrigger>
        )}
        <span className="question-text">
          {(this.props.text || this.state.editing) && (
            <>
              <RichTeXEditor
                className="static-editor"
                value={this.state.text}
                size={this.props.size || 'large'}
                onChange={this.onTextChanged}
                readOnly={!this.props.editable || !this.state.editing}
              />
            </>
          )}

          {this.state.editing && (
            <span>
              <OverlayTrigger placement="top" overlay={<Tooltip>{this.props.saveLabel || 'Save'}</Tooltip>}>
                <Button className="close" onClick={this.onSave}>
                  <FontAwesomeIcon icon="check" size="xs" />
                </Button>
              </OverlayTrigger>
              &nbsp;
              <OverlayTrigger placement="top" overlay={<Tooltip>{this.props.cancelLabel || 'Cancel'}</Tooltip>}>
                <Button className="close" onClick={this.cancelEditing}>
                  <FontAwesomeIcon icon="times" size="xs" />
                </Button>
              </OverlayTrigger>
            </span>
          )}
        </span>
      </div>
    );
  }
}

TextEditor.propTypes = {
  text: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  emptyHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  size: PropTypes.string,
  editLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default TextEditor;
