import { Collection, QuestionDto, QuestionUpdateDto } from '../model';
import fetch from '../util/fetch';

export const fetchQuestions = async (examId: number, bankId: number, options = {}): Promise<Collection<QuestionDto>> => {
  const params = new URLSearchParams(options).toString();
  const path = `/api/exams/${examId}/banks/${bankId}/questions?${params}`;
  const response = await fetch<Array<QuestionDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const fetchQuestionById = async (examId: number, bankId: number, questionId: number): Promise<QuestionDto> => {
  const path = `/api/exams/${examId}/banks/${bankId}/questions/${questionId}`;
  const response = await fetch<QuestionDto>(path);
  return response.body;
};

export const createQuestion = async (examId: number, bankId: number, question: QuestionDto): Promise<QuestionDto> => {
  const path = `/api/exams/${examId}/banks/${bankId}/questions`;
  const response = await fetch<QuestionDto, QuestionDto>(path, {
    method: 'POST',
    body: question,
  });
  return response.body;
};

export const updateQuestion = async (
  examId: number,
  bankId: number,
  questionId: number,
  question: QuestionUpdateDto,
): Promise<QuestionDto> => {
  const path = `/api/exams/${examId}/banks/${bankId}/questions/${questionId}`;
  const response = await fetch<QuestionDto, QuestionUpdateDto>(path, {
    method: 'PATCH',
    body: question,
  });
  return response.body;
};

export const deleteQuestion = async (examId: number, bankId: number, questionId: number): Promise<void> => {
  const path = `/api/exams/${examId}/banks/${bankId}/questions/${questionId}`;
  await fetch(path, {
    method: 'DELETE',
  });
};
