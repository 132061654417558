import autoBind from 'auto-bind';
import React, { ChangeEvent, Component, ComponentType } from 'react';

import { QuestionType, SittingQuestionDto, UploadResourceDto } from '../../model';
import CodeQuestionEditor, { CodeQuestionEditorModelProps } from './CodeQuestionEditor';
import EssayQuestionEditor, { EssayQuestionEditorModelProps } from './EssayQuestionEditor';
import FileQuestionEditor, { FileQuestionEditorModelProps } from './FileQuestionEditor';
import McaQuestionEditor, { McaQuestionEditorModelProps } from './McaQuestionEditor';
import MpQuestionEditor, { MpQuestionEditorModelProps } from './MpQuestionEditor';
import NoAnswerQuestionEditor, { NoAnswerQuestionEditorModelProps } from './NoAnswerQuestionEditor';
import NumericQuestionEditor, { NumericQuestionEditorModelProps } from './NumericQuestionEditor';
import { QuestionEditorMode, QuestionEditorModelProps, QuestionEditorProps } from './QuestionEditorModel';
import ScaQuestionEditor, { ScaQuestionEditorModelProps } from './ScaQuestionEditor';
import ShortTextQuestionEditor, { ShortTextQuestionEditorModelProps } from './ShortTextQuestionEditor';

/**
 * Mappings
 */
const questionTypeMappings = {
  noanswer: {
    prettyText: 'Just question text',
    modelPropsClass: NoAnswerQuestionEditorModelProps,
    editorElement: NoAnswerQuestionEditor,
  },
  shorttext: {
    prettyText: 'Short text answer',
    modelPropsClass: ShortTextQuestionEditorModelProps,
    editorElement: ShortTextQuestionEditor,
  },
  numeric: {
    prettyText: 'Numeric answer',
    modelPropsClass: NumericQuestionEditorModelProps,
    editorElement: NumericQuestionEditor,
  },
  singlecorrect: {
    prettyText: 'Quiz with single correct answer',
    modelPropsClass: ScaQuestionEditorModelProps,
    editorElement: ScaQuestionEditor,
  },
  multiplecorrect: {
    prettyText: 'Quiz with multiple correct answers',
    modelPropsClass: McaQuestionEditorModelProps,
    editorElement: McaQuestionEditor,
  },
  essay: {
    prettyText: 'Essay',
    modelPropsClass: EssayQuestionEditorModelProps,
    editorElement: EssayQuestionEditor,
  },
  code: {
    prettyText: 'Source code answer',
    modelPropsClass: CodeQuestionEditorModelProps,
    editorElement: CodeQuestionEditor,
  },
  file: {
    prettyText: 'File upload answer',
    modelPropsClass: FileQuestionEditorModelProps,
    editorElement: FileQuestionEditor,
  },
  multipart: {
    prettyText: 'Question with multiple sub-questions',
    modelPropsClass: MpQuestionEditorModelProps,
    editorElement: MpQuestionEditor,
  },
};

/**
 * General alias
 */

export type GeneralQuestionEditorModelProps = QuestionEditorModelProps<SittingQuestionDto>;
export type GeneralQuestionEditorProps = QuestionEditorProps<SittingQuestionDto, GeneralQuestionEditorModelProps>;

/**
 * Main model
 */

export class QuestionEditorModel {
  modelProps: GeneralQuestionEditorModelProps;

  constructor(question?: SittingQuestionDto) {
    if (!question) {
      this.modelProps = new NoAnswerQuestionEditorModelProps({
        id: null,
        questionType: 'noanswer',
        questionContent: 'New question',
      });

      if (localStorage.cvqPreferredQuestionType) {
        this.setQuestionType(localStorage.cvqPreferredQuestionType);
      }

      return;
    }

    const ModelPropsClass = questionTypeMappings[question.questionType].modelPropsClass;
    this.modelProps = new ModelPropsClass(question as never);
  }

  getQuestion(): SittingQuestionDto {
    return this.modelProps.getQuestion();
  }

  setQuestionType(newQuestionType: QuestionType): void {
    const oldQuestion = this.getQuestion();
    const oldQuestionType = oldQuestion.questionType;

    if (oldQuestionType === newQuestionType) {
      return;
    }

    localStorage.cvqPreferredQuestionType = newQuestionType;

    const ModelPropsClass = questionTypeMappings[newQuestionType].modelPropsClass;
    this.modelProps = new ModelPropsClass(null as never, oldQuestion);
  }
}

/**
 * Main props & component
 */

export interface MainQuestionEditorProps {
  mode: QuestionEditorMode;
  model: QuestionEditorModel;
  onChange?: (model: QuestionEditorModel) => unknown;
  allowFileUpload?: boolean;
  onUpload?: (resource: UploadResourceDto) => void;
  streaming?: boolean;
}

export default class QuestionEditor extends Component<MainQuestionEditorProps> {
  constructor(props: MainQuestionEditorProps) {
    super(props);
    autoBind(this);
  }

  onChange(modelProps: GeneralQuestionEditorModelProps): void {
    const { model, onChange } = this.props;
    model.modelProps = modelProps;
    onChange(model);
  }

  onModeChange(event: ChangeEvent<HTMLSelectElement>): void {
    const newQuestionType = event.target.value as QuestionType;
    const { model, onChange } = this.props;
    model.setQuestionType(newQuestionType);
    onChange(model);
  }

  render(): JSX.Element {
    const { mode, model, onUpload, allowFileUpload, streaming } = this.props;
    const { questionType } = model.modelProps.question;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const EditorElement: ComponentType<any> = questionTypeMappings[questionType].editorElement;
    const mainElement = (
      <EditorElement mode={mode} onChange={this.onChange} onUpload={onUpload} allowFileUpload={allowFileUpload} model={model.modelProps} />
    );

    return (
      <>
        <div className="eventmsg float-left" style={{ paddingTop: '8px', paddingBottom: '8px' }}>
          <b>Question type:</b>&nbsp;
          {mode !== 'edit' && (
            <>
              {questionTypeMappings[questionType].prettyText}
              <br />
            </>
          )}
          {mode === 'edit' && (
            <>
              <select value={questionType} onChange={this.onModeChange} required>
                {Object.entries(questionTypeMappings).map(([newQuestionType, { prettyText }]) => (
                  <option key={newQuestionType} value={newQuestionType}>
                    {prettyText}
                  </option>
                ))}
              </select>
              <br />
            </>
          )}
          {mode === 'edit' && questionType === 'noanswer' && !streaming && (
            <>
              <div className="errormsg">
                * No response expected, but current bank does not stream video. Are your sure this is the intended behavior?
              </div>
            </>
          )}
        </div>

        {mainElement}
      </>
    );
  }
}
