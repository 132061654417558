import {
  CodeQuestionContent,
  McaQuestionContent,
  McaQuestionEvaluationKey,
  MpQuestionContent,
  MpQuestionEvaluationKey,
  MpQuestionEntryType,
  NumericQuestionEvaluationKey,
  QuestionDto,
  ScaQuestionContent,
  ShortTextQuestionContent,
  ShortTextQuestionEvaluationKey,
} from './question';
import { AnswerStartPoint } from './questionbank';

/**
 * Sitting question response
 */

export type FileSittingQuestionResponseEntry = {
  name: string;
  size: number;
  location: string;
};

export type FileSittingQuestionResponse = {
  [key: string]: FileSittingQuestionResponseEntry;
};

export type McaSittingQuestionResponse = {
  [key: string]: boolean;
};

export type AbstractMpSittingQuestionResponseEntry = {
  type: MpQuestionEntryType;
};

export type MpSittingQuestionResponseShortTextEntry = AbstractMpSittingQuestionResponseEntry & {
  type: 'shorttext';
  response: string;
};

export type MpSittingQuestionResponseNumericEntry = AbstractMpSittingQuestionResponseEntry & {
  type: 'numeric';
  response: number;
};

export type MpSittingQuestionResponseScaEntry = AbstractMpSittingQuestionResponseEntry & {
  type: 'singlecorrect';
  selectedKey: string;
};

export type MpSittingQuestionResponseMcaEntry = AbstractMpSittingQuestionResponseEntry & {
  type: 'multiplecorrect';
  selectedKeys: McaSittingQuestionResponse;
};

export type MpSittingQuestionResponseEntry =
  | MpSittingQuestionResponseShortTextEntry
  | MpSittingQuestionResponseNumericEntry
  | MpSittingQuestionResponseScaEntry
  | MpSittingQuestionResponseMcaEntry;

export type MpSittingQuestionResponse = {
  [key: string]: MpSittingQuestionResponseEntry;
};

export type SittingQuestionResponse =
  | string
  | number
  | FileSittingQuestionResponse
  | McaSittingQuestionResponse
  | MpSittingQuestionResponse;

/**
 * Sitting questions
 */

export type SittingQuestionState = 'unacknowledged' | 'resumable_ack' | 'acknowledged' | 'answered';

export const sittingQuestionStateMappings = {
  unacknowledged: {
    prettyName: 'Unacknowledged',
    faIcon: 'eye-slash',
  },
  resumable_ack: {
    prettyName: 'Resumabley acknowledged',
    faIcon: 'low-vision',
  },
  acknowledged: {
    prettyName: 'Acknowledged',
    faIcon: 'eye',
  },
  answered: {
    prettyName: 'Answered',
    faIcon: 'clipboard-check',
  },
};

export interface SittingQuestionDto extends QuestionDto {
  questionId?: number;
  questionOrder?: number;
  possiblePoints?: number;
  state?: SittingQuestionState;
  timeRemaining?: number;
  totalTime?: number;
  minTime?: number;
  resumable?: boolean;
  answerStartPoint?: AnswerStartPoint;
  answerStartStreamingKey?: string;
  answerStartTime?: number;

  response?: SittingQuestionResponse;

  // graded
  points?: number;
  comment?: string;
  commenterId?: number;
  commenterName?: string;
  commentTime?: string;

  // with student name
  sittingId?: number;
  studentId?: number;
  studentName?: string;
  streamingPath?: string;
}

/**
 * Custom sitting question content
 */
export type FileSittingQuestionContent = {
  text: string;
  files: {
    [key: string]: {
      entryKey: string;
      maxFileSize: number;
      acceptedExtensions: string;
      uploadObjectPrefix: string;
    };
  };
};

/**
 * Sitting questions concrete
 */

export interface NoAnswerSittingQuestionDto extends SittingQuestionDto {
  questionType: 'noanswer';
  questionContent: string;
}

export interface EssaySittingQuestionDto extends SittingQuestionDto {
  questionType: 'essay';
  questionContent: string;
  response?: string;
}

export interface CodeSittingQuestionDto extends SittingQuestionDto {
  questionType: 'code';
  questionContent: CodeQuestionContent;
  response?: string;
}

export interface FileSittingQuestionDto extends SittingQuestionDto {
  questionType: 'file';
  questionContent: FileSittingQuestionContent;
  response?: FileSittingQuestionResponse;
}

export interface McaSittingQuestionDto extends SittingQuestionDto {
  questionType: 'multiplecorrect';
  questionContent: McaQuestionContent;
  evaluationKey: McaQuestionEvaluationKey;
  response?: McaSittingQuestionResponse;
}

export interface ScaSittingQuestionDto extends SittingQuestionDto {
  questionType: 'singlecorrect';
  questionContent: ScaQuestionContent;
  evaluationKey: string;
  response?: string;
}

export interface ShortTextSittingQuestionDto extends SittingQuestionDto {
  questionType: 'shorttext';
  questionContent: ShortTextQuestionContent;
  evaluationKey: ShortTextQuestionEvaluationKey;
  response?: string;
}

export interface NumericSittingQuestionDto extends SittingQuestionDto {
  questionType: 'numeric';
  questionContent: string;
  evaluationKey: NumericQuestionEvaluationKey;
  response?: number;
}

export interface MpSittingQuestionDto extends SittingQuestionDto {
  questionType: 'multipart';
  questionContent: MpQuestionContent;
  evaluationKey: MpQuestionEvaluationKey;
  response?: MpSittingQuestionResponse;
}
