import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AsyncComponent from './AsyncComponent';
import CredentialsContext from '../../context/credentials';

export default function withErrorScreen(WrappedComponent) {
  const WithErrorScreen = class extends AsyncComponent {
    constructor(props) {
      super(props);

      this.state = {
        show: false,
        message: '',
        autoClose: false,
        keepTrying: false,
        error: {},
      };

      this.showError = this.showError.bind(this);
      this.close = this.close.bind(this);
    }

    async showError(error, autoClose, keepTrying, onClose) {
      await this.setStateAsync({
        show: true,
        error,
        message: error.message || 'Unknown error',
        autoClose,
        keepTrying,
      });

      if (!error.getModalFooter && autoClose) {
        setTimeout(() => this.close(undefined, onClose), 5000);
      }
    }

    async close(event, callback) {
      await this.setStateAsync({ show: false });
      if (callback) {
        callback();
      }
    }

    render() {
      const { show, error, message, autoClose, keepTrying } = this.state;
      const CustomErrorFooter = error.getModalFooter ? error.getModalFooter() : undefined;

      const { onLogout } = this.context;

      return (
        <span>
          <WrappedComponent showError={this.showError} {...this.props} />

          <Modal show={show} onHide={this.close}>
            <Modal.Header closeButton={!autoClose}>
              <Modal.Title>An error occurred</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
              {error.getModalFooter && (
                <>
                  <CustomErrorFooter onLogout={onLogout}></CustomErrorFooter>
                </>
              )}

              {!error.getModalFooter && (
                <>
                  {/* close button */}
                  {!autoClose && (
                    <>
                      <div className="btn-group">
                        <Button variant="danger" onClick={this.close}>
                          <FontAwesomeIcon icon="times" />
                          &nbsp;Close
                        </Button>
                      </div>
                    </>
                  )}
                  {/* retying message */}
                  {keepTrying && (
                    <>
                      <div className="btn-group">
                        <FontAwesomeIcon icon="stopwatch" />
                        &nbsp;
                        <i>Retrying in a few seconds...</i>
                      </div>
                    </>
                  )}
                </>
              )}
            </Modal.Footer>
          </Modal>
        </span>
      );
    }
  };

  WithErrorScreen.displayName = 'WithErrorScreen';
  WithErrorScreen.contextType = CredentialsContext;
  return WithErrorScreen;
}
