import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';

import KatexOutput from '../KatexOutput';
import ImageWithFallback from '../ImageWithFallback';

import { themes } from '../../../util/ace';
import CredentialsContext from '../../../context/credentials';

export class AceCodeBlock extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { blockProps } = this.props;
    if (!blockProps.readOnly) {
      blockProps.onClick(blockProps.language, blockProps.text);
    }
  }

  render() {
    const { language, text, readOnly, inline } = this.props.blockProps;
    let numLines = text.split(/\r\n|\r|\n/).length;

    if (!inline) {
      numLines += 1; // show 1 extra line for padding when editing
    }

    const editor = (
      <>
        <AceEditor
          className="inline-code-editor"
          mode={language}
          theme={this.context.darkMode ? themes.dark : themes.light}
          fontSize={16}
          minLines={numLines}
          maxLines={numLines}
          style={{ pointerEvents: !readOnly && 'none' }}
          value={text}
          showGutter={!inline}
          showPrintMargin={false}
          readOnly={true}
          highlightActiveLine={false}
        />
      </>
    );

    if (readOnly) {
      return editor;
    }

    return (
      <>
        <span style={{ cursor: 'pointer' }} onClick={this.onClick}>
          {editor}
        </span>
      </>
    );
  }
}

AceCodeBlock.contextType = CredentialsContext;

AceCodeBlock.propTypes = {
  blockProps: PropTypes.shape({
    readOnly: PropTypes.bool,
    language: PropTypes.string.isRequired,
    inline: PropTypes.bool,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  block: PropTypes.object.isRequired,
  contentState: PropTypes.object.isRequired,
};

export class TeXBlock extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { blockProps } = this.props;
    if (!blockProps.readOnly) {
      blockProps.onClick(blockProps.texText);
    }
  }

  render() {
    const { readOnly, texText, inline } = this.props.blockProps;

    if (readOnly) {
      return <KatexOutput texText={texText} inline={inline} />;
    }
    return <KatexOutput texText={texText} onClick={this.onClick} inline={inline} style={{ cursor: 'pointer' }} />;
  }
}

TeXBlock.propTypes = {
  blockProps: PropTypes.shape({
    readOnly: PropTypes.bool,
    texText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    inline: PropTypes.bool,
  }).isRequired,
  block: PropTypes.object.isRequired,
  contentState: PropTypes.object.isRequired,
};

export class ImageBlock extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { blockProps } = this.props;
    if (!blockProps.readOnly) {
      blockProps.onClick(blockProps.src, blockProps.alt);
    }
  }

  render() {
    const { readOnly, src, alt } = this.props.blockProps;

    if (readOnly) {
      return <ImageWithFallback src={src} alt={alt} />;
    }
    return (
      <span onClick={this.onClick} style={{ cursor: 'pointer' }}>
        <ImageWithFallback src={src} alt={alt} />
      </span>
    );
  }
}

ImageBlock.propTypes = {
  blockProps: PropTypes.shape({
    readOnly: PropTypes.bool,
    src: PropTypes.string,
    alt: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  block: PropTypes.object.isRequired,
  contentState: PropTypes.object.isRequired,
};
