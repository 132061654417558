import React from 'react';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { shortId } from '../../util/random';
import { SortingOptions } from '../../model/options';

interface SortByProps {
  options: SortingOptions;
  keys: {
    [key: string]: string;
  };
  onChange: (options: Partial<SortingOptions>) => void;
}

export default function SortBy({ options, keys, onChange }: SortByProps) {
  const { sortBy, sortDirection } = options;

  const title = (
    <>
      Sort by: <i>{keys[sortBy]}</i>&nbsp;
      <FontAwesomeIcon icon={sortDirection === 'asc' ? 'sort-amount-down-alt' : 'sort-amount-down'} size="xs" />
    </>
  );

  return (
    <SplitButton
      id={`sortby-${shortId()}`}
      className="dynamic-sort"
      size="sm"
      variant="outline-secondary"
      as="span"
      title={title}
      onClick={() => onChange({ sortDirection: sortDirection === 'asc' ? 'desc' : 'asc' })}
    >
      {Object.entries(keys).map(([key, text]) => (
        <span className="likeALink" key={key} onClick={() => onChange({ sortBy: key })}>
          <Dropdown.Item eventKey="studentName" active={key === sortBy}>
            {text}
          </Dropdown.Item>
        </span>
      ))}
    </SplitButton>
  );
}
