import { useNumQuestions, usePoints, useStreamingQuality } from '../../hooks/useFormatted';
import useRichTeX from '../../hooks/useRichTeX';
import { ExamDetailsDto } from '../../model';
import { If } from '../util/conditional';
import { CheckboxPanelEntry, DatePanelEntry, IntervalPanelEntry, PanelEntry } from '../util/Panel';
import { RichTeXEditor } from '../util/RichTeXEditor';

type ExamDetailsPanelProps = {
  exam: ExamDetailsDto;
};

export default function ExamDetailsPanel({ exam }: ExamDetailsPanelProps) {
  const assignment = exam.jsonMetadata?.assignment;
  const description = useRichTeX(exam.description);
  const numQuestions = useNumQuestions(exam.numQuestions);
  const totalPoints = usePoints(exam.totalPoints);
  const streamingQuality = useStreamingQuality(exam.streamingQuality);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="jumbotron">
          <div className="description">
            <RichTeXEditor value={description} readOnly />
          </div>
          <dl className="examdescription">
            <PanelEntry name="ExamType" icon="edit" value={exam.examType} />
            <PanelEntry name="Group" icon="university" value={exam.group.name} to={`/exams/?groupId=${exam.group.id}`} />
            <PanelEntry name="Assignment" icon="tasks" visible={Boolean(assignment)} value={assignment?.name} />
            <DatePanelEntry name="Start time" icon="calendar" value={exam.startTime} />
            <DatePanelEntry name="Hard start time" icon="calendar-plus" value={exam.hardStartTime} />
            <DatePanelEntry name="End time" icon="calendar-times" value={exam.endTime} />
            <IntervalPanelEntry name="Enrolling possible before" icon="history" value={exam.cutoffTime} />
            <IntervalPanelEntry name="Resumability time" icon="step-forward" value={exam.resumableTime} />
            <CheckboxPanelEntry name="Redoable" icon="redo" value={exam.redoable} />

            <If c={exam.examType === 'interactive' || exam.examType === 'continuous'}>
              <CheckboxPanelEntry name="Auto-release points when evaluated" icon="sync" value={exam.autoReleasePoints} />
              <CheckboxPanelEntry name="Students can see questions" icon="eye" value={exam.studentsCanSeeBanks} />
              <CheckboxPanelEntry name="Streaming answers" icon="video" value={exam.streaming} />

              <If c={exam.streaming}>
                <CheckboxPanelEntry name="Students can see their own streams" icon="eye" value={exam.studentsCanSeeStreams} />
                <PanelEntry name="Streaming quality" icon="film" value={streamingQuality} />
              </If>

              <If c={exam.examType === 'interactive'}>
                <IntervalPanelEntry name="Grace period" icon="hourglass" value={exam.gracePeriod} />
              </If>

              <PanelEntry name="Number of questions" icon="hashtag" value={numQuestions} />
              <PanelEntry name="Points" icon="trophy" value={totalPoints} />
            </If>

            <If c={exam.examType === 'continuous'}>
              <CheckboxPanelEntry name="Accumulate remaining time" icon="hourglass-half" value={exam.accumulateRemainingTime} />
              <If c={!exam.accumulateRemainingTime}>
                <IntervalPanelEntry name="Pause between questions" icon="pause-circle" value={exam.pauseBetweenQuestions} />
              </If>
              <CheckboxPanelEntry name="Random bank order" icon="random" value={exam.randomBankOrder} />
              <IntervalPanelEntry name="Total estimated time" icon="clock" value={exam.totalTime} />
            </If>
          </dl>
        </div>
      </div>
    </div>
  );
}
