import React, { Component, ComponentType } from 'react';
import autoBind from 'auto-bind';
import { Link } from 'react-router-dom';

export type Breadcrumb = {
  text: string;
  path: string;
};

export type WithBreadcrumbsProps = {
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
};

type WithBreadcrumbsState = {
  breadcrumbs: Breadcrumb[];
};

export default function withBreadcrumbs<P>(WrappedComponent: ComponentType<P>): ComponentType<P & WithBreadcrumbsProps> {
  const WithBreadcrumbs = class extends Component<P & WithBreadcrumbsProps, WithBreadcrumbsState> {
    displayName = 'WithBreadcrumbs';

    constructor(props) {
      super(props);

      this.state = { breadcrumbs: [] };

      autoBind(this);
    }

    setBreadcrumbs(breadcrumbs) {
      this.setState({ breadcrumbs });
    }

    render() {
      const { breadcrumbs } = this.state;

      return (
        <span>
          <div className="row">
            <div className="col-md-12">
              <ol className="breadcrumb">
                {breadcrumbs.map(({ text, path }, key) =>
                  key + 1 === breadcrumbs.length ? (
                    <li key={`${text}-${path}`} className="breadcrumb-item active">
                      {text}
                    </li>
                  ) : (
                    <li key={`${text}-${path}`} className="breadcrumb-item">
                      <Link to={path}>{text}</Link>
                    </li>
                  ),
                )}
              </ol>
            </div>
          </div>

          <WrappedComponent setBreadcrumbs={this.setBreadcrumbs} {...this.props} />
        </span>
      );
    }
  };

  return WithBreadcrumbs;
}
