import fetch from '../util/fetch';
import { Collection, UserAffiliationDto } from '../model';
import { toUrlParams } from '../util/queryparser';
import { AffiliationFilterOptions, defaultAffiliationOptions } from './affiliation';

const fetchUserAffiliations = async (userId: number, options: AffiliationFilterOptions = {}): Promise<Collection<UserAffiliationDto>> => {
  const path = `/api/users/${userId}/groups?${toUrlParams(options, defaultAffiliationOptions)}`;
  const response = await fetch<Array<UserAffiliationDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export default fetchUserAffiliations;
