import { useMemo } from 'react';
import { AnswerStartPoint, answerStartPointMappings, StreamingQuality, streamingQualityMappings } from '../model';

export function useNumQuestions(numQuestions: number) {
  return useMemo(() => {
    if (numQuestions == null || Number.isNaN(numQuestions)) {
      return 'Unknown number of questions';
    }

    return `${numQuestions} question${numQuestions !== 1 ? 's' : ''}`;
  }, [numQuestions]);
}

export function usePoints(points: number, suffix = '') {
  return useMemo(() => {
    if (points == null || Number.isNaN(points)) {
      return `Unknown points${suffix}`;
    }

    return `${points} point${points !== 1 ? 's' : ''}${suffix}`;
  }, [points, suffix]);
}

export function useStreamingQuality(streamingQuality: StreamingQuality) {
  return useMemo(() => {
    return streamingQualityMappings[streamingQuality]?.prettyName || 'Unknown';
  }, [streamingQuality]);
}

export function useAnswerStartPoint(answerStartPoint: AnswerStartPoint) {
  return useMemo(() => {
    return answerStartPointMappings[answerStartPoint] || 'Unknown';
  }, [answerStartPoint]);
}
