import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import autoBind from 'auto-bind';
import { isEqual } from 'lodash';

import SittingQuestionNavigationEntry from './SittingQuestionNavigationEntry';
import { SittingQuestionDto } from '../../model';
import { Streams } from '../../model/filestorage';
import { fetchStreams } from '../../service/filestorage';

type SittingQuestionNavigatorProps = {
  questions: SittingQuestionDto[];
  streamingPath?: string;
  blocking: boolean;
  maintainer: boolean;
  pointsReleased: boolean;
  showStudentName?: boolean;
  showQuestionBank?: boolean;
  showQuestionOrder?: boolean;
  onUpdateSittingQuestionPoints: (event: { sittingQuestionId: number; points: number }) => Promise<void>;
};

type SittingQuestionNavigatorState = {
  streams?: Streams;
};

class SittingQuestionNavigator extends Component<SittingQuestionNavigatorProps, SittingQuestionNavigatorState> {
  constructor(props: SittingQuestionNavigatorProps) {
    super(props);

    this.state = {
      streams: {},
    };

    autoBind(this);
  }

  async componentDidMount(): Promise<void> {
    if (this.props.streamingPath) {
      const streams = (await fetchStreams(this.props.streamingPath)) || {};
      this.setState({ streams });
    }
  }

  async componentDidUpdate(prevProps: SittingQuestionNavigatorProps): Promise<void> {
    if (prevProps.streamingPath !== this.props.streamingPath || !isEqual(prevProps.questions, this.props.questions)) {
      if (this.props.streamingPath) {
        const streams = (await fetchStreams(this.props.streamingPath)) || {};
        this.setState({ streams });
      }
    }
  }

  render(): JSX.Element {
    if (!this.props.questions.length) {
      return <div className="infomsg">No questions to show</div>;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <table className="table table-hover">
            <tbody>
              {this.props.questions.map((question) => (
                <tr key={question.id}>
                  <BlockUi tag="td" blocking={this.props.blocking}>
                    <SittingQuestionNavigationEntry
                      {...this.props}
                      question={question}
                      questionStreams={this.state.streams[question.questionId]}
                    />
                  </BlockUi>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default SittingQuestionNavigator;
