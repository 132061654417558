import React from 'react';
import { UserDto } from '../../model';
import UserListEntry from './UserListEntry';

type UserListProps = {
  users: UserDto[];
  onUpdate: (user: UserDto) => void;
  onDelete: (userId: number) => void;
};

export default function UserList({ users, onUpdate, onDelete }: UserListProps) {
  if (!users.length) {
    return <div className="infomsg">No users to show</div>;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <table className="table table-hover">
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>
                  <UserListEntry user={user} onUpdate={() => onUpdate(user)} onDelete={() => onDelete(user.id)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
