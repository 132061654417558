import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Form, Modal } from 'react-bootstrap';
import CredentialsContext, { CredentialsContextType } from '../../context/credentials';
import { UserCreationDto, UserDto, UserUpdateDto } from '../../model';
import UserIdentityList from './UserIdentityList';

/**
 * User information editing modal
 */

type EditUserModalProps = {
  editMode: boolean;
  show: boolean;
  user?: UserDto;
  onCreate?: (user: UserCreationDto) => void;
  onUpdate: (desc: { userId: number; user: UserUpdateDto }) => void;
  onCancel: () => void;
};

export default function EditUserModal({
  editMode,
  show,
  user: initialUser = null,
  onCreate = null,
  onUpdate,
  onCancel,
}: EditUserModalProps) {
  const { userId, admin, darkMode } = useContext<CredentialsContextType>(CredentialsContext);

  const [user, setUser] = useState<UserCreationDto>(() => {
    if (editMode) {
      return { ...initialUser };
    }

    return {
      name: initialUser?.name || 'User name',
      admin: false,
    };
  });

  useEffect(() => {
    if (editMode) {
      setUser({ ...initialUser });
    } else {
      setUser({
        name: initialUser?.name || 'User name',
        admin: false,
      });
    }
  }, [editMode, initialUser]);

  const onValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const value = event.target.type === 'checkbox' ? target.checked : target.value;
      const key = target.name;

      setUser((u) => ({
        ...u,
        [key]: value,
      }));
    },
    [setUser],
  );

  const onConfirm = useCallback(() => {
    if (editMode) {
      onUpdate({
        userId: initialUser.id,
        user,
      });
    } else {
      onCreate(user);
    }
  }, [user]);

  const isCurrentUser = editMode && userId === initialUser.id;

  return (
    <Modal show={show} onHide={onCancel} dialogClassName={darkMode ? 'cvqDarkMode' : 'cvqLightMode'}>
      <Modal.Header closeButton>
        <Modal.Title>{editMode ? 'Edit user' : 'New user'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" name="name" onChange={onValueChange} value={user.name} disabled={!admin} />
        </Form.Group>
        <Form.Check
          type="checkbox"
          id="admin"
          name="admin"
          checked={user.admin}
          onChange={onValueChange}
          disabled={isCurrentUser}
          label={
            <>
              This user has <b>administrator</b> access
            </>
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="outline-secondary" onClick={onCancel}>
            <FontAwesomeIcon icon="times" />
            &nbsp;Cancel
          </Button>
          <Button variant="success" onClick={onConfirm}>
            <FontAwesomeIcon icon="check" />
            &nbsp;Confirm
          </Button>
        </ButtonGroup>
      </Modal.Footer>
      {editMode && (
        <>
          <Modal.Header>
            <Modal.Title>User Identities</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UserIdentityList user={user} editMode={editMode} />
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}
