import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

type QuestionListHeaderProps = {
  createDisabled?: boolean;
  onNewQuestionRequest: () => unknown;
};

export function QuestionListHeader({ createDisabled = false, onNewQuestionRequest }: QuestionListHeaderProps) {
  return (
    <div className="row">
      <div className="col-md-12 titlebar">
        <div className="float-left">
          <h3>Questions</h3>
        </div>
        <div className="float-right btn-group">
          <Button disabled={createDisabled} variant="success" onClick={onNewQuestionRequest}>
            <FontAwesomeIcon icon="plus" />
            <span>&nbsp;Create</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
