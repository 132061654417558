import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import React from 'react';
import CredentialsContext, { CredentialsContextType } from '../../context/credentials';

import { GroupFilterOptions } from '../../service/group';
import AsyncComponent from '../util/AsyncComponent';
import OverlayButton from '../util/OverlayButton';
import SearchBy from '../util/SearchBy';
import SortBy from '../util/SortBy';
import withErrorScreen from '../util/withErrorScreen';

type GroupListHeaderProps = {
  options: GroupFilterOptions;
  onOptionsChange: (options: Partial<GroupFilterOptions>) => void;
};

class GroupListHeader extends AsyncComponent<GroupListHeaderProps> {
  context: CredentialsContextType;

  constructor(props: GroupListHeaderProps) {
    super(props);

    this.state = {};

    autoBind(this);
  }

  render(): JSX.Element {
    const { options, onOptionsChange } = this.props;
    const { admin } = this.context;

    return (
      <div className="row">
        <div className="col-md-12 titlebar">
          <h3>Groups</h3>

          <div className="float-left">
            <SortBy
              options={options}
              onChange={onOptionsChange}
              keys={{
                name: 'Name',
              }}
            />
            <SearchBy
              queryKey="name"
              term={options.name}
              onChange={onOptionsChange}
              placeholder="Search by group name..."
              resetPage={true}
            />
          </div>

          {admin && (
            <>
              <div className="float-right btn-group">
                <OverlayButton variant="success" to="/groups/new">
                  <FontAwesomeIcon icon="plus" />
                  &nbsp;Create
                </OverlayButton>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

GroupListHeader.contextType = CredentialsContext;

export default withErrorScreen(GroupListHeader);
