import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConfirmModal, DeleteModal } from '../util/Modals';
import withErrorScreen from '../util/withErrorScreen';
import OverlayButton from '../util/OverlayButton';
import { ExamDetailsDto, SittingDetailsDto } from '../../model';

type SittingDetailsHeaderProps = {
  exam: ExamDetailsDto;
  sitting: SittingDetailsDto;
  maintainer: boolean;
  prohibitMessage?: string;
  resumable?: boolean;
  onConcludeSitting: () => void;
  onUnreleasePoints: () => void;
  onReleasePoints: () => void;
  onUploadPointsToCanvas?: () => void;
  onAllowResume: () => void;
  onDelete: () => void;
};

type SittingDetailsHeaderState = {
  showDeleteModal: boolean;
  showConcludeModal: boolean;
};

class SittingDetailsHeader extends Component<SittingDetailsHeaderProps, SittingDetailsHeaderState> {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal: false,
      showConcludeModal: false,
    };

    autoBind(this);
  }

  proposeDeleteSitting() {
    this.setState({ showDeleteModal: true });
  }

  cancelDeleteSitting() {
    this.setState({ showDeleteModal: false });
  }

  confirmDeleteSitting() {
    this.setState({ showDeleteModal: false });
    this.props.onDelete();
  }

  proposeConcludeSitting(): void {
    this.setState({ showConcludeModal: true });
  }

  cancelConcludeSitting(): void {
    this.setState({ showConcludeModal: false });
  }

  confirmConcludeSitting(): void {
    this.setState({ showConcludeModal: false });
    this.props.onConcludeSitting();
  }

  render() {
    const { exam, sitting, prohibitMessage, resumable, maintainer } = this.props;
    const canUploadToCanvas = Boolean(sitting.studentProviderSpecificId && exam.group.providerName && exam.jsonMetadata?.assignment);
    const finished = Boolean(sitting.finishTime);

    return (
      <div className="row">
        <div className="col-md-12 titlebar">
          <div className="float-left">
            <h3>Sitting {sitting.id}</h3>
          </div>
          <div className="float-right btn-group">
            {/* student buttons */}
            {!maintainer && (
              <>
                <OverlayButton
                  variant="success"
                  to="resume"
                  visible={!finished}
                  disabled={Boolean(prohibitMessage)}
                  disabledTooltip={prohibitMessage}
                >
                  <FontAwesomeIcon icon="step-forward" />
                  &nbsp;Resume
                </OverlayButton>
              </>
            )}

            {/* conclude (common) */}
            <OverlayButton
              variant="outline-warning"
              onClick={this.props.maintainer ? this.props.onConcludeSitting : this.proposeConcludeSitting}
              visible={!finished}
            >
              <FontAwesomeIcon icon="flag-checkered" />
              &nbsp;Conclude
            </OverlayButton>

            {/* maintainer buttons */}
            {maintainer && (
              <>
                {/* release */}
                <OverlayButton
                  variant="outline-success"
                  onClick={this.props.onUnreleasePoints}
                  visible={!sitting.simulated && finished && sitting.pointsReleased}
                  tooltip="Click to revoke points"
                >
                  <FontAwesomeIcon icon="check-circle" />
                  &nbsp;Released
                </OverlayButton>
                <OverlayButton
                  variant="outline-secondary"
                  onClick={this.props.onReleasePoints}
                  visible={!sitting.simulated && finished && !sitting.pointsReleased}
                  tooltip="Click to release points"
                >
                  <FontAwesomeIcon icon="times-circle" />
                  &nbsp;Not released
                </OverlayButton>

                <OverlayButton
                  variant="outline-info"
                  onClick={this.props.onAllowResume}
                  visible={!sitting.simulated && !finished && !resumable}
                  tooltip="Sitting blocked, click to allow resume"
                >
                  <FontAwesomeIcon icon="redo" />
                  &nbsp;Allow resume
                </OverlayButton>
                {/* upload to canvas */}
                <OverlayButton
                  variant="outline-info"
                  onClick={this.props.onUploadPointsToCanvas}
                  visible={!sitting.simulated && canUploadToCanvas && finished}
                >
                  <FontAwesomeIcon icon="upload" />
                  &nbsp;Upload to Canvas
                </OverlayButton>

                {/* delete */}
                <Button variant="outline-danger" onClick={this.proposeDeleteSitting}>
                  <FontAwesomeIcon icon="trash" />
                  &nbsp;Delete
                </Button>
              </>
            )}
          </div>
        </div>

        <ConfirmModal
          show={this.state.showConcludeModal}
          onCancel={this.cancelConcludeSitting}
          onConfirm={this.confirmConcludeSitting}
          title="Conclude sitting"
        >
          <div>
            Are you sure you would like to conclude <b>Sitting {this.props.sitting.id}</b>?
          </div>
          <div>No more answers can be provided after this step.</div>
        </ConfirmModal>

        <DeleteModal
          show={this.state.showDeleteModal}
          onCancel={this.cancelDeleteSitting}
          onConfirm={this.confirmDeleteSitting}
          title="Delete sitting"
        >
          <div>Are you sure you would like to delete this sitting?</div>
          <div>All associated questions/answers will be deleted. This action is irreversible.</div>
        </DeleteModal>
      </div>
    );
  }
}

export default withErrorScreen(SittingDetailsHeader);
