import { createContext } from 'react';

export type ErrorDisplayOptions = {
  autoClose?: boolean;
  keepTrying?: boolean;
};

export type ErrorDisplay = {
  error: Error;
  options?: ErrorDisplayOptions;
  onClose?: () => unknown;
};

export type ShowError = (error: ErrorDisplay) => unknown;

const ErrorModalContext = createContext<ShowError>(null);

export default ErrorModalContext;
