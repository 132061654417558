import autoBind from 'auto-bind';
import React, { Component } from 'react';
import { NoAnswerSittingQuestionDto, SittingQuestionDto } from '../../model';
import { RichTeX, RichTeXEditor } from '../util/RichTeXEditor';
import { QuestionEditorModelProps, QuestionEditorProps } from './QuestionEditorModel';

/**
 * Extended model props
 */

export class NoAnswerQuestionEditorModelProps extends QuestionEditorModelProps<NoAnswerSittingQuestionDto> {
  questionText: RichTeX;

  constructor(question: NoAnswerSittingQuestionDto, template?: SittingQuestionDto) {
    super(question, template);
    this.questionText = new RichTeX(this.question.questionContent);
    autoBind(this);
  }

  setQuestionText(questionText: RichTeX): void {
    this.questionText = questionText;
    this.questionUpToDate = false;
  }

  refreshQuestion(): void {
    this.question.questionContent = this.questionText.getMarkdown();
  }

  fromQuestionTemplate(template: SittingQuestionDto): NoAnswerSittingQuestionDto {
    return {
      id: template.id,
      questionType: 'noanswer',
      questionContent: (template.questionContent as { text: string }).text || (template.questionContent as string),
    };
  }
}

/**
 * Alias
 */

export type NoAnswerQuestionEditorProps = QuestionEditorProps<NoAnswerSittingQuestionDto, NoAnswerQuestionEditorModelProps>;

/**
 * Associated components
 */

export default class NoAnswerQuestionEditor extends Component<NoAnswerQuestionEditorProps> {
  constructor(props: NoAnswerQuestionEditorProps) {
    super(props);
    autoBind(this);
  }

  onQuestionEditorChange(questionText: RichTeX): void {
    const { model, onChange } = this.props;
    model.setQuestionText(questionText);
    onChange(model);
  }

  render(): JSX.Element {
    const { mode, model, allowFileUpload, onUpload } = this.props;
    return (
      <span className="question-text">
        <RichTeXEditor
          className="static-editor"
          size="medium"
          value={model.questionText}
          readOnly={mode !== 'edit'}
          onChange={this.onQuestionEditorChange}
          allowFileUpload={allowFileUpload}
          onUpload={onUpload}
        />
      </span>
    );
  }
}
