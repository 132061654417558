import { AuthProviderParams, PasswordResetDto } from '../model/auth';
import { LoginDto } from '../model/login';
import { IntrospectedPayload, StandardUserIdentityDto, StandardUserIdentityCreationDto } from '../model';
import { authServerUrl } from '../util/config';
import fetch from '../util/fetch';

export const getProviders = async (): Promise<AuthProviderParams[]> => {
  const response = await fetch<AuthProviderParams[]>(`${authServerUrl}/auth/providers`);
  return response.body;
};

export const login = async (dto: LoginDto): Promise<IntrospectedPayload> => {
  const response = await fetch<IntrospectedPayload>(`${authServerUrl}/auth/login`, {
    method: 'POST',
    body: dto,
  });
  return response.body;
};

export const refreshToken = async (): Promise<IntrospectedPayload> => {
  const response = await fetch<IntrospectedPayload>(`${authServerUrl}/auth/refresh_token`, { method: 'POST' });
  return response.body;
};

export const logout = async (): Promise<void> => {
  await fetch(`${authServerUrl}/auth/logout`, { method: 'POST' });
};

export const resetPassword = async (dto: PasswordResetDto): Promise<void> => {
  await fetch(`${authServerUrl}/auth/password`, {
    method: 'PATCH',
    body: dto,
  });
};

export const setPassword = async (dto: StandardUserIdentityCreationDto): Promise<StandardUserIdentityDto> => {
  const response = await fetch<StandardUserIdentityDto>(`${authServerUrl}/auth/password`, {
    method: 'PUT',
    body: dto,
  });
  return response.body;
};
