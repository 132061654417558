import fetch from '../util/fetch';
import { Collection, GroupAffiliationCreationDto, GroupAffiliationDto, GroupAffiliationUpdateDto } from '../model';
import { toUrlParams } from '../util/queryparser';
import { AffiliationFilterOptions, defaultAffiliationOptions } from './affiliation';

export const fetchGroupAffiliations = async (
  groupId: number,
  options: AffiliationFilterOptions = {},
): Promise<Collection<GroupAffiliationDto>> => {
  const path = `/api/groups/${groupId}/users?${toUrlParams(options, defaultAffiliationOptions)}`;
  const response = await fetch<Array<GroupAffiliationDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const createGroupAffiliation = async (groupId: number, affiliation: GroupAffiliationCreationDto): Promise<GroupAffiliationDto> => {
  const path = `/api/groups/${groupId}/users`;
  const response = await fetch<GroupAffiliationDto, GroupAffiliationCreationDto>(path, {
    method: 'POST',
    body: affiliation,
  });
  return response.body;
};

export const updateGroupAffiliation = async (
  groupId: number,
  userId: number,
  affiliation: GroupAffiliationUpdateDto,
): Promise<GroupAffiliationDto> => {
  const path = `/api/groups/${groupId}/users/${userId}`;
  const response = await fetch<GroupAffiliationDto, GroupAffiliationUpdateDto>(path, {
    method: 'PATCH',
    body: affiliation,
  });
  return response.body;
};

export const deleteGroupAffiliation = async (groupId: number, userId: number): Promise<void> => {
  const path = `/api/groups/${groupId}/users/${userId}`;
  await fetch<void>(path, {
    method: 'DELETE',
  });
};
