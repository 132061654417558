import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import React, { Component, KeyboardEvent } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import { isEqual, cloneDeep, min, max } from 'lodash';

import { uploadResource } from '../../service/filestorage';
import { FileSittingQuestionDto, FileSittingQuestionResponseEntry, SittingQuestionDto } from '../../model';
import { formatBytes } from '../../util/date';
import { RichTeX, RichTeXEditor } from '../util/RichTeXEditor';
import { QuestionEditorModelProps, QuestionEditorProps, QuestionEditorMode } from './QuestionEditorModel';
import { blocking } from '../util/decorators';
import AsyncComponent from '../util/AsyncComponent';
import withErrorScreen from '../util/withErrorScreen';
import { fileStorageHttpUrl } from '../../util/config';
import { shortId } from '../../util/random';
import OverlayButton from '../util/OverlayButton';

const filenameEndsInGivenExtension = (name: string, extensionsString: string): boolean => {
  if (!extensionsString) {
    return true;
  }
  const extensions = extensionsString.split(',').map((ext) => ext.trim().toLowerCase());
  const nameLower = name.trim().toLowerCase();
  return Boolean(extensions.find((ext) => nameLower.endsWith(`.${ext}`)));
};

type FileEntryEditorModel = {
  token: string;
  entryKey: string;
  acceptedExtensions: string;
  maxFileSize: number;
  uploadObjectPrefix?: string;
};

/**
 * Extended model props
 */

export class FileQuestionEditorModelProps extends QuestionEditorModelProps<FileSittingQuestionDto> {
  questionText: RichTeX;
  files: { [token: string]: FileEntryEditorModel };

  constructor(question: FileSittingQuestionDto, template?: SittingQuestionDto) {
    super(question, template);

    this.questionText = new RichTeX(this.question.questionContent.text);
    this.files = {};
    if (this.question.questionContent.files) {
      Object.entries(this.question.questionContent.files).forEach(([token, fileEntry]) => {
        this.files[token] = {
          token,
          ...fileEntry,
        };
      });
    }
  }

  refreshQuestion(): void {
    this.question.questionContent.text = this.questionText.getMarkdown();
    this.question.questionContent.files = {};
    Object.values(this.files).forEach(({ token, entryKey, acceptedExtensions, maxFileSize, uploadObjectPrefix }) => {
      this.question.questionContent.files[token] = {
        entryKey,
        acceptedExtensions,
        maxFileSize,
        uploadObjectPrefix,
      };
    });
  }

  fromQuestionTemplate(template: SittingQuestionDto): FileSittingQuestionDto {
    return {
      id: template.id,
      questionType: 'file',
      questionContent: {
        text: (template.questionContent as { text: string }).text || (template.questionContent as string),
        files: {},
      },
    };
  }

  setQuestionText(questionText: RichTeX): void {
    this.questionText = questionText;
    this.questionUpToDate = false;
  }

  setFileEntry(fileEntry: FileEntryEditorModel): void {
    this.files[fileEntry.token] = cloneDeep(fileEntry);
    this.questionUpToDate = false;
  }

  deleteFileEntry(token: string): void {
    delete this.files[token];
    this.questionUpToDate = false;
  }

  setResponseEntry(token: string, responseEntry: FileSittingQuestionResponseEntry): void {
    if (!this.question.response) {
      this.question.response = {};
    }
    this.question.response[token] = responseEntry;
  }
}

/**
 * Associated components
 */

type FileResponseChangedEvent = { file: File; target: HTMLInputElement };

type FileQuestionEntryEditorProps = {
  mode: QuestionEditorMode;
  blocking: boolean;
  fileEntry?: FileEntryEditorModel;
  response?: FileSittingQuestionResponseEntry;
  new?: boolean;

  onEntryChange: (entry: FileEntryEditorModel) => void;
  onDelete?: () => void;
  onResponseFileChange?: (event: FileResponseChangedEvent) => void;
};

type FileQuestionEntryEditorState = {
  fileEntry: FileEntryEditorModel;
  editing: boolean;
};

class FileQuestionEntryEditor extends Component<FileQuestionEntryEditorProps, FileQuestionEntryEditorState> {
  constructor(props: FileQuestionEntryEditorProps) {
    super(props);

    this.state = {
      editing: props.new,
      fileEntry: props.fileEntry
        ? cloneDeep(props.fileEntry)
        : {
            token: shortId(),
            entryKey: '',
            maxFileSize: 1048576,
            acceptedExtensions: 'pdf',
          },
    };
    autoBind(this);
  }

  componentDidUpdate(prevProps: FileQuestionEntryEditorProps) {
    if (!isEqual(prevProps.fileEntry, this.props.fileEntry)) {
      this.setState({ fileEntry: cloneDeep(this.props.fileEntry) });
    }
  }

  startEditing(): void {
    this.setState({ editing: true });
  }

  finishEditing(): void {
    if (this.state.fileEntry.entryKey) {
      this.props.onEntryChange(this.state.fileEntry);

      if (this.props.new) {
        this.setState({
          fileEntry: {
            token: shortId(),
            entryKey: '',
            maxFileSize: 1048576,
            acceptedExtensions: 'pdf',
          },
        });
      } else {
        this.setState({ editing: false });
      }
    }
  }

  cancelEditing(): void {
    if (this.props.new) {
      this.props.onDelete();
    } else {
      this.setState({ editing: false, fileEntry: cloneDeep(this.props.fileEntry) });
    }
  }

  onEntryKeyChange(entryKey: string): void {
    this.setState({ fileEntry: { ...this.state.fileEntry, entryKey } });
  }

  onMaxFileSizeChange(maxFileSize: number): void {
    this.setState({
      fileEntry: {
        ...this.state.fileEntry,
        maxFileSize: max([min([maxFileSize, 64 * 1024 * 1024]), 1]),
      },
    });
  }

  onAcceptedExtensionsChange(acceptedExtensions: string): void {
    this.setState({
      fileEntry: {
        ...this.state.fileEntry,
        acceptedExtensions: acceptedExtensions.toLowerCase().replace(/[^a-z0-9, ]/g, ''),
      },
    });
  }

  onKeyUp(event: KeyboardEvent<HTMLInputElement>): void {
    // enter
    if (event.key === 'Enter' || event.keyCode === 13) {
      this.finishEditing();
    }
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.cancelEditing();
    }
  }

  render(): JSX.Element {
    const { mode, response } = this.props;
    const { editing, fileEntry } = this.state;

    return (
      <div style={{ display: 'flex', marginTop: 8 }}>
        {/* editing */}

        {editing && (
          <div>
            <hr style={{ marginTop: 2, marginBottom: 2 }} />
            {/* file entry ID */}
            <FontAwesomeIcon icon="file-upload" />
            &nbsp;
            {editing && (
              <>
                <label htmlFor={`entryKey-${fileEntry.token}`}>File entry key:&nbsp;</label>
                <input
                  type="text"
                  id={`entryKey-${fileEntry.token}`}
                  value={fileEntry.entryKey}
                  onKeyUp={this.onKeyUp}
                  onChange={(e) => this.onEntryKeyChange(e.target.value)}
                />
                <br />
              </>
            )}
            {/* maximum file size */}
            <FontAwesomeIcon icon="weight-hanging" />
            &nbsp;
            <label htmlFor={`maxFileSize-${fileEntry.token}`}>Max file size (bytes):&nbsp;</label>
            <input
              type="number"
              id={`maxFileSize-${fileEntry.token}`}
              min={1}
              max={64 * 1024 * 1024}
              value={fileEntry.maxFileSize}
              onKeyUp={this.onKeyUp}
              onChange={(e) => this.onMaxFileSizeChange(Number(e.target.value))}
            />
            <br />
            {/* accepted file extensions */}
            <FontAwesomeIcon icon="file" />
            &nbsp;
            <label htmlFor={`acceptedExtensions-${fileEntry.token}`}>
              Accepted file extensions <i>(comma-separated)</i>:&nbsp;
            </label>
            <input
              type="text"
              pattern="[a-z0-9, ]+"
              id={`acceptedExtensions-${fileEntry.token}`}
              value={fileEntry.acceptedExtensions}
              onKeyUp={this.onKeyUp}
              onChange={(e) => this.onAcceptedExtensionsChange(e.target.value)}
            />
            <hr style={{ marginTop: 2, marginBottom: 2 }} />
          </div>
        )}

        {/* not editing */}

        {!editing && (
          <div style={{ flexGrow: 1 }}>
            {/* file entry key */}
            <FontAwesomeIcon icon="file-upload" />
            &nbsp;
            <b>{fileEntry.entryKey}</b>&nbsp;·&nbsp;
            {/* file download link */}
            {(mode === 'take' || mode === 'response' || mode === 'evaluate') && (
              <>
                {response ? (
                  <>
                    <a href={`${fileStorageHttpUrl}/${response.location}`} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon="file-download" />
                      &nbsp;{response.name}
                    </a>
                    &nbsp;·&nbsp;
                  </>
                ) : (
                  <span style={{ color: mode === 'take' ? '#a01010' : '#a0a010' }}>
                    <FontAwesomeIcon icon="file-excel" />
                    &nbsp;<i>No file provided</i>&nbsp;·&nbsp;
                  </span>
                )}
              </>
            )}
            {/* maximum file size */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id={`maxFileSize-${fileEntry.token}`}>Maximum file size: {fileEntry.maxFileSize} bytes</Tooltip>}
            >
              <span className="objectstreams">
                <FontAwesomeIcon icon="weight-hanging" />
                &nbsp;
                {response && <>{formatBytes(response.size, 1)} / </>}
                {formatBytes(fileEntry.maxFileSize, 1)}
              </span>
            </OverlayTrigger>
            &nbsp;·&nbsp;
            {/* accepted file extensions */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id={`acceptedExtensions-${fileEntry.token}`}>Accepted file extensions</Tooltip>}
            >
              <span className="objectstreams">
                <FontAwesomeIcon icon="file" />
                &nbsp;{fileEntry.acceptedExtensions || <i>any</i>}
              </span>
            </OverlayTrigger>
            {/* file upload dialog */}
            {mode === 'take' && (
              <>
                <br />
                <BlockUi tag="div" blocking={this.props.blocking}>
                  <input
                    type="file"
                    className="form-control file-input"
                    onChange={(event) => this.props.onResponseFileChange({ file: event.target.files[0], target: event.target })}
                    accept="*/*"
                  />
                </BlockUi>
              </>
            )}
          </div>
        )}

        {/* buttons to start/finish editing */}

        {mode === 'edit' && (
          <>
            {/* space to push to right */}
            <span style={{ flexGrow: 1 }}>&nbsp;</span>

            {editing && (
              <>
                &nbsp;
                <OverlayButton
                  style={{ display: 'inherit' }}
                  className="close"
                  tooltip="Save entry"
                  onClick={this.finishEditing}
                  disabled={!fileEntry.entryKey}
                  disabledTooltip="Question text must not be empty"
                >
                  <FontAwesomeIcon icon="check" size="xs" />
                </OverlayButton>
                <OverlayButton style={{ display: 'inherit' }} className="close" tooltip="Cancel editing" onClick={this.cancelEditing}>
                  &nbsp;
                  <FontAwesomeIcon icon="times" size="xs" />
                </OverlayButton>
              </>
            )}

            {!editing && (
              <>
                &nbsp;
                <OverlayButton style={{ display: 'inherit' }} className="close" tooltip="Edit file entry" onClick={this.startEditing}>
                  <FontAwesomeIcon icon="edit" size="xs" />
                </OverlayButton>
                &nbsp;
                <OverlayButton
                  style={{ display: 'inherit' }}
                  className="close"
                  visible={!this.props.new}
                  tooltip="Delete file entry"
                  onClick={this.props.onDelete}
                >
                  <FontAwesomeIcon icon="trash" size="xs" />
                </OverlayButton>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

type FileQuestionEditorProps = QuestionEditorProps<FileSittingQuestionDto, FileQuestionEditorModelProps>;

type FileQuestionEditorState = {
  blocking: boolean;
  showNew: boolean;
};

class FileQuestionEditor extends AsyncComponent<FileQuestionEditorProps, FileQuestionEditorState> {
  constructor(props: FileQuestionEditorProps) {
    super(props);
    this.state = {
      blocking: false,
      showNew: !Object.keys(props.model.files).length,
    };
    autoBind(this);
    this.onResponseFileChange = blocking(this.onResponseFileChange, this);
  }

  onQuestionTextChange(questionText: RichTeX): void {
    const { model, onChange } = this.props;
    model.setQuestionText(questionText);
    onChange(model);
  }

  onNewEntry(fileEntry: FileEntryEditorModel): void {
    this.onEntryChange(fileEntry);
    this.setState({ showNew: false });
  }

  onEntryChange(fileEntry: FileEntryEditorModel): void {
    const { model, onChange } = this.props;
    model.setFileEntry(fileEntry);
    onChange(model);
  }

  onDeleteFileEntry(token: string): void {
    const { model, onChange } = this.props;
    model.deleteFileEntry(token);
    onChange(model);
  }

  async onResponseFileChange(params: { fileEntry: FileEntryEditorModel; file: File; target: HTMLInputElement }): Promise<void> {
    const { fileEntry, file, target } = params;
    if (!filenameEndsInGivenExtension(file.name, fileEntry.acceptedExtensions)) {
      target.value = null;
      throw Error(`"${file.name}" does not end in an accepted file extension (${fileEntry.acceptedExtensions})`);
    }
    if (file.size > fileEntry.maxFileSize) {
      target.value = null;
      throw Error(`"${file.name}" is over the ${formatBytes(fileEntry.maxFileSize, 1)} size limit`);
    }

    const { model, onChange } = this.props;
    const response = await uploadResource(fileEntry.uploadObjectPrefix, file.name, file);
    model.setResponseEntry(fileEntry.token, {
      name: file.name,
      size: file.size,
      location: response.location,
    });
    onChange(model);
  }

  render(): JSX.Element {
    const { mode, model, allowFileUpload, onUpload } = this.props;

    return (
      <>
        {/* question text editor */}
        <span className="question-text">
          <RichTeXEditor
            className="static-editor"
            size="medium"
            value={model.questionText}
            readOnly={mode !== 'edit'}
            onChange={this.onQuestionTextChange}
            allowFileUpload={allowFileUpload}
            onUpload={onUpload}
          />
        </span>

        <div style={{ marginLeft: 8 }}>
          <div>
            <b style={{ fontSize: '110%' }}>File entries</b>
            {mode === 'edit' && !this.state.showNew && (
              <>
                &nbsp;
                <OverlayButton className="close" tooltip="Add new entry" onClick={() => this.setState({ showNew: true })}>
                  <FontAwesomeIcon icon="plus" size="xs" />
                </OverlayButton>
              </>
            )}
          </div>

          {Object.values(model.files).map((fileEntry) => (
            <FileQuestionEntryEditor
              key={fileEntry.token}
              fileEntry={fileEntry}
              mode={mode}
              response={model.question.response && model.question.response[fileEntry.token]}
              blocking={this.state.blocking}
              onEntryChange={this.onEntryChange}
              onDelete={() => this.onDeleteFileEntry(fileEntry.token)}
              onResponseFileChange={({ file, target }) => this.onResponseFileChange({ file, target, fileEntry })}
            />
          ))}

          {mode === 'edit' && (
            <>
              {this.state.showNew && (
                <>
                  <FileQuestionEntryEditor
                    new={true}
                    mode={mode}
                    blocking={this.state.blocking}
                    onEntryChange={this.onNewEntry}
                    onDelete={() => this.setState({ showNew: false })}
                  />
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

export default withErrorScreen(FileQuestionEditor);
