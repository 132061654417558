import autoBind from 'auto-bind';
import React, { Component } from 'react';

type ImageWithFallbackProps = {
  src: string;
  alt?: string;
};

type ImageWithFallbackState = {
  loaded: boolean;
  retries: number;
  error: boolean;
  retryTimeout: NodeJS.Timeout;
};

/**
 * Fallback version of img tag
 */
export default class ImageWithFallback extends Component<ImageWithFallbackProps, ImageWithFallbackState> {
  constructor(props: ImageWithFallbackProps) {
    super(props);
    this.state = {
      loaded: false,
      retries: 0,
      error: false,
      retryTimeout: null,
    };

    autoBind(this);
  }

  componentDidMount(): void {
    this.loadSrc();
  }

  componentWillUnmount(): void {
    if (this.state.retryTimeout) {
      clearTimeout(this.state.retryTimeout);
    }
  }

  componentDidUpdate(prevProps: ImageWithFallbackProps): void {
    if (prevProps.src !== this.props.src) {
      this.setState(
        {
          loaded: false,
          retries: 0,
          error: false,
        },
        this.loadSrc,
      );
    }
  }

  loadSrc(): void {
    const image = new Image();
    image.src = this.props.src;

    image.onload = () =>
      this.setState({
        loaded: true,
        retries: 0,
        error: false,
      });

    image.onerror = () => {
      if (this.state.retries >= 5) {
        this.setState({
          loaded: true,
          error: true,
        });
      } else {
        this.setState({
          retries: this.state.retries + 1,
          retryTimeout: setTimeout(() => {
            this.setState({ retryTimeout: null }, this.loadSrc);
          }, 1000),
        });
      }
    };
  }

  render(): JSX.Element {
    if (!this.state.loaded) {
      return (
        <div className="image-loading-frame">
          <div className="image-notfound">Loading image...</div>
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div>
          <div className="image-notfound-frame">
            <div className="image-notfound">Image {this.props.alt} not found.</div>
          </div>
        </div>
      );
    }

    const { src, alt } = this.props;

    return (
      <>
        <div className="image-with-fallback">
          <img src={src} alt={alt} />
        </div>
      </>
    );
  }
}
