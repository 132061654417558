import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserFilterOptions } from '../../service/user';
import FilterBy from '../util/FilterBy';
import OverlayButton from '../util/OverlayButton';
import SearchBy from '../util/SearchBy';
import SortBy from '../util/SortBy';
import withErrorScreen from '../util/withErrorScreen';

type UserListHeaderProps = {
  options: UserFilterOptions;
  onCreate: () => void;
  onOptionsChange: (options: Partial<UserFilterOptions>) => void;
};

function UserListHeader({ options, onCreate, onOptionsChange }: UserListHeaderProps) {
  return (
    <div className="row">
      <div className="col-md-12 titlebar">
        <h3>Users</h3>

        <div className="float-left">
          <FilterBy
            values={options}
            onChange={onOptionsChange}
            resetPage={true}
            options={[
              {
                name: 'User type',
                queryKey: 'admin',
                options: [
                  { key: 'all', label: 'Show all', value: undefined, default: true },
                  { key: 'admin', label: 'Only admins', value: true },
                  { key: 'nonadmin', label: 'Only non-admins', value: false },
                ],
              },
            ]}
          />
          <SortBy
            options={options}
            onChange={onOptionsChange}
            keys={{
              name: 'Name',
            }}
          />
          <SearchBy queryKey="name" term={options.name} onChange={onOptionsChange} placeholder="Search by user name..." resetPage={true} />
        </div>

        <div className="float-right btn-user">
          <OverlayButton variant="success" onClick={onCreate}>
            <FontAwesomeIcon icon="plus" />
            &nbsp;Create
          </OverlayButton>
        </div>
      </div>
    </div>
  );
}

export default withErrorScreen(UserListHeader);
