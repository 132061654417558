import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';

const getAmplitude = (dataArray) => {
  let gain = 0;
  for (let i = 0; i < dataArray.length; i += 1) {
    const sample = Math.abs(dataArray[i] - 128.0);
    gain += sample * sample;
  }

  /* normalized gain, take rms
     factor is 8192 = 128 * 128 * 0.5
     - 128 is scale applied twice in square
     - 0.5 is gain from full amplitube sine wave */
  gain = Math.sqrt(gain / 8192.0 / dataArray.length);
  // convert to db
  return 10 * Math.log(gain);
};

class VolumeMeter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amplitude: -60.0,
      bufferSize: this.props.bufferSize || 8192,
    };

    // test
    /* const fs = 44100;
    const f = 500;
    const omega = (2 * Math.PI * f) / fs;

    const baseArray = new Uint8Array(65536);
    for (let i = 0; i < 65536; i += 1) {
      baseArray[i] = Math.cos(i * omega) * 128 + 128;
    }
    console.log(baseArray);
    console.log(getAmplitude(baseArray)); */
    // /test
  }

  componentDidMount() {
    this.audioContext = new AudioContext();
    const source = this.audioContext.createMediaStreamSource(this.props.stream);

    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = this.state.bufferSize * 2;
    const bufferLength = this.analyser.frequencyBinCount;
    this.dataArray = new Uint8Array(bufferLength);

    const { sampleRate } = this.audioContext;
    const intervalSize = (bufferLength / sampleRate) * 1000.0;

    source.connect(this.analyser);
    console.log(
      `Started monitoring volume, sample rate=${sampleRate}, buffer size=${bufferLength}, check interval=${intervalSize.toFixed(1)}ms`,
    );

    this.interval = setInterval(() => {
      this.analyser.getByteTimeDomainData(this.dataArray);
      const amplitude = getAmplitude(this.dataArray);
      this.setState({ amplitude });
    }, intervalSize);
  }

  componentWillUnmount() {
    if (this.interval) {
      console.log('Stopping monitoring volume');
      clearInterval(this.interval);
    }
  }

  render() {
    return (
      <div className="volume-meter">
        <div className="float-left small-label">
          Volume meter&nbsp;
          {this.props.onHide && (
            <i className="likeALink" onClick={this.props.onHide}>
              (hide)
            </i>
          )}
          :&nbsp;
          <i>{this.state.amplitude.toFixed(2)}dB</i>
        </div>
        <ProgressBar variant="info" min={-60} max={0} now={Math.max(this.state.amplitude, -60.0)} />
      </div>
    );
  }
}

VolumeMeter.propTypes = {
  stream: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  bufferSize: PropTypes.number,
};

export default VolumeMeter;
