import React from 'react';
import BlockUi from 'react-block-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';

import { createQuestion, updateQuestion, deleteQuestion } from '../../service/question';
import { uploadExamResource } from '../../service/filestorage';

import { DeleteModal } from '../util/Modals';
import { blocking } from '../util/decorators';
import AsyncComponent from '../util/AsyncComponent';
import withErrorScreen from '../util/withErrorScreen';
import OverlayButton from '../util/OverlayButton';
import QuestionEditor, { QuestionEditorModel } from '../question/QuestionEditor';
import { QuestionDto, UploadResourceDto } from '../../model';

type QuestionListEntryProps = {
  new?: boolean;
  examId: number;
  groupId: number;
  bankId: number;
  examStreaming: boolean;
  question?: QuestionDto;
  onNewQuestion?: (question: QuestionDto) => Promise<void>;
  onDelete?: () => void;
};

type QuestionListEntryState = {
  blocking: boolean;
  deleted: boolean;
  showDeleteModal: boolean;
  editing: boolean;
  questionModel: QuestionEditorModel;
  oldQuestion?: string;
};

class QuestionListEntry extends AsyncComponent<QuestionListEntryProps, QuestionListEntryState> {
  constructor(props: QuestionListEntryProps) {
    super(props);

    if (this.props.new) {
      this.state = {
        questionModel: new QuestionEditorModel(),
        showDeleteModal: false,
        deleted: false,
        blocking: false,
        editing: true,
      };
    } else {
      this.state = {
        questionModel: new QuestionEditorModel(this.props.question),
        showDeleteModal: false,
        deleted: false,
        blocking: false,
        editing: false,
      };
    }

    autoBind(this);

    this.confirmDeleteQuestion = blocking(this.confirmDeleteQuestion, this);
    this.saveQuestion = blocking(this.saveQuestion, this);
    this.onUpload = blocking(this.onUpload, this);
  }

  startEditing() {
    this.setState({
      editing: true,
      oldQuestion: JSON.stringify(this.state.questionModel.getQuestion()),
    });
  }

  cancelEditing() {
    if (this.props.new) {
      this.props.onDelete();
    } else {
      this.setState({
        editing: false,
        questionModel: new QuestionEditorModel(JSON.parse(this.state.oldQuestion)),
      });
    }
  }

  onQuestionModelChange(questionModel) {
    this.setState({ questionModel });
  }

  onKeyUp(event) {
    // enter
    if (event.keyCode === 27) {
      this.cancelEditing();
    }
  }

  async saveQuestion() {
    let question = this.state.questionModel.getQuestion();

    if (this.props.new) {
      question = await createQuestion(this.props.examId, this.props.bankId, question);
      await this.props.onNewQuestion(question);
      this.setState({ questionModel: new QuestionEditorModel() });
    } else {
      await updateQuestion(this.props.examId, this.props.bankId, this.props.question.id, question);
      this.setState({ editing: false });
    }
  }

  proposeDeleteQuestion() {
    this.setState({ showDeleteModal: true });
  }

  cancelDeleteQuestion() {
    this.setState({ showDeleteModal: false });
  }

  async confirmDeleteQuestion() {
    await this.setStateAsync({ showDeleteModal: false });
    await deleteQuestion(this.props.examId, this.props.bankId, this.props.question.id);
    this.setState({ deleted: true });
  }

  onUpload(upload: UploadResourceDto) {
    return uploadExamResource(this.props.groupId, this.props.examId, upload.filename, upload.file);
  }

  render() {
    if (this.state.deleted) {
      return <div className="infomsg">This question has been deleted.</div>;
    }

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div className="md-12">
          <dl>
            {this.props.new ? <dt>New question</dt> : <dt>Question {this.props.question.id}</dt>}
            <dd>
              <QuestionEditor
                mode={this.state.editing ? 'edit' : 'view'}
                model={this.state.questionModel}
                allowFileUpload={true}
                onUpload={this.onUpload}
                onChange={this.onQuestionModelChange}
                streaming={this.props.examStreaming}
              />
            </dd>
          </dl>
        </div>

        <div className="float-right btn-group" style={{ paddingTop: 12 }}>
          <OverlayButton visible={this.state.editing} tooltip="Save question" variant="outline-success" onClick={this.saveQuestion}>
            <FontAwesomeIcon icon="check" />
          </OverlayButton>
          <OverlayButton visible={this.state.editing} tooltip="Cancel editing" variant="outline-secondary" onClick={this.cancelEditing}>
            <FontAwesomeIcon icon="times" />
          </OverlayButton>
          <OverlayButton
            visible={!this.props.new && !this.state.editing}
            tooltip="Sitting questions"
            variant="outline-info"
            to={`/exams/${this.props.examId}/banks/${this.props.bankId}/questions/${
              !this.props.new && this.props.question.id
            }/sittingQuestions/`}
          >
            <FontAwesomeIcon icon="question-circle" />
          </OverlayButton>
          <OverlayButton visible={!this.state.editing} tooltip="Edit question" variant="outline-warning" onClick={this.startEditing}>
            <FontAwesomeIcon icon="edit" />
          </OverlayButton>
          <OverlayButton visible={!this.props.new} tooltip="Delete" variant="outline-danger" onClick={this.proposeDeleteQuestion}>
            <FontAwesomeIcon icon="trash" />
          </OverlayButton>
        </div>

        <DeleteModal
          show={this.state.showDeleteModal}
          onCancel={this.cancelDeleteQuestion}
          onConfirm={this.confirmDeleteQuestion}
          title="Delete question"
        >
          <div>Are you sure you would like to delete this question?</div>
          <div>All associated answers will be deleted. This action is irreversible.</div>
        </DeleteModal>
      </BlockUi>
    );
  }
}

export default withErrorScreen(QuestionListEntry);
