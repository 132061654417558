import React, { Component } from 'react';
import { ExamReducedDto } from '../../model';
import ExamListEntry from './ExamListEntry';

type ExamListProps = {
  exams: ExamReducedDto[];
};

export default class ExamList extends Component<ExamListProps> {
  render(): JSX.Element {
    if (!this.props.exams.length) {
      return <div className="infomsg">No exams to show</div>;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <table className="table table-hover">
            <tbody>
              {this.props.exams.map((exam) => (
                <tr key={exam.id}>
                  <td>
                    <ExamListEntry exam={exam} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
