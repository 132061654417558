import autoBind from 'auto-bind';
import AuthError from './AuthError';
import { apiServerUrl, logFetchRequests } from './config';

export interface GenericObject {
  constructor: unknown;
}

export interface IndexedObject extends GenericObject {
  [key: string]: unknown;
}

export type FetchRequestOptions<REQ extends GenericObject = IndexedObject> = {
  method?: string;
  headers?: { [key: string]: string };
  body?: REQ;
  blob?: boolean;
  // whether promise should be rejected if response status code is >400 (default: true)
  failOnError?: boolean;
};

export type FetchRequestPatchedOptions = {
  method: string;
  headers: { [key: string]: string };
  body?: string;
  credentials: 'include';
};

export type FetchResponse<RES> = {
  status: number;
  statusText?: string;
  headers: { [key: string]: unknown };
  bodyText: string;
  body: RES;
};

/**
 * Fetcher customizing options for constructor
 */
export type FetcherOptions = {
  // default base URL if non-absolute one given
  baseUrl: string;
  loginUrl?: string;
};

/**
 * Expand built-in fetch method.
 */
export class Fetcher {
  globalOptions: FetcherOptions;

  constructor(options: FetcherOptions) {
    this.globalOptions = options;
    autoBind(this);
  }

  setLoginUrl(loginUrl: string): void {
    this.globalOptions.loginUrl = loginUrl;
  }

  // eslint-disable-next-line
  decodeErrorMessage(body: any): string {
    if (body && body.message) {
      return body.message;
    }
    if (body && body.messages) {
      return body.messages.join('\n');
    }
    if (body) {
      return body.toString();
    }

    return 'Unknown error';
  }

  async fetch<RES, REQ extends GenericObject = IndexedObject>(
    path: string,
    options: FetchRequestOptions<REQ> = {},
  ): Promise<FetchResponse<RES>> {
    // only append base URL if not already absolute
    let absolutePath = path;
    if (!absolutePath.startsWith('http')) {
      absolutePath = `${this.globalOptions.baseUrl}${path}`;
    }

    // serialize body and set content-type header
    const patchedOptions: FetchRequestPatchedOptions = {
      method: options.method || 'GET',
      headers: {
        ...options.headers,
      },
      credentials: 'include',
    };

    if (options.body) {
      if (options.body.constructor === FormData) {
        // if form data, copy simply
        patchedOptions.body = options.body;
      } else {
        // if other object, serialize it to JSON
        patchedOptions.headers['Content-Type'] = 'application/json';
        patchedOptions.body = JSON.stringify(options.body);
      }
    }

    // actual request
    const response = await fetch(absolutePath, patchedOptions);

    // decode main info from Response object
    const ret = {
      status: response.status,
      statusText: response.statusText,
      headers: {} as Record<string, unknown>,
      bodyText: '',
      body: null as unknown,
    };

    // decode headers to standard object
    [...response.headers.entries()].forEach(([key, val]) => {
      ret.headers[key] = val;
    });

    // decode body if JSON
    if (!options.blob) {
      const contentType = ret.headers['content-type'];
      if (contentType && `${contentType}`.startsWith('application/json')) {
        ret.bodyText = await response.text();
        ret.headers['content-length'] = ret.bodyText.length;
        try {
          ret.body = JSON.parse(ret.bodyText);
        } catch (e) {
          // do nothing, could not parse
        }
      } else {
        ret.headers['content-length'] = 0;
      }
    } else {
      const blob = await response.blob();
      ret.body = blob;
      ret.bodyText = await blob.text();
    }

    // log request information
    if (logFetchRequests) {
      console.log(
        `${new Date().toISOString()} - ${patchedOptions.method} ${absolutePath} - ${ret.status} ${ret.headers['content-length']}`,
      );
    }

    // redirect to login page if unauthorized
    if (response.status === 401 && ret.body) {
      const { loginUrl } = ret.body as Record<string, unknown>;
      const finalLoginUrl = loginUrl || this.globalOptions.loginUrl;
      if (finalLoginUrl) {
        throw new AuthError(finalLoginUrl as string);
      }
    }

    // reject on erroneous status code
    if (ret.status >= 400) {
      console.error(ret.body);
      const errorMessage = this.decodeErrorMessage(ret.body);
      // reject on erroneous status code if option set (default: true)
      let failOnError = true;
      if (options.failOnError !== undefined && options.failOnError !== null) {
        failOnError = Boolean(options.failOnError);
      }
      if (failOnError) {
        throw new Error(errorMessage);
      }
    }

    return ret as FetchResponse<RES>;
  }
}

/**
 * Fetcher instance - loginUrl and accessToken can be updated
 */
export const fetcher = new Fetcher({ baseUrl: apiServerUrl });

/**
 * Default fetch instance - fail on error and by default to API server
 */
export default fetcher.fetch;
