import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import React, { Component } from 'react';
import { McaSittingQuestionDto, ScaSittingQuestionDto, ShortTextSittingQuestionDto, SittingQuestionDto } from '../../model';
import { shortId } from '../../util/random';
import { RichTeX, RichTeXEditor } from '../util/RichTeXEditor';
import AnswerEditor from './AnswerEditor';
import { QuestionEditorModelProps, QuestionEditorProps } from './QuestionEditorModel';

/**
 * Extended model props
 */

export class ScaQuestionEditorModelProps extends QuestionEditorModelProps<ScaSittingQuestionDto> {
  questionText: RichTeX;
  answers: {
    [key: string]: {
      dynamic: boolean;
      text: RichTeX | string;
    };
  };

  constructor(question: ScaSittingQuestionDto, template?: SittingQuestionDto) {
    super(question, template);

    this.questionText = new RichTeX(this.question.questionContent.text);
    this.answers = {};

    Object.entries(this.question.questionContent.answers).forEach(([answerId, answer]) => {
      this.answers[answerId] = {
        dynamic: answer.dynamic,
        text: answer.dynamic ? new RichTeX(answer.text) : answer.text,
      };
    });
  }

  refreshQuestion(): void {
    this.question.questionContent.text = this.questionText.getMarkdown();
    this.question.questionContent.answers = {};

    Object.entries(this.answers).forEach(([answerId, answer]) => {
      this.question.questionContent.answers[answerId] = {
        dynamic: answer.dynamic,
        text: answer.dynamic ? (answer.text as RichTeX).getMarkdown() : answer.text,
      };
    });
  }

  fromQuestionTemplate(template: SittingQuestionDto): ScaSittingQuestionDto {
    // special case
    if (template.questionType === 'multiplecorrect') {
      const castQuestion = template as McaSittingQuestionDto;

      const answerKeys = Object.keys(castQuestion.evaluationKey);
      const newEvaluationKey = answerKeys.find((answerKey) => castQuestion.evaluationKey[answerKey]) || answerKeys[0] || null;

      return {
        id: template.id,
        questionType: 'singlecorrect',
        questionContent: castQuestion.questionContent,
        evaluationKey: newEvaluationKey,
      };
    }

    // special case
    if (template.questionType === 'shorttext') {
      const castQuestion = template as ShortTextSittingQuestionDto;

      const newAnswers = {};
      let newEvaluationKey = null;
      castQuestion.evaluationKey.forEach((answer) => {
        const answerId = shortId();
        newAnswers[answerId] = {
          text: answer,
          dynamic: false,
        };
        newEvaluationKey = newEvaluationKey || answerId;
      });

      return {
        id: template.id,
        questionType: 'singlecorrect',
        questionContent: {
          text: castQuestion.questionContent.text,
          shuffleAnswers: true,
          answers: newAnswers,
        },
        evaluationKey: newEvaluationKey,
      };
    }

    // general case
    return {
      id: template.id,
      questionType: 'singlecorrect',
      questionContent: {
        text: (template.questionContent as { text: string }).text || (template.questionContent as string),
        shuffleAnswers: true,
        answers: {},
      },
      evaluationKey: null,
    };
  }

  setQuestionText(questionText: RichTeX): void {
    this.questionText = questionText;
    this.questionUpToDate = false;
  }

  toggleQuestionShuffleAnswers(): void {
    this.question.questionContent.shuffleAnswers = !this.question.questionContent.shuffleAnswers;
  }

  setAnswerText(answerId: string, answerText: RichTeX | string, dynamic: boolean): void {
    this.answers[answerId] = {
      text: answerText,
      dynamic,
    };
    this.questionUpToDate = false;
  }

  addNewAnswer(answerText: RichTeX | string, dynamic: boolean): void {
    const answerId = shortId();
    this.answers[answerId] = {
      text: answerText,
      dynamic,
    };
    // if one answer left, it is automatically correct
    if (Object.keys(this.answers).length === 1) {
      [this.question.evaluationKey] = Object.keys(this.answers);
    }
    this.questionUpToDate = false;
  }

  setCorrectAnswer(answerId: string): void {
    this.question.evaluationKey = answerId;
  }

  setSelectedAnswer(answerId: string): void {
    this.question.response = answerId;
  }

  deleteAnswer(answerId: string): void {
    delete this.answers[answerId];
    // if one answer left, it is automatically correct
    if (Object.keys(this.answers).length === 1) {
      [this.question.evaluationKey] = Object.keys(this.answers);
    }
    this.questionUpToDate = false;
  }
}

/**
 * Alias
 */

type ScaQuestionEditorProps = QuestionEditorProps<ScaSittingQuestionDto, ScaQuestionEditorModelProps>;
type ScaQuestionEditorState = {
  answerEntries: [
    string,
    {
      dynamic: boolean;
      text: RichTeX | string;
    },
  ][];
};

/**
 * Associated components
 */

export default class ScaQuestionEditor extends Component<ScaQuestionEditorProps, ScaQuestionEditorState> {
  token = shortId();

  constructor(props: ScaQuestionEditorProps) {
    super(props);
    autoBind(this);

    if (props.mode === 'take') {
      const { shuffleAnswers } = props.model.question.questionContent;
      let answerEntries = Object.entries(props.model.answers);
      if (shuffleAnswers && props.mode === 'take') {
        answerEntries = answerEntries.sort(() => Math.random() - 0.5);
      }

      this.state = {
        answerEntries,
      };
    }
  }

  onQuestionTextChange(questionText: RichTeX): void {
    const { model, onChange } = this.props;
    model.setQuestionText(questionText);
    onChange(model);
  }

  onQuestionToggleShuffleAnswers(): void {
    const { model, onChange } = this.props;
    model.toggleQuestionShuffleAnswers();
    onChange(model);
  }

  onAnswerTextChange(answerId: string, answerText: RichTeX | string, dynamic: boolean): void {
    const { model, onChange } = this.props;
    model.setAnswerText(answerId, answerText, dynamic);
    onChange(model);
  }

  onNewAnswer(answerText: RichTeX | string, dynamic: boolean): void {
    const { model, onChange } = this.props;
    model.addNewAnswer(answerText, dynamic);
    onChange(model);
  }

  onAnswerToggleCorrect(answerId: string): void {
    const { model, onChange } = this.props;
    model.setCorrectAnswer(answerId);
    onChange(model);
  }

  onAnswerToggleSelected(answerId: string): void {
    const { model, onChange } = this.props;
    model.setSelectedAnswer(answerId);
    onChange(model);
  }

  onDeleteAnswer(answerId: string): void {
    const { model, onChange } = this.props;
    model.deleteAnswer(answerId);
    onChange(model);
  }

  render(): JSX.Element {
    const { mode, model, allowFileUpload, onUpload } = this.props;
    const { shuffleAnswers } = model.question.questionContent;

    const answerEntries = mode === 'take' ? this.state.answerEntries : Object.entries(model.answers);

    return (
      <>
        {(mode === 'edit' || mode === 'view') && (
          <>
            <div className="float-right">
              <input
                type="checkbox"
                id={`shuffleAnswers-${model.question.id}`}
                checked={shuffleAnswers}
                onChange={this.onQuestionToggleShuffleAnswers}
                disabled={mode !== 'edit'}
              />
              &nbsp;
              <FontAwesomeIcon icon="random" />
              &nbsp;
              <label htmlFor={`shuffleAnswers-${model.question.id}`} className="uncolored">
                Answers shuffled
              </label>
            </div>
          </>
        )}

        <span className="question-text">
          <RichTeXEditor
            className="static-editor"
            size="medium"
            value={model.questionText}
            readOnly={mode !== 'edit'}
            onChange={this.onQuestionTextChange}
            allowFileUpload={allowFileUpload}
            onUpload={onUpload}
          />
        </span>
        <br />

        {answerEntries.map(([answerId, answer]) => (
          <AnswerEditor
            key={answerId}
            mode={mode}
            variant="singlecorrect"
            allowDynamic={true}
            token={this.token}
            answerId={answerId}
            correct={model.question.evaluationKey === answerId}
            selected={model.question.response === answerId}
            {...answer}
            onTextChange={(answerText: RichTeX | string, dynamic: boolean) => this.onAnswerTextChange(answerId, answerText, dynamic)}
            onToggleCorrect={() => this.onAnswerToggleCorrect(answerId)}
            onToggleSelected={() => this.onAnswerToggleSelected(answerId)}
            onDelete={() => this.onDeleteAnswer(answerId)}
          />
        ))}

        {mode === 'edit' && (
          <>
            <AnswerEditor
              mode={mode}
              variant="singlecorrect"
              allowDynamic={true}
              token={this.token}
              new={true}
              onTextChange={this.onNewAnswer}
            />
          </>
        )}
      </>
    );
  }
}
