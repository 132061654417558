import { createContext } from 'react';

export type Breadcrumb = {
  text: string;
  path: string;
};

export type Breadcrumbs = Breadcrumb[];

export type BreadcrumbsSetter = (breadcrumbs: Breadcrumbs) => unknown;

const BreadcrumbsSetterContext = createContext<BreadcrumbsSetter>(null);

export default BreadcrumbsSetterContext;
