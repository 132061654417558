import { GroupReducedDto } from './group';
import { QuestionBankDto } from './questionbank';

export type ExamType = 'surveillance' | 'continuous' | 'interactive';

export type StreamingQuality = 'low' | 'medium' | 'high';

export const streamingQualityMappings = {
  low: {
    prettyName: 'Low',
    webcamWidth: 240,
    h264desktopBitRate: 200000,
    h264webcamBitRate: 200000,
    vp8desktopBitRate: 200000,
    vp8webcamBitRate: 200000,
  },
  medium: {
    prettyName: 'Medium',
    webcamWidth: 480,
    h264desktopBitRate: 800000,
    h264webcamBitRate: 500000,
    vp8desktopBitRate: 700000,
    vp8webcamBitRate: 400000,
  },
  high: {
    prettyName: 'High',
    webcamWidth: 720,
    h264desktopBitRate: 2000000,
    h264webcamBitRate: 1200000,
    vp8desktopBitRate: 1400000,
    vp8webcamBitRate: 800000,
  },
};

export interface ExamMetadata {
  assignment?: { id: number; name: string };
}

/**
 * Incoming
 */

export interface ExamReducedDto {
  id: number;
  name: string;
  examType: ExamType;
  published: boolean;
  group: GroupReducedDto;
  startTime: string;
  hardStartTime: string;
  endTime: string;
  cutoffTime: number;
  resumableTime: number;
  redoable: boolean;
  maintainer: boolean;
  starred: boolean;
  numStars: number;

  // continuous & interactive
  autoReleasePoints?: boolean;
  studentsCanSeeBanks?: boolean;
  streaming?: boolean;
  studentsCanSeeStreams?: boolean;
  streamingQuality?: StreamingQuality;

  // continuous
  accumulateRemainingTime?: boolean;
  pauseBetweenQuestions?: number;
  randomBankOrder?: boolean;

  // interactive
  gracePeriod?: number;
}

export interface ExamDetailsDto extends ExamReducedDto {
  description: string;

  // continuous & interactive
  numQuestions?: number;
  totalPoints?: number;

  // continuous
  totalTime?: number;

  // FOR EXAMS CONTAINING EXTRA DATA FROM EXTERNAL SOURCES
  jsonMetadata?: ExamMetadata;
}

/**
 * Outgoing
 */

export interface ExamCreationDto {
  name: string;
  examType: ExamType;
  description?: string;
  groupId: number;
  startTime: string | Date;
  hardStartTime: string | Date;
  endTime: string | Date;
  cutoffTime?: number;
  resumableTime?: number;
  accumulateRemainingTime?: boolean;
  pauseBetweenQuestions?: number;
  gracePeriod?: number;
  randomBankOrder?: boolean;
  redoable?: boolean;
  autoReleasePoints?: boolean;
  studentsCanSeeBanks?: boolean;
  streaming?: boolean;
  studentsCanSeeStreams?: boolean;
  streamingQuality?: StreamingQuality;
  // EXTRA DATA FROM EXTERNAL SOURCES
  jsonMetadata?: ExamMetadata;
}

export interface ExamUpdateDto {
  starred?: boolean;
  name?: string;
  description?: string;
  startTime?: string | Date;
  hardStartTime?: string | Date;
  endTime?: string | Date;
  cutoffTime?: number;
  resumableTime?: number;
  redoable?: boolean;
  autoReleasePoints?: boolean;
  studentsCanSeeBanks?: boolean;
  published?: boolean;
  accumulateRemainingTime?: boolean;
  pauseBetweenQuestions?: number;
  gracePeriod?: number;
  randomBankOrder?: boolean;
  streaming?: boolean;
  studentsCanSeeStreams?: boolean;
  streamingQuality?: StreamingQuality;
  // EXTRA DATA FROM EXTERNAL SOURCES
  jsonMetadata?: ExamMetadata;
}

export interface ExamForUploadDto {
  name: string;
  examType: ExamType;
  description: string;
  groupId: number;
  startTime?: string;
  hardStartTime?: string;
  endTime?: string;
  cutoffTime?: number;
  resumableTime?: number;
  accumulateRemainingTime?: boolean;
  pauseBetweenQuestions?: number;
  gracePeriod?: number;
  randomBankOrder?: boolean;
  redoable?: boolean;
  studentsCanSeeBanks?: boolean;
  autoReleasePoints?: boolean;
  streaming?: boolean;
  studentsCanSeeStreams?: boolean;
  streamingQuality?: StreamingQuality;

  questionBanks?: Array<QuestionBankDto>;

  // EXTRA DATA FROM EXTERNAL SOURCES
  jsonMetadata?: ExamMetadata;
}
