import autoBind from 'auto-bind';
import ms from 'ms';
import React from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import './App.scss';

import BankDetails from './components/BankDetails';
import EditBank from './components/EditBank';
import EditExam from './components/EditExam';
import EditGroup from './components/EditGroup';
import ExamDetails from './components/ExamDetails';
import Exams from './components/Exams';
import GroupDetails from './components/GroupDetails';
import Groups from './components/Groups';
import Login from './components/Login';
import NavBar from './components/NavBar';
import PasswordReset from './components/PasswordReset';
import Sandbox from './components/sandbox/Sandbox';
import SittingDetails from './components/SittingDetails';
import SittingQuestionSearch from './components/SittingQuestionSearch';
import TakeExam from './components/TakeExam';
import Users from './components/Users';
import AsyncComponent from './components/util/AsyncComponent';
import { blocking, withErrorHandling } from './components/util/decorators';
import withErrorScreen from './components/util/withErrorScreen';
import CredentialsContext, { CredentialsContextType } from './context/credentials';
import { IntrospectedPayload, LoginDto } from './model';
import { refreshToken, logout, login, getProviders } from './service/auth';
import { accessTokenExpirationTime } from './util/config';
import { fetcher } from './util/fetch';
import { parseBool } from './util/queryparser';
import WebSocketContainer from './util/wscontainer';
import cacheBust from './util/cachebust';
import BreadcrumbsProvider from './providers/breadcrumbs';
import ErrorModalProvider from './providers/error';

type AppProps = RouteComponentProps;

type AppState = {
  loaded: boolean;
  loggedIn: boolean;
  forcedChangePassword: boolean;
  canChangePassword: boolean;
  blocking: boolean;
  refreshTokenIntervalId: NodeJS.Timeout;
  context: CredentialsContextType;
};

class App extends AsyncComponent<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      loaded: false,
      loggedIn: false,
      blocking: false,
      forcedChangePassword: false,
      canChangePassword: false,
      refreshTokenIntervalId: null,
      context: {
        userId: 0,
        userName: '',
        admin: false,
        canCreateExam: false,
        darkMode: parseBool(localStorage.cvqDarkMode) || false,
        wsContainer: new WebSocketContainer(),
        fetcher,
        providers: {},
        onLogout: () => this.onLogout(),
      },
    };

    autoBind(this);

    this.cacheBust = blocking(this.cacheBust, this);
    this.fetchProviders = blocking(this.fetchProviders, this);
    this.ensureAccessToken = blocking(this.ensureAccessToken, this);
    this.onLogin = blocking(this.onLogin, this);
    this.onLogout = withErrorHandling(this.onLogout, this);
  }

  async componentDidMount() {
    await this.cacheBust();
    await this.fetchProviders();
    await this.ensureAccessToken();
  }

  async cacheBust() {
    await cacheBust();
  }

  componentWillUnmount() {
    if (this.state.refreshTokenIntervalId) {
      clearInterval(this.state.refreshTokenIntervalId);
    }
  }

  async fetchProviders() {
    const providers = await getProviders();
    const providerMap = providers.reduce(
      (map, provider) => ({
        ...map,
        [provider.name]: provider,
      }),
      {},
    );
    await this.setStateAsync({
      context: {
        ...this.state.context,
        providers: providerMap,
      },
    });
  }

  async onLogin(loginDto: LoginDto) {
    const payload = await login(loginDto);
    await this.postLogin(payload);
  }

  async onLogout() {
    await logout();
    await this.postLogout();
  }

  async ensureAccessToken() {
    try {
      const payload = await refreshToken();
      await this.postLogin(payload);
    } catch (ex) {
      // set state as logged out
      await this.postLogout();
    }
  }

  async postLogin(payload: IntrospectedPayload): Promise<void> {
    if (payload.loginUrl) {
      fetcher.setLoginUrl(payload.loginUrl);
    }
    if (payload.providerName) {
      localStorage.cvqLoggedInProvider = payload.providerName;
    } else {
      localStorage.removeItem('cvqLoggedInProvider');
    }

    await this.setStateAsync({
      loaded: true,
      loggedIn: true,
      forcedChangePassword: payload.mustChangePassword,
      canChangePassword: payload.canChangePassword,
      context: {
        ...this.state.context,
        userId: payload.userId,
        userName: payload.userName,
        admin: payload.admin,
        canCreateExam: payload.canCreateExam,
      },
    });

    if (!this.state.refreshTokenIntervalId) {
      const intervalId = setInterval(() => this.ensureAccessToken(), ms(accessTokenExpirationTime as string));
      await this.setStateAsync({ refreshTokenIntervalId: intervalId });
    }
  }

  async postLogout(): Promise<void> {
    if (this.state.refreshTokenIntervalId) {
      clearInterval(this.state.refreshTokenIntervalId);
    }

    await this.setStateAsync({
      loaded: true,
      loggedIn: false,
      forcedChangePassword: false,
      canChangePassword: false,
      refreshTokenIntervalId: null,
      context: {
        ...this.state.context,
        userId: 0,
        userName: '',
        admin: false,
        canCreateExam: false,
      },
    });

    localStorage.removeItem('cvqLoggedInProvider');

    this.props.history.push('/');
  }

  onPasswordReset() {
    this.setState({ forcedChangePassword: false });
  }

  async onToggleDarkMode() {
    const darkMode = !this.state.context.darkMode;
    await this.setStateAsync({
      context: {
        ...this.state.context,
        darkMode,
      },
    });
    localStorage.cvqDarkMode = darkMode;
  }

  render() {
    const { loaded, loggedIn, context, forcedChangePassword } = this.state;
    const { darkMode } = context;

    const themeLink = `https://bootswatch.com/4/${darkMode ? 'darkly' : 'flatly'}/bootstrap.min.css`;
    if (document.getElementById('bootswatchThemeLink').getAttribute('href') !== themeLink) {
      document.getElementById('bootswatchThemeLink').setAttribute('href', themeLink);
    }

    return (
      <CredentialsContext.Provider value={context}>
        <ErrorModalProvider>
          <BlockUi tag="div" blocking={this.state.blocking} className={darkMode ? 'cvqDarkMode' : 'cvqLightMode'}>
            {/* nav bar */}
            <NavBar onLogout={this.onLogout} darkMode={darkMode} onToggleDarkMode={this.onToggleDarkMode} />
            <BreadcrumbsProvider>
              {/* loading screen */}
              {!loaded && <div className="infomsg">Loading...</div>}

              {/* login page */}
              {loaded && !loggedIn && (
                <>
                  <Login onLogin={this.onLogin} />
                </>
              )}

              {/* password reset page */}
              {loaded && loggedIn && forcedChangePassword && (
                <>
                  <PasswordReset onPasswordReset={this.onPasswordReset} />
                </>
              )}

              {/* routed page */}
              {loaded && loggedIn && !forcedChangePassword && (
                <>
                  <Switch>
                    <Route exact path="/" render={() => <Redirect to="/exams/" />} />

                    {/* Exam listing page */}
                    <Route exact path="/exams/" component={Exams} />

                    {/* Exam editing page */}
                    <Route exact path="/exams/new" component={EditExam} />
                    <Route exact path="/exams/:examId/edit" component={EditExam} />

                    {/* Exam details page */}
                    <Route exact path="/exams/:examId/" render={(props) => <ExamDetails {...props} page="description" />} />
                    <Route exact path="/exams/:examId/banks/" render={(props) => <ExamDetails {...props} page="banks" />} />
                    <Route exact path="/exams/:examId/timeline/" render={(props) => <ExamDetails {...props} page="timeline" />} />
                    <Route exact path="/exams/:examId/sittings/" render={(props) => <ExamDetails {...props} page="sittings" />} />
                    <Route exact path="/exams/:examId/events/" render={(props) => <ExamDetails {...props} page="events" />} />
                    <Route exact path="/exams/:examId/reports/" render={(props) => <ExamDetails {...props} page="reports" />} />

                    {/* Exam taking page */}
                    <Route exact path="/exams/:examId/take" component={TakeExam} />
                    <Route exact path="/exams/:examId/sittings/:sittingId/resume" component={TakeExam} />

                    {/* Question bank editing page */}
                    <Route exact path="/exams/:examId/banks/new" component={EditBank} />
                    <Route exact path="/exams/:examId/banks/:bankId/edit" component={EditBank} />

                    {/* Question bank details page */}
                    <Route exact path="/exams/:examId/banks/:bankId/" component={BankDetails} />
                    <Route exact path="/exams/:examId/banks/:bankId/sittingQuestions/" component={SittingQuestionSearch} />
                    <Route
                      exact
                      path="/exams/:examId/banks/:bankId/questions/:questionId/sittingQuestions/"
                      component={SittingQuestionSearch}
                    />

                    {/* Sitting details page */}
                    <Route
                      exact
                      path="/exams/:examId/sittings/:sittingId/"
                      render={(props) => <SittingDetails {...props} page="questions" />}
                    />
                    <Route
                      exact
                      path="/exams/:examId/sittings/:sittingId/events/"
                      render={(props) => <SittingDetails {...props} page="events" />}
                    />
                    <Route
                      exact
                      path="/exams/:examId/sittings/:sittingId/reports/"
                      render={(props) => <SittingDetails {...props} page="reports" />}
                    />

                    {/* Groups management */}
                    <Route exact path="/groups" component={Groups} />
                    <Route exact path="/groups/new" component={EditGroup} />
                    <Route exact path="/groups/:groupId/" component={GroupDetails} />
                    <Route exact path="/groups/:groupId/edit" component={EditGroup} />

                    {/* Users management */}
                    <Route exact path="/users" component={Users} />

                    {/* sandbox */}
                    <Route exact path="/sandbox" render={(props) => <Sandbox {...props} />} />
                  </Switch>
                </>
              )}
            </BreadcrumbsProvider>
          </BlockUi>
        </ErrorModalProvider>
      </CredentialsContext.Provider>
    );
  }
}

export default withRouter(withErrorScreen(App));
