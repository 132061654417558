import fetch from '../util/fetch';
import download from '../util/download';
import { PagingAndSortingOptions } from '../model/options';
import { Collection, ExamCreationDto, ExamDetailsDto, ExamForUploadDto, ExamReducedDto, ExamType, ExamUpdateDto } from '../model';
import { toUrlParams } from '../util/queryparser';

export type ExamSortOptions = 'startTime' | 'name' | 'groupName';

export type ExamFilterOptions = PagingAndSortingOptions & {
  sortBy?: ExamSortOptions;
  starred?: boolean;
  upcoming?: boolean;
  name?: string;
  groupId?: number;
  streaming?: boolean;
  examType?: ExamType;
};

export const defaultExamOptions: Readonly<ExamFilterOptions> = {
  page: 1,
  perPage: 25,
  sortBy: 'startTime',
  sortDirection: 'desc',
};

export const fetchExams = async (options: ExamFilterOptions = {}): Promise<Collection<ExamReducedDto>> => {
  const path = `/api/exams?${toUrlParams(options, defaultExamOptions)}`;
  const response = await fetch<Array<ExamReducedDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const fetchExamById = async (examId: number): Promise<ExamDetailsDto> => {
  const path = `/api/exams/${examId}`;
  const response = await fetch<ExamDetailsDto>(path);
  return response.body;
};

export const exportExam = async (examId: number): Promise<void> => {
  const path = `/api/exams/${examId}/export`;
  const response = await fetch(path, {
    blob: true,
  });
  download(response.body, `exam-${examId}.json`);
};

export const createExam = async (exam: ExamCreationDto): Promise<ExamDetailsDto> => {
  const path = '/api/exams';
  const response = await fetch<ExamDetailsDto, ExamCreationDto>(path, {
    method: 'POST',
    body: exam,
  });
  return response.body;
};

export const importExam = async (exam: ExamForUploadDto): Promise<ExamDetailsDto> => {
  const path = '/api/exams/import';
  const response = await fetch<ExamDetailsDto, ExamForUploadDto>(path, {
    method: 'POST',
    body: exam,
  });
  return response.body;
};

export const updateExam = async (examId: number, exam: ExamUpdateDto): Promise<ExamDetailsDto> => {
  const path = `/api/exams/${examId}`;
  const response = await fetch<ExamDetailsDto, ExamUpdateDto>(path, {
    method: 'PATCH',
    body: exam,
  });
  return response.body;
};

export const starExam = async (examId: number): Promise<ExamDetailsDto> => {
  const path = `/api/exams/${examId}`;
  const response = await fetch<ExamDetailsDto>(path, {
    method: 'PATCH',
    body: {
      starred: true,
    },
  });
  return response.body;
};

export const unstarExam = async (examId: number): Promise<ExamDetailsDto> => {
  const path = `/api/exams/${examId}`;
  const response = await fetch<ExamDetailsDto>(path, {
    method: 'PATCH',
    body: {
      starred: false,
    },
  });
  return response.body;
};

export const publishExam = async (examId: number): Promise<ExamDetailsDto> => {
  const path = `/api/exams/${examId}`;
  const response = await fetch<ExamDetailsDto>(path, {
    method: 'PATCH',
    body: {
      published: true,
    },
  });
  return response.body;
};

export const unpublishExam = async (examId: number): Promise<ExamDetailsDto> => {
  const path = `/api/exams/${examId}`;
  const response = await fetch<ExamDetailsDto>(path, {
    method: 'PATCH',
    body: {
      published: false,
    },
  });
  return response.body;
};

export const releaseQuestion = async (examId: number, questionIdx: number): Promise<void> => {
  const path = `/api/exams/${examId}/release?questionIdx=${questionIdx}`;
  await fetch<void>(path, {
    method: 'POST',
  });
};

export const concludeExam = async (examId: number): Promise<void> => {
  const path = `/api/exams/${examId}/conclude`;
  await fetch<void>(path, {
    method: 'POST',
  });
};

export const releaseAllPoints = async (examId: number): Promise<void> => {
  const path = `/api/exams/${examId}/sittings`;
  await fetch<void>(path, {
    method: 'PATCH',
    body: {
      pointsReleased: true,
    },
  });
};

export const deleteExam = async (examId: number): Promise<void> => {
  const path = `/api/exams/${examId}`;
  await fetch<void>(path, {
    method: 'DELETE',
  });
};

export const deleteAllSittings = async (examId: number): Promise<void> => {
  const path = `/api/exams/${examId}/sittings`;
  await fetch<void>(path, {
    method: 'DELETE',
  });
};
