import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';

import { fetchBanks } from '../../service/questionbank';
import { fetchExamEvents } from '../../service/event';

import { blocking } from '../util/decorators';
import withErrorScreen from '../util/withErrorScreen';
import AsyncComponent from '../util/AsyncComponent';
import OverlayButton from '../util/OverlayButton';
import { releaseQuestion, concludeExam } from '../../service/exam';

class ExamTimeline extends AsyncComponent {
  constructor(props) {
    super(props);

    this.state = {
      blocking: false,
      loaded: false,
      banksToReleases: [],
      count: 0,
      page: 1,
      perPage: 50,
      sortBy: 'bankOrder',
      sortDirection: 'asc',
    };

    this.fetchBanksAndEvents = this.fetchBanksAndEvents.bind(this);
    this.onRelease = this.onRelease.bind(this);
    this.onConclude = this.onConclude.bind(this);

    this.fetchBanksAndEvents = blocking(this.fetchBanksAndEvents, this);
    this.onRelease = blocking(this.onRelease, this);
    this.onConclude = blocking(this.onConclude, this);
  }

  componentDidMount() {
    this.fetchBanksAndEvents();
  }

  async fetchBanksAndEvents() {
    const questionBankCollection = await fetchBanks(this.props.exam.id, {
      perPage: 50,
      sortBy: 'bankOrder',
      sortDirection: 'asc',
    });
    const questionBanks = questionBankCollection.entities;

    const eventsCollection = await fetchExamEvents(this.props.exam.id, {
      perPage: 50,
      eventType: 'questionReleased',
    });
    const events = eventsCollection.entities;

    const banksToReleases = questionBanks.map((bank) => ({
      bankId: bank.id,
      bankName: bank.name,
      bankOrder: bank.bankOrder,
      chooseQuantity: bank.chooseQuantity,
      questions: [],
    }));

    let questionIdx = 0;
    let nextQuestionIdx = -1;
    for (let i = 0; i < banksToReleases.length; i += 1) {
      for (let j = 0; j < banksToReleases[i].chooseQuantity; j += 1) {
        banksToReleases[i].questions[j] = {
          questionIdx,
          // eslint-disable-next-line no-loop-func
          events: events.filter((event) => Number(event.parameters) === questionIdx),
        };
        if (!banksToReleases[i].questions[j].events.length && nextQuestionIdx === -1) {
          nextQuestionIdx = questionIdx;
        }
        questionIdx += 1;
      }
    }

    this.setState({
      loaded: true,
      banksToReleases,
      nextQuestionIdx,
    });
  }

  async onRelease(questionIdx) {
    await releaseQuestion(this.props.exam.id, questionIdx);
    await this.fetchBanksAndEvents();
  }

  async onConclude() {
    await concludeExam(this.props.exam.id);
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading timeline...</div>;
    }

    if (!this.state.banksToReleases.length) {
      return <div className="infomsg">No question banks to show</div>;
    }

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div className="row">
          <div className="col-md-12 titlebar">
            <div className="float-right btn-group">
              {/* question release */}
              <OverlayButton
                disabled={this.state.nextQuestionIdx === -1}
                variant="success"
                onClick={() => this.onRelease(this.state.nextQuestionIdx)}
              >
                <FontAwesomeIcon icon="paper-plane" />
                &nbsp;Release question&nbsp;
                <b>{this.state.nextQuestionIdx !== -1 ? this.state.nextQuestionIdx + 1 : ''}</b>
              </OverlayButton>
              <OverlayButton variant="warning" onClick={this.onConclude}>
                <FontAwesomeIcon icon="flag-checkered" />
                &nbsp;Conclude exam
              </OverlayButton>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-hover">
              <tbody>
                {this.state.banksToReleases.map((bank) => (
                  <tr key={bank.bankId}>
                    <td>
                      <div className="float-left mr-3">
                        <dl>
                          <dt>
                            <Link to={`/exams/${this.props.exam.id}/banks/${bank.bankId}/`}>
                              {bank.bankOrder + 1}. {bank.bankName}
                            </Link>
                          </dt>
                          <dd>
                            <ul className="timeline">
                              {bank.questions.map((question) => (
                                <li key={question.questionIdx}>
                                  <div className="timeline-question">
                                    Question {question.questionIdx + 1}&nbsp;
                                    <OverlayButton
                                      size="sm"
                                      onClick={() => this.onRelease(question.questionIdx)}
                                      tooltip={!question.events.length ? 'Release question' : 'Re-release this question'}
                                      variant={!question.events.length ? 'outline-success' : 'outline-warning'}
                                    >
                                      <FontAwesomeIcon icon="paper-plane" />
                                    </OverlayButton>
                                  </div>
                                  {question.events.map((event) => (
                                    <div key={event.id} className="timeline-event">
                                      <FontAwesomeIcon icon="paper-plane" />
                                      &nbsp;
                                      <span className="likeALink">{event.actorName}</span>&nbsp; released at{' '}
                                      {new Date(event.date).toLocaleString()}&nbsp;
                                    </div>
                                  ))}
                                </li>
                              ))}
                            </ul>
                          </dd>
                        </dl>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </BlockUi>
    );
  }
}

ExamTimeline.propTypes = {
  exam: PropTypes.object.isRequired,
};

export default withRouter(withErrorScreen(ExamTimeline));
