export type ProctorSeverity = 'low' | 'medium' | 'high';

export interface ProctoringEntryReducedDto {
  id: number;
  filename: string;
  streamingObjectKey: string;
  timestampStart: number;
  timestampEnd: number;
  severity: ProctorSeverity;
  message: string;
}

export interface ProctoringEntryWithSittingQuestionDto extends ProctoringEntryReducedDto {
  questionId: number;
  questionOrder: number;
}

export interface ProctoringEntryDetailsDto extends ProctoringEntryWithSittingQuestionDto {
  sittingId: number;
  studentName: string;
}

export const instanceOfProctoringEntryReducedDto = (
  object: ProctoringEntryReducedDto | ProctoringEntryWithSittingQuestionDto | ProctoringEntryDetailsDto,
): object is ProctoringEntryWithSittingQuestionDto => !('questionId' in object) && !('sittingId' in object);

export const instanceOfProctoringEntryWithSittingQuestionDto = (
  object: ProctoringEntryReducedDto | ProctoringEntryWithSittingQuestionDto | ProctoringEntryDetailsDto,
): object is ProctoringEntryWithSittingQuestionDto => 'questionId' in object && !('sittingId' in object);

export const instanceOfProctoringEntryDetailsDto = (
  object: ProctoringEntryReducedDto | ProctoringEntryWithSittingQuestionDto | ProctoringEntryDetailsDto,
): object is ProctoringEntryDetailsDto => 'sittingId' in object;
