/* eslint-disable complexity */
import React, { createRef, KeyboardEvent, RefObject } from 'react';
import autoBind from 'auto-bind';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Button, Tooltip, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isEqual } from 'lodash';

import { updateSittingQuestionComment } from '../../service/sittingquestion';
import { fetchStreams } from '../../service/filestorage';

import VideoPlayer from '../util/VideoPlayer';
import AsyncComponent from '../util/AsyncComponent';
import withErrorScreen from '../util/withErrorScreen';
import TextEditor from '../util/TextEditor';
import { blocking } from '../util/decorators';
import QuestionEditor, { QuestionEditorModel } from '../question/QuestionEditor';
import { secondsToHrShortText } from '../../util/date';
import { SittingQuestionDto, sittingQuestionStateMappings } from '../../model';
import { SittingQuestionStreams } from '../../model/filestorage';

import './SittingQuestionNavigationEntry.scss';
import { parseQuery } from '../../util/queryparser';
import SittingQuestionReportList from '../reportlist/SittingQuestionReportList';

type SittingQuestionNavigationEntryProps = RouteComponentProps & {
  match: { params: { [key: string]: string } };
  question: SittingQuestionDto;
  streamingPath?: string;
  questionStreams?: SittingQuestionStreams;
  maintainer: boolean;
  pointsReleased: boolean;
  showStudentName?: boolean;
  showQuestionBank?: boolean;
  showQuestionOrder?: boolean;
  onUpdateSittingQuestionPoints: (event: SittingQuestionPointUpdateEvent) => Promise<void>;
};

type SittingQuestionNavigationEntryState = {
  question: SittingQuestionDto;
  questionModel: QuestionEditorModel;
  questionStreams: SittingQuestionStreams;
  numAvailableStreams: number;
  streamingObjectKey: string;
  streamingPath: string;
  editingComment: boolean;
  points: number;
  pointsChanged: boolean;
  page: 'points' | 'proctoring';
  skipTime: number | undefined;
  videoLoaded: boolean;
  videoPlayerRef: RefObject<VideoPlayer>;
};

type SittingQuestionPointUpdateEvent = {
  sittingQuestionId: number;
  sittingId: number;
  points: number;
};

class SittingQuestionNavigationEntry extends AsyncComponent<SittingQuestionNavigationEntryProps, SittingQuestionNavigationEntryState> {
  constructor(props) {
    super(props);

    const questionStreams = this.props.questionStreams || {};
    const numAvailableStreams = Object.keys(questionStreams).length;
    const streamingObjectKey = Object.keys(questionStreams)[0];

    this.state = {
      question: this.props.question,
      questionStreams,
      numAvailableStreams,
      streamingObjectKey,
      streamingPath: this.props.streamingPath,
      questionModel: new QuestionEditorModel(this.props.question),
      editingComment: false,
      points: this.props.question.points,
      pointsChanged: false,
      page: 'points',
      skipTime: undefined,
      videoLoaded: false,
      videoPlayerRef: createRef(),
    };

    autoBind(this);

    this.saveComment = blocking(this.saveComment, this);
    this.savePoints = blocking(this.savePoints, this);
  }

  async componentDidMount() {
    // streams not received from above, i.e. not common, will load here
    const { streamingPath, questionId } = this.props.question;
    if (this.state.numAvailableStreams === 0 && streamingPath) {
      const streams = (await fetchStreams(streamingPath)) || {};
      const questionStreams: SittingQuestionStreams = streams[questionId] || {};
      const numAvailableStreams = Object.keys(questionStreams).length;
      const streamingObjectKey = Object.keys(questionStreams)[0];

      this.setState({
        questionStreams,
        numAvailableStreams,
        streamingObjectKey,
        streamingPath,
      });
    }

    const query = parseQuery(this.props.location.search);
    const skipTime = Number(query.skip);
    if (Number(query.questionId) === questionId && !Number.isNaN(skipTime)) {
      this.setState({ skipTime });
    }
  }

  componentDidUpdate(prevProps: SittingQuestionNavigationEntryProps) {
    if (!isEqual(prevProps.questionStreams, this.props.questionStreams)) {
      const questionStreams = this.props.questionStreams || {};
      const numAvailableStreams = Object.keys(questionStreams).length;
      const streamingObjectKey = Object.keys(questionStreams)[0];

      this.setState({
        questionStreams,
        numAvailableStreams,
        streamingObjectKey,
      });
    }

    const { questionId } = this.props.question;
    const query = parseQuery(this.props.location.search);

    if (prevProps.location.search !== this.props.location.search) {
      const prevQuery = parseQuery(prevProps.location.search);
      const skipTime = Number(query.skip);

      // set/reset skipTime based on query params
      if (Number(query.questionId) === questionId && !Number.isNaN(skipTime)) {
        this.setState({ skipTime });
      } else if (Number(prevQuery.questionId) === questionId) {
        this.setState({ skipTime: undefined });
      }
    }

    // scroll to component if questionId matches
    if (Number(query.questionId) === questionId) {
      if (this.state.videoPlayerRef.current) {
        this.state.videoPlayerRef.current.scrollIntoView();
      }
    }
  }

  async saveComment(comment) {
    const examId = Number(this.props.match.params.examId);
    const sittingId = this.props.question.sittingId || Number(this.props.match.params.sittingId);

    const question = await updateSittingQuestionComment(examId, sittingId, this.props.question.id, comment);

    this.setState({ question });
  }

  async onStreamObjectKeyChanged(event): Promise<void> {
    const { numAvailableStreams } = this.state;

    const streamingObjectKey = event.target.value;
    await this.setStateAsync({
      streamingObjectKey,
      numAvailableStreams: 0,
    });
    // workaround to force video player re-render
    // TODO: use https://www.npmjs.com/package/react-player
    await this.setStateAsync({
      numAvailableStreams,
    });
  }

  onPointsChanged(event) {
    const newPoints = Number(event.target.value);
    this.setState({
      points: newPoints,
      pointsChanged: this.props.question.points !== newPoints,
    });
  }

  async savePoints() {
    await this.props.onUpdateSittingQuestionPoints({
      sittingQuestionId: this.props.question.id,
      sittingId: this.props.question.sittingId,
      points: this.state.points,
    });
    await this.setStateAsync({ pointsChanged: false });
  }

  cancelEditingPoints() {
    this.setState({
      points: this.props.question.points,
      pointsChanged: false,
    });
  }

  onKeyUp(event: KeyboardEvent): void {
    // enter
    if (event.key === 'Enter' || event.keyCode === 13) {
      this.savePoints();
    }
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.cancelEditingPoints();
    }
  }

  onVideoLoaded() {
    this.setState({ videoLoaded: true });
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    const { question, match } = this.props;
    const { questionStreams, numAvailableStreams, streamingObjectKey, streamingPath, skipTime, page } = this.state;
    const { examId } = match.params;
    const sittingId = this.props.question.sittingId || Number(this.props.match.params.sittingId);
    const showElapsedTime = question.state !== 'unacknowledged' && question.totalTime !== question.timeRemaining;

    return (
      <div>
        <div className="md-12">
          <dl>
            <dt>
              <span>
                {this.props.showQuestionOrder && <>{question.questionOrder + 1}. </>}
                {question.questionId ? `Question ${question.questionId}` : <i>Archived question</i>}
              </span>
              <span className="objectstreams">
                &nbsp;·&nbsp;
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tt-state-${question.id}`}>{sittingQuestionStateMappings[question.state].prettyName}</Tooltip>}
                >
                  <FontAwesomeIcon icon={sittingQuestionStateMappings[question.state].faIcon as IconProp} />
                </OverlayTrigger>
                &nbsp;
              </span>

              <div className="float-right infomsg">
                <FontAwesomeIcon icon="stopwatch" />
                &nbsp;
                {showElapsedTime ? secondsToHrShortText(question.totalTime - question.timeRemaining) : '--'}
                &nbsp;/&nbsp;
                {secondsToHrShortText(question.totalTime)}
              </div>
            </dt>
            <dd>
              {/* TODO fix question bank showing */}
              {/* show question bank
              {(this.props.showQuestionBank && question.bankId) && <>
                <span style={{ paddingBottom: 8, display: 'inline-block' }}>
                  from question bank&nbsp;
                  {this.props.maintainer
                    ? <Link to={`/exams/${examId}/banks/${question.bankId}`}><i>{question.bankName}</i></Link>
                    : <i>{question.bankName}</i>}
                </span>
              </>} */}

              {/* show student name and link */}
              {this.props.showStudentName && (
                <>
                  <div style={{ paddingBottom: 8 }}>
                    <FontAwesomeIcon icon="user-graduate" />
                    &nbsp;<i>{question.studentName}</i>&nbsp;·&nbsp;
                    <Link to={`/exams/${examId}/sittings/${question.sittingId}`}>
                      <i>Go to sitting {question.sittingId}</i>
                    </Link>
                  </div>
                </>
              )}

              {/* question content with answers if relevant */}
              <QuestionEditor
                model={this.state.questionModel}
                mode={this.props.maintainer || this.props.pointsReleased ? 'evaluate' : 'response'}
              />

              {/* show video player if streaming was active */}
              {numAvailableStreams > 0 && (
                <>
                  <hr className="large-hr" />
                  <div>
                    <b>View recorded streams:</b>&nbsp;
                    {/* video selector if more than one stream */}
                    {numAvailableStreams > 1 && (
                      <>
                        <select
                          value={streamingObjectKey}
                          onChange={this.onStreamObjectKeyChanged}
                          style={{ fontSize: '90%', padding: 4, marginBottom: 8 }}
                        >
                          {Object.entries(questionStreams).map(([key, entry]) => (
                            <option key={`stream-${question.id}.${key}`} value={key}>
                              {entry.webcam && entry.webcam.dateStarted
                                ? new Date(entry.webcam.dateStarted).toLocaleString()
                                : 'unknown start time'}
                              &nbsp;--&nbsp;
                              {entry.webcam && entry.webcam.dateFinished
                                ? new Date(entry.webcam.dateFinished).toLocaleString()
                                : 'unknown end time'}
                              {question.answerStartStreamingKey === key && ' (answer marked here)'}
                            </option>
                          ))}
                        </select>
                        &nbsp;
                      </>
                    )}
                    {/* label if only one stream */}
                    {numAvailableStreams === 1 && (
                      <>
                        <i style={{ fontSize: '90%' }}>
                          {questionStreams[streamingObjectKey].webcam && questionStreams[streamingObjectKey].webcam.dateStarted
                            ? new Date(questionStreams[streamingObjectKey].webcam.dateStarted).toLocaleString()
                            : 'unknown start time'}
                          &nbsp;--&nbsp;
                          {questionStreams[streamingObjectKey].webcam && questionStreams[streamingObjectKey].webcam.dateFinished
                            ? new Date(questionStreams[streamingObjectKey].webcam.dateFinished).toLocaleString()
                            : 'unknown end time'}
                        </i>
                      </>
                    )}
                  </div>
                  {streamingObjectKey in questionStreams && (
                    <>
                      <VideoPlayer
                        ref={this.state.videoPlayerRef}
                        streamDescription={questionStreams[streamingObjectKey]}
                        streamingPath={streamingPath}
                        answerStartPoint={question.answerStartPoint}
                        answerStartTime={question.answerStartStreamingKey === streamingObjectKey ? question.answerStartTime : undefined}
                        onVideoLoaded={this.onVideoLoaded}
                        skipTime={skipTime}
                      />
                    </>
                  )}
                </>
              )}
            </dd>
          </dl>
        </div>
        <hr className="large-hr" />
        <div>
          {/* nav tab panel */}
          {this.props.maintainer && numAvailableStreams > 0 && question.state !== 'unacknowledged' && (
            <>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link active={page === 'points'} onClick={() => this.setState({ page: 'points' })}>
                    Points
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link active={page === 'proctoring'} onClick={() => this.setState({ page: 'proctoring' })}>
                    Reports
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </>
          )}

          {page === 'points' && (
            <>
              <div>
                &nbsp;
                {(this.props.maintainer || this.props.pointsReleased) && (
                  <div className="float-left comment-block">
                    <TextEditor
                      text={this.state.question.comment}
                      header={
                        <i>
                          {this.state.question.commenterName || <b>Deleted User</b>} has left the following comment at{' '}
                          {new Date(this.state.question.commentTime).toLocaleString()}
                        </i>
                      }
                      emptyHeader={<i>No comment left for this sitting question...</i>}
                      editLabel="Edit comment"
                      saveLabel="Save comment"
                      cancelLabel="Cancel editing comment"
                      editable={this.props.maintainer}
                      size="small"
                      onSave={this.saveComment}
                    />
                  </div>
                )}
                <div className="float-right question-points">
                  Points:&nbsp;
                  {this.props.maintainer ? (
                    <>
                      <form className="inline-form" onSubmit={(e) => e.preventDefault()} noValidate>
                        <input
                          type="number"
                          name="points"
                          className="mini-input"
                          min={0.0}
                          max={100.0}
                          step={0.1}
                          value={this.state.points === undefined || this.state.points === null ? '' : this.state.points}
                          onChange={this.onPointsChanged}
                          onKeyUp={this.onKeyUp}
                          required
                        />
                      </form>
                      &nbsp;
                      {this.state.pointsChanged && (
                        <span>
                          <OverlayTrigger placement="top" overlay={<Tooltip id={`tt-save-${question.id}`}>Save points</Tooltip>}>
                            <Button className="close" onClick={this.savePoints}>
                              <FontAwesomeIcon icon="check" size="xs" />
                            </Button>
                          </OverlayTrigger>
                          &nbsp;
                          <OverlayTrigger placement="top" overlay={<Tooltip id={`tt-cancel-${question.id}`}>Cancel editing</Tooltip>}>
                            <Button className="close" onClick={this.cancelEditingPoints}>
                              <FontAwesomeIcon icon="times" size="xs" />
                            </Button>
                          </OverlayTrigger>
                        </span>
                      )}
                    </>
                  ) : (
                    <>{this.props.pointsReleased ? question.points : '--'}</>
                  )}{' '}
                  / {question.possiblePoints}
                </div>
              </div>
            </>
          )}

          {page === 'proctoring' && (
            <>
              <SittingQuestionReportList
                examId={examId}
                sittingId={sittingId}
                sittingQuestionId={this.props.question.id}
                streamingObjectKey={streamingObjectKey}
                questionId={this.props.question.questionId}
                maintainer={this.props.maintainer}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(withErrorScreen(SittingQuestionNavigationEntry));
