import { Streams, SyncFileUploadResponse, SyncFileUploadResult } from '../model/filestorage';
import { fileStorageHttpUrl } from '../util/config';
import fetch from '../util/fetch';

const sanitizeUserName = (userName: string): string => {
  if (!userName) {
    return 'unknown_user';
  }

  let ret = userName.toLowerCase();
  ret = ret.replace(/[ \t\n\r]/g, '_');
  ret = ret.replace(/[^a-zA-Z0-9_-]/g, '');
  return ret;
};

export const fetchStreams = async (streamingPath: string): Promise<Streams> => {
  const path = `${fileStorageHttpUrl}/${streamingPath}/streams.json`;
  const response = await fetch<Streams>(path, { failOnError: false });
  if (response.status >= 400 || !response.body) {
    return undefined;
  }
  return response.body;
};

export const uploadResource = async (
  resourceRoot: string,
  resourceName: string,
  resourceContent: string | Blob,
): Promise<SyncFileUploadResult> => {
  const formData = new FormData();
  // console.log(resourceRoot, resourceName, resourceContent);
  formData.append('file', resourceContent, resourceName);

  const path = `${fileStorageHttpUrl}/${resourceRoot}`;
  const response = await fetch<SyncFileUploadResponse, FormData>(path, {
    method: 'POST',
    body: formData,
  });

  return {
    ...response.body,
    url: `${fileStorageHttpUrl}/${response.body.location}`,
  };
};

export const resourceExists = async (resourceRoot: string): Promise<boolean> => {
  const path = `${fileStorageHttpUrl}/${resourceRoot}`;
  const response = await fetch(path, {
    method: 'HEAD',
    failOnError: false,
  });
  return response.status === 200;
};

export const uploadUserResource = async (
  userId: number,
  userName: string,
  resourceName: string,
  resourceContent: string | Blob,
): Promise<SyncFileUploadResult> => {
  const userNameSanitized = sanitizeUserName(userName);
  const resourceRoot = `user${userId}_${userNameSanitized}`;

  const formData = new FormData();
  formData.append('file', resourceContent, resourceName);

  const path = `${fileStorageHttpUrl}/${resourceRoot}/`;
  const response = await fetch<SyncFileUploadResponse, FormData>(path, {
    method: 'POST',
    body: formData,
  });

  return {
    ...response.body,
    url: `${fileStorageHttpUrl}/${response.body.location}`,
  };
};

export const uploadExamResource = async (
  groupId: number,
  examId: number,
  resourceName: string,
  resourceContent: string | Blob,
): Promise<SyncFileUploadResult> => {
  const groupIdPadded = groupId.toString().padStart(4, '0');
  const examIdPadded = examId.toString().padStart(4, '0');
  const resourceRoot = `group${groupIdPadded}/exam${examIdPadded}/resources`;

  const formData = new FormData();
  formData.append('file', resourceContent, resourceName);

  const path = `${fileStorageHttpUrl}/${resourceRoot}/`;
  const response = await fetch<SyncFileUploadResponse, FormData>(path, {
    method: 'POST',
    body: formData,
  });

  return {
    ...response.body,
    url: `${fileStorageHttpUrl}/${response.body.location}`,
  };
};

export const uploadGroupResource = async (
  groupId: number,
  resourceName: string,
  resourceContent: string | Blob,
): Promise<SyncFileUploadResult> => {
  const groupIdPadded = groupId.toString().padStart(4, '0');
  const resourceRoot = `group${groupIdPadded}/resources`;

  const formData = new FormData();
  formData.append('file', resourceContent, resourceName);

  const path = `${fileStorageHttpUrl}/${resourceRoot}/`;
  const response = await fetch<SyncFileUploadResponse, FormData>(path, {
    method: 'POST',
    body: formData,
  });

  return {
    ...response.body,
    url: `${fileStorageHttpUrl}/${response.body.location}`,
  };
};

export const moveToExamResource = async (resourceName: string, groupId: number, examId: number): Promise<SyncFileUploadResult> => {
  const groupIdPadded = groupId.toString().padStart(4, '0');
  const examIdPadded = examId.toString().padStart(4, '0');
  const destinationResourceRoot = `group${groupIdPadded}/exam${examIdPadded}/resources`;

  const formData = new FormData();
  formData.append('move', resourceName);

  const path = `${fileStorageHttpUrl}/${destinationResourceRoot}/`;
  const response = await fetch<SyncFileUploadResponse, FormData>(path, {
    method: 'PUT',
    body: formData,
  });

  return {
    ...response.body,
    url: `${fileStorageHttpUrl}/${response.body.location}`,
  };
};

export const moveToGroupResource = async (resourceName: string, groupId: number): Promise<SyncFileUploadResult> => {
  const groupIdPadded = groupId.toString().padStart(4, '0');
  const destinationResourceRoot = `group${groupIdPadded}/resources`;

  const formData = new FormData();
  formData.append('move', resourceName);

  const path = `${fileStorageHttpUrl}/${destinationResourceRoot}/`;
  const response = await fetch<SyncFileUploadResponse, FormData>(path, {
    method: 'PUT',
    body: formData,
  });

  return {
    ...response.body,
    url: `${fileStorageHttpUrl}/${response.body.location}`,
  };
};
