import React, { createRef, RefObject } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import BlockUi from 'react-block-ui';
import autoBind from 'auto-bind';

import AsyncComponent from './util/AsyncComponent';
import Paginator from './util/Paginator';
import { blocking } from './util/decorators';
import withErrorScreen from './util/withErrorScreen';
import withBreadcrumbs, { WithBreadcrumbsProps } from './util/withBreadcrumbs';
import { addQueryParameters, parseBool, parseQuery } from '../util/queryparser';
import {
  updateSittingQuestionPoints,
  fetchSittingQuestionsByBankId,
  fetchSittingQuestionsByQuestionId,
  SittingQuestionFilterOptions,
  defaultSittingQuestionOptions,
} from '../service/sittingquestion';
import { fetchExamById } from '../service/exam';

import SittingQuestionNavigator from './sittingDetails/SittingQuestionNavigator';
import { fetchBankById } from '../service/questionbank';
import { fetchQuestionById } from '../service/question';
import {
  Collection,
  ExamDetailsDto,
  QuestionBankDto,
  QuestionDto,
  SittingQuestionDto,
  SittingQuestionState,
  sittingQuestionStateMappings,
} from '../model';
import FilterBy from './util/FilterBy';
import SortBy from './util/SortBy';
import SearchBy from './util/SearchBy';
import storage from '../util/storage';

type SittingQuestionSearchPathParams = {
  examId: string;
  bankId: string;
  questionId?: string;
};

type SittingQuestionSearchProps = WithBreadcrumbsProps & RouteComponentProps<SittingQuestionSearchPathParams>;

type SittingQuestionSearchState = {
  loaded: boolean;
  blocking: false;
  exam: ExamDetailsDto;
  bank: QuestionBankDto;
  question: QuestionDto;
  sittingQuestions: Collection<SittingQuestionDto>;
  options: SittingQuestionFilterOptions;
  pageTopRef: RefObject<HTMLDivElement>;
};

class SittingQuestionSearch extends AsyncComponent<SittingQuestionSearchProps, SittingQuestionSearchState> {
  constructor(props: SittingQuestionSearchProps) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      exam: null,
      bank: null,
      question: null,
      sittingQuestions: {
        entities: [],
        count: 0,
      },
      options: {},
      pageTopRef: createRef(),
    };

    autoBind(this);

    this.fetchByPathParams = blocking(this.fetchByPathParams, this, {
      autoClose: true,
      keepTrying: true,
    });
    this.fetchQuestions = blocking(this.fetchQuestions, this, {
      autoClose: true,
      keepTrying: true,
    });
    this.onUpdateSittingQuestionPoints = blocking(this.onUpdateSittingQuestionPoints, this);
  }

  async searchToOptions() {
    const query = parseQuery(this.props.location.search);
    await this.setStateAsync({
      options: {
        studentName: query.studentName || '',
        page: Number(query.page) || 1,
        perPage: Number(query.perPage) || 5,
        state: query.state as SittingQuestionState,
        graded: parseBool(query.graded),
        sortBy: storage.sittingQuestionSortBy.get(query.sortBy),
        sortDirection: storage.sittingQuestionSortDirection.get(query.sortDirection),
      },
    });
  }

  async componentDidMount() {
    await this.searchToOptions();
    await this.fetchByPathParams();

    const search = addQueryParameters(this.props.location.search, this.state.options, defaultSittingQuestionOptions);
    if (search !== this.props.location.search) {
      this.props.history.replace(search);
    } else {
      await this.fetchQuestions();
    }
  }

  async componentDidUpdate(prevProps: SittingQuestionSearchProps) {
    if (prevProps.location.search !== this.props.location.search) {
      storage.sittingQuestionSortBy.set(this.state.options.sortBy);
      storage.sittingQuestionSortDirection.set(this.state.options.sortDirection);

      await this.searchToOptions();
      await this.fetchQuestions();
      if (this.state.pageTopRef.current) {
        this.state.pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onOptionsChange(newOptions: Partial<SittingQuestionFilterOptions>) {
    const options = {
      ...this.state.options,
      ...newOptions,
    };

    const search = addQueryParameters(this.props.location.search, options, defaultSittingQuestionOptions);
    if (search !== this.props.location.search) {
      this.props.history.push(search);
    }

    this.setState({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
  }

  async fetchByPathParams() {
    const { params } = this.props.match;
    const examId = Number(params.examId);
    const bankId = Number(params.bankId);
    const questionId = Number(params.questionId);

    const exam = await fetchExamById(examId);
    const bank = await fetchBankById(examId, bankId);

    let question;
    if (questionId) {
      question = await fetchQuestionById(examId, bankId, questionId);
    }

    await this.setStateAsync({
      exam,
      bank,
      question,
    });
  }

  async fetchQuestions() {
    const { params } = this.props.match;
    const examId = Number(params.examId);
    const bankId = Number(params.bankId);
    const questionId = Number(params.questionId);

    const { exam, bank, options } = this.state;

    let sittingQuestions;
    if (questionId) {
      sittingQuestions = await fetchSittingQuestionsByQuestionId(examId, questionId, options);
    } else {
      sittingQuestions = await fetchSittingQuestionsByBankId(examId, bankId, options);
    }

    await this.setStateAsync({ loaded: true, sittingQuestions });

    if (questionId) {
      this.props.setBreadcrumbs([
        { path: '/exams/', text: 'Exams' },
        { path: `/exams/${exam.id}/`, text: exam.name },
        { path: `/exams/${exam.id}/banks/`, text: 'Banks' },
        { path: `/exams/${exam.id}/banks/${bank.id}/`, text: bank.name },
        { path: `/exams/${exam.id}/banks/${bank.id}/`, text: `Question ${questionId}` },
        { path: `/exams/${exam.id}/banks/${bank.id}/questions/${questionId}/sittingQuestions/`, text: 'Sitting questions' },
      ]);
    } else {
      this.props.setBreadcrumbs([
        { path: '/exams/', text: 'Exams' },
        { path: `/exams/${exam.id}/`, text: exam.name },
        { path: `/exams/${exam.id}/banks/`, text: 'Banks' },
        { path: `/exams/${exam.id}/banks/${bank.id}/`, text: bank.name },
        { path: `/exams/${exam.id}/banks/${bank.id}/sittingQuestions/`, text: 'Sitting questions' },
      ]);
    }
  }

  async onUpdateSittingQuestionPoints({ sittingId, sittingQuestionId, points }) {
    const { params } = this.props.match;
    const examId = Number(params.examId);
    await updateSittingQuestionPoints(examId, sittingId, sittingQuestionId, points);

    await this.setStateAsync({
      sittingQuestions: {
        count: this.state.sittingQuestions.count,
        entities: this.state.sittingQuestions.entities.map((question) =>
          question.id !== sittingQuestionId ? question : { ...question, points },
        ),
      },
    });
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading sitting...</div>;
    }

    const { options, sittingQuestions } = this.state;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div className="row">
          <div className="col-md-12 titlebar">
            <div className="float-left">
              <h3>
                Sitting question search&nbsp;-&nbsp;
                <i>{this.state.question ? <span>Question {this.state.question.id}</span> : <span>{this.state.bank.name}</span>}</i>
              </h3>
            </div>
          </div>
        </div>

        <div className="row" ref={this.state.pageTopRef}>
          <div className="col-md-12 titlebar">
            <FilterBy
              values={options}
              onChange={this.onOptionsChange}
              resetPage={true}
              options={[
                {
                  name: 'Gradedness',
                  queryKey: 'graded',
                  options: [
                    { key: 'all', label: 'Show all', value: undefined, default: true },
                    { key: 'graded', label: 'Only graded', value: true },
                    { key: 'ungraded', label: 'Only ungraded', value: false },
                  ],
                },
                {
                  name: 'State',
                  queryKey: 'state',
                  options: [
                    { key: 'all', label: 'Show all', value: undefined, default: true },
                    ...Object.entries(sittingQuestionStateMappings).map(([state, { prettyName }]) => ({
                      key: state,
                      label: prettyName,
                      value: state,
                    })),
                  ],
                },
              ]}
            />
            <SortBy
              options={options}
              onChange={this.onOptionsChange}
              keys={{
                sittingStartTime: 'Sitting start time',
                sittingStudentName: 'Student name',
                points: 'Points',
              }}
            />
            <SearchBy
              queryKey="studentName"
              term={options.studentName}
              onChange={this.onOptionsChange}
              placeholder="Search by student name..."
              resetPage={true}
            />
          </div>
        </div>

        <Paginator options={options} count={sittingQuestions.count} onChange={this.onOptionsChange} />

        <div>
          <SittingQuestionNavigator
            questions={this.state.sittingQuestions.entities}
            maintainer={this.state.exam.maintainer}
            blocking={this.state.blocking}
            pointsReleased
            showStudentName
            onUpdateSittingQuestionPoints={this.onUpdateSittingQuestionPoints}
          />
        </div>

        <Paginator options={options} count={sittingQuestions.count} onChange={this.onOptionsChange} />
      </BlockUi>
    );
  }
}

export default withRouter(withErrorScreen(withBreadcrumbs(SittingQuestionSearch)));
