import React, { createRef, RefObject } from 'react';
import autoBind from 'auto-bind';
import { RouteComponentProps, withRouter } from 'react-router';
import BlockUi from 'react-block-ui';
import { Nav } from 'react-bootstrap';

import ExamList from './exams/ExamList';
import ExamListHeader from './exams/ExamListHeader';
import AsyncComponent from './util/AsyncComponent';
import { blocking } from './util/decorators';
import withErrorScreen from './util/withErrorScreen';
import { addQueryParameters, parseBool, parseQuery } from '../util/queryparser';
import Paginator from './util/Paginator';

import { defaultExamOptions, ExamFilterOptions, fetchExams } from '../service/exam';
import { Collection, ExamReducedDto, ExamType } from '../model';
import storage, { ExamsPage } from '../util/storage';

type ExamsProps = RouteComponentProps;

type ExamsState = {
  loaded: boolean;
  blocking: boolean;
  exams: Collection<ExamReducedDto>;
  page: ExamsPage;
  options: ExamFilterOptions;
  pageTopRef: RefObject<HTMLDivElement>;
};

class Exams extends AsyncComponent<ExamsProps, ExamsState> {
  constructor(props: ExamsProps) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      exams: {
        entities: [],
        count: 0,
      },
      page: 'all',
      options: {},
      pageTopRef: createRef(),
    };

    autoBind(this);

    this.fetchExams = blocking(this.fetchExams, this, {
      autoClose: true,
      keepTrying: true,
    });
  }

  async searchToOptions() {
    const query = parseQuery(this.props.location.search);

    let page: ExamsPage;
    if (parseBool(query.starred)) {
      page = 'starred';
    } else if (parseBool(query.upcoming)) {
      page = 'upcoming';
    } else {
      page = storage.examPage.get();
    }

    await this.setStateAsync({
      page,
      options: {
        name: query.name || '',
        starred: page === 'starred' ? true : null,
        upcoming: page === 'upcoming' ? true : null,
        examType: query.examType as ExamType,
        groupId: Number(query.groupId) || undefined,
        streaming: parseBool(query.streaming),
        page: Number(query.page) || 1,
        perPage: Number(query.perPage) || 25,
        sortBy: storage.examSortBy.get(query.sortBy),
        sortDirection: storage.examSortDirection.get(query.sortDirection),
      },
    });
  }

  async componentDidMount() {
    await this.searchToOptions();
    const search = addQueryParameters(this.props.location.search, this.state.options, defaultExamOptions);
    if (search !== this.props.location.search) {
      this.props.history.replace(search);
    } else {
      await this.fetchExams();
    }
  }

  async componentDidUpdate(prevProps: ExamsProps) {
    if (prevProps.location.search !== this.props.location.search) {
      storage.examSortBy.set(this.state.options.sortBy);
      storage.examSortDirection.set(this.state.options.sortDirection);

      await this.searchToOptions();
      await this.fetchExams();
      if (this.state.pageTopRef.current) {
        this.state.pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onOptionsChange(newOptions: Partial<ExamFilterOptions>) {
    const options = {
      ...this.state.options,
      ...newOptions,
    };

    const search = addQueryParameters(this.props.location.search, options, defaultExamOptions);
    if (search !== this.props.location.search) {
      this.props.history.push(search);
    }

    this.setState({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
  }

  onPageChange(page: ExamsPage): void {
    storage.examPage.set(page);
    this.onOptionsChange({
      starred: page === 'starred' ? true : null,
      upcoming: page === 'upcoming' ? true : null,
      page: 1,
    });
    this.setState({ page });
  }

  async fetchExams() {
    const exams = await fetchExams(this.state.options);
    await this.setStateAsync({ loaded: true, exams });
  }

  onUpload(exam) {
    this.props.history.push(`/exams/${exam.id}/`);
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading exams...</div>;
    }

    const { options, page } = this.state;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <ExamListHeader options={options} onOptionsChange={this.onOptionsChange} onUpload={this.onUpload} />

        <Nav variant="tabs" ref={this.state.pageTopRef}>
          <Nav.Item>
            <Nav.Link active={page === 'starred'} onClick={() => this.onPageChange('starred')}>
              Starred
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link active={page === 'upcoming'} onClick={() => this.onPageChange('upcoming')}>
              Upcoming
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link active={page === 'all'} onClick={() => this.onPageChange('all')}>
              All
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <ExamList exams={this.state.exams.entities} />

        <Paginator options={options} count={this.state.exams.count} onChange={this.onOptionsChange} />
      </BlockUi>
    );
  }
}

export default withRouter(withErrorScreen(Exams));
