import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DeleteModal } from '../util/Modals';
import OverlayButton from '../util/OverlayButton';

type BankPathParams = {
  examId: string;
  bankId: string;
};

type BankDetailsHeaderProps = RouteComponentProps<BankPathParams> & {
  name: string;
  onDelete?: () => unknown;
};

function BankDetailsHeader({ match, name, onDelete = null }: BankDetailsHeaderProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const confirmDeleteBank = useCallback(() => {
    setShowDeleteModal(false);
    onDelete?.();
  }, [setShowDeleteModal, onDelete]);

  const { examId, bankId } = match.params;

  return (
    <div className="row">
      <div className="col-md-12 titlebar">
        <div className="float-left">
          <h3>{name}</h3>
        </div>
        <div className="float-right btn-group">
          <OverlayButton tooltip="Sitting questions" variant="outline-info" to={`/exams/${examId}/banks/${bankId}/sittingQuestions/`}>
            <FontAwesomeIcon icon="question-circle" />
            &nbsp;Sitting questions
          </OverlayButton>
          <OverlayButton tooltip="Edit" variant="outline-warning" to={`/exams/${examId}/banks/${bankId}/edit`}>
            <FontAwesomeIcon icon="edit" />
            &nbsp;Edit
          </OverlayButton>
          <OverlayButton variant="outline-danger" onClick={() => setShowDeleteModal(true)}>
            <FontAwesomeIcon icon="trash" />
            &nbsp;Delete
          </OverlayButton>
        </div>
      </div>

      <DeleteModal show={showDeleteModal} onCancel={() => setShowDeleteModal(false)} onConfirm={confirmDeleteBank} title="Delete bank">
        <div>
          Are you sure you would like to delete the question bank&nbsp;
          <b>{name}</b>?
        </div>
        <div>All associated questions will be deleted. This action is irreversible.</div>
      </DeleteModal>
    </div>
  );
}

export default withRouter(BankDetailsHeader);
