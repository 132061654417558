import autoBind from 'auto-bind';
import QWebSocket, { ConnectCallback } from 'qws-browser';

import { wsReconnectIntervalMillis, wsReconnectNumTries } from './config';

/**
 * Cache of WebSocket endpoints we want to send data to.
 */

type WebSocketContainerConnectionOptions = {
  url: string;
  extraArgs?: Record<string, unknown>;
  onConnect: ConnectCallback;
  onErroneousDisconnect: () => void;
};

export default class WebSocketContainer {
  queue: { [id: string]: QWebSocket };
  onError?: (message: string) => void;

  constructor() {
    console.log('Initializing WebSocket container');
    autoBind(this);
    this.queue = {};
  }

  getConnection(id: string, options: WebSocketContainerConnectionOptions): QWebSocket {
    // check if connection is in container
    if (!(id in this.queue)) {
      console.log(`${id}: First time adding object to container`);

      this.queue[id] = new QWebSocket(options.url, {
        name: id,
        reconnect: true,
        reconnectNumTries: wsReconnectNumTries,
        reconnectIntervalMillis: wsReconnectIntervalMillis,
        ...options,
      });

      this.queue[id].onConnect(options.onConnect);

      this.queue[id].onErroneousDisconnect(options.onErroneousDisconnect);

      this.queue[id].onError((message: string) => {
        this.onError?.(message);
      });

      this.queue[id].onClose(() => {
        delete this.queue[id];
      });
    }
    return this.queue[id];
  }

  backedUp(): { messages: number; bytes: number } {
    let messages = 0;
    let bytes = 0;

    Object.values(this.queue).forEach((queueObject) => {
      messages += queueObject.queue.numUnackMessages;
      bytes += queueObject.queue.numUnackBytes;
      if (queueObject.wws.ws) {
        bytes += queueObject.wws.ws.bufferedAmount;
      }
    });

    return { messages, bytes };
  }
}
