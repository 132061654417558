import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import autoBind from 'auto-bind';

import { ExamDetailsDto, SittingReducedDto } from '../../model';
import OverlayButton from '../util/OverlayButton';
import { ConfirmModal, DeleteModal } from '../util/Modals';
import { blocking } from '../util/decorators';
import withErrorScreen from '../util/withErrorScreen';
import AsyncComponent from '../util/AsyncComponent';
import { isCodecAppropriate } from '../../util/media';
import { allowResume, deleteSitting } from '../../service/sitting';
import { updateCanvasPoints } from '../../service/canvas';

type SittingListEntryProps = {
  exam: ExamDetailsDto;
  maintainer: boolean;
  sitting: SittingReducedDto;
  onConcludeSitting: () => Promise<void>;
  onReleasePoints: () => Promise<void>;
  onUnreleasePoints: () => Promise<void>;
};

type SittingListEntryState = {
  blocking: boolean;
  deleted: boolean;
  resumable: boolean;
  showDeleteModal: boolean;
  showConcludeModal: boolean;
  prohibitMessage: string;
};

class SittingListEntry extends AsyncComponent<SittingListEntryProps, SittingListEntryState> {
  constructor(props: SittingListEntryProps) {
    super(props);

    this.state = {
      showDeleteModal: false,
      showConcludeModal: false,
      deleted: false,
      blocking: false,
      resumable: false,
      prohibitMessage: '',
    };

    autoBind(this);

    // this.uploadPointsToCanvas = blocking(this.uploadPointsToCanvas, this);
    this.confirmDeleteSitting = blocking(this.confirmDeleteSitting, this);
    this.confirmConcludeSitting = blocking(this.confirmConcludeSitting, this);
    this.onReleasePoints = blocking(this.onReleasePoints, this);
    this.onUnreleasePoints = blocking(this.onUnreleasePoints, this);
    this.onAllowResume = blocking(this.onAllowResume, this);
  }

  componentDidMount() {
    const { exam, sitting } = this.props;

    const resumableTimeMillis = exam.resumableTime * 1000;
    const millisSinceLastContact = new Date().getTime() - new Date(sitting.lastContactTime).getTime();
    const resumable = millisSinceLastContact <= resumableTimeMillis;

    // check 1. is this video exam and do we have the appropriate codec
    const appropriateVideo = !exam.streaming || isCodecAppropriate();
    if (!appropriateVideo) {
      this.setState({
        prohibitMessage: 'Browser not supported, please use Chrome',
        resumable,
      });
      return;
    }

    // check 2. check if still resumable
    this.setState({
      prohibitMessage: resumable ? '' : 'Resumable period is expired',
      resumable,
    });
  }

  async uploadPointsToCanvas() {
    const { exam, sitting } = this.props;
    const {
      group: { providerName, providerSpecificId },
      jsonMetadata: { assignment },
    } = exam;
    const { studentProviderSpecificId, totalPoints } = sitting;
    await updateCanvasPoints(providerName, providerSpecificId, assignment.id, studentProviderSpecificId, {
      points: totalPoints,
      examId: exam.id,
      sittingId: sitting.id,
    });
  }

  proposeDeleteSitting() {
    this.setState({ showDeleteModal: true });
  }

  cancelDeleteSitting() {
    this.setState({ showDeleteModal: false });
  }

  async confirmDeleteSitting() {
    await this.setStateAsync({ showDeleteModal: false });
    await deleteSitting(this.props.exam.id, this.props.sitting.id);
    this.setState({ deleted: true });
  }

  proposeConcludeSitting(): void {
    this.setState({ showConcludeModal: true });
  }

  cancelConcludeSitting(): void {
    this.setState({ showConcludeModal: false });
  }

  async confirmConcludeSitting(): Promise<void> {
    await this.setStateAsync({ showConcludeModal: false });
    await this.props.onConcludeSitting();
  }

  async onReleasePoints() {
    await this.props.onReleasePoints();
  }

  async onUnreleasePoints() {
    await this.props.onUnreleasePoints();
  }

  async onAllowResume() {
    const { exam, sitting } = this.props;
    await allowResume(exam.id, sitting.id);
    await this.setStateAsync({ resumable: true });
  }

  render() {
    if (this.state.deleted) {
      return <div className="infomsg">This sitting has been deleted.</div>;
    }

    const { exam, sitting, maintainer } = this.props;
    const { resumable } = this.state;

    const {
      group: { providerName },
      jsonMetadata,
    } = exam;
    const assignment = jsonMetadata?.assignment;

    const canUploadToCanvas = Boolean(providerName && assignment && sitting.studentProviderSpecificId);
    const finished = Boolean(sitting.finishTime);

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <Link to={`/exams/${exam.id}/sittings/${sitting.id}/`}>
          <div className="float-left mr-3">
            <dl>
              <dt>
                Sitting {sitting.id}&nbsp;
                {sitting.simulated && (
                  <OverlayTrigger placement="top" overlay={<Tooltip id={`simulation-${sitting.id}`}>simulation</Tooltip>}>
                    <FontAwesomeIcon icon="glasses" />
                  </OverlayTrigger>
                )}
                &nbsp;
                {finished ? (
                  <OverlayTrigger placement="top" overlay={<Tooltip id={`completed-${sitting.id}`}>completed</Tooltip>}>
                    <FontAwesomeIcon icon="check" />
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger placement="top" overlay={<Tooltip id={`inprogress-${sitting.id}`}>in progress</Tooltip>}>
                    <FontAwesomeIcon icon="spinner" />
                  </OverlayTrigger>
                )}
                &nbsp;
              </dt>
              <dd>
                {maintainer && (
                  <>
                    <FontAwesomeIcon icon="user-graduate" />
                    &nbsp;
                    {sitting.studentName || <i>Deleted User</i>}&nbsp;·&nbsp;
                  </>
                )}
                <>
                  <FontAwesomeIcon icon="calendar" />
                  &nbsp;
                  {new Date(sitting.startTime).toLocaleString()}&nbsp;·&nbsp;
                </>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`questions-${sitting.id}`}>{`${sitting.numQuestions} question${sitting.numQuestions !== 1 ? 's' : ''}/${
                      sitting.totalQuestions
                    }`}</Tooltip>
                  }
                >
                  <span>
                    <FontAwesomeIcon icon="hashtag" />
                    &nbsp;{sitting.numQuestions}
                  </span>
                </OverlayTrigger>

                {(maintainer || sitting.pointsReleased) && (
                  <span>
                    &nbsp;·&nbsp;
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`points-${sitting.id}`}>{`${sitting.totalPoints} point${sitting.totalPoints !== 1 ? 's' : ''}/${
                          sitting.totalPossiblePoints
                        }`}</Tooltip>
                      }
                    >
                      <span>
                        <FontAwesomeIcon icon="trophy" />
                        &nbsp;{sitting.totalPoints}p
                      </span>
                    </OverlayTrigger>
                  </span>
                )}
              </dd>
            </dl>
          </div>
        </Link>
        <div className="float-right btn-group">
          {/* student buttons */}
          {!maintainer && (
            <>
              <OverlayButton
                variant="outline-success"
                to={`/exams/${exam.id}/sittings/${sitting.id}/resume`}
                visible={!finished}
                tooltip="Resume sitting"
                disabled={Boolean(this.state.prohibitMessage)}
                disabledTooltip={this.state.prohibitMessage}
              >
                <FontAwesomeIcon icon="step-forward" />
              </OverlayButton>
            </>
          )}

          {/* conclude (common) */}
          <OverlayButton
            variant="outline-warning"
            onClick={this.props.maintainer ? this.props.onConcludeSitting : this.proposeConcludeSitting}
            visible={!finished}
            tooltip="Conclude sitting"
          >
            <FontAwesomeIcon icon="flag-checkered" />
          </OverlayButton>

          {/* maintainer buttons */}
          {maintainer && (
            <>
              {/* release */}
              <OverlayButton
                variant="outline-success"
                onClick={this.onUnreleasePoints}
                visible={!sitting.simulated && finished && sitting.pointsReleased}
                tooltip="Click to revoke points"
              >
                <FontAwesomeIcon icon="check-circle" />
              </OverlayButton>
              <OverlayButton
                variant="outline-secondary"
                onClick={this.onReleasePoints}
                visible={!sitting.simulated && finished && !sitting.pointsReleased}
                tooltip="Click to release points"
              >
                <FontAwesomeIcon icon="times-circle" />
              </OverlayButton>

              <OverlayButton
                variant="outline-info"
                onClick={this.onAllowResume}
                visible={!sitting.simulated && !finished && !resumable}
                tooltip="Allow student resume"
              >
                <FontAwesomeIcon icon="redo" />
              </OverlayButton>
              {/* upload to canvas */}
              <OverlayButton
                variant="outline-info"
                onClick={this.uploadPointsToCanvas}
                visible={!sitting.simulated && canUploadToCanvas && finished}
                tooltip="Upload to Canvas"
              >
                <FontAwesomeIcon icon="upload" />
              </OverlayButton>

              {/* delete */}
              <OverlayButton variant="outline-danger" onClick={this.proposeDeleteSitting} tooltip="Delete">
                <FontAwesomeIcon icon="trash" />
              </OverlayButton>
            </>
          )}
        </div>

        <ConfirmModal
          show={this.state.showConcludeModal}
          onCancel={this.cancelConcludeSitting}
          onConfirm={this.confirmConcludeSitting}
          title="Conclude sitting"
        >
          <div>
            Are you sure you would like to conclude <b>Sitting {this.props.sitting.id}</b>?
          </div>
          <div>No more answers can be provided after this step.</div>
        </ConfirmModal>

        <DeleteModal
          show={this.state.showDeleteModal}
          onCancel={this.cancelDeleteSitting}
          onConfirm={this.confirmDeleteSitting}
          title="Delete sitting"
        >
          <div>Are you sure you would like to delete this sitting?</div>
          <div>All associated questions/answers will be deleted. This action is irreversible.</div>
        </DeleteModal>
      </BlockUi>
    );
  }
}

export default withErrorScreen(SittingListEntry);
