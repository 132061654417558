"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toUrlParams = exports.addQueryParameters = exports.addQueryParamsToUrl = exports.parseQuery = exports.parseUrl = void 0;
// parse query parameters from URL
const parseUrl = (urlString) => {
    const url = new URL(urlString);
    const query = {};
    url.searchParams.forEach((val, key) => {
        query[key] = val;
    });
    return query;
};
exports.parseUrl = parseUrl;
// parse query parameters from location
const parseQuery = (search) => {
    const params = new URLSearchParams(search);
    const query = {};
    params.forEach((val, key) => {
        query[key] = val;
    });
    return query;
};
exports.parseQuery = parseQuery;
// parse query parameters from URL
const addQueryParamsToUrl = (urlString, params) => {
    const url = new URL(urlString);
    Object.entries(params).forEach(([key, val]) => {
        url.searchParams.set(key, `${val}`);
    });
    return url.toString();
};
exports.addQueryParamsToUrl = addQueryParamsToUrl;
// add extra query parameters to location
const addQueryParameters = (search, params = {}, defaultParams = {}) => {
    const query = (0, exports.parseQuery)(search);
    let changed = false;
    Object.entries(params).forEach(([key, val]) => {
        if (key in defaultParams) {
            // has default
            if (val !== defaultParams[key]) {
                query[key] = `${val}`;
                changed = true;
            }
            else if (key in query) {
                delete query[key];
                changed = true;
            }
        }
        else {
            // no default
            // eslint-disable-next-line no-lonely-if
            if (val !== undefined && val !== null && val !== '') {
                query[key] = `${val}`;
                changed = true;
            }
            else if (key in query) {
                delete query[key];
                changed = true;
            }
        }
    });
    if (!changed) {
        return search;
    }
    return `?${new URLSearchParams(query).toString()}`;
};
exports.addQueryParameters = addQueryParameters;
// parse into URLParams
const toUrlParams = (query = {}, defaultParams = {}) => {
    const params = {};
    Object.entries(query).forEach(([key, val]) => {
        // omit default values
        if (key in defaultParams && defaultParams[key] === val) {
            return;
        }
        if (Array.isArray(val)) {
            params[key] = val.join(',');
        }
        else if (val !== undefined && val !== null && val !== '' && !Number.isNaN(val)) {
            params[key] = `${val}`;
        }
    });
    return new URLSearchParams(params).toString();
};
exports.toUrlParams = toUrlParams;
