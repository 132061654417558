import { createContext } from 'react';
import { AuthProviderParams } from '../model';
import { Fetcher } from '../util/fetch';
import WebSocketContainer from '../util/wscontainer';

export type CredentialsContextType = {
  userId: number;
  userName: string;
  admin: boolean;
  canCreateExam: boolean;
  darkMode: boolean;
  wsContainer: WebSocketContainer;
  fetcher: Fetcher;
  providers: Record<string, AuthProviderParams>;
  onLogout: () => Promise<void>;
};

const CredentialsContext = createContext<CredentialsContextType>({
  userId: 0,
  userName: '',
  admin: false,
  canCreateExam: false,
  darkMode: false,
  wsContainer: null,
  fetcher: null,
  providers: {},
  onLogout: undefined,
});

export default CredentialsContext;
