import fetch from '../util/fetch';
import { PagingOptions } from '../model/options';
import {
  Collection,
  StandardUserIdentityCreationDto,
  ExternalUserIdentityCreationDto,
  ExternalUserIdentityDto,
  IdentityType,
  StandardUserIdentityDto,
  UserIdentityDto,
  UserIdentityUpdateDto,
  UserIdentityWithUserIdDto,
  UserIdentityCreationDto,
} from '../model';
import { toUrlParams } from '../util/queryparser';

export type UserIdentityFilterOptions = PagingOptions & {
  email?: string;
  identityType?: IdentityType;
  providerName?: string;
  providerSpecificId?: number;
};

export const defaultUserIdentityOptions: Readonly<UserIdentityFilterOptions> = {
  page: 1,
  perPage: 25,
};

export const fetchIdentities = async (options: UserIdentityFilterOptions = {}): Promise<Collection<UserIdentityWithUserIdDto>> => {
  const path = `/api/identities?${toUrlParams(options, defaultUserIdentityOptions)}`;
  const response = await fetch<Array<UserIdentityWithUserIdDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const fetchUserIdentities = async (
  userId: number,
  options: UserIdentityFilterOptions = {},
): Promise<Collection<UserIdentityDto>> => {
  const path = `/api/users/${userId}/identities?${toUrlParams(options, defaultUserIdentityOptions)}`;
  const response = await fetch<Array<UserIdentityDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const fetchUserIdentityById = async (userId: number, identityId: number): Promise<UserIdentityDto> => {
  const path = `/api/users/${userId}/identities/${identityId}`;
  const response = await fetch<UserIdentityDto>(path);
  return response.body;
};

export const createUserIdentity = async (userId: number, identity: UserIdentityCreationDto): Promise<UserIdentityDto> => {
  const path = `/api/users/${userId}/identities`;
  const response = await fetch<UserIdentityDto, UserIdentityCreationDto>(path, {
    method: 'POST',
    body: identity,
  });
  return response.body;
};

export const createStandardUserIdentity = async (
  userId: number,
  identity: StandardUserIdentityCreationDto,
): Promise<StandardUserIdentityDto> => {
  const path = `/api/users/${userId}/identities`;
  const response = await fetch<StandardUserIdentityDto, StandardUserIdentityCreationDto>(path, {
    method: 'POST',
    body: identity,
  });
  return response.body;
};

export const createExternalUserIdentity = async (
  userId: number,
  identity: ExternalUserIdentityCreationDto,
): Promise<ExternalUserIdentityDto> => {
  const path = `/api/users/${userId}/identities`;
  const response = await fetch<ExternalUserIdentityDto, ExternalUserIdentityCreationDto>(path, {
    method: 'POST',
    body: identity,
  });
  return response.body;
};

export const updateUserIdentity = async (userId: number, identityId: number, identity: UserIdentityUpdateDto): Promise<UserIdentityDto> => {
  const path = `/api/users/${userId}/identities/${identityId}`;
  const response = await fetch<UserIdentityDto, UserIdentityUpdateDto>(path, {
    method: 'PATCH',
    body: identity,
  });
  return response.body;
};

export const deleteUserIdentity = async (userId: number, identityId: number): Promise<void> => {
  const path = `/api/users/${userId}/identities/${identityId}`;
  await fetch<void>(path, {
    method: 'DELETE',
  });
};
