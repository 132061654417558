import { ProctorSeverity } from '../model/report';
import fetch from '../util/fetch';
import { PagingAndSortingOptions } from '../model/options';
import { Collection, ProctoringEntryDetailsDto, ProctoringEntryReducedDto, ProctoringEntryWithSittingQuestionDto } from '../model';
import { toUrlParams } from '../util/queryparser';

export type ExamReportFilterOptions = PagingAndSortingOptions & {
  // sort
  sortBy?: 'sittingQuestionSittingStartTime' | 'sittingQuestionQuestionOrder' | 'sittingQuestionSittingStudentName';
  // search
  studentName?: string;
  // filter
  severity?: ProctorSeverity;
};

export const defaultExamReportFilterOptions: Readonly<ExamReportFilterOptions> = {
  page: 1,
  perPage: 25,
  sortBy: 'sittingQuestionSittingStartTime',
  sortDirection: 'asc',
};

export const fetchExamReports = async (
  examId: number,
  options: ExamReportFilterOptions = {},
): Promise<Collection<ProctoringEntryDetailsDto>> => {
  const path = `/api/exams/${examId}/entries?${toUrlParams(options, defaultExamReportFilterOptions)}`;
  const response = await fetch<Array<ProctoringEntryDetailsDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export type SittingReportFilterOptions = PagingAndSortingOptions & {
  // sort
  sortBy?: 'sittingQuestionQuestionOrder';
  // filter
  severity?: ProctorSeverity;
};

export const defaultSittingReportFilterOptions: Readonly<SittingReportFilterOptions> = {
  page: 1,
  perPage: 25,
  sortBy: 'sittingQuestionQuestionOrder',
  sortDirection: 'asc',
};

export const fetchSittingReports = async (
  examId: number,
  sittingId: number,
  options: SittingReportFilterOptions = {},
): Promise<Collection<ProctoringEntryWithSittingQuestionDto>> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}/entries?${toUrlParams(options, defaultSittingReportFilterOptions)}`;
  const response = await fetch<Array<ProctoringEntryWithSittingQuestionDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export type SittingQuestionReportFilterOptions = PagingAndSortingOptions & {
  // sort
  sortBy?: 'timestampStart';
  // filter
  severity?: ProctorSeverity;
  streamingObjectKey?: string;
};

export const defaultSittingQuestionReportFilterOptions: Readonly<SittingQuestionReportFilterOptions> = {
  page: 1,
  perPage: 25,
  sortBy: 'timestampStart',
  sortDirection: 'asc',
};

export const fetchSittingQuestionReports = async (
  examId: number,
  sittingId: number,
  sittingQuestionId: number,
  options: SittingQuestionReportFilterOptions = {},
): Promise<Collection<ProctoringEntryReducedDto>> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}/questions/${sittingQuestionId}/entries?${toUrlParams(
    options,
    defaultSittingQuestionReportFilterOptions,
  )}`;
  const response = await fetch<Array<ProctoringEntryReducedDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};
