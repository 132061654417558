import React, { createRef, RefObject } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import BlockUi from 'react-block-ui';
import autoBind from 'auto-bind';

import { blocking } from '../util/decorators';
import withErrorScreen from '../util/withErrorScreen';
import AsyncComponent from '../util/AsyncComponent';
import { defaultSittingReportFilterOptions, fetchSittingReports, SittingReportFilterOptions } from '../../service/report';
import { ProctoringEntryWithSittingQuestionDto, Collection } from '../../model';
import ReportListEntry from './ReportListEntry';
import Paginator from '../util/Paginator';
import { addQueryParameters, parseQuery } from '../../util/queryparser';
import SortBy from '../util/SortBy';
import FilterBy from '../util/FilterBy';

type SittingReportListProps = RouteComponentProps & {
  examId: number;
  sittingId: number;
  maintainer: boolean;
};

type SittingReportListState = {
  loaded: boolean;
  blocking: boolean;
  reports: Collection<ProctoringEntryWithSittingQuestionDto>;
  options: SittingReportFilterOptions;
  pageTopRef: RefObject<HTMLDivElement>;
};

class SittingReportList extends AsyncComponent<SittingReportListProps, SittingReportListState> {
  constructor(props: SittingReportListProps) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      reports: {
        entities: [],
        count: 0,
      },
      options: {},
      pageTopRef: createRef(),
    };

    autoBind(this);

    this.fetchReports = blocking(this.fetchReports, this);
  }

  async searchToOptions() {
    const query = parseQuery(this.props.location.search);
    await this.setStateAsync({
      options: {
        severity: query.severity,
        page: Number(query.page) || 1,
        perPage: Number(query.perPage) || 25,
        sortBy: query.sortBy || localStorage.cvqSittingReportsSortBy || 'sittingQuestionQuestionOrder',
        sortDirection: query.sortDirection || localStorage.cvqSittingReportsSortDirection || 'asc',
      } as SittingReportFilterOptions,
    });
  }

  async componentDidMount() {
    await this.searchToOptions();
    const search = addQueryParameters(this.props.location.search, this.state.options, defaultSittingReportFilterOptions);
    if (search !== this.props.location.search) {
      this.props.history.replace(search);
    } else {
      await this.fetchReports();
    }
  }

  async componentDidUpdate(prevProps: SittingReportListProps) {
    if (prevProps.location.search !== this.props.location.search) {
      localStorage.cvqSittingReportsSortBy = this.state.options.sortBy;
      localStorage.cvqSittingReportsSortDirection = this.state.options.sortDirection;

      await this.searchToOptions();
      await this.fetchReports();
      if (this.state.pageTopRef.current) {
        this.state.pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onOptionsChange(newOptions: Partial<SittingReportFilterOptions>) {
    const options = {
      ...this.state.options,
      ...newOptions,
    };

    const search = addQueryParameters(this.props.location.search, options, defaultSittingReportFilterOptions);
    if (search !== this.props.location.search) {
      this.props.history.push(search);
    }

    this.setState({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
  }

  async fetchReports() {
    const reports = await fetchSittingReports(this.props.examId, this.props.sittingId, this.state.options);
    await this.setStateAsync({ loaded: true, reports });
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading reports...</div>;
    }

    const { reports, options } = this.state;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div ref={this.state.pageTopRef}>
          {this.props.maintainer && (
            <div className="row">
              <div className="col-md-12 titlebar">
                <div className="float-left">
                  <FilterBy
                    values={options}
                    onChange={this.onOptionsChange}
                    resetPage={true}
                    options={[
                      {
                        name: 'Severity',
                        queryKey: 'severity',
                        options: [
                          { key: 'all', label: 'All', value: undefined, default: true },
                          { key: 'low', label: 'Low', value: 'low' },
                          { key: 'medium', label: 'Medium', value: 'medium' },
                          { key: 'high', label: 'High', value: 'high' },
                        ],
                      },
                    ]}
                  />
                  <SortBy
                    options={options}
                    onChange={this.onOptionsChange}
                    keys={{
                      sittingQuestionQuestionOrder: 'Question index',
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {!reports.entities.length ? (
            <div className="infomsg">No reports to show</div>
          ) : (
            <div>
              <Paginator options={options} count={reports.count} onChange={this.onOptionsChange} />

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-hover">
                    <tbody>
                      {reports.entities.map((report) => (
                        <tr key={report.id}>
                          <td>
                            <ReportListEntry examId={this.props.examId} sittingId={this.props.sittingId} report={report} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <Paginator options={options} count={reports.count} onChange={this.onOptionsChange} />
            </div>
          )}
        </div>
      </BlockUi>
    );
  }
}

export default withRouter(withErrorScreen(SittingReportList));
