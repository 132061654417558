import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UserDto } from '../../model';
import { DeleteModal } from '../util/Modals';
import OverlayButton from '../util/OverlayButton';
import withErrorScreen from '../util/withErrorScreen';

type UserListEntryProps = {
  user: UserDto;
  onUpdate: () => void;
  onDelete: () => void;
};

function UserListEntry({ user, onUpdate, onDelete }: UserListEntryProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onClickDeleteExam = useCallback(() => {
    setShowDeleteModal(false);
    onDelete();
  }, [onDelete]);

  return (
    <div>
      <div>
        <div className="float-left mr-3">
          <dl>
            <dt>
              <FontAwesomeIcon icon="user" />
              &nbsp;
              {user.name}
              {user.admin && (
                <span className="objectstreams">
                  &nbsp;
                  <OverlayTrigger placement="top" overlay={<Tooltip id={`admin-${user.id}`}>Administrator</Tooltip>}>
                    <FontAwesomeIcon icon="user-shield" />
                  </OverlayTrigger>
                </span>
              )}
            </dt>
            <dd>&nbsp;</dd>
          </dl>
        </div>
        <div className="float-right btn-group">
          {/* edit */}
          <OverlayButton tooltip="Edit" onClick={onUpdate} variant="outline-warning">
            <FontAwesomeIcon icon="edit" />
          </OverlayButton>
          {/* delete */}
          <OverlayButton tooltip="Delete" onClick={() => setShowDeleteModal(true)} variant="outline-danger" visible={Boolean(onDelete)}>
            <FontAwesomeIcon icon="trash" />
          </OverlayButton>
        </div>
      </div>

      <DeleteModal show={showDeleteModal} onCancel={() => setShowDeleteModal(false)} onConfirm={onClickDeleteExam} title="Delete User">
        <div>
          Are you sure you would like to delete the user <b>{user.name}</b>?
        </div>
        <div>All associated user identities will be deleted. This action is irreversible.</div>
      </DeleteModal>
    </div>
  );
}

export default withErrorScreen(UserListEntry);
