import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import BlockUi from 'react-block-ui';
import { Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import CredentialsContext from '../context/credentials';
import {
  AuthProviderParams,
  Collection,
  ExamCreationDto,
  GroupReducedDto,
  streamingQualityMappings,
  SyncFileUploadResult,
  UserAffiliationDto,
} from '../model';
import { ProviderAssignment } from '../model/assignment';
import { fetchCanvasAssignments } from '../service/canvas';
import { createExam, fetchExamById, updateExam } from '../service/exam';
import { moveToExamResource, uploadExamResource, uploadUserResource } from '../service/filestorage';
import fetchUserAffiliations from '../service/useraffiliation';
import AsyncComponent from './util/AsyncComponent';
import { blocking } from './util/decorators';
import ExamSittingsWarning from './util/ExamSittingsWarning';
import HelpMarker from './util/HelpMarker';
import { RichTeX, RichTeXEditor } from './util/RichTeXEditor';
import SecondsPicker from './util/SecondsPicker';
import withBreadcrumbs, { WithBreadcrumbsProps } from './util/withBreadcrumbs';
import withErrorScreen from './util/withErrorScreen';

type EditExamPathParams = {
  examId: string;
};

type EditExamProps = WithBreadcrumbsProps & RouteComponentProps<EditExamPathParams>;

type EditExamState = {
  editMode: boolean;
  newExam: ExamCreationDto;
  pendingResources: SyncFileUploadResult[];
  descriptionEditor: RichTeX;
  affiliations: Collection<UserAffiliationDto>;
  group: GroupReducedDto;
  assignments: Collection<ProviderAssignment>;
  assignment: ProviderAssignment;
  blocking: boolean;
  blockingGroupDropdown: boolean;
  blockingAssignmentDropdown: boolean;
};

class EditExam extends AsyncComponent<EditExamProps, EditExamState> {
  constructor(props: EditExamProps) {
    super(props);

    const defaultStartTime = new Date(Date.now() + 24 * 60 * 60 * 1000);
    const defaultHardStartTime = new Date(defaultStartTime.getTime() + 2 * 60 * 1000);
    const defaultEndTime = new Date(defaultStartTime.getTime() + 60 * 60 * 1000);

    this.state = {
      editMode: 'examId' in props.match.params,
      newExam: {
        name: 'New exam',
        examType: 'continuous',
        description: 'Enter description here',
        groupId: null,
        startTime: defaultStartTime,
        hardStartTime: defaultHardStartTime,
        endTime: defaultEndTime,
        cutoffTime: 900,
        resumableTime: 300,
        accumulateRemainingTime: false,
        pauseBetweenQuestions: 30,
        randomBankOrder: false,
        redoable: false,
        autoReleasePoints: true,
        studentsCanSeeBanks: false,
        streaming: false,
        studentsCanSeeStreams: true,
        streamingQuality: 'low',
        jsonMetadata: null,
      },
      pendingResources: [],
      descriptionEditor: new RichTeX('Enter description here'),
      affiliations: {
        entities: [],
        count: 0,
      },
      group: {
        id: 0,
        name: '',
      },
      assignments: {
        entities: [],
        count: 0,
      },
      assignment: {
        id: 0,
        name: '',
      },
      blocking: false,
      blockingGroupDropdown: true,
      blockingAssignmentDropdown: true,
    };

    autoBind(this);

    this.fetchExam = blocking(this.fetchExam, this, {
      autoClose: true,
      keepTrying: true,
    });
    this.fetchGroups = blocking(this.fetchGroups, this, {
      stateName: 'blockingGroupDropdown',
      autoClose: true,
      keepTrying: true,
    });
    this.fetchCanvasAssignments = blocking(this.fetchCanvasAssignments, this, {
      stateName: 'blockingAssignmentDropdown',
      autoClose: true,
      keepTrying: true,
    });
    this.onUpload = blocking(this.onUpload, this);
    this.createExam = blocking(this.createExam, this);
    this.updateExam = blocking(this.updateExam, this);
  }

  async componentDidMount() {
    if (this.state.editMode) {
      await this.fetchExam();
    } else {
      await this.fetchGroups();
    }
  }

  async fetchExam() {
    const examId = Number(this.props.match.params.examId);
    const exam = await fetchExamById(examId);

    await this.setStateAsync({
      group: exam.group,
      newExam: {
        ...exam,
        groupId: exam.group.id,
        startTime: new Date(exam.startTime),
        hardStartTime: new Date(exam.hardStartTime),
        endTime: new Date(exam.endTime),
      },
      descriptionEditor: new RichTeX(exam.description),
    });

    await this.fetchAssignmentsIfPossible();

    this.props.setBreadcrumbs([
      { path: '/exams/', text: 'Exams' },
      { path: `/exams/${exam.id}/`, text: exam.name },
      { path: `/exams/${exam.id}/edit`, text: 'Edit' },
    ]);
  }

  async fetchGroups() {
    const affiliations = await fetchUserAffiliations(this.context.userId, {
      sortBy: 'favorite',
      sortDirection: 'desc',
    });

    const newState: Partial<EditExamState> = { affiliations };

    if (!this.state.newExam.groupId) {
      // eslint-disable-next-line prefer-destructuring
      const { group } = affiliations.entities[0];
      newState.group = group;
      newState.newExam = {
        ...this.state.newExam,
        groupId: group.id,
      };
    }

    await this.setStateAsync(newState as unknown);

    await this.fetchAssignmentsIfPossible();

    this.props.setBreadcrumbs([
      { path: '/exams/', text: 'Exams' },
      { path: '/exams/new', text: 'New exam' },
    ]);
  }

  async createExam() {
    await this.setStateAsync({
      newExam: {
        ...this.state.newExam,
        description: this.state.descriptionEditor.getMarkdown(),
      },
    });
    const exam = await createExam(this.state.newExam);

    // check if any temp files were created, and move them to exam space
    if (this.state.pendingResources.length > 0) {
      // move files on storage
      const moveUrlMappings = await Promise.all(
        this.state.pendingResources.map(async (upload) => {
          const newUpload = await moveToExamResource(upload.location, exam.group.id, exam.id);
          return [upload.url, newUpload.url];
        }),
      );

      // change description text to reflect new URLs
      this.state.descriptionEditor.setLinksByMapping(moveUrlMappings);

      // update on backend
      await updateExam(exam.id, {
        description: this.state.descriptionEditor.getMarkdown(),
      });

      // clear pending resources
      await this.setStateAsync({
        pendingResources: [],
      });
    }

    // redirect to newly created exam page
    this.props.history.push(`/exams/${exam.id}/`);
  }

  async updateExam() {
    const examId = Number(this.props.match.params.examId);
    await this.setStateAsync({
      newExam: {
        ...this.state.newExam,
        description: this.state.descriptionEditor.getMarkdown(),
      },
    });
    await updateExam(examId, this.state.newExam);
    this.props.history.push(`/exams/${examId}/`);
  }

  onValueChange(event) {
    const { target } = event;
    const value = event.target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;

    this.setState({
      newExam: {
        ...this.state.newExam,
        [key]: value,
      },
    });
  }

  onGroupChange(event) {
    const idx = event.target.value;
    const { group } = this.state.affiliations.entities[idx];
    this.setState(
      {
        group,
        newExam: {
          ...this.state.newExam,
          groupId: group.id,
        },
      },
      this.fetchAssignmentsIfPossible,
    );
  }

  onAssignmentChange(event) {
    const idx = event.target.value;
    const assignment = this.state.assignments.entities[idx];
    this.setState({
      assignment,
      newExam: {
        ...this.state.newExam,
        jsonMetadata: { assignment },
      },
    });
  }

  async fetchAssignmentsIfPossible() {
    const { group } = this.state;

    const { providers } = this.context as { providers: Record<string, AuthProviderParams> };
    if (group?.providerName && providers[group.providerName]) {
      const provider = providers[group.providerName];

      if (provider.type === 'canvas') {
        const { providerSpecificId } = group;
        await this.fetchCanvasAssignments({ providerName: provider.name, courseId: providerSpecificId });
      }
    } else {
      this.setState({
        newExam: {
          ...this.state.newExam,
          jsonMetadata: {},
        },
        assignments: {
          count: 0,
          entities: [],
        },
        assignment: {
          id: 0,
          name: '',
        },
      });
    }
  }

  async fetchCanvasAssignments({ providerName, courseId }) {
    const assignments = await fetchCanvasAssignments(providerName, courseId);
    const metadata = this.state.newExam.jsonMetadata;
    const assignment: ProviderAssignment = (metadata?.assignment?.id &&
      assignments.entities.find((a) => a.id === metadata.assignment.id)) || { id: 0, name: '' };

    const newState: Partial<EditExamState> = {
      assignments,
      assignment,
    };

    if (!assignment) {
      // assignment not available anymore for exam assignment, reassign automatically
      console.log('Original assignment not available, resetting to no assignment');
      newState.newExam = {
        ...this.state.newExam,
        jsonMetadata: {},
      };
    }

    await this.setStateAsync(newState as unknown);
  }

  onDescriptionChange(descriptionEditor) {
    this.setState({ descriptionEditor });
  }

  onValuesChanged(newValues) {
    this.setState({
      newExam: {
        ...this.state.newExam,
        ...newValues,
      },
    });
  }

  async onUpload({ filename, file }) {
    if (this.state.editMode) {
      const examId = Number(this.props.match.params.examId);
      const { groupId } = this.state.newExam;
      return uploadExamResource(groupId, examId, filename, file);
    }

    // exam not created yet, cache created URLs and move upon create
    const { userId, userName } = this.context;
    const upload = await uploadUserResource(userId, userName, filename, file);
    await this.setStateAsync({
      pendingResources: [...this.state.pendingResources, upload],
    });
    return upload;
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.state.editMode) {
      this.updateExam();
    } else {
      this.createExam();
    }
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    const { providers } = this.context as { providers: Record<string, AuthProviderParams> };
    const { editMode, newExam, group, affiliations, assignment, assignments } = this.state;

    return (
      <BlockUi tag="form" onSubmit={this.onSubmit} blocking={this.state.blocking}>
        {/* header */}
        <div className="form-row">
          <div className="form-group col-md-12 titlebar">
            <h3>
              <label htmlFor="newExamName">{editMode ? 'Exam name' : 'New exam name'}</label>
              <input
                type="text"
                name="name"
                className="form-control"
                id="newExamName"
                maxLength={256}
                placeholder="Name"
                required
                value={newExam.name}
                onChange={this.onValueChange}
              />
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="jumbotron">
              <div className="description">
                <dt>
                  <label htmlFor="newExamDescription">Description</label>
                </dt>
                <RichTeXEditor
                  value={this.state.descriptionEditor}
                  onChange={this.onDescriptionChange}
                  allowFileUpload={true}
                  onUpload={this.onUpload}
                />
              </div>

              {/* warn if there are active sittings */}
              {editMode && <ExamSittingsWarning examId={Number(this.props.match.params.examId)} />}

              <dl className="examdescription">
                <dt>
                  Exam type&nbsp;
                  <HelpMarker
                    text={
                      <ul>
                        <li>Surveillance = no questions, just streaming to watch (Coming soon!);</li>
                        <li>Continuous = questions in order with preset pause between them;</li>
                        <li>Interactive = questions released manually one by one by teacher.</li>
                      </ul>
                    }
                  />
                </dt>
                {editMode ? (
                  <dd>
                    <FontAwesomeIcon icon="edit" />
                    <span>&nbsp;{newExam.examType}</span>
                  </dd>
                ) : (
                  <dd>
                    <FontAwesomeIcon icon="edit" />
                    <span>
                      &nbsp;
                      <select
                        className="mid-input"
                        id="newExamype"
                        name="examType"
                        value={newExam.examType}
                        onChange={this.onValueChange}
                        required
                      >
                        <option value="surveillance" disabled={true}>
                          Surveillance
                        </option>
                        <option value="continuous">Continuous</option>
                        <option value="interactive">Interactive</option>
                      </select>
                    </span>
                  </dd>
                )}
                <dt>Group</dt>
                {editMode ? (
                  <dd>
                    <FontAwesomeIcon icon="university" />
                    &nbsp;
                    <Link to={`/exams/?groupId=${group.id}`}>{group.name}</Link>
                  </dd>
                ) : (
                  <BlockUi tag="dd" blocking={this.state.blockingGroupDropdown}>
                    <FontAwesomeIcon icon="university" />
                    &nbsp;
                    <select className="mid-input" id="newExamCourse" name="groupId" onChange={this.onGroupChange} required>
                      {affiliations.entities.map((a, idx) => (
                        <option key={a.group.id} value={idx} selected={a.group.id === group.id}>
                          {a.favorite ? `\u2605 ${a.group.name}` : a.group.name}
                        </option>
                      ))}
                    </select>
                    &nbsp;
                  </BlockUi>
                )}
                {newExam.examType !== 'surveillance' && group.providerName && (
                  <>
                    <dt>
                      Assignment&nbsp;
                      <HelpMarker
                        text={`(Optional) Connect to ${providers[group.providerName]
                          ?.description} assignment. Grades can be synced if set.`}
                      />
                    </dt>
                    <BlockUi tag="dd" blocking={this.state.blockingAssignmentDropdown}>
                      <FontAwesomeIcon icon="tasks" />
                      &nbsp;
                      <select className="mid-input" id="newExamAssignment" name="assignmentId" onChange={this.onAssignmentChange} required>
                        <option selected={!assignment || assignment.id === 0} value={-1}>
                          - none -
                        </option>
                        {assignments?.entities?.map((a, idx) => (
                          <option key={a.id} value={idx} selected={a.id === assignment?.id}>
                            {a.name}
                          </option>
                        ))}
                      </select>
                      &nbsp;
                    </BlockUi>
                  </>
                )}
                <dt>Start time</dt>
                <dd>
                  <FontAwesomeIcon icon="calendar" />
                  &nbsp;
                  <ReactDatePicker
                    selected={newExam.startTime}
                    name="startTime"
                    className="mid-input"
                    id="newExamStartTime"
                    onChange={(startTime) => this.onValuesChanged({ startTime })}
                    showTimeSelect
                    timeFormat="HH:mm"
                    minDate={new Date()}
                    timeIntervals={60}
                    timeCaption="time"
                    dateFormat="MM/dd/yyyy, hh:mm aa"
                  />
                </dd>
                <dt>Hard start time</dt>
                <dd>
                  <FontAwesomeIcon icon="calendar-plus" />
                  &nbsp;
                  <ReactDatePicker
                    selected={newExam.hardStartTime}
                    name="startTime"
                    className="mid-input"
                    id="newExamStartTime"
                    onChange={(hardStartTime) => this.onValuesChanged({ hardStartTime })}
                    showTimeSelect
                    timeFormat="HH:mm"
                    minDate={newExam.startTime}
                    timeIntervals={60}
                    timeCaption="time"
                    dateFormat="MM/dd/yyyy, hh:mm aa"
                  />
                </dd>
                <dt>End time</dt>
                <dd>
                  <FontAwesomeIcon icon="calendar-times" />
                  &nbsp;
                  <ReactDatePicker
                    selected={newExam.endTime}
                    name="startTime"
                    className="mid-input"
                    id="newExamStartTime"
                    onChange={(endTime) => this.onValuesChanged({ endTime })}
                    showTimeSelect
                    timeFormat="HH:mm"
                    minDate={newExam.startTime}
                    timeIntervals={60}
                    timeCaption="time"
                    dateFormat="MM/dd/yyyy, hh:mm aa"
                  />
                </dd>
                <dt>Enrolling possible before</dt>
                <dd>
                  <SecondsPicker
                    maxValue={1800}
                    faicon="history"
                    value={newExam.cutoffTime}
                    onChange={(cutoffTime) => this.onValuesChanged({ cutoffTime })}
                  />
                </dd>
                <dt>Resumability time</dt>
                <dd>
                  <SecondsPicker
                    faicon="step-forward"
                    value={newExam.resumableTime}
                    onChange={(resumableTime) => this.onValuesChanged({ resumableTime })}
                  />
                </dd>
                <dt>Redoable</dt>
                <dd>
                  <FontAwesomeIcon icon="redo" />
                  &nbsp;
                  <input type="checkbox" name="redoable" checked={newExam.redoable} onChange={this.onValueChange}></input>
                </dd>

                <dt>Stream answers</dt>
                <dd>
                  <FontAwesomeIcon icon="video" />
                  &nbsp;
                  <input type="checkbox" name="streaming" checked={newExam.streaming} onChange={this.onValueChange} />
                </dd>

                {newExam.streaming && (
                  <>
                    <dt>Students can see their own streams</dt>
                    <dd>
                      <FontAwesomeIcon icon="eye" />
                      &nbsp;
                      <input
                        type="checkbox"
                        name="studentsCanSeeStreams"
                        checked={newExam.studentsCanSeeStreams}
                        onChange={this.onValueChange}
                      />
                    </dd>

                    <dt>Streaming quality</dt>
                    <dd>
                      <FontAwesomeIcon icon="film" />
                      <span>
                        &nbsp;
                        <select
                          className="mid-input"
                          name="streamingQuality"
                          value={newExam.streamingQuality}
                          onChange={this.onValueChange}
                          required
                        >
                          {Object.entries(streamingQualityMappings).map(([key, { prettyName }]) => (
                            <option key={key} value={key}>
                              {prettyName}
                            </option>
                          ))}
                        </select>
                      </span>
                    </dd>
                  </>
                )}

                {newExam.examType === 'interactive' && (
                  <>
                    <dt>Grace period</dt>
                    <dd>
                      <SecondsPicker
                        maxValue={300}
                        faicon="hourglass"
                        value={newExam.gracePeriod}
                        onChange={(gracePeriod) => this.onValuesChanged({ gracePeriod })}
                      />
                    </dd>
                  </>
                )}
                {(newExam.examType === 'interactive' || newExam.examType === 'continuous') && (
                  <>
                    <dt>Auto-release points when evaluated</dt>
                    <dd>
                      <FontAwesomeIcon icon="sync" />
                      &nbsp;
                      <input
                        type="checkbox"
                        name="autoReleasePoints"
                        checked={newExam.autoReleasePoints}
                        onChange={this.onValueChange}
                      ></input>
                    </dd>
                    <dt>Students can see question banks</dt>
                    <dd>
                      <FontAwesomeIcon icon="eye" />
                      &nbsp;
                      <input
                        type="checkbox"
                        name="studentsCanSeeBanks"
                        checked={newExam.studentsCanSeeBanks}
                        onChange={this.onValueChange}
                      ></input>
                    </dd>
                  </>
                )}
                {newExam.examType === 'continuous' && (
                  <>
                    <dt>Accumulate remaining time</dt>
                    <dd>
                      <FontAwesomeIcon icon="hourglass-half" />
                      &nbsp;
                      <input
                        type="checkbox"
                        name="accumulateRemainingTime"
                        checked={newExam.accumulateRemainingTime}
                        onChange={this.onValueChange}
                      ></input>
                    </dd>
                    {!newExam.accumulateRemainingTime && (
                      <>
                        <dt>Pause between questions (mm:ss)</dt>
                        <dd>
                          <SecondsPicker
                            faicon="pause-circle"
                            value={newExam.pauseBetweenQuestions}
                            onChange={(pauseBetweenQuestions) => this.onValuesChanged({ pauseBetweenQuestions })}
                          />
                        </dd>
                      </>
                    )}
                    <dt>Random bank order</dt>
                    <dd>
                      <FontAwesomeIcon icon="random" />
                      &nbsp;
                      <input type="checkbox" name="randomBankOrder" checked={newExam.randomBankOrder} onChange={this.onValueChange}></input>
                    </dd>
                  </>
                )}
              </dl>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="float-right">
              <Button className="float-right" variant="success" type="submit">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </BlockUi>
    );
  }
}

EditExam.contextType = CredentialsContext;

export default withRouter(withErrorScreen(withBreadcrumbs(EditExam)));
