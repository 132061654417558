import { EditorValue } from 'react-rte';
import { EditorState, Modifier, SelectionState, convertToRaw, RichUtils, ContentBlock, genKey } from 'draft-js';
import { Map } from 'immutable';

import decorators from './decorators';
import stateToMarkdown from '../draft-js-utils-patch/stateToMarkdown';
import stateFromMarkdown from '../draft-js-utils-patch/stateFromMarkdown';

class RichTeX {
  constructor(markdown) {
    this.markdown = markdown || '';
    this.markdownUpToDate = true;

    this.editorValue = {};
    this.editorValueUpToDate = false;
  }

  getMarkdown() {
    if (!this.markdownUpToDate) {
      this.bringMarkdownUpToDate();
    }
    return this.markdown;
  }

  setMarkdown(markdown) {
    this.markdown = markdown;
    this.markdownUpToDate = true;
    this.editorValueUpToDate = false;
  }

  getEditorValue() {
    if (!this.editorValueUpToDate) {
      this.bringEditorValueUpToDate();
    }
    return this.editorValue;
  }

  getEditorState() {
    if (!this.editorValueUpToDate) {
      this.bringEditorValueUpToDate();
    }
    return this.editorValue.getEditorState();
  }

  getJson() {
    if (!this.editorValueUpToDate) {
      this.bringEditorValueUpToDate();
    }

    this.sanitize();
    const contentState = this.editorValue.getEditorState().getCurrentContent();
    return JSON.stringify(convertToRaw(contentState));
  }

  setEditorValue(editorValue) {
    this.editorValue = editorValue;
    this.editorValueUpToDate = true;
    this.markdownUpToDate = false;
  }

  setEditorState(editorState) {
    this.editorValue = EditorValue.createFromState(editorState);
    this.editorValueUpToDate = true;
    this.markdownUpToDate = false;
  }

  bringMarkdownUpToDate() {
    if (!this.markdownUpToDate) {
      this.sanitize();
      const contentState = this.editorValue.getEditorState().getCurrentContent();
      this.markdown = stateToMarkdown(contentState, { gfm: true });
      this.markdownUpToDate = true;
    }
  }

  bringEditorValueUpToDate() {
    if (!this.editorValueUpToDate) {
      const contentState = stateFromMarkdown(this.markdown);
      const editorState = EditorState.createWithContent(contentState, decorators);
      this.editorValue = EditorValue.createFromState(editorState);
      this.editorValueUpToDate = true;
    }
  }

  sanitize() {
    // delete all empty atomic blocks
    const editorState = this.getEditorState();
    let contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    let selection = editorState.getSelection();

    const emptyBlocks = blockMap.filter((block) => {
      if (block.getType() !== 'atomic') {
        return false;
      }

      // search for any entity for this block
      let found = false;
      block.findEntityRanges(
        (character) => character.getEntity() !== null,
        () => {
          found = true;
        },
      );

      return !found;
    });

    emptyBlocks.forEach((block) => {
      selection = selection.merge({
        anchorKey: block.getKey(),
        focusKey: block.getKey(),
        anchorOffset: 0,
        focusOffset: block.getLength(),
        isBackward: false,
      });

      contentState = Modifier.removeRange(contentState, selection, 'forward');
    });

    const newEditorState = EditorState.push(editorState, contentState, 'change-block-data');
    this.setEditorState(newEditorState);
    return newEditorState;
  }

  toggleBlockType(blockType) {
    const editorState = this.getEditorState();
    const newEditorState = RichUtils.toggleBlockType(editorState, blockType);
    this.setEditorState(newEditorState);
    return newEditorState;
  }

  getCurrentBlockType() {
    const editorState = this.getEditorState();
    return RichUtils.getCurrentBlockType(editorState);
  }

  getSelectedText() {
    const editorState = this.getEditorState();
    const selection = editorState.getSelection();

    // if selection spans multiple blocks, ignore
    if (selection.getAnchorKey() !== selection.getFocusKey()) {
      return '';
    }

    // if no text selected, we create a new text block
    if (selection.getAnchorOffset() === selection.getFocusOffset()) {
      return '';
    }

    let start = selection.getAnchorOffset();
    let end = selection.getFocusOffset();

    if (start > end) {
      start = selection.getFocusOffset();
      end = selection.getAnchorOffset();
    }

    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(selection.getAnchorKey());
    return block.getText().slice(start, end);
  }

  insertBlock(newBlock) {
    const editorState = this.getEditorState();
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const blockMap = contentState.getBlockMap();

    const lastBlockBefore = blockMap.get(selectionState.getAnchorKey());
    const blocksBefore = blockMap.toSeq().takeUntil((v) => v === lastBlockBefore);
    const blocksAfter = blockMap
      .toSeq()
      .skipUntil((v) => v === lastBlockBefore)
      .rest();
    const newBlockMap = blocksBefore
      .concat(
        [
          [lastBlockBefore.getKey(), lastBlockBefore],
          [newBlock.getKey(), newBlock],
        ],
        blocksAfter,
      )
      .toOrderedMap();
    const newContentState = contentState.merge({ blockMap: newBlockMap });
    let newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');

    // select block
    newEditorState = EditorState.forceSelection(
      newEditorState,
      selectionState.merge({
        anchorKey: newBlock.getKey(),
        focusKey: newBlock.getKey(),
        anchorOffset: 0,
        focusOffset: newBlock.getText().length,
        isBackward: false,
      }),
    );

    this.setEditorState(newEditorState);
    return {
      editorState: newEditorState,
      block: newBlock,
      blockKey: newBlock.getKey(),
    };
  }

  insertCodeBlock(language, code) {
    const newBlock = new ContentBlock({
      key: genKey(),
      text: code || '',
      type: 'code-block',
      data: Map({
        language: language || 'text',
      }),
    });
    this.insertBlock(newBlock);
  }

  insertLinkEntity(url, placeholder) {
    const editorState = this.getEditorState();
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // create link type entity
    let newContentState = contentState.createEntity('LINK', 'MUTABLE', { url });
    const entityKey = newContentState.getLastCreatedEntityKey();

    // toggle selection as link
    let newEditorState = EditorState.push(editorState, newContentState, 'create-entity');
    newEditorState = RichUtils.toggleLink(newEditorState, selection, entityKey);

    // change placeholder text
    newContentState = newEditorState.getCurrentContent();
    newContentState = Modifier.replaceText(newContentState, selection, placeholder, null, entityKey);

    newEditorState = EditorState.push(newEditorState, newContentState, 'change-block-data');
    this.setEditorState(newEditorState);
    return newEditorState;
  }

  insertImageBlock(src, alt) {
    // create a new empty text block
    const { editorState } = this.insertBlock(
      new ContentBlock({
        key: genKey(),
        text: ' ',
        type: 'atomic',
      }),
    );
    const selection = editorState.getSelection();

    const contentState = editorState.getCurrentContent();

    // create link type entity
    const newContentState = contentState.createEntity('IMAGE', 'MUTABLE', { src, alt });
    const entityKey = newContentState.getLastCreatedEntityKey();
    let newEditorState = EditorState.push(editorState, newContentState, 'create-entity');

    newEditorState = RichUtils.toggleLink(newEditorState, selection, entityKey);

    this.setEditorState(newEditorState);
    return newEditorState;
  }

  insertTeXBlock(texText) {
    let editorState = this.getEditorState();
    const contentState = editorState.getCurrentContent();
    let newContentState = contentState.createEntity('TOKEN', 'IMMUTABLE', {
      latex: true,
      content: texText || '',
    });
    const entityKey = newContentState.getLastCreatedEntityKey();
    editorState = EditorState.set(editorState, { currentContent: newContentState });
    this.setEditorState(editorState);

    const { blockKey } = this.insertBlock(
      new ContentBlock({
        key: genKey(),
        text: ' ',
        type: 'atomic',
        data: Map({
          latex: true,
        }),
      }),
    );

    editorState = this.getEditorState();
    const targetRange = new SelectionState({
      anchorKey: blockKey,
      focusKey: blockKey,
      anchorOffset: 0,
      focusOffset: 1,
    });

    newContentState = Modifier.applyEntity(editorState.getCurrentContent(), targetRange, entityKey);
    let newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');
    newEditorState = EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
    this.setEditorState(newEditorState);
    return newEditorState;
  }

  removeBlock(block) {
    const editorState = this.getEditorState();
    const contentState = editorState.getCurrentContent();
    const blockKey = block.getKey();

    const targetRange = new SelectionState({
      anchorKey: blockKey,
      focusKey: blockKey,
      anchorOffset: 0,
      focusOffset: block.getLength(),
    });

    let newContentState = Modifier.removeRange(contentState, targetRange, 'backward');
    newContentState = Modifier.setBlockType(newContentState, newContentState.getSelectionAfter(), 'unstyled');

    let newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
    newEditorState = EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
    this.setEditorState(newEditorState);
    return newEditorState;
  }

  removeEntity(blockKey, entityKey) {
    const editorState = this.getEditorState();
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(blockKey);

    block.findEntityRanges(
      (character) => character.getEntity() === entityKey,
      (start, end) => {
        const selection = editorState.getSelection().merge({
          anchorKey: blockKey,
          focusKey: blockKey,
          anchorOffset: start,
          focusOffset: end,
          isBackward: false,
        });

        // toggle selection as link
        const newContentState = Modifier.applyEntity(contentState, selection, null);
        const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');
        this.setEditorState(newEditorState);
        return newEditorState;
      },
    );
  }

  setLinkEntity(blockKey, entityKey, url, placeholder) {
    const editorState = this.getEditorState();
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(blockKey);

    block.findEntityRanges(
      (character) => character.getEntity() === entityKey,
      (start, end) => {
        const replacementRange = editorState.getSelection().merge({
          anchorKey: blockKey,
          focusKey: blockKey,
          anchorOffset: start,
          focusOffset: end,
          isBackward: false,
        });

        let newContentState = Modifier.replaceText(contentState, replacementRange, placeholder, null, entityKey);

        newContentState = newContentState.mergeEntityData(entityKey, { url });

        const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data');

        this.setEditorState(newEditorState);
        return newEditorState;
      },
    );
  }

  setLinksByMapping(urlMappings) {
    const editorState = this.getEditorState();
    let contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    const entityMappings = {};

    blockMap.forEach((block) => {
      // search for entities
      block.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (!entityKey) {
          return;
        }
        const entity = contentState.getEntity(entityKey);

        // if not image or link entity, skip
        const typeToDataKey = {
          IMAGE: 'src',
          LINK: 'url',
        };
        const dataKey = typeToDataKey[entity.getType()];
        if (!dataKey) {
          return;
        }
        const dataValue = entity.getData()[dataKey];

        // check if source is something we have to map
        const result = urlMappings.find((mapping) => mapping[0] === dataValue);
        if (!result) {
          return;
        }

        // save in list of entities to update, together with key and new value
        entityMappings[entityKey] = {
          dataKey,
          target: result[1],
        };
      });
    });

    Object.entries(entityMappings).forEach(([entityKey, { dataKey, target }]) => {
      contentState = contentState.mergeEntityData(entityKey, { [dataKey]: target });
    });
    const newEditorState = EditorState.push(editorState, contentState, 'change-block-data');
    this.setEditorState(newEditorState);
    return newEditorState;
  }

  setImageBlock(block, src, alt) {
    const editorState = this.getEditorState();
    const contentState = editorState.getCurrentContent();

    const entityKey = block.getEntityAt(0);
    const newContentState = contentState.mergeEntityData(entityKey, { src, alt });
    const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data');

    this.setEditorState(newEditorState);
    return newEditorState;
  }

  setTeXBlock(block, texText) {
    const editorState = this.getEditorState();
    const contentState = editorState.getCurrentContent();

    const entityKey = block.getEntityAt(0);
    const newContentState = contentState.mergeEntityData(entityKey, { content: texText });
    const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data');

    this.setEditorState(newEditorState);
    return newEditorState;
  }

  setCodeBlock(block, language, text) {
    const editorState = this.getEditorState();
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    const blockKey = block.getKey();

    const mergedBlock = block.merge({
      text,
      data: {
        language,
      },
    });
    const mergedContent = contentState.merge({
      blockMap: blockMap.set(blockKey, mergedBlock),
    });

    let newEditorState = EditorState.push(editorState, mergedContent, 'change-block-data');
    newEditorState = EditorState.forceSelection(newEditorState, mergedContent.getSelectionAfter());

    this.setEditorState(newEditorState);
    return newEditorState;
  }
}

export default RichTeX;
