import React from 'react';
import { Link } from 'react-router-dom';
import { EventDto } from '../../model';
import useFormattedDate from '../../hooks/useFormattedDate';

type EventListEntryProps = {
  event: EventDto;
  examId: number;
};

export default function EventListEntry({ event, examId }: EventListEntryProps) {
  const eventDate = useFormattedDate(event.date);

  const actor = <span className="likeALink">{event.actorName}</span>;

  let entity: JSX.Element;
  switch (event.entityType) {
    case 'Exam':
      entity = <Link to={`/exams/${event.entityId}/`}>Exam {event.entityId}</Link>;
      break;
    case 'Sitting':
      entity = <Link to={`/exams/${examId}/sittings/${event.entityId}/`}>Sitting {event.entityId}</Link>;
      break;
    default:
      entity = <>unknown entity</>;
  }

  let message: JSX.Element;
  switch (event.eventType) {
    case 'examConcluded':
      message = (
        <>
          {actor} has concluded a round from {entity}
        </>
      );
      break;
    case 'questionReleased':
      message = (
        <>
          {actor} has released a question no. {Number(event.parameters) + 1} in {entity}
        </>
      );
      break;
    case 'sittingCreated':
      message = (
        <>
          {actor} created {entity}
        </>
      );
      break;
    case 'sittingResumed':
      message = (
        <>
          {actor} resumed {entity}
        </>
      );
      break;
    case 'sittingConcluded':
      message = event.actorId ? (
        <>
          {actor} has manually concluded {entity}
        </>
      ) : (
        <>{entity} has concluded</>
      );
      break;
    case 'questionAck':
      message = (
        <>
          {actor} has acknowledged question {event.parameters} from {entity}
        </>
      );
      break;
    case 'questionAnswered':
      message = (
        <>
          {actor} has submitted an answer to question {event.parameters} from {entity}
        </>
      );
      break;
    case 'pointsReleased':
      message = event.actorId ? (
        <>
          {actor} has manually released points for {entity}
        </>
      ) : (
        <>{entity} points have been released automatically</>
      );
      break;
    default:
      message = (
        <>
          {actor} has performed action {event.eventType} on {entity}
        </>
      );
  }

  return (
    <>
      <span className="eventdatemsg">{eventDate}</span>&nbsp;·&nbsp;
      <span className="eventmsg">{message}</span>
    </>
  );
}
