import { SortDirection } from '../model/options';
import { ExamSortOptions } from '../service/exam';
import { QuestionBankSortOptions } from '../service/questionbank';
import { SittingSortOptions } from '../service/sitting';
import { SittingQuestionSortOptions } from '../service/sittingquestion';

/**
 * Custom types
 */
export type ExamsPage = 'starred' | 'upcoming' | 'all';

/**
 * Validated entry in local storage
 */
export class TypedStorageEntry<T> {
  key: string;
  allowedValues: T[];
  defaultValue: T;

  constructor(key: string, allowedValues: T[], defaultValue: T) {
    this.key = key;
    this.allowedValues = [...allowedValues];
    this.defaultValue = defaultValue;

    if (!this.allowedValues.includes(this.defaultValue)) {
      throw new Error(`Default value ${this.defaultValue} not allowed for LS key ${this.key}`);
    }
  }

  get(externalValue?: string): T {
    if (externalValue) {
      const castExternalValue = externalValue as unknown as T;
      if (this.allowedValues.includes(castExternalValue)) {
        return castExternalValue;
      }
    }

    const value: T = (localStorage.getItem(this.key) ?? this.defaultValue) as unknown as T;
    if (this.allowedValues.includes(value)) {
      return value;
    }

    localStorage.removeItem(this.key);
    return this.defaultValue;
  }

  set(value: T): void {
    if (!this.allowedValues.includes(value)) {
      throw new Error(`Value ${value} not allowed for LS key ${this.key}`);
    }

    if (value === this.defaultValue) {
      localStorage.removeItem(this.key);
    } else {
      localStorage.setItem(this.key, `${value}`);
    }
  }
}

/**
 * Custom entries
 */
export class SortDirectionStorageEntry extends TypedStorageEntry<SortDirection> {
  constructor(key: string, defaultValue: SortDirection = 'asc') {
    super(key, ['asc', 'desc'], defaultValue);
  }
}

/**
 * Type-checked keys in local storage
 */
export class TypedStorage {
  examPage: TypedStorageEntry<ExamsPage>;
  examSortBy: TypedStorageEntry<ExamSortOptions>;
  examSortDirection: SortDirectionStorageEntry;
  questionBankSortBy: TypedStorageEntry<QuestionBankSortOptions>;
  questionBankSortDirection: SortDirectionStorageEntry;
  sittingSortBy: TypedStorageEntry<SittingSortOptions>;
  sittingSortDirection: SortDirectionStorageEntry;
  sittingQuestionSortBy: TypedStorageEntry<SittingQuestionSortOptions>;
  sittingQuestionSortDirection: SortDirectionStorageEntry;

  constructor() {
    this.examPage = new TypedStorageEntry<ExamsPage>('cvqExamsPreferredPage', ['starred', 'upcoming', 'all'], 'all');
    this.examSortBy = new TypedStorageEntry<ExamSortOptions>('cvqExamsSortBy', ['startTime', 'name', 'groupName'], 'startTime');
    this.examSortDirection = new SortDirectionStorageEntry('cvqExamsSortDirection', 'desc');
    this.questionBankSortBy = new TypedStorageEntry<QuestionBankSortOptions>('cvqQuestionBanksSortBy', ['bankOrder', 'name'], 'bankOrder');
    this.questionBankSortDirection = new SortDirectionStorageEntry('cvqQuestionBanksSortDirection');
    this.sittingSortBy = new TypedStorageEntry<SittingSortOptions>(
      'cvqSittingsSortBy',
      ['studentName', 'numQuestions', 'totalPoints', 'startTime', 'finishTime'],
      'studentName',
    );
    this.sittingSortDirection = new SortDirectionStorageEntry('cvqSittingsSortDirection');
    this.sittingQuestionSortBy = new TypedStorageEntry<SittingQuestionSortOptions>(
      'cvqSittingQuestionsSortBy',
      ['questionOrder', 'sittingStartTime', 'sittingStudentName', 'points'],
      'questionOrder',
    );
    this.sittingQuestionSortDirection = new SortDirectionStorageEntry('cvqSittingQuestionsSortDirection');

    // TODO migrate other local storage keys
    // TODO make complex local storage keys, such as combined sort by and direction
    // TODO check and clear existing local storage entries
  }
}

/**
 * Default instance
 */
const storage = new TypedStorage();
export default storage;
