import { useContext, useEffect } from 'react';
import BreadcrumbsSetterContext from '../context/breadcrumbs';

export default function useBreadcrumbs() {
  const setBreadcrumbs = useContext(BreadcrumbsSetterContext);

  useEffect(() => {
    // start with empty breadcrumbs
    setBreadcrumbs([]);
    // end with empty breadcrumbs
    return () => {
      setBreadcrumbs([]);
    };
  }, []);

  return setBreadcrumbs;
}
