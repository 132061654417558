import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import React from 'react';
import { withRouter } from 'react-router';
import BlockUi from 'react-block-ui';

import CredentialsContext from '../context/credentials';
import { UserDto, UserUpdateDto } from '../model';
import { fetchUserById, updateUser } from '../service/user';
import EditUserModal from './users/EditUserModal';
import AsyncComponent from './util/AsyncComponent';
import { blocking } from './util/decorators';
import OverlayButton from './util/OverlayButton';
import withErrorScreen from './util/withErrorScreen';

type AccountProps = Record<string, unknown>;

type AccountState = {
  user: UserDto;
  showEditor: boolean;
  blocking: boolean;
};

class Account extends AsyncComponent<AccountProps, AccountState> {
  constructor(props: AccountProps) {
    super(props);

    this.state = {
      user: undefined,
      showEditor: false,
      blocking: false,
    };

    autoBind(this);

    this.fetchUser = blocking(this.fetchUser, this);
    this.updateUser = blocking(this.updateUser, this);
  }

  async fetchUser() {
    const { userId } = this.context;
    const user = await fetchUserById(userId);

    this.setStateAsync({ user, showEditor: true });
  }

  async updateUser(description: { userId: number; user: UserUpdateDto }) {
    const { userId, user } = description;
    await this.setStateAsync({ showEditor: false });
    await updateUser(userId, user);
    await this.fetchUser();
  }

  render() {
    const { userName } = this.context;
    const { user, showEditor } = this.state;

    return (
      <>
        <OverlayButton
          variant="secondary"
          tooltipPlacement="bottom"
          tooltip="Account settings"
          onClick={this.fetchUser}
          buttonStyle={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
        >
          <FontAwesomeIcon icon="user" />
          &nbsp;{userName}
        </OverlayButton>

        <BlockUi tag="div" blocking={this.state.blocking}>
          {user && (
            <>
              <EditUserModal
                editMode={true}
                show={showEditor}
                user={user}
                onUpdate={this.updateUser}
                onCancel={() => this.setState({ showEditor: false })}
              />
            </>
          )}
        </BlockUi>
      </>
    );
  }
}

Account.contextType = CredentialsContext;

export default withRouter(withErrorScreen(Account));
