import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConfirmModal, DeleteModal } from '../util/Modals';
import OverlayButton from '../util/OverlayButton';
import { ExamDetailsDto } from '../../model';

type ExamDetailsHeaderProps = {
  exam: ExamDetailsDto;
  maintainer: boolean;
  resumableSittingId: number;
  prohibitMessage: string;
  onStar: () => void;
  onUnstar: () => void;
  onPublish: () => void;
  onUnpublish: () => void;
  onExport: () => void;
  onDelete: () => void;
  onConcludeResumableSitting: () => void;
};

export default function ExamDetailsHeader({
  exam,
  maintainer,
  resumableSittingId,
  prohibitMessage,
  onExport,
  onStar,
  onUnstar,
  onPublish,
  onUnpublish,
  onDelete,
  onConcludeResumableSitting,
}: ExamDetailsHeaderProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConcludeModal, setShowConcludeModal] = useState(false);

  const confirmDeleteExam = useCallback(() => {
    setShowDeleteModal(false);
    onDelete();
  }, [onDelete]);

  const confirmConcludeResumableSitting = useCallback(() => {
    setShowConcludeModal(false);
    onConcludeResumableSitting();
  }, [onConcludeResumableSitting]);

  return (
    <div className="row">
      <div className="col-md-12 titlebar">
        <div className="float-left">
          <h3>{exam.name}</h3>
        </div>
        <div className="float-right btn-group">
          {/* sitting for maintainer */}
          <OverlayButton visible={maintainer} variant="outline-success" to={`/exams/${exam.id}/take`}>
            <FontAwesomeIcon icon="glasses" />
            <span>&nbsp;Simulate</span>
          </OverlayButton>
          {/* sitting for student */}
          <OverlayButton
            visible={!maintainer && Boolean(resumableSittingId)}
            disabled={Boolean(prohibitMessage)}
            disabledTooltip={prohibitMessage}
            to={`/exams/${exam.id}/sittings/${resumableSittingId}/resume`}
            variant="success"
          >
            <FontAwesomeIcon icon="step-forward" />
            <span>&nbsp;Resume sitting {resumableSittingId}</span>
          </OverlayButton>
          <OverlayButton visible={!maintainer && Boolean(resumableSittingId)} onClick={() => setShowConcludeModal(true)} variant="warning">
            <FontAwesomeIcon icon="flag-checkered" />
            <span>&nbsp;Conclude sitting {resumableSittingId}</span>
          </OverlayButton>
          <OverlayButton
            visible={!maintainer && !resumableSittingId}
            disabled={Boolean(prohibitMessage)}
            disabledTooltip={prohibitMessage}
            to={`/exams/${exam.id}/take`}
            variant="success"
          >
            <FontAwesomeIcon icon="play" />
            <span>&nbsp;Start exam</span>
          </OverlayButton>
          {/* export */}
          <OverlayButton visible={maintainer} variant="outline-info" onClick={onExport}>
            <FontAwesomeIcon icon="file-export" />
            <span>&nbsp;Export</span>
          </OverlayButton>
          {/* star */}
          <OverlayButton visible={exam.starred} tooltip="Click to unstar" onClick={onUnstar} variant="outline-warning">
            <FontAwesomeIcon icon="star" />
            &nbsp;Starred
          </OverlayButton>
          <OverlayButton visible={!exam.starred} tooltip="Click to star" onClick={onStar} variant="outline-secondary">
            <FontAwesomeIcon icon={['far', 'star']} />
            &nbsp;Not starred
          </OverlayButton>
          {/* publish, edit, delete */}
          <OverlayButton visible={maintainer && exam.published} tooltip="Click to unpublish" onClick={onUnpublish} variant="outline-info">
            <FontAwesomeIcon icon="check-circle" />
            &nbsp;Published
          </OverlayButton>
          <OverlayButton visible={maintainer && !exam.published} tooltip="Click to publish" onClick={onPublish} variant="outline-secondary">
            <FontAwesomeIcon icon="times-circle" />
            &nbsp;Not published
          </OverlayButton>
          <OverlayButton visible={maintainer} to={`/exams/${exam.id}/edit`} variant="outline-warning">
            <FontAwesomeIcon icon="edit" />
            <span>&nbsp;Edit</span>
          </OverlayButton>
          <OverlayButton visible={maintainer} onClick={() => setShowDeleteModal(true)} variant="outline-danger">
            <FontAwesomeIcon icon="trash" />
            <span>&nbsp;Delete</span>
          </OverlayButton>
        </div>
      </div>

      <ConfirmModal
        show={showConcludeModal}
        onCancel={() => setShowConcludeModal(false)}
        onConfirm={confirmConcludeResumableSitting}
        title="Conclude sitting"
      >
        <div>
          Are you sure you would like to conclude <b>Sitting {resumableSittingId}</b>?
        </div>
        <div>No more answers can be provided after this step.</div>
      </ConfirmModal>

      <DeleteModal show={showDeleteModal} onCancel={() => setShowDeleteModal(false)} onConfirm={confirmDeleteExam} title="Delete exam">
        <div>
          Are you sure you would like to delete the exam <b>{exam.name}</b>?
        </div>
        <div>All associated questions will be deleted. This action is irreversible.</div>
      </DeleteModal>
    </div>
  );
}
