import { Collection, QuestionBankCreationDto, QuestionBankDto, QuestionBankUpdateDto } from '../model';
import { PagingAndSortingOptions } from '../model/options';
import fetch from '../util/fetch';
import { toUrlParams } from '../util/queryparser';

export type QuestionBankSortOptions = 'bankOrder' | 'name';

export type QuestionBankFilterOptions = PagingAndSortingOptions & {
  sortBy?: QuestionBankSortOptions;
};

export const defaultQuestionBankOptions: Readonly<QuestionBankFilterOptions> = {
  page: 1,
  perPage: 25,
  sortBy: 'bankOrder',
  sortDirection: 'asc',
};

export const fetchBanks = async (examId: number, options: QuestionBankFilterOptions = {}): Promise<Collection<QuestionBankDto>> => {
  const path = `/api/exams/${examId}/banks?${toUrlParams(options)}`;
  const response = await fetch<Array<QuestionBankDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const fetchBankById = async (examId: number, bankId: number): Promise<QuestionBankDto> => {
  const path = `/api/exams/${examId}/banks/${bankId}`;
  const response = await fetch<QuestionBankDto>(path);
  return response.body;
};

export const createBank = async (examId: number, bank: QuestionBankCreationDto): Promise<QuestionBankDto> => {
  const path = `/api/exams/${examId}/banks`;
  const response = await fetch<QuestionBankDto, QuestionBankCreationDto>(path, {
    method: 'POST',
    body: bank,
  });
  return response.body;
};

export const updateBank = async (examId: number, bankId: number, bank: QuestionBankUpdateDto): Promise<QuestionBankDto> => {
  const path = `/api/exams/${examId}/banks/${bankId}`;
  const response = await fetch<QuestionBankDto, QuestionBankUpdateDto>(path, {
    method: 'PATCH',
    body: bank,
  });
  return response.body;
};

export const deleteBank = async (examId: number, bankId: number): Promise<void> => {
  const path = `/api/exams/${examId}/banks/${bankId}`;
  await fetch(path, {
    method: 'DELETE',
  });
};
