import React, { createRef, RefObject } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import autoBind from 'auto-bind';
import { isEqual } from 'lodash';

import AsyncComponent from './util/AsyncComponent';
import { withErrorHandling, blocking as blockingMethod } from './util/decorators';
import withErrorScreen from './util/withErrorScreen';
import withBreadcrumbs, { WithBreadcrumbsProps } from './util/withBreadcrumbs';
import {
  fetchSittingById,
  deleteSitting,
  releaseSittingPoints,
  unreleaseSittingPoints,
  concludeSitting,
  allowResume,
} from '../service/sitting';
import {
  updateSittingQuestionPoints,
  fetchSittingQuestions,
  SittingQuestionFilterOptions,
  defaultSittingQuestionOptions,
} from '../service/sittingquestion';

import SittingDetailsHeader from './sittingDetails/SittingDetailsHeader';
import SittingDetailsPanel from './sittingDetails/SittingDetailsPanel';
import SittingQuestionNavigator from './sittingDetails/SittingQuestionNavigator';
import EventList from './eventlist/EventList';
import { fetchExamById } from '../service/exam';
import {
  Collection,
  ExamDetailsDto,
  ExternalUserIdentityDto,
  SittingDetailsDto,
  SittingQuestionDto,
  SittingQuestionState,
  sittingQuestionStateMappings,
} from '../model';
import Paginator from './util/Paginator';
import { addQueryParameters, parseBool, parseQuery } from '../util/queryparser';
import FilterBy from './util/FilterBy';
import { isCodecAppropriate } from '../util/media';
import SittingReportList from './reportlist/SittingReportList';
import { updateCanvasPoints } from '../service/canvas';
import { fetchUserIdentities } from '../service/useridentity';

/**
 * SittingDetailsNavBar
 */

type SittingDetailsPage = 'questions' | 'events' | 'reports';

type SittingDetailsNavBarProps = {
  pageTopRef: RefObject<HTMLDivElement>;
  page: SittingDetailsPage;
  examId: number;
  sitting: SittingDetailsDto;
};

export function SittingDetailsNavBar({ pageTopRef, page, examId, sitting }: SittingDetailsNavBarProps) {
  return (
    <Nav variant="tabs" ref={pageTopRef}>
      <Nav.Item>
        <Nav.Link as={Link} active={page === 'questions'} to={`/exams/${examId}/sittings/${sitting.id}/`}>
          Questions
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} active={page === 'events'} to={`/exams/${examId}/sittings/${sitting.id}/events/`}>
          Events
        </Nav.Link>
      </Nav.Item>
      {sitting.maintainer && (
        <Nav.Item>
          <Nav.Link as={Link} active={page === 'reports'} to={`/exams/${examId}/sittings/${sitting.id}/reports/`}>
            Reports
          </Nav.Link>
        </Nav.Item>
      )}
    </Nav>
  );
}

/**
 * SittingDetailsQuestionList
 */

type SittingDetailsQuestionListProps = {
  blocking: boolean;
  sitting: SittingDetailsDto;
  sittingQuestions: Collection<SittingQuestionDto>;
  options: SittingQuestionFilterOptions;
  onOptionsChange: (newOptions: Partial<SittingQuestionFilterOptions>) => unknown;
  onUpdateSittingQuestionPoints: (event: { sittingQuestionId: number; points: number }) => Promise<void>;
};

export function SittingDetailsQuestionList({
  blocking,
  sitting,
  sittingQuestions,
  options,
  onOptionsChange,
  onUpdateSittingQuestionPoints,
}: SittingDetailsQuestionListProps) {
  return (
    <>
      {sitting.maintainer && (
        <div className="row">
          <div className="col-md-12 titlebar">
            <FilterBy
              values={options}
              onChange={onOptionsChange}
              resetPage
              options={[
                {
                  name: 'Gradedness',
                  queryKey: 'graded',
                  options: [
                    { key: 'all', label: 'Show all', value: undefined, default: true },
                    { key: 'graded', label: 'Only graded', value: true },
                    { key: 'ungraded', label: 'Only ungraded', value: false },
                  ],
                },
                {
                  name: 'State',
                  queryKey: 'state',
                  options: [
                    { key: 'all', label: 'Show all', value: undefined, default: true },
                    ...Object.entries(sittingQuestionStateMappings).map(([state, { prettyName }]) => ({
                      key: state,
                      label: prettyName,
                      value: state,
                    })),
                  ],
                },
              ]}
            />
          </div>
        </div>
      )}

      <Paginator options={options} count={sittingQuestions.count} onChange={onOptionsChange} />

      <SittingQuestionNavigator
        questions={sittingQuestions.entities}
        maintainer={sitting.maintainer}
        streamingPath={sitting.streamingPath}
        blocking={blocking}
        pointsReleased={sitting.pointsReleased}
        showQuestionBank
        showQuestionOrder
        onUpdateSittingQuestionPoints={onUpdateSittingQuestionPoints}
      />

      <Paginator options={options} count={sittingQuestions.count} onChange={onOptionsChange} />
    </>
  );
}

/**
 * SittingDetails
 */

type SittingDetailsSearchPathParams = {
  examId: string;
  sittingId: string;
};

type SittingDetailsProps = WithBreadcrumbsProps &
  RouteComponentProps<SittingDetailsSearchPathParams> & {
    page: SittingDetailsPage;
  };

type SittingDetailsState = {
  loaded: boolean;
  blocking: boolean;
  resumable: boolean;
  sitting: SittingDetailsDto;
  exam: ExamDetailsDto;
  sittingQuestions: Collection<SittingQuestionDto>;
  options: SittingQuestionFilterOptions;
  prohibitMessage: string;
  pageTopRef: RefObject<HTMLDivElement>;
};

class SittingDetails extends AsyncComponent<SittingDetailsProps, SittingDetailsState> {
  constructor(props: SittingDetailsProps) {
    super(props);

    this.state = {
      loaded: false,
      sitting: null,
      exam: null,
      blocking: false,
      resumable: false,
      sittingQuestions: {
        count: 0,
        entities: [],
      },
      options: {},
      prohibitMessage: '',
      pageTopRef: createRef(),
    };

    autoBind(this);

    this.fetchSittingById = withErrorHandling(this.fetchSittingById, this, {
      autoClose: true,
      keepTrying: true,
    });
    this.fetchSittingQuestions = blockingMethod(this.fetchSittingQuestions, this);
    this.onUpdateSittingQuestionPoints = blockingMethod(this.onUpdateSittingQuestionPoints, this);
    this.onConcludeSitting = blockingMethod(this.onConcludeSitting, this);
    this.onReleasePoints = blockingMethod(this.onReleasePoints, this);
    this.onUnreleasePoints = blockingMethod(this.onUnreleasePoints, this);
    this.onDelete = blockingMethod(this.onDelete, this);
    this.onAllowResume = blockingMethod(this.onAllowResume, this);
    this.onUploadPointsToCanvas = blockingMethod(this.onUploadPointsToCanvas, this);
  }

  async searchToOptions() {
    const query = parseQuery(this.props.location.search);
    await this.setStateAsync({
      options: {
        page: Number(query.page) || 1,
        perPage: Number(query.perPage) || 5,
        state: query.state as SittingQuestionState,
        graded: parseBool(query.graded),
      },
    });
  }

  async componentDidMount() {
    await this.searchToOptions();
    await this.fetchSittingById();
    if (this.props.page === 'questions') {
      const search = addQueryParameters(this.props.location.search, this.state.options, defaultSittingQuestionOptions);
      if (search !== this.props.location.search) {
        this.props.history.replace(search);
      } else {
        await this.fetchSittingQuestions();
      }
    }
  }

  getParamsThatShouldTriggerFetch(queryParams: Record<string, string>): Record<string, string> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { questionId, skip, ...rest } = queryParams;
    return rest;
  }

  shouldFetch(prevQuery: Record<string, string>, query: Record<string, string>): boolean {
    return !isEqual(this.getParamsThatShouldTriggerFetch(prevQuery), this.getParamsThatShouldTriggerFetch(query));
  }

  async componentDidUpdate(prevProps: SittingDetailsProps) {
    const prevQuery = parseQuery(prevProps.location.search);
    const query = parseQuery(this.props.location.search);

    if (this.shouldFetch(prevQuery, query) || (prevProps.page !== this.props.page && this.props.page === 'questions')) {
      await this.searchToOptions();
      await this.fetchSittingQuestions();

      const noQuestionIdInQuery = Number.isNaN(Number(query.questionId));
      if (noQuestionIdInQuery && this.state.pageTopRef.current) {
        this.state.pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onOptionsChange(newOptions: Partial<SittingQuestionFilterOptions>) {
    const options = {
      ...this.state.options,
      ...newOptions,
    };

    const search = addQueryParameters(this.props.location.search, options, defaultSittingQuestionOptions);
    if (search !== this.props.location.search) {
      this.props.history.push(search);
    }

    this.setState({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
  }

  async fetchSittingById() {
    const { params } = this.props.match;
    const examId = Number(params.examId);
    const sittingId = Number(params.sittingId);

    const exam = await fetchExamById(examId);
    const sitting = await fetchSittingById(examId, sittingId);

    await this.setStateAsync({
      loaded: true,
      exam,
      sitting,
    });

    // check 1. is this video exam and do we have the appropriate codec
    const appropriateVideo = !exam.streaming || isCodecAppropriate();
    if (!appropriateVideo) {
      await this.setStateAsync({ prohibitMessage: 'Browser not supported, please use Chrome' });
    }

    // check 2. check if still resumable
    const resumableTimeMillis = exam.resumableTime * 1000;
    const millisSinceLastContact = new Date().getTime() - new Date(sitting.lastContactTime).getTime();
    const resumable = millisSinceLastContact <= resumableTimeMillis;
    if (!resumable) {
      await this.setStateAsync({ prohibitMessage: 'Resumable period is expired' });
    }

    const { perPage } = this.state.options;
    if (exam.streaming && perPage !== 5) {
      await this.setStateAsync({ options: { ...this.state.options, perPage: 5 } });
    } else if (!exam.streaming && perPage !== 25) {
      await this.setStateAsync({ options: { ...this.state.options, perPage: 25 } });
    }

    const breadcrumbs = [
      { path: '/exams/', text: 'Exams' },
      { path: `/exams/${sitting.examId}/`, text: sitting.examName },
      { path: `/exams/${sitting.examId}/sittings/`, text: 'Sittings' },
      { path: `/exams/${sitting.examId}/sittings/${sitting.id}/`, text: `Sitting ${sitting.id}` },
    ];
    if (this.props.page === 'events') {
      breadcrumbs.push({ path: `/exams/${sitting.examId}/sittings/${sitting.id}/events/`, text: 'Events' });
    }

    await this.setStateAsync({ resumable });
    this.props.setBreadcrumbs(breadcrumbs);
  }

  async fetchSittingQuestions() {
    const { exam, sitting, options } = this.state;
    const sittingQuestions = await fetchSittingQuestions(exam.id, sitting.id, options);

    if (sitting.totalPoints) {
      sitting.totalPoints = Math.round((sitting.totalPoints + Number.EPSILON) * 100) / 100;
    }
    await this.setStateAsync({
      sittingQuestions,
    });
  }

  async onUpdateSittingQuestionPoints({ sittingQuestionId, points }) {
    const { params } = this.props.match;
    const examId = Number(params.examId);
    const sittingId = Number(params.sittingId);

    await updateSittingQuestionPoints(examId, sittingId, sittingQuestionId, points);

    let totalPoints;
    const sittingQuestionEntities = this.state.sittingQuestions.entities.map((sittingQuestion) => {
      if (sittingQuestion.id === sittingQuestionId) {
        totalPoints = this.state.sitting.totalPoints - sittingQuestion.points + points;
        totalPoints = Math.round((totalPoints + Number.EPSILON) * 100) / 100;
        return { ...sittingQuestion, points };
      }
      return sittingQuestion;
    });

    await this.setStateAsync({
      sitting: {
        ...this.state.sitting,
        totalPoints,
      },
      sittingQuestions: {
        count: this.state.sittingQuestions.count,
        entities: sittingQuestionEntities,
      },
    });
  }

  async onConcludeSitting() {
    const { finishTime } = await concludeSitting(this.state.exam.id, this.state.sitting.id);
    this.setState({ sitting: { ...this.state.sitting, finishTime } });
  }

  async onReleasePoints() {
    await releaseSittingPoints(this.state.exam.id, this.state.sitting.id);
    this.setState({ sitting: { ...this.state.sitting, pointsReleased: true } });
  }

  async onUnreleasePoints() {
    await unreleaseSittingPoints(this.state.exam.id, this.state.sitting.id);
    this.setState({ sitting: { ...this.state.sitting, pointsReleased: false } });
  }

  async onAllowResume() {
    const { exam, sitting } = this.state;
    await allowResume(exam.id, sitting.id);
    await this.setStateAsync({ resumable: true });
  }

  async onDelete() {
    const { params } = this.props.match;
    const examId = Number(params.examId);
    const sittingId = Number(params.sittingId);

    await deleteSitting(examId, sittingId);
    this.props.history.push(`/exams/${examId}/`);
  }

  async onUploadPointsToCanvas() {
    const { params } = this.props.match;
    const examId = Number(params.examId);
    const sittingId = Number(params.sittingId);

    const { group, jsonMetadata } = this.state.exam;
    const { assignment } = jsonMetadata;
    if (!group.providerName || !group.providerSpecificId || !assignment) {
      throw new Error("Can't upload to canvas, not enough metadata");
    }

    const { studentId, totalPoints } = this.state.sitting;

    const identities = await fetchUserIdentities(studentId, { identityType: 'external', providerName: group.providerName });
    const canvasIdentity = identities.entities[0] as ExternalUserIdentityDto;

    await updateCanvasPoints(group.providerName, group.providerSpecificId, assignment.id, canvasIdentity.providerSpecificId, {
      points: totalPoints,
      examId,
      sittingId,
    });
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading sitting...</div>;
    }

    const { page, match } = this.props;
    const examId = Number(match.params.examId);
    const { pageTopRef, sitting, sittingQuestions, exam, options, prohibitMessage, resumable, blocking } = this.state;

    return (
      <div>
        {/* header */}
        <SittingDetailsHeader
          sitting={sitting}
          exam={exam}
          maintainer={sitting.maintainer}
          prohibitMessage={prohibitMessage}
          resumable={resumable}
          onConcludeSitting={this.onConcludeSitting}
          onUploadPointsToCanvas={this.onUploadPointsToCanvas}
          onDelete={this.onDelete}
          onReleasePoints={this.onReleasePoints}
          onUnreleasePoints={this.onUnreleasePoints}
          onAllowResume={this.onAllowResume}
        />

        {/* description panel */}
        <SittingDetailsPanel sitting={sitting} blocking={blocking} />

        {/* nav tab panel */}
        <SittingDetailsNavBar pageTopRef={pageTopRef} page={page} examId={examId} sitting={sitting} />

        {(() => {
          switch (page) {
            case 'questions':
              return (
                <SittingDetailsQuestionList
                  blocking={blocking}
                  sitting={sitting}
                  sittingQuestions={sittingQuestions}
                  options={options}
                  onOptionsChange={this.onOptionsChange}
                  onUpdateSittingQuestionPoints={this.onUpdateSittingQuestionPoints}
                />
              );

            case 'events':
              return <EventList type="sitting" examId={examId} sittingId={sitting.id} maintainer={sitting.maintainer} />;

            case 'reports':
              return <SittingReportList examId={examId} sittingId={sitting.id} maintainer={sitting.maintainer} />;

            default:
              return null;
          }
        })()}
      </div>
    );
  }
}

export default withRouter(withErrorScreen(withBreadcrumbs(SittingDetails)));
