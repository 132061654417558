import autoBind from 'auto-bind';
import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';

import { millisecondsToTimeText } from '../../util/date';

type CountdownWidgetProps = {
  startTime: Date;
  endTime: Date;
  paused?: boolean;
  refreshInterval?: number;
  completeText?: string;
  playTick1?: boolean;
  playTick2?: boolean;
  onComplete?: () => void;
};

type CountdownWidgetState = {
  paused: boolean;
  percentage: number;
  remainingSeconds: number;
  remainingHr: string;
  variant?: Variant;
  completed: boolean;
  label: string;
  overlayLabel?: string;
};

export default class CountdownWidget extends Component<CountdownWidgetProps, CountdownWidgetState> {
  countdownInterval: NodeJS.Timeout;
  finalCountdownTimeout: NodeJS.Timeout;
  warningTimeout: NodeJS.Timeout;
  dangerTimeout: NodeJS.Timeout;
  clearOverlayTimeout: NodeJS.Timeout;

  tick1 = new Audio('/sound/tick1.mp3');
  tick2 = new Audio('/sound/tick2.mp3');

  constructor(props: CountdownWidgetProps) {
    super(props);
    autoBind(this);
    this.state = {
      ...this.constructState(),
      variant: 'success',
      overlayLabel: '',
    };
  }

  constructState(): CountdownWidgetState {
    const { startTime, endTime } = this.props;
    const percentage = ((endTime.getTime() - Date.now()) / (endTime.getTime() - startTime.getTime())) * 100;
    const remainingSeconds = (endTime.getTime() - Date.now()) / 1000;
    const remainingHr = millisecondsToTimeText(endTime.getTime() - Date.now());
    const variant = percentage > 30 ? 'success' : percentage > 10 ? 'warning' : 'danger';
    const completed = remainingSeconds <= 0;
    const label = completed ? this.props.completeText || 'Completed' : remainingHr;

    if (!this.warningTimeout) {
      const timeToWarning = endTime.getTime() - 0.3 * (endTime.getTime() - startTime.getTime()) - Date.now();
      this.warningTimeout = setTimeout(this.onSwitchToWarning, timeToWarning);
    }

    if (!this.dangerTimeout) {
      const timeToDanger = endTime.getTime() - 0.1 * (endTime.getTime() - startTime.getTime()) - Date.now();
      this.dangerTimeout = setTimeout(this.onSwitchToDanger, timeToDanger);
    }

    return {
      percentage,
      remainingSeconds,
      remainingHr,
      completed,
      label,
      variant,
      paused: false,
    };
  }

  componentDidMount(): void {
    this.clearTimeouts();
    if (!this.state.completed) {
      console.debug(`Setting countdown interval to ${this.props.refreshInterval || 1000}`);
      this.setTimer();
    }
  }

  componentDidUpdate(): void {
    if (!this.state.paused && !this.state.completed && !this.countdownInterval) {
      console.debug('Resetting countdown interval');
      this.setTimer();
    }

    if (this.props.paused && !this.state.paused) {
      // on pause
      this.clearTimeouts();
      this.setState({
        percentage: 100,
        label: 'paused',
        variant: 'secondary',
        paused: true,
      });
    }
    if (!this.props.paused && this.state.paused) {
      // on unpause
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
        this.countdownInterval = null;
      }
      this.reevaluate();
      if (!this.state.completed) {
        this.setTimer();
      }
    }
  }

  componentWillUnmount(): void {
    this.clearTimeouts();
  }

  onSwitchToWarning(): void {
    if (this.props.playTick1) {
      this.tick1.play();
      this.setState({ overlayLabel: '<30% remaining' });
    }
    this.setState({ variant: 'warning' });
    this.clearOverlayTimeout = setTimeout(this.clearOverlay, 3000);
  }

  onSwitchToDanger(): void {
    if (this.props.playTick2) {
      this.tick2.play();
      this.setState({ overlayLabel: '<10% !' });
    }
    this.setState({ variant: 'danger' });
    this.clearOverlayTimeout = setTimeout(this.clearOverlay, 3000);
  }

  setTimer(): void {
    this.countdownInterval = setInterval(this.reevaluate, this.props.refreshInterval || 1000);
    const remainingTime = this.props.endTime.getTime() - Date.now();
    this.finalCountdownTimeout = setTimeout(this.reevaluate, remainingTime);
  }

  clearOverlay(): void {
    this.setState({ overlayLabel: '' });
  }

  clearTimeouts(): void {
    if (this.countdownInterval) {
      console.debug('Clearing countdown interval on close');
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    }
    if (this.finalCountdownTimeout) {
      clearTimeout(this.finalCountdownTimeout);
      this.finalCountdownTimeout = null;
    }
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
      this.warningTimeout = null;
    }
    if (this.dangerTimeout) {
      clearTimeout(this.dangerTimeout);
      this.dangerTimeout = null;
    }
    if (this.clearOverlayTimeout) {
      clearTimeout(this.clearOverlayTimeout);
      this.clearOverlayTimeout = null;
    }
  }

  reevaluate(): void {
    this.setState(this.constructState(), () => {
      if (this.state.completed) {
        this.clearTimeouts();
        if (this.props.onComplete) {
          this.props.onComplete();
        }
      }
    });
  }

  render(): JSX.Element {
    return (
      <div className="custom-progress-bar">
        <div className="float-left">
          <b>{this.state.label}</b>&nbsp;
        </div>
        <ProgressBar now={this.state.percentage} variant={this.state.variant} label={<b>{this.state.overlayLabel}</b>} />
      </div>
    );
  }
}
