import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import OverlayButton from './OverlayButton';

export interface FilterOptions {
  name: string;
  queryKey: string;
  options: {
    key: string;
    label: string;
    value: unknown;
    default?: boolean;
  }[];
}

interface FilterByProps extends RouteComponentProps {
  values: { [key: string]: unknown };
  onChange: (options: { [key: string]: unknown }) => void;
  options: FilterOptions[];
  resetPage?: boolean;
}

function FilterBy({ values, onChange, options, resetPage = false }: FilterByProps) {
  const onOptionChange = useCallback(
    (queryKey: string, value: unknown) => {
      if (values[queryKey] !== value) {
        onChange({
          [queryKey]: value,
          page: resetPage ? 1 : undefined,
        });
      }
    },
    [values, onChange, resetPage],
  );

  const onClear = useCallback(() => {
    const ret = {};
    options.forEach((filterOption) => {
      const activeOption = filterOption.options.find((option) => values[filterOption.queryKey] === option.value);
      if (activeOption && !activeOption.default) {
        const defaultOption = filterOption.options.find((option) => option.default);
        ret[filterOption.queryKey] = defaultOption.value;
      }
    });

    onChange({
      ...ret,
      page: resetPage ? 1 : undefined,
    });
  }, [options, values, resetPage]);

  const activeOptions = useMemo(() => {
    return options
      .map((filterOption) => {
        const activeOption = filterOption.options.find((option) => values[filterOption.queryKey] === option.value);
        if (activeOption && !activeOption.default) {
          return `${filterOption.name}=${activeOption.label}`;
        }
        return undefined;
      })
      .filter((s) => s)
      .join('; ');
  }, [options, values]);

  const title = (
    <>
      Filter: <i>{activeOptions || 'Show all'}</i>&nbsp;
      <FontAwesomeIcon icon="filter" size="xs" />
    </>
  );

  return (
    <ButtonGroup className="dynamic-filter">
      <DropdownButton as={ButtonGroup} size="sm" variant="outline-secondary" title={title}>
        {options.map((filterOption) => (
          <span key={filterOption.queryKey}>
            <Dropdown.Item disabled>
              <b>{filterOption.name}</b>
            </Dropdown.Item>

            {filterOption.options.map((option) => (
              <Dropdown.Item
                key={`${filterOption.queryKey}-${option.value}`}
                active={values[filterOption.queryKey] === option.value}
                onClick={() => onOptionChange(filterOption.queryKey, option.value)}
              >
                {option.label}
              </Dropdown.Item>
            ))}
          </span>
        ))}
      </DropdownButton>
      <OverlayButton size="sm" variant="outline-secondary" tooltip="Clear filter" onClick={onClear}>
        <FontAwesomeIcon icon="times" size="xs" />
      </OverlayButton>
    </ButtonGroup>
  );
}

export default withRouter(FilterBy);
