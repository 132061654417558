import React, { ChangeEvent, KeyboardEvent } from 'react';
import autoBind from 'auto-bind';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Form, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AsyncComponent from './util/AsyncComponent';
import withErrorScreen from './util/withErrorScreen';
import OverlayButton from './util/OverlayButton';
import CredentialsContext, { CredentialsContextType } from '../context/credentials';

import { AuthProviderParams, LoginDto } from '../model';

type LoginPageType = 'standard' | 'external';

type LoginProps = RouteComponentProps & {
  onLogin: (loginDto: LoginDto) => void;
};

type LoginState = {
  loginCredentials: LoginDto;
  valid: boolean;
  page: LoginPageType;
};

class Login extends AsyncComponent<LoginProps, LoginState> {
  context: CredentialsContextType;

  constructor(props: LoginProps) {
    super(props);

    this.state = {
      loginCredentials: {
        email: localStorage.cvqLastLoginEmail || '',
        password: '',
      },
      valid: false,
      page: localStorage.cvqLastLoginPage || 'standard',
    };

    autoBind(this);
  }

  onSelectLoginPage(loginPage: LoginPageType): void {
    this.setState({ page: loginPage });
    localStorage.cvqLastLoginPage = loginPage;
  }

  onSelectProvider(provider: AuthProviderParams) {
    return () => {
      if (provider.strategy === 'redirect') {
        const loginUrl = provider.externalLoginUrl as unknown;
        window.location = loginUrl as Location;
      }
    };
  }

  onValueChange(event: ChangeEvent<HTMLInputElement>): void {
    const { target } = event;
    const value = event.target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;

    const loginCredentials = {
      ...this.state.loginCredentials,
      [key]: value,
    };
    const { email, password } = loginCredentials;

    this.setState({
      loginCredentials,
      valid: email.length > 0 && password.length > 0,
    });
  }

  onKeyUp(event: KeyboardEvent): void {
    const { loginCredentials, valid } = this.state;

    if (!valid) {
      return;
    }
    if (event.key === 'Enter' || event.keyCode === 13) {
      this.props.onLogin(loginCredentials);
    }
  }

  onSubmit(): void {
    const { loginCredentials, valid } = this.state;

    if (!valid) {
      return;
    }
    localStorage.cvqLastLoginEmail = loginCredentials.email;
    this.props.onLogin(loginCredentials);
  }

  render(): JSX.Element {
    const { loginCredentials, valid, page } = this.state;
    const { providers } = this.context;
    const numProviders = Object.keys(providers || {}).length;

    return (
      <>
        {/* header */}
        <div className="form-row">
          <div className="form-group col-md-12 titlebar">
            <h3>Login</h3>
          </div>
        </div>

        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link active={page === 'standard'} onClick={() => this.onSelectLoginPage('standard')}>
              Standard
            </Nav.Link>
          </Nav.Item>
          {numProviders > 0 && (
            <>
              <Nav.Item>
                <Nav.Link active={page === 'external'} onClick={() => this.onSelectLoginPage('external')}>
                  External
                </Nav.Link>
              </Nav.Item>
            </>
          )}
        </Nav>

        <div className="row">
          <div className="col-md-12">
            <div className="jumbotron">
              {page === 'standard' && (
                <>
                  <Form.Group>
                    <Form.Label>E-mail address</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      onChange={this.onValueChange}
                      onKeyUp={this.onKeyUp}
                      placeholder="user.email@example.com"
                      value={loginCredentials.email}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      onChange={this.onValueChange}
                      onKeyUp={this.onKeyUp}
                      value={loginCredentials.password}
                    />
                  </Form.Group>

                  {/* submit */}
                  <div className="float-right">
                    <OverlayButton
                      variant="success"
                      disabled={!valid}
                      disabledTooltip="Please fill out form correctly"
                      onClick={this.onSubmit}
                    >
                      <FontAwesomeIcon icon="check" />
                      &nbsp;Confirm
                    </OverlayButton>
                  </div>
                </>
              )}

              {page === 'external' && numProviders > 0 && (
                <>
                  Sign in with
                  <div className="single-button-row">
                    {Object.values(providers).map((provider) => (
                      <Button key={provider.name} onClick={this.onSelectProvider(provider)}>
                        {provider.logo && <img src={provider.logo} alt={provider.name} height="40"></img>}
                        {provider.description}
                      </Button>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Login.contextType = CredentialsContext;
export default withRouter(withErrorScreen(Login));
