import { useMemo, useState } from 'react';
import BlockUi from 'react-block-ui';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useBlocking, useWithErrorHandlingEffect } from '../hooks/useAsync';
import useBreadcrumbs from '../hooks/useBreadcrumbs';
import { ExamDetailsDto, QuestionBankDto } from '../model';
import { fetchExamById } from '../service/exam';
import { deleteBank, fetchBankById } from '../service/questionbank';
import BankDetailsHeader from './bankDetails/BankDetailsHeader';
import BankDetailsPanel from './bankDetails/BankDetailsPanel';
import QuestionList from './bankDetails/QuestionList';
import ExamSittingsWarning from './util/ExamSittingsWarning';

export type BankDetailsRouteProps = {
  examId: string;
  bankId: string;
};

export type BankDetailsProps = RouteComponentProps<BankDetailsRouteProps>;

function BankDetails({ match, history }: BankDetailsProps) {
  const examId = useMemo(() => Number(match.params.examId), [match.params.examId]);
  const bankId = useMemo(() => Number(match.params.bankId), [match.params.bankId]);

  const [loaded, setLoaded] = useState(false);
  const [exam, setExam] = useState<ExamDetailsDto>(null);
  const [bank, setBank] = useState<QuestionBankDto>(null);

  const setBreadcrumbs = useBreadcrumbs();

  useWithErrorHandlingEffect(
    async () => {
      const newExam = await fetchExamById(examId);
      const newBank = await fetchBankById(examId, bankId);

      setExam(newExam);
      setBank(newBank);
      setBreadcrumbs([
        { path: '/exams/', text: 'Exams' },
        { path: `/exams/${examId}/`, text: newExam.name },
        { path: `/exams/${examId}/banks/`, text: 'Banks' },
        { path: `/exams/${examId}/banks/${bankId}/`, text: newBank.name },
      ]);
      setLoaded(true);
    },
    {
      autoClose: true,
      keepTrying: true,
    },
  );

  const { decorated: onDelete, blocking } = useBlocking(async () => {
    await deleteBank(examId, bankId);
    history.push(`/exams/${examId}/`);
  });

  if (!loaded) {
    return <div className="infomsg">Loading bank...</div>;
  }

  return (
    <BlockUi tag="div" blocking={blocking}>
      {/* header */}
      <BankDetailsHeader name={bank?.name} onDelete={onDelete} />

      {/* description panel */}
      <BankDetailsPanel bank={bank} exam={exam} />

      {/* warn if there are active sittings */}
      <ExamSittingsWarning examId={examId} />

      {/* question list */}
      <QuestionList bank={bank} exam={exam} />
    </BlockUi>
  );
}

export default withRouter(BankDetails);
