import React from 'react';
import RichTextEditor from 'react-rte';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import { DefaultDraftBlockRenderMap, getDefaultKeyBinding } from 'draft-js';
import { Nav } from 'react-bootstrap';

import { LinkDecorator } from './rich-tex-editor/decorators';
import { AceCodeBlock, ImageBlock, TeXBlock } from './rich-tex-editor/blocks';
import RichTeX from './rich-tex-editor/RichTex';
import AsyncComponent from './AsyncComponent';
import { ImageUploadModal, FileLinkUploadModal, TeXEditorModal, CodeEditorModal, ImageSourceEditorModal, LinkEditorModal } from './Modals';
import { uploadUserResource } from '../../service/filestorage';
import CredentialsContext from '../../context/credentials';
import { themes } from '../../util/ace';

import './RichTeXEditor.scss';

class RichTeXEditor extends AsyncComponent {
  constructor(props) {
    super(props);

    this.state = {
      wysiwyg: props.hideMarkdown || !Number(localStorage.cvqPreferMarkdownEditor),
      edit: {
        block: null,
        entityKey: null,
        type: '',
        linkUrl: '',
        linkPlaceholder: '',
        imgSrc: '',
        imgAlt: '',
        texText: '',
        codeLanguage: 'text',
        codeText: '',
      },
      showUploadFile: false,
      showUploadImage: false,
    };

    this.toWysiwyg = this.toWysiwyg.bind(this);
    this.toMarkdown = this.toMarkdown.bind(this);
    this.blockRenderer = this.blockRenderer.bind(this);
    this.onFinishEditing = this.onFinishEditing.bind(this);
    this.onCancelEditing = this.onCancelEditing.bind(this);
    this.onRemoveBlock = this.onRemoveBlock.bind(this);
    this.onRemoveEntity = this.onRemoveEntity.bind(this);
    this.onConfirmLinkEditing = this.onConfirmLinkEditing.bind(this);
    this.onConfirmImgEditing = this.onConfirmImgEditing.bind(this);
    this.onConfirmTeXEditing = this.onConfirmTeXEditing.bind(this);
    this.onConfirmCodeEditing = this.onConfirmCodeEditing.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);

    this.blockRenderMap = DefaultDraftBlockRenderMap;
    this.blockRenderMap.get('code-block').element = 'div';
    delete this.blockRenderMap.get('code-block').wrapper;
  }

  toWysiwyg() {
    localStorage.cvqPreferMarkdownEditor = 0;
    this.setState({ wysiwyg: true });
  }

  toMarkdown() {
    localStorage.cvqPreferMarkdownEditor = 1;
    this.setState({ wysiwyg: false });
  }

  blockRenderer(block) {
    // latex
    if (block.getType() === 'atomic' && block.getData().get('latex')) {
      const contentState = this.props.value.getEditorState().getCurrentContent();
      const entityKey = block.getEntityAt(0);
      if (!entityKey) {
        return null;
      }
      const entityData = contentState.getEntity(entityKey).getData();

      return {
        component: TeXBlock,
        editable: false,
        props: {
          readOnly: this.props.readOnly,
          texText: entityData.content,
          inline: this.props.inline,
          onClick: (texText) => {
            this.setState({
              edit: {
                type: 'tex',
                block,
                texText,
              },
            });
          },
        },
      };
    }

    // figure
    if (block.getType() === 'atomic') {
      const contentState = this.props.value.getEditorState().getCurrentContent();
      const entityKey = block.getEntityAt(0);
      if (!entityKey) {
        return null;
      }
      const entityData = contentState.getEntity(entityKey).getData();

      return {
        component: ImageBlock,
        editable: false,
        props: {
          readOnly: this.props.readOnly,
          src: entityData.src,
          alt: entityData.alt,
          onClick: (imgSrc, imgAlt) => {
            this.setState({
              edit: {
                type: 'img',
                block,
                imgSrc,
                imgAlt,
              },
            });
          },
        },
      };
    }

    // code
    if (block.getType() === 'code-block') {
      return {
        component: AceCodeBlock,
        editable: true,
        props: {
          readOnly: this.props.readOnly,
          text: block.getText(),
          language: block.getData().get('language') || 'text',
          inline: this.props.inline,
          onClick: (codeLanguage, codeText) => {
            this.setState({
              edit: {
                type: 'code',
                block,
                codeLanguage,
                codeText,
              },
            });
          },
        },
      };
    }
    return null;
  }

  onFinishEditing() {
    const { value, onChange } = this.props;
    this.setState(
      {
        edit: {
          block: null,
          entityKey: null,
          type: '',
          texText: '',
          codeLanguage: 'text',
          codeText: '',
        },
        showUploadFile: false,
        showUploadImage: false,
      },
      () => onChange(value),
    );
  }

  onCancelEditing() {
    this.setState({
      edit: {
        block: null,
        entityKey: null,
        type: '',
        texText: '',
        codeLanguage: 'text',
        codeText: '',
      },
    });
  }

  onRemoveBlock() {
    const { block } = this.state.edit;
    this.props.value.removeBlock(block);
    this.onFinishEditing();
  }

  onRemoveEntity() {
    const { blockKey, entityKey } = this.state.edit;
    this.props.value.removeEntity(blockKey, entityKey);
    this.onFinishEditing();
  }

  onConfirmLinkEditing({ url, placeholder }) {
    const { blockKey, entityKey } = this.state.edit;
    if (entityKey) {
      this.props.value.setLinkEntity(blockKey, entityKey, url, placeholder);
    } else {
      this.props.value.insertLinkEntity(url, placeholder);
    }
    this.onFinishEditing();
  }

  onConfirmImgEditing({ src, alt }) {
    const { block } = this.state.edit;
    if (block) {
      this.props.value.setImageBlock(block, src, alt);
    } else {
      this.props.value.insertImageBlock(src, alt);
    }
    this.onFinishEditing();
  }

  onConfirmTeXEditing({ texText }) {
    const { block } = this.state.edit;
    if (block) {
      this.props.value.setTeXBlock(block, texText);
    } else {
      this.props.value.insertTeXBlock(texText);
    }
    this.onFinishEditing();
  }

  onConfirmCodeEditing({ language, text }) {
    const { block } = this.state.edit;
    if (block) {
      this.props.value.setCodeBlock(block, language, text);
    } else {
      this.props.value.insertCodeBlock(language, text);
    }
    this.onFinishEditing();
  }

  async onFileUpload({ filename, file }) {
    let upload;
    if (this.props.onUpload) {
      upload = await this.props.onUpload({ filename, file });
    } else {
      upload = await uploadUserResource(this.context.userId, this.context.userName, filename, file);
    }

    if (upload && upload.url) {
      await this.setStateAsync({ showUploadFile: false });
      this.props.value.insertLinkEntity(upload.url, filename);
      this.onFinishEditing();
    }
  }

  async onImageUpload({ filename, file }) {
    let upload;
    if (this.props.onUpload) {
      upload = await this.props.onUpload({ filename, file });
    } else {
      upload = await uploadUserResource(this.context.userId, this.context.userName, filename, file);
    }

    if (upload && upload.url) {
      this.props.value.insertImageBlock(upload.url, filename);
      this.onFinishEditing();
    }
  }

  render() {
    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH' },
        { label: 'Monospace', style: 'CODE' },
        { label: 'Underline', style: 'UNDERLINE' },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'List', style: 'unordered-list-item' },
        { label: 'Numbered list', style: 'ordered-list-item' },
        { label: 'Blockquote', style: 'blockquote' },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading 1', style: 'header-one' },
        { label: 'Heading 2', style: 'header-two' },
        { label: 'Heading 3', style: 'header-three' },
      ],
    };

    const { hideMarkdown, readOnly, value, onChange } = this.props;
    const { wysiwyg, edit } = this.state;

    const size = this.props.size || 'large';

    LinkDecorator.decoratorProps = {
      readOnly,
      onClick: (blockKey, entityKey, linkUrl, linkPlaceholder) => {
        this.setState({
          edit: {
            type: 'link',
            blockKey,
            entityKey,
            linkUrl,
            linkPlaceholder,
          },
        });
      },
    };

    const keyBindingFn = (e) => {
      // if selection is atomic or code block, jump to a new unstyled
      const currentBlockType = value.getCurrentBlockType();
      if (currentBlockType === 'atomic' || currentBlockType === 'code-block') {
        return 'none';
      }
      return getDefaultKeyBinding(e);
    };

    return (
      <>
        {/* navigation between WYSIWYG and Markdown code */}
        {!readOnly && !hideMarkdown && (
          <>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link active={wysiwyg} onClick={this.toWysiwyg}>
                  WYSIWYG
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link active={!wysiwyg} onClick={this.toMarkdown}>
                  Markdown
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </>
        )}

        {/* WYSIWYG editor */}
        {!readOnly && wysiwyg && (
          <>
            <div className="TeXEditor-container">
              <div className="TeXEditor-root">
                <div className="TeXEditor-editor">
                  <RichTextEditor
                    className={this.props.className || 'static-editor'}
                    editorClassName={`${size}-active-rte-editor`}
                    value={this.props.value.getEditorValue()}
                    toolbarConfig={toolbarConfig}
                    blockRendererFn={this.blockRenderer}
                    blockRenderMap={this.blockRenderMap}
                    keyBindingFn={keyBindingFn}
                    onChange={(editorValue) => {
                      value.setEditorValue(editorValue);
                      onChange(value);
                    }}
                    customStyleMap={{ CODE: { strokeDashoffset: '0' } }}
                    customControls={[
                      <div key={0} className="rte-custom-button-group">
                        <div className="rte-custom-button-wrapper">
                          <button
                            type="button"
                            title="Insert link"
                            className="rte-custom-button"
                            onClick={() => this.setState({ edit: { type: 'link', linkPlaceholder: this.props.value.getSelectedText() } })}
                          >
                            <span className="rte-custom-button-icon rte-custom-button-icon-link"></span>
                          </button>
                        </div>
                        {this.props.allowFileUpload && (
                          <div className="rte-custom-button-wrapper">
                            <button
                              type="button"
                              title="Upload image"
                              className="rte-custom-button"
                              onClick={() => this.setState({ showUploadImage: true })}
                            >
                              <span className="rte-custom-button-icon rte-custom-button-icon-upload-image"></span>
                            </button>
                          </div>
                        )}
                        {this.props.allowFileUpload && (
                          <div className="rte-custom-button-wrapper">
                            <button
                              type="button"
                              title="Upload file"
                              className="rte-custom-button"
                              onClick={() => this.setState({ showUploadFile: true })}
                            >
                              <span className="rte-custom-button-icon rte-custom-button-icon-upload-file"></span>
                            </button>
                          </div>
                        )}
                      </div>,
                      <div key={1} className="rte-custom-button-group">
                        <div className="rte-custom-button-wrapper">
                          <button
                            type="button"
                            title="TeX block"
                            className="rte-custom-button"
                            onClick={() => this.setState({ edit: { type: 'tex' } })}
                          >
                            <span className="rte-custom-button-icon rte-custom-button-icon-tex"></span>
                          </button>
                        </div>
                        <div className="rte-custom-button-wrapper">
                          <button
                            type="button"
                            title="Code block"
                            className="rte-custom-button"
                            onClick={() => this.setState({ edit: { type: 'code' } })}
                          >
                            <span className="rte-custom-button-icon rte-custom-button-icon-codeblock"></span>
                          </button>
                        </div>
                      </div>,
                    ]}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {/* Markdown editor */}
        {!readOnly && !wysiwyg && (
          <>
            <AceEditor
              className={`${size}-active-code-editor`}
              mode="markdown"
              theme={this.context.darkMode ? themes.dark : themes.light}
              fontSize={16}
              height="400px"
              placeholder="Insert Markdown script here"
              value={this.props.value.getMarkdown()}
              showPrintMargin={false}
              onChange={(markdown) => {
                value.setMarkdown(markdown);
                onChange(value);
              }}
              editorProps={{ $blockScrolling: true }}
            />
          </>
        )}

        {/* Render view (read-only) */}
        {readOnly && (
          <>
            <RichTextEditor
              className={this.props.className || 'static-editor'}
              value={this.props.value.getEditorValue()}
              blockRendererFn={this.blockRenderer}
              blockRenderMap={this.blockRenderMap}
              customStyleMap={{ CODE: { strokeDashoffset: '0' } }}
              readOnly={true}
            />
          </>
        )}

        {/* Modal */}
        {!readOnly && (
          <>
            <FileLinkUploadModal
              show={this.state.showUploadFile}
              onConfirm={this.onFileUpload}
              onCancel={() => this.setState({ showUploadFile: false })}
            />

            <ImageUploadModal
              show={this.state.showUploadImage}
              accepts="image/*"
              onConfirmUpload={this.onImageUpload}
              onConfirmUrl={this.onConfirmImgEditing}
              onCancel={() => this.setState({ showUploadImage: false })}
            />

            <LinkEditorModal
              url={edit.linkUrl}
              placeholder={edit.linkPlaceholder}
              show={edit.type === 'link'}
              onConfirm={this.onConfirmLinkEditing}
              onCancel={this.onCancelEditing}
              onRemove={edit.entityKey && this.onRemoveEntity}
            />

            <ImageSourceEditorModal
              src={edit.imgSrc}
              alt={edit.imgAlt}
              show={edit.type === 'img'}
              onConfirm={this.onConfirmImgEditing}
              onCancel={this.onCancelEditing}
              onRemove={this.onRemoveBlock}
            />

            <TeXEditorModal
              texText={edit.texText}
              show={edit.type === 'tex'}
              onConfirm={this.onConfirmTeXEditing}
              onCancel={this.onCancelEditing}
              onRemove={edit.block && this.onRemoveBlock}
            />

            <CodeEditorModal
              language={edit.codeLanguage}
              text={edit.codeText}
              show={edit.type === 'code'}
              onConfirm={this.onConfirmCodeEditing}
              onCancel={this.onCancelEditing}
              onRemove={edit.block && this.onRemoveBlock}
            />
          </>
        )}
      </>
    );
  }
}

RichTeXEditor.contextType = CredentialsContext;

RichTeXEditor.propTypes = {
  value: PropTypes.instanceOf(RichTeX).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  size: PropTypes.string,
  inline: PropTypes.bool,
  hideMarkdown: PropTypes.bool,
  allowFileUpload: PropTypes.bool,
  onUpload: PropTypes.func,
};

export { RichTeX, RichTeXEditor };
