import autoBind from 'auto-bind';
import React, { Component, CSSProperties } from 'react';
import { NumericSittingQuestionDto, SittingQuestionDto } from '../../model';
import { shortId } from '../../util/random';
import { RichTeX, RichTeXEditor } from '../util/RichTeXEditor';
import { QuestionEditorModelProps, QuestionEditorProps } from './QuestionEditorModel';

/**
 * Extended model props
 */

export class NumericQuestionEditorModelProps extends QuestionEditorModelProps<NumericSittingQuestionDto> {
  questionText: RichTeX;

  constructor(question: NumericSittingQuestionDto, template?: SittingQuestionDto) {
    super(question, template);
    this.questionText = new RichTeX(this.question.questionContent);

    if (this.question.response === null) {
      this.question.response = undefined;
    }
  }

  refreshQuestion(): void {
    this.question.questionContent = this.questionText.getMarkdown();
  }

  fromQuestionTemplate(template: SittingQuestionDto): NumericSittingQuestionDto {
    return {
      id: template.id,
      questionType: 'numeric',
      questionContent: (template.questionContent as { text: string }).text || (template.questionContent as string),
      evaluationKey: {
        min: 0,
        max: 42,
      },
    };
  }

  setQuestionText(questionText: RichTeX): void {
    this.questionText = questionText;
    this.questionUpToDate = false;
  }

  setAcceptedMin(newMin: number): void {
    this.question.evaluationKey.min = newMin;
  }

  setAcceptedMax(newMax: number): void {
    this.question.evaluationKey.max = newMax;
  }

  setResponse(response: number): void {
    this.question.response = response;
  }
}

/**
 * Alias
 */

type NumericQuestionEditorProps = QuestionEditorProps<NumericSittingQuestionDto, NumericQuestionEditorModelProps>;

/**
 * Associated components
 */

export default class NumericQuestionEditor extends Component<NumericQuestionEditorProps> {
  token = shortId();

  constructor(props: NumericQuestionEditorProps) {
    super(props);
    autoBind(this);
  }

  onQuestionTextChange(questionText: RichTeX): void {
    const { model, onChange } = this.props;
    model.setQuestionText(questionText);
    onChange(model);
  }

  onAcceptedMinChange(newMin: number): void {
    const { model, onChange } = this.props;
    model.setAcceptedMin(newMin);
    onChange(model);
  }

  onAcceptedMaxChange(newMax: number): void {
    const { model, onChange } = this.props;
    model.setAcceptedMax(newMax);
    onChange(model);
  }

  onResponseChange(response: number): void {
    const { model, onChange } = this.props;
    model.setResponse(response);
    onChange(model);
  }

  render(): JSX.Element {
    const { mode, model, allowFileUpload, onUpload } = this.props;

    let min: number;
    let max: number;
    const responseStyle: CSSProperties = { paddingBottom: 4 };

    if (model.question.evaluationKey) {
      min = model.question.evaluationKey.min;
      max = model.question.evaluationKey.max;

      if (mode === 'evaluate') {
        const correct = model.question.response >= min && model.question.response <= max;
        responseStyle.color = correct ? '#009900' : '#990000';
        responseStyle.backgroundColor = correct ? '#00ff0010' : '#ff000010';
      }
    }

    return (
      <>
        {/* question editor */}
        <span className="question-text">
          <RichTeXEditor
            className="static-editor"
            size="medium"
            value={model.questionText}
            readOnly={mode !== 'edit'}
            onChange={this.onQuestionTextChange}
            allowFileUpload={allowFileUpload}
            onUpload={onUpload}
          />
        </span>
        <br />

        {/* response when taking */}
        {mode === 'take' && (
          <>
            <div style={{ paddingBottom: 4, paddingTop: 12 }}>
              <b className="eventmsg">Response:</b>&nbsp;
              <input
                type="number"
                placeholder="Your response goes here"
                value={model.question.response}
                onChange={(event) => this.onResponseChange(Number(event.target.value))}
                style={{ flexGrow: 1, width: 100 }}
                required
              />
            </div>
          </>
        )}

        {/* response */}
        {(mode === 'response' || mode === 'evaluate') && (
          <>
            <div style={responseStyle}>
              {model.question.response !== undefined && model.question.response !== null && !Number.isNaN(model.question.response) ? (
                <i>
                  Response: <b>{model.question.response}</b>
                </i>
              ) : (
                <i>No response given</i>
              )}
            </div>
          </>
        )}

        {/* edit accepted min/max */}
        {mode === 'edit' && (
          <>
            <div className="eventmsg" style={{ paddingBottom: 4 }}>
              <b>Correct answers are between&nbsp;</b>
              <input
                type="number"
                placeholder="Min"
                value={min}
                onChange={(event) => this.onAcceptedMinChange(Number(event.target.value))}
                style={{ flexGrow: 1, width: 100 }}
                required
              />
              <b>&nbsp;and&nbsp;</b>
              <input
                type="number"
                placeholder="Max"
                value={max}
                onChange={(event) => this.onAcceptedMaxChange(Number(event.target.value))}
                style={{ flexGrow: 1, width: 100 }}
                required
              />

              {min > max && (
                <>
                  <span className="errormsg">&nbsp;Warning: max value is smaller than min, no answers can be correct</span>
                </>
              )}
            </div>
          </>
        )}

        {/* show accepted min/max */}
        {(mode === 'view' || mode === 'evaluate') && (
          <>
            <div className="eventmsg" style={{ paddingBottom: 4 }}>
              <b>
                Correct answers are between {min} and {max}
              </b>
            </div>
          </>
        )}
      </>
    );
  }
}
