import React, { createRef, RefObject } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import BlockUi from 'react-block-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';

import SittingListEntry from './SittingListEntry';
import { blocking } from '../util/decorators';
import withErrorScreen from '../util/withErrorScreen';
import AsyncComponent from '../util/AsyncComponent';
import { addQueryParameters, parseBool, parseQuery } from '../../util/queryparser';
import Paginator from '../util/Paginator';
import SearchBy from '../util/SearchBy';
import OverlayButton from '../util/OverlayButton';
import SortBy from '../util/SortBy';
import { DeleteModal } from '../util/Modals';

import {
  fetchSittings,
  releaseSittingPoints,
  unreleaseSittingPoints,
  concludeSitting,
  SittingFilterOptions,
  defaultSittingFilterOptions,
  exportAllPoints,
  fetchSittingsOnlyPoints,
} from '../../service/sitting';
import { releaseAllPoints, deleteAllSittings, concludeExam } from '../../service/exam';
import { updateMultipleCanvasPoints } from '../../service/canvas';
import FilterBy from '../util/FilterBy';
import { Collection, ExamDetailsDto, SittingReducedDto } from '../../model';
import CredentialsContext from '../../context/credentials';
import storage from '../../util/storage';

type SittingListProps = RouteComponentProps & {
  exam: ExamDetailsDto;
  maintainer: boolean;
  onConcludeSitting: () => void;
};

type SittingListState = {
  loaded: boolean;
  blocking: boolean;
  sittings: Collection<SittingReducedDto>;
  options: SittingFilterOptions;
  showDeleteModal: boolean;
  pageTopRef: RefObject<HTMLDivElement>;
};

class SittingList extends AsyncComponent<SittingListProps, SittingListState> {
  constructor(props: SittingListProps) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      sittings: {
        entities: [],
        count: 0,
      },
      showDeleteModal: false,
      options: {},
      pageTopRef: createRef(),
    };

    autoBind(this);

    this.fetchSittings = blocking(this.fetchSittings, this);
    this.onConclude = blocking(this.onConclude, this);
    this.onReleaseAllPoints = blocking(this.onReleaseAllPoints, this);
    this.onExportPoints = blocking(this.onExportPoints, this);
    this.onUploadAllPointsToCanvas = blocking(this.onUploadAllPointsToCanvas, this);
  }

  async searchToOptions() {
    const query = parseQuery(this.props.location.search);
    await this.setStateAsync({
      options: {
        studentName: query.studentName || '',
        simulated: parseBool(query.simulated),
        active: parseBool(query.active),
        page: Number(query.page) || 1,
        perPage: Number(query.perPage) || 25,
        sortBy: storage.sittingSortBy.get(query.sortBy),
        sortDirection: storage.sittingSortDirection.get(query.sortDirection),
      },
    });
  }

  async componentDidMount() {
    await this.searchToOptions();
    const search = addQueryParameters(this.props.location.search, this.state.options, defaultSittingFilterOptions);
    if (search !== this.props.location.search) {
      this.props.history.replace(search);
    } else {
      await this.fetchSittings();
    }
  }

  async componentDidUpdate(prevProps: SittingListProps) {
    if (prevProps.location.search !== this.props.location.search) {
      storage.sittingSortBy.set(this.state.options.sortBy);
      storage.sittingSortDirection.set(this.state.options.sortDirection);

      await this.searchToOptions();
      await this.fetchSittings();
      if (this.state.pageTopRef.current) {
        this.state.pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onOptionsChange(newOptions: Partial<SittingFilterOptions>) {
    const options = {
      ...this.state.options,
      ...newOptions,
    };

    const search = addQueryParameters(this.props.location.search, options, defaultSittingFilterOptions);
    if (search !== this.props.location.search) {
      this.props.history.push(search);
    }

    this.setState({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
  }

  proposeDeleteSittings() {
    this.setState({ showDeleteModal: true });
  }

  cancelDeleteSittings() {
    this.setState({ showDeleteModal: false });
  }

  async confirmDeleteSittings() {
    await deleteAllSittings(this.props.exam.id);
    await this.setStateAsync({
      showDeleteModal: false,
      sittings: {
        entities: [],
        count: 0,
      },
    });
  }

  async fetchSittings() {
    const sittings = await fetchSittings(this.props.exam.id, this.state.options);
    await this.setStateAsync({ loaded: true, sittings });
  }

  async onConcludeSitting(sittingId) {
    await concludeSitting(this.props.exam.id, sittingId);
    await this.setStateAsync({
      sittings: {
        count: this.state.sittings.count,
        entities: this.state.sittings.entities.map((sitting) => ({
          ...sitting,
          finishTime: sitting.id === sittingId ? new Date().toISOString() : sitting.finishTime,
        })),
      },
    });

    if (!this.props.maintainer && this.props.onConcludeSitting) {
      this.props.onConcludeSitting();
    }
  }

  async onReleasePoints(sittingId) {
    await releaseSittingPoints(this.props.exam.id, sittingId);
    this.setState({
      sittings: {
        count: this.state.sittings.count,
        entities: this.state.sittings.entities.map((sitting) => ({
          ...sitting,
          pointsReleased: sitting.id === sittingId ? true : sitting.pointsReleased,
        })),
      },
    });
  }

  async onUnreleasePoints(sittingId) {
    await unreleaseSittingPoints(this.props.exam.id, sittingId);
    this.setState({
      sittings: {
        count: this.state.sittings.count,
        entities: this.state.sittings.entities.map((sitting) => ({
          ...sitting,
          pointsReleased: sitting.id === sittingId ? false : sitting.pointsReleased,
        })),
      },
    });
  }

  async onConclude() {
    await concludeExam(this.props.exam.id);
    await this.setStateAsync({
      sittings: {
        count: this.state.sittings.count,
        entities: this.state.sittings.entities.map((sitting) => ({
          ...sitting,
          finishTime: sitting.finishTime || new Date().toISOString(),
        })),
      },
    });
  }

  async onReleaseAllPoints() {
    await releaseAllPoints(this.props.exam.id);
    await this.setStateAsync({
      sittings: {
        count: this.state.sittings.count,
        entities: this.state.sittings.entities.map((sitting) => ({
          ...sitting,
          pointsReleased: Boolean(sitting.finishTime),
        })),
      },
    });
  }

  async onExportPoints() {
    await exportAllPoints(this.props.exam.id);
  }

  async onUploadAllPointsToCanvas() {
    const {
      group: { providerName, providerSpecificId },
      jsonMetadata: { assignment },
    } = this.props.exam;
    const sittings = await fetchSittingsOnlyPoints(this.props.exam.id);

    if (sittings.count === 0) {
      // don't send request with empty list
      return;
    }

    const studentPoints = sittings.entities
      .map((sitting) => ({
        studentId: sitting.studentProviderSpecificId,
        points: sitting.totalPoints,
        examId: this.props.exam.id,
        sittingId: sitting.id,
      }))
      .filter((studentPoint) => Boolean(studentPoint.studentId));
    console.log(`Updating ${studentPoints.length} points on Canvas`, studentPoints);

    await updateMultipleCanvasPoints(providerName, providerSpecificId, assignment.id, studentPoints);
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading sittings...</div>;
    }

    const { sittings, options } = this.state;

    const {
      exam: {
        group: { providerName },
        jsonMetadata,
      },
    } = this.props;
    const assignment = jsonMetadata?.assignment;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div ref={this.state.pageTopRef}>
          {this.props.maintainer && (
            <div className="row">
              <div className="col-md-12 titlebar">
                <div className="float-left">
                  <FilterBy
                    values={options}
                    onChange={this.onOptionsChange}
                    resetPage={true}
                    options={[
                      {
                        name: 'Simulated',
                        queryKey: 'simulated',
                        options: [
                          { key: 'all', label: 'All', value: undefined, default: true },
                          { key: 'simulated', label: 'Only simulated', value: true },
                          { key: 'student', label: 'Only student', value: false },
                        ],
                      },
                      {
                        name: 'State',
                        queryKey: 'active',
                        options: [
                          { key: 'all', label: 'All', value: undefined, default: true },
                          { key: 'active', label: 'In progress', value: true },
                          { key: 'finished', label: 'Finished', value: false },
                        ],
                      },
                    ]}
                  />
                  <SortBy
                    options={options}
                    onChange={this.onOptionsChange}
                    keys={{
                      studentName: 'Student name',
                      numQuestions: 'Recorded question count',
                      totalPoints: 'Total points',
                      startTime: 'Start time',
                      finishTime: 'Finish time',
                    }}
                  />
                  <SearchBy
                    queryKey="studentName"
                    term={options.studentName}
                    onChange={this.onOptionsChange}
                    placeholder="Search by student name..."
                    resetPage={true}
                  />
                </div>
                <div className="float-right btn-group">
                  <OverlayButton variant="warning" onClick={this.onConclude} visible={sittings.count > 0}>
                    <FontAwesomeIcon icon="flag-checkered" />
                    &nbsp;Conclude
                  </OverlayButton>
                  <OverlayButton
                    variant="success"
                    onClick={this.onReleaseAllPoints}
                    visible={sittings.count > 0}
                    tooltip="Release points for all finished sittings"
                  >
                    <FontAwesomeIcon icon="check" />
                    &nbsp;Release all
                  </OverlayButton>
                  <OverlayButton
                    variant="info"
                    onClick={this.onUploadAllPointsToCanvas}
                    visible={Boolean(providerName && assignment) && sittings.count > 0}
                    tooltip="Upload points to Canvas for all finished sittings"
                  >
                    <FontAwesomeIcon icon="upload" />
                    &nbsp;Upload all to Canvas
                  </OverlayButton>
                  <OverlayButton
                    variant="info"
                    onClick={this.onExportPoints}
                    visible={sittings.count > 0}
                    tooltip="Export all points in JSON format"
                  >
                    <FontAwesomeIcon icon="file-export" />
                    &nbsp;Export points
                  </OverlayButton>
                  <OverlayButton
                    variant="danger"
                    onClick={this.proposeDeleteSittings}
                    visible={sittings.count > 0}
                    tooltip="Delete all sittings for this exam"
                  >
                    <FontAwesomeIcon icon="trash" />
                    &nbsp;Delete all
                  </OverlayButton>
                </div>
              </div>
            </div>
          )}

          {!sittings.entities.length ? (
            <div className="infomsg">No sittings to show</div>
          ) : (
            <div>
              <Paginator options={options} count={sittings.count} onChange={this.onOptionsChange} />

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-hover">
                    <tbody>
                      {sittings.entities.map((sitting) => (
                        <tr key={sitting.id}>
                          <td>
                            <SittingListEntry
                              exam={this.props.exam}
                              maintainer={this.props.maintainer}
                              sitting={sitting}
                              onConcludeSitting={() => this.onConcludeSitting(sitting.id)}
                              onReleasePoints={() => this.onReleasePoints(sitting.id)}
                              onUnreleasePoints={() => this.onUnreleasePoints(sitting.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <Paginator options={options} count={sittings.count} onChange={this.onOptionsChange} />

              <DeleteModal
                show={this.state.showDeleteModal}
                title="Delete sittings"
                onCancel={this.cancelDeleteSittings}
                onConfirm={this.confirmDeleteSittings}
              >
                <div>
                  Are you sure you would like to delete all sittings for exam&nbsp;
                  <b>{this.props.exam.name}</b>?
                </div>
                <div>All associated sitting questions will be deleted.&nbsp; This action is irreversible.</div>
              </DeleteModal>
            </div>
          )}
        </div>
      </BlockUi>
    );
  }
}

SittingList.contextType = CredentialsContext;

export default withRouter(withErrorScreen(SittingList));
