import { authServerUrl } from '../util/config';
import fetch from '../util/fetch';
import { CacheCourse, CacheCourseEnrollment, CacheCourseState, CanvasAssignment, CanvasPointUpgradeDto, Collection } from '../model';
import { toUrlParams } from '../util/queryparser';

export type CanvasCourseFilterOptions = {
  enrollments?: CacheCourseEnrollment[];
  states?: CacheCourseState[];
  force?: boolean;
};

export const fetchCourses = async (providerName: string, options: CanvasCourseFilterOptions = {}): Promise<CacheCourse[]> => {
  const path = `${authServerUrl}/auth/providers/${providerName}/courses?${toUrlParams(options)}`;
  const response = await fetch<CacheCourse[]>(path);
  return response.body;
};

export const fetchCanvasAssignments = async (providerName: string, courseId: number): Promise<Collection<CanvasAssignment>> => {
  const path = `${authServerUrl}/auth/providers/${providerName}/courses/${courseId}/assignments`;
  const response = await fetch<CanvasAssignment[]>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const updateCanvasPoints = async (
  providerName: string,
  courseId: number,
  assignmentId: number,
  studentId: number,
  body: CanvasPointUpgradeDto,
): Promise<void> => {
  const path = `${authServerUrl}/auth/providers/${providerName}/courses/${courseId}/assignments/${assignmentId}/points/${studentId}`;
  await fetch<void, CanvasPointUpgradeDto>(path, {
    method: 'PATCH',
    body,
  });
};

export const updateMultipleCanvasPoints = async (
  providerName: string,
  courseId: number,
  assignmentId: number,
  body: CanvasPointUpgradeDto[],
): Promise<void> => {
  const path = `${authServerUrl}/auth/providers/${providerName}/courses/${courseId}/assignments/${assignmentId}/points`;
  await fetch<void, CanvasPointUpgradeDto[]>(path, {
    method: 'PATCH',
    body,
  });
};
