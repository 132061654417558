import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import BlockUi from 'react-block-ui';
import autoBind from 'auto-bind';

import AsyncComponent from './util/AsyncComponent';
import withErrorScreen from './util/withErrorScreen';
import withBreadcrumbs, { WithBreadcrumbsProps } from './util/withBreadcrumbs';
import { blocking, withErrorHandling } from './util/decorators';
import { GroupDetailsDto } from '../model';
import { fetchGroupById, deleteGroup } from '../service/group';

import GroupDetailsHeader from './groupDetails/GroupDetailsHeader';
import GroupDetailsPanel from './groupDetails/GroupDetailsPanel';
import AffiliationList from './groupDetails/AffiliationList';

type GroupDetailsPathParams = {
  groupId: string;
};

type GroupDetailsProps = WithBreadcrumbsProps & RouteComponentProps<GroupDetailsPathParams>;

type GroupDetailsState = {
  loaded: boolean;
  blocking: boolean;
  group: GroupDetailsDto;
};

class GroupDetails extends AsyncComponent<GroupDetailsProps, GroupDetailsState> {
  constructor(props: GroupDetailsProps) {
    super(props);

    this.state = {
      loaded: false,
      group: null,
      blocking: false,
    };

    autoBind(this);

    this.fetchGroupById = withErrorHandling(this.fetchGroupById, this, {
      autoClose: true,
      keepTrying: true,
    });
    this.onDelete = blocking(this.onDelete, this);
  }

  async componentDidMount() {
    await this.fetchGroupById();
    this.setBreadcrumbs();
  }

  componentDidUpdate(prevProps: GroupDetailsProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setBreadcrumbs();
    }
  }

  setBreadcrumbs() {
    const { group } = this.state;
    this.props.setBreadcrumbs([
      { path: '/groups/', text: 'Groups' },
      { path: `/groups/${group.id}/`, text: group.name },
    ]);
  }

  async fetchGroupById() {
    const group = await fetchGroupById(Number(this.props.match.params.groupId));
    await this.setStateAsync({
      loaded: true,
      group,
    });
  }

  async onDelete() {
    await deleteGroup(this.state.group.id);
    this.props.history.push('/groups/');
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading group...</div>;
    }

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        {/* header */}
        <GroupDetailsHeader group={this.state.group} onDelete={this.onDelete} />

        {/* description panel */}
        <GroupDetailsPanel group={this.state.group} />

        {/* user affiliation list */}
        <AffiliationList group={this.state.group} />
      </BlockUi>
    );
  }
}

export default withRouter(withErrorScreen(withBreadcrumbs(GroupDetails)));
