import {
  Collection,
  SittingDetailsDto,
  SittingOnlyPointsDto,
  SittingReducedDto,
  SittingWithEntryCodeDto,
  SittingWithQuestionPointsDto,
} from '../model';
import { PagingAndSortingOptions } from '../model/options';
import download from '../util/download';
import fetch from '../util/fetch';
import { toUrlParams } from '../util/queryparser';

export type SittingSortOptions = 'studentName' | 'numQuestions' | 'totalPoints' | 'startTime' | 'finishTime';

export type SittingFilterOptions = PagingAndSortingOptions & {
  // sort
  sortBy?: SittingSortOptions;
  // search
  studentName?: string;
  // filter
  active?: boolean;
  simulated?: boolean;
};

export const defaultSittingFilterOptions: Readonly<SittingFilterOptions> = {
  page: 1,
  perPage: 25,
  sortBy: 'studentName',
  sortDirection: 'asc',
};

export const fetchSittings = async (examId: number, options: SittingFilterOptions = {}): Promise<Collection<SittingReducedDto>> => {
  const path = `/api/exams/${examId}/sittings?${toUrlParams(options, defaultSittingFilterOptions)}`;
  const response = await fetch<Array<SittingReducedDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const countSittings = async (examId: number, options: SittingFilterOptions = {}): Promise<number> => {
  const path = `/api/exams/${examId}/sittings?${toUrlParams(options)}`;
  const response = await fetch<void>(path, { method: 'HEAD' });
  return Number(response.headers['x-total-count']);
};

export const fetchSittingsOnlyPoints = async (examId: number): Promise<Collection<SittingOnlyPointsDto>> => {
  const path = `/api/exams/${examId}/sittings?onlyPoints=true`;
  const response = await fetch<Array<SittingOnlyPointsDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const exportAllPoints = async (examId: number): Promise<void> => {
  const path = `/api/exams/${examId}/sittings?allPoints=true`;
  const response = await fetch<SittingWithQuestionPointsDto[]>(path, { blob: true });
  download(response.body, `points-exam-${examId}.json`);
};

export const fetchSittingById = async (examId: number, sittingId: number): Promise<SittingDetailsDto> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}`;
  const response = await fetch<SittingDetailsDto>(path);
  return response.body;
};

export const startSitting = async (examId: number): Promise<SittingWithEntryCodeDto> => {
  const path = `/api/exams/${examId}/sittings`;
  const response = await fetch<SittingWithEntryCodeDto>(path, {
    method: 'POST',
  });
  return response.body;
};

export const resumeSitting = async (examId: number, sittingId: number): Promise<SittingWithEntryCodeDto> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}/resume`;
  const response = await fetch<SittingWithEntryCodeDto>(path, {
    method: 'POST',
  });
  return response.body;
};

export const deleteSitting = async (examId: number, sittingId: number): Promise<void> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}`;
  await fetch<void>(path, {
    method: 'DELETE',
  });
};

export const concludeSitting = async (examId: number, sittingId: number): Promise<SittingDetailsDto> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}/conclude`;
  const response = await fetch<SittingDetailsDto>(path, {
    method: 'POST',
  });
  return response.body;
};

export const releaseSittingPoints = async (examId: number, sittingId: number): Promise<SittingDetailsDto> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}`;
  const response = await fetch<SittingDetailsDto>(path, {
    method: 'PATCH',
    body: {
      pointsReleased: true,
    },
  });
  return response.body;
};

export const unreleaseSittingPoints = async (examId: number, sittingId: number): Promise<SittingDetailsDto> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}`;
  const response = await fetch<SittingDetailsDto>(path, {
    method: 'PATCH',
    body: {
      pointsReleased: false,
    },
  });
  return response.body;
};

export const allowResume = async (examId: number, sittingId: number): Promise<SittingDetailsDto> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}`;
  const response = await fetch<SittingDetailsDto>(path, {
    method: 'PATCH',
    body: {
      lastContactTime: new Date().toISOString(),
    },
  });
  return response.body;
};
