import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import autoBind from 'auto-bind';

import { JsonUploadModal } from '../util/Modals';
import SortBy from '../util/SortBy';
import { ExamFilterOptions, importExam } from '../../service/exam';
import CredentialsContext, { CredentialsContextType } from '../../context/credentials';
import SearchBy from '../util/SearchBy';
import { Collection, ExamDetailsDto, ExamForUploadDto, UserAffiliationDto } from '../../model';
import AsyncComponent from '../util/AsyncComponent';
import withErrorScreen from '../util/withErrorScreen';
import { blocking } from '../util/decorators';
import FilterBy from '../util/FilterBy';
import OverlayButton from '../util/OverlayButton';
import fetchUserAffiliations from '../../service/useraffiliation';

type ExamListHeaderProps = {
  options: ExamFilterOptions;
  onOptionsChange: (options: Partial<ExamFilterOptions>) => void;
  onUpload: (exam: ExamDetailsDto) => void;
};

type ExamListHeaderState = {
  showImport: boolean;
  affiliations: Collection<UserAffiliationDto>;
};

class ExamListHeader extends AsyncComponent<ExamListHeaderProps, ExamListHeaderState> {
  context: CredentialsContextType;

  constructor(props: ExamListHeaderProps) {
    super(props);

    this.state = {
      showImport: false,
      affiliations: {
        entities: [],
        count: 0,
      },
    };

    autoBind(this);

    this.fetchUserAffiliations = blocking(this.fetchUserAffiliations, this, {
      stateName: 'blockingGroupDropdown',
      autoClose: true,
      keepTrying: true,
    });
  }

  async componentDidMount() {
    await this.fetchUserAffiliations();
  }

  async fetchUserAffiliations() {
    const affiliations = await fetchUserAffiliations(this.context.userId, {
      sortBy: 'favorite',
      sortDirection: 'desc',
    });
    await this.setStateAsync({ affiliations });
  }

  async importExam(modalFile: { content: ExamForUploadDto }): Promise<void> {
    const exam = await importExam(modalFile.content);
    this.setState({ showImport: false });
    this.props.onUpload(exam);
  }

  render(): JSX.Element {
    const { options, onOptionsChange } = this.props;

    return (
      <div className="row">
        <div className="col-md-12 titlebar">
          <h3>Exams</h3>

          <div className="float-left">
            <FilterBy
              values={options}
              onChange={onOptionsChange}
              resetPage={true}
              options={[
                {
                  name: 'Exam type',
                  queryKey: 'examType',
                  options: [
                    { key: 'all', label: 'All', value: undefined, default: true },
                    { key: 'continuous', label: 'Continuous', value: 'continuous' },
                    { key: 'interactive', label: 'Interactive', value: 'interactive' },
                    { key: 'surveillance', label: 'Surveillance', value: 'surveillance' },
                  ],
                },
                {
                  name: 'Group',
                  queryKey: 'groupId',
                  options: [
                    {
                      key: 'all',
                      label: 'Show all',
                      value: undefined,
                      default: true,
                    },
                    ...this.state.affiliations.entities.map((affiliation) => ({
                      key: `${affiliation.group.id}`,
                      value: affiliation.group.id,
                      label: affiliation.favorite ? `\u2605 ${affiliation.group.name}` : affiliation.group.name,
                    })),
                  ],
                },
                {
                  name: 'Streaming',
                  queryKey: 'streaming',
                  options: [
                    { key: 'all', label: 'Show all', value: undefined, default: true },
                    { key: 'true', label: 'Only video exams', value: true },
                    { key: 'false', label: 'Only non-video exams', value: false },
                  ],
                },
              ]}
            />
            <SortBy
              options={options}
              onChange={onOptionsChange}
              keys={{
                startTime: 'Start time',
                name: 'Name',
                groupName: 'Group',
              }}
            />
            <SearchBy
              queryKey="name"
              term={options.name}
              onChange={onOptionsChange}
              placeholder="Search by exam name..."
              resetPage={true}
            />
          </div>

          {this.context.canCreateExam && (
            <div className="float-right btn-group">
              <OverlayButton variant="success" to="/exams/new">
                <FontAwesomeIcon icon="plus" />
                &nbsp;Create
              </OverlayButton>
              <Button variant="info" onClick={() => this.setState({ showImport: true })}>
                <FontAwesomeIcon icon="file-import" />
                &nbsp;Import
              </Button>
            </div>
          )}
        </div>

        <JsonUploadModal
          title="Upload exam JSON"
          show={this.state.showImport}
          onConfirm={this.importExam}
          onCancel={() => this.setState({ showImport: false })}
          parseJson={true}
        />
      </div>
    );
  }
}

ExamListHeader.contextType = CredentialsContext;

export default withErrorScreen(ExamListHeader);
