import { Component } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export default class AsyncComponent<P = {}, S = {}> extends Component<P, S> {
  setStateAsync<K extends keyof S>(state: Pick<S, K>): Promise<void> {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
}
