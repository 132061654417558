import fetch from '../util/fetch';
import { PagingAndSortingOptions } from '../model/options';
import { Collection, UserDto, UserCreationDto, UserUpdateDto } from '../model';
import { toUrlParams } from '../util/queryparser';

export type UserFilterOptions = PagingAndSortingOptions & {
  sortBy?: 'name';
  name?: string;
  admin?: boolean;
};

export const defaultUserOptions: Readonly<UserFilterOptions> = {
  page: 1,
  perPage: 25,
  sortBy: 'name',
  sortDirection: 'asc',
};

export const fetchUsers = async (options: UserFilterOptions = {}): Promise<Collection<UserDto>> => {
  const path = `/api/users?${toUrlParams(options, defaultUserOptions)}`;
  const response = await fetch<Array<UserDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const fetchUserById = async (userId: number): Promise<UserDto> => {
  const path = `/api/users/${userId}`;
  const response = await fetch<UserDto>(path);
  return response.body;
};

export const createUser = async (user: UserCreationDto): Promise<UserDto> => {
  const path = '/api/users';
  const response = await fetch<UserDto, UserCreationDto>(path, {
    method: 'POST',
    body: user,
  });
  return response.body;
};

export const updateUser = async (userId: number, user: UserUpdateDto): Promise<UserDto> => {
  const path = `/api/users/${userId}`;
  const response = await fetch<UserDto, UserUpdateDto>(path, {
    method: 'PATCH',
    body: user,
  });
  return response.body;
};

export const deleteUser = async (userId: number): Promise<void> => {
  const path = `/api/users/${userId}`;
  await fetch<void>(path, {
    method: 'DELETE',
  });
};
