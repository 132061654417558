import fetch from '../util/fetch';
import { PagingAndSortingOptions } from '../model/options';
import { Collection, GroupAffiliationRole, AffiliationDto } from '../model';
import { toUrlParams } from '../util/queryparser';

export type AffiliationFilterOptions = PagingAndSortingOptions & {
  sortBy?: 'userName' | 'groupName' | 'favorite';
  role?: GroupAffiliationRole;
  favorite?: boolean;
  userId?: number;
  userName?: string;
  groupId?: number;
  groupName?: string;
  groupProviderName?: string;
  groupProviderSpecificId?: number;
};

export const defaultAffiliationOptions: Readonly<AffiliationFilterOptions> = {
  page: 1,
  perPage: 25,
  sortBy: 'userName',
  sortDirection: 'asc',
};

export const fetchAffiliations = async (options: AffiliationFilterOptions = {}): Promise<Collection<AffiliationDto>> => {
  const path = `/api/affiliations?${toUrlParams(options, defaultAffiliationOptions)}`;
  const response = await fetch<Array<AffiliationDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};
