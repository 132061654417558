import React from 'react';
import BlockUi from 'react-block-ui';

import EventListEntry from './EventListEntry';
import { blocking } from '../util/decorators';
import withErrorScreen from '../util/withErrorScreen';
import AsyncComponent from '../util/AsyncComponent';

import { EventFilterOptions, fetchExamEvents, fetchSittingEvents } from '../../service/event';
import { Collection, EventDto } from '../../model';

type EventListProps = {
  type: string;
  examId: number;
  sittingId?: number;
};

type EventListState = {
  loaded: boolean;
  blocking: boolean;
  events: Collection<EventDto>;
  options: EventFilterOptions;
};

class EventList extends AsyncComponent<EventListProps, EventListState> {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      events: {
        entities: [],
        count: 0,
      },
      options: {},
    };

    this.fetchEvents = this.fetchEvents.bind(this);
    this.fetchMoreEvents = this.fetchMoreEvents.bind(this);

    this.fetchEvents = blocking(this.fetchEvents, this);
    this.fetchMoreEvents = blocking(this.fetchMoreEvents, this);
  }

  componentDidMount() {
    this.fetchEvents();
  }

  async fetchEvents() {
    let events: Collection<EventDto>;
    switch (this.props.type) {
      case 'exam':
        events = await fetchExamEvents(this.props.examId, this.state.options);
        break;
      case 'sitting':
        events = await fetchSittingEvents(this.props.examId, this.props.sittingId, this.state.options);
        break;
      default:
        return;
    }

    await this.setStateAsync({ loaded: true, events });
  }

  async fetchMoreEvents() {
    await this.setStateAsync({
      options: {
        ...this.state.options,
        page: this.state.options.page + 1,
      },
    });
    await this.fetchEvents();
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading events...</div>;
    }

    if (!this.state.events?.count) {
      return <div className="infomsg">No events to show</div>;
    }

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-hover">
              <tbody>
                {this.state.events.entities.map((event) => (
                  <tr key={event.id}>
                    <td>
                      <EventListEntry examId={this.props.examId} event={event} />
                    </td>
                  </tr>
                ))}

                <tr>
                  <td className="infomsg likeALink" onClick={this.fetchMoreEvents}>
                    Load more events...
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </BlockUi>
    );
  }
}

export default withErrorScreen(EventList);
