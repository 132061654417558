import 'ace-builds/src-noconflict/ext-searchbox';

export const themes = {
  dark: 'tomorrow_night',
  light: 'xcode',
};

Object.values(themes).forEach((theme) => {
  // eslint-disable-next-line
  require(`ace-builds/src-noconflict/theme-${theme}`);
});

export const languages = {
  text: 'Plain text',
  c_cpp: 'C/C++',
  csharp: 'C#',
  css: 'CSS',
  latex: 'LaTeX',
  markdown: 'Markdown',
  java: 'Java',
  javascript: 'JavaScript',
  json: 'JSON',
  python: 'Python',
  sql: 'SQL',
  xml: 'XML',
  yaml: 'YAML',
};

Object.keys(languages).forEach((lang) => {
  // eslint-disable-next-line
  require(`ace-builds/src-noconflict/mode-${lang}`);
});

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ace = require('ace-builds/src-noconflict/ace');

// TODO find better way to avoid worker.js load error
ace.config.set('basePath', 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/');
