import fetch from '../util/fetch';
import { Collection, EventDto } from '../model';
import { PagingOptions } from '../model/options';
import { toUrlParams } from '../util/queryparser';

export type EventFilterOptions = PagingOptions & {
  eventType?: string;
};

export const defaultEventOptions: Readonly<EventFilterOptions> = {
  page: 1,
  perPage: 25,
};

export const fetchExamEvents = async (examId: number, options: EventFilterOptions = {}): Promise<Collection<EventDto>> => {
  const params = toUrlParams(options, defaultEventOptions);
  const path = `/api/exams/${examId}/events?${params}`;
  const response = await fetch<Array<EventDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const fetchSittingEvents = async (
  examId: number,
  sittingId: number,
  options: EventFilterOptions = {},
): Promise<Collection<EventDto>> => {
  const params = toUrlParams(options, defaultEventOptions);
  const path = `/api/exams/${examId}/sittings/${sittingId}/events?${params}`;
  const response = await fetch<Array<EventDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};
