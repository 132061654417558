import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import BlockUi from 'react-block-ui';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import autoBind from 'auto-bind';

import QuestionBankList from './examDetails/QuestionBankList';
import AsyncComponent from './util/AsyncComponent';
import withErrorScreen from './util/withErrorScreen';
import withBreadcrumbs, { WithBreadcrumbsProps } from './util/withBreadcrumbs';
import { blocking, withErrorHandling } from './util/decorators';
import { isCodecAppropriate } from '../util/media';
import { ExamDetailsDto } from '../model';
import { fetchExamById, starExam, unstarExam, publishExam, unpublishExam, deleteExam, exportExam } from '../service/exam';
import { concludeSitting, countSittings, fetchSittings } from '../service/sitting';

import ExamDetailsHeader from './examDetails/ExamDetailsHeader';
import ExamDetailsPanel from './examDetails/ExamDetailsPanel';
import SittingList from './examDetails/SittingList';
import EventList from './eventlist/EventList';
import ExamTimeline from './examDetails/ExamTimeline';
import ExamReportList from './reportlist/ExamReportList';

type ExamDetailsPathParams = {
  examId: string;
};

type ExamDetailsProps = WithBreadcrumbsProps &
  RouteComponentProps<ExamDetailsPathParams> & {
    page: 'description' | 'banks' | 'timeline' | 'sittings' | 'events' | 'reports';
  };

type ExamDetailsState = {
  loaded: boolean;
  blocking: boolean;
  exam: ExamDetailsDto;
  resumableSittingId: number;
  prohibitMessage: string;
};

class ExamDetails extends AsyncComponent<ExamDetailsProps, ExamDetailsState> {
  constructor(props: ExamDetailsProps) {
    super(props);

    this.state = {
      loaded: false,
      exam: null,
      resumableSittingId: null,
      prohibitMessage: '',
      blocking: false,
    };

    autoBind(this);

    this.fetchExamById = withErrorHandling(this.fetchExamById, this, {
      autoClose: true,
      keepTrying: true,
    });
    this.starExam = blocking(this.starExam, this);
    this.unstarExam = blocking(this.unstarExam, this);
    this.onPublish = blocking(this.onPublish, this);
    this.onUnpublish = blocking(this.onUnpublish, this);
    this.onExport = blocking(this.onExport, this);
    this.onDelete = blocking(this.onDelete, this);
    this.onConcludeResumableSitting = blocking(this.onConcludeResumableSitting, this);
  }

  async componentDidMount() {
    await this.fetchExamById();
    this.setBreadcrumbs();
  }

  componentDidUpdate(prevProps: ExamDetailsProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setBreadcrumbs();
    }
  }

  setBreadcrumbs() {
    const { exam } = this.state;

    const breadcrumbs = [
      { path: '/exams/', text: 'Exams' },
      { path: `/exams/${exam.id}/`, text: exam.name },
    ];
    if (this.props.page === 'banks') {
      breadcrumbs.push({ path: `/exams/${exam.id}/banks/`, text: 'Banks' });
    }
    if (this.props.page === 'timeline') {
      breadcrumbs.push({ path: `/exams/${exam.id}/timeline/`, text: 'Timeline' });
    }
    if (this.props.page === 'sittings') {
      breadcrumbs.push({ path: `/exams/${exam.id}/sittings/`, text: 'Sittings' });
    }
    if (this.props.page === 'events') {
      breadcrumbs.push({ path: `/exams/${exam.id}/events/`, text: 'Events' });
    }
    if (this.props.page === 'reports') {
      breadcrumbs.push({ path: `/exams/${exam.id}/reports/`, text: 'Reports' });
    }

    this.props.setBreadcrumbs(breadcrumbs);
  }

  async fetchExamById() {
    const exam = await fetchExamById(Number(this.props.match.params.examId));
    await this.setStateAsync({
      loaded: true,
      exam,
    });

    if (exam.maintainer) {
      return;
    }

    // check 1. is this video exam and do we have the appropriate codec
    const appropriateVideo = !this.state.exam.streaming || isCodecAppropriate();
    if (!appropriateVideo) {
      await this.setStateAsync({
        resumableSittingId: null,
        prohibitMessage: 'Browser not supported, please use Chrome',
      });
      return;
    }

    const response = await fetchSittings(this.state.exam.id, { active: true });

    /*
     * Sitting resume
     */
    if (response.entities.length > 0) {
      const resumableSitting = response.entities[0];

      // 2. check if still resumable
      const resumableTimeMillis = exam.resumableTime * 1000;
      const millisSinceLastContact = new Date().getTime() - new Date(resumableSitting.lastContactTime).getTime();
      const timeIsRight = millisSinceLastContact <= resumableTimeMillis;
      if (!timeIsRight) {
        await this.setStateAsync({
          resumableSittingId: resumableSitting.id,
          prohibitMessage: 'Resumable period is expired',
        });
        return;
      }

      await this.setStateAsync({
        resumableSittingId: resumableSitting.id,
        prohibitMessage: '',
      });

      /*
       * Sitting start
       */
    } else {
      // 3. check if can be taken at this time
      const millis = new Date(exam.hardStartTime).getTime() - new Date().getTime();
      const betweenStartAndHardStart = new Date(exam.hardStartTime).getTime() - new Date(exam.startTime).getTime();
      const cutoffMillis = exam.cutoffTime * 1000;
      const timeIsRight = millis <= cutoffMillis + betweenStartAndHardStart && millis > 0;
      if (!timeIsRight) {
        await this.setStateAsync({
          resumableSittingId: null,
          prohibitMessage: 'Cannot be taken at this time',
        });
        return;
      }

      // 4. check if there isn't already a sitting on a non-redoable exam
      let doable: boolean;
      if (exam.redoable) {
        doable = true;
      } else {
        const sittingCount = await countSittings(exam.id);
        doable = sittingCount === 0;
      }
      if (!doable) {
        await this.setStateAsync({
          resumableSittingId: null,
          prohibitMessage: 'You already have a sitting for this exam',
        });
        return;
      }

      // all good, can start
      await this.setStateAsync({
        resumableSittingId: null,
        prohibitMessage: '',
      });
    }
  }

  async starExam() {
    const { starred } = await starExam(this.state.exam.id);
    this.setState({ exam: { ...this.state.exam, starred } });
  }

  async unstarExam() {
    const { starred } = await unstarExam(this.state.exam.id);
    this.setState({ exam: { ...this.state.exam, starred } });
  }

  async onPublish() {
    const { published } = await publishExam(this.state.exam.id);
    this.setState({ exam: { ...this.state.exam, published } });
  }

  async onUnpublish() {
    const { published } = await unpublishExam(this.state.exam.id);
    this.setState({ exam: { ...this.state.exam, published } });
  }

  async onExport() {
    await exportExam(this.state.exam.id);
  }

  async onDelete() {
    await deleteExam(this.state.exam.id);
    this.props.history.push('/exams/');
  }

  async onConcludeResumableSitting() {
    await concludeSitting(this.state.exam.id, this.state.resumableSittingId);
    await this.fetchExamById();
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading exam...</div>;
    }

    const { page, match } = this.props;
    const { examId } = match.params;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        {/* header */}
        <ExamDetailsHeader
          exam={this.state.exam}
          maintainer={this.state.exam.maintainer}
          resumableSittingId={this.state.resumableSittingId}
          prohibitMessage={this.state.prohibitMessage}
          onStar={this.starExam}
          onUnstar={this.unstarExam}
          onPublish={this.onPublish}
          onUnpublish={this.onUnpublish}
          onExport={this.onExport}
          onDelete={this.onDelete}
          onConcludeResumableSitting={this.onConcludeResumableSitting}
        />

        {/* nav tab panel */}
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link as={Link} active={page === 'description'} to={`/exams/${examId}/`}>
              Description
            </Nav.Link>
          </Nav.Item>
          {this.state.exam.examType !== 'surveillance' && (this.state.exam.maintainer || this.state.exam.studentsCanSeeBanks) && (
            <Nav.Item>
              <Nav.Link as={Link} active={page === 'banks'} to={`/exams/${examId}/banks/`}>
                Question banks
              </Nav.Link>
            </Nav.Item>
          )}
          {this.state.exam.examType === 'interactive' && this.state.exam.maintainer && (
            <Nav.Item>
              <Nav.Link as={Link} active={page === 'timeline'} to={`/exams/${examId}/timeline/`}>
                Timeline
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link as={Link} active={page === 'sittings'} to={`/exams/${examId}/sittings/`}>
              Sittings
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} active={page === 'events'} to={`/exams/${examId}/events/`}>
              Events
            </Nav.Link>
          </Nav.Item>
          {this.state.exam.maintainer && (
            <>
              <Nav.Item>
                <Nav.Link as={Link} active={page === 'reports'} to={`/exams/${examId}/reports/`}>
                  Reports
                </Nav.Link>
              </Nav.Item>
            </>
          )}
        </Nav>

        {(() => {
          switch (page) {
            /* description panel */
            case 'description':
              return <ExamDetailsPanel exam={this.state.exam} />;

            /* question banks list */
            case 'banks':
              return (
                <>
                  <QuestionBankList maintainer={this.state.exam.maintainer} exam={this.state.exam} />
                </>
              );

            /* timeline */
            case 'timeline':
              return (
                <>
                  <ExamTimeline maintainer={this.state.exam.maintainer} exam={this.state.exam} />
                </>
              );

            /* sittings list */
            case 'sittings':
              return (
                <>
                  <SittingList maintainer={this.state.exam.maintainer} exam={this.state.exam} onConcludeSitting={this.fetchExamById} />
                </>
              );

            /* events list */
            case 'events':
              return (
                <>
                  <EventList type="exam" examId={this.state.exam.id} maintainer={this.state.exam.maintainer} />
                </>
              );

            /* reports list */
            case 'reports':
              return (
                <>
                  <ExamReportList type="exam" examId={this.state.exam.id} maintainer={this.state.exam.maintainer} />
                </>
              );

            default:
              return null;
          }
        })()}
      </BlockUi>
    );
  }
}

export default withRouter(withErrorScreen(withBreadcrumbs(ExamDetails)));
