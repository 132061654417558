import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import useFormattedDate from '../../hooks/useFormattedDate';
import FormattedTime from './FormattedTime';

export type CommonPanelEntryProps<T = string> = {
  name: string;
  icon: IconProp;
  value: T;
  visible?: unknown;
};

export type PanelEntryProps<T = string> = CommonPanelEntryProps<T> & {
  to?: string;
};

export function PanelEntry<T = string>({ name, icon, value, to = null, visible = true }: PanelEntryProps<T>) {
  if (!visible) {
    return null;
  }

  const finalValue = to ? <Link to={to}>{value}</Link> : value;

  return (
    <>
      <dt>{name}</dt>
      <dd>
        <FontAwesomeIcon icon={icon} />
        &nbsp;
        {finalValue}
      </dd>
    </>
  );
}

export function DatePanelEntry({ name, icon, value, visible = true }: CommonPanelEntryProps<string>) {
  const formattedDate = useFormattedDate(value);

  const expired = useMemo(() => {
    return new Date() > new Date(value);
  }, [value]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <dt>{name}</dt>
      {expired ? (
        <dd className="expired" data-toggle="tooltip" data-placement="top" title="Expired">
          <FontAwesomeIcon icon={icon} />
          &nbsp;{formattedDate}
        </dd>
      ) : (
        <dd>
          <FontAwesomeIcon icon={icon} />
          &nbsp;{formattedDate}
        </dd>
      )}
    </>
  );
}

export function CheckboxPanelEntry({ name, icon, value, visible = true }: CommonPanelEntryProps<boolean>) {
  if (!visible) {
    return null;
  }

  return (
    <>
      <dt>{name}</dt>
      <dd>
        <FontAwesomeIcon icon={icon} />
        &nbsp;
        <input type="checkbox" checked={value} disabled />
      </dd>
    </>
  );
}

export type IntervalPanelEntryType = CommonPanelEntryProps<number> & {
  suffix?: string;
};

export function IntervalPanelEntry({ name, icon, value, visible = true, suffix = '' }: IntervalPanelEntryType) {
  if (!visible) {
    return null;
  }

  return (
    <>
      <dt>{name}</dt>
      <dd>
        <FormattedTime value={value} faicon={icon} suffix={suffix} />
      </dd>
    </>
  );
}
