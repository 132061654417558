import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';

import { DeleteModal } from '../util/Modals';
import OverlayButton from '../util/OverlayButton';
import { GroupDetailsDto } from '../../model';

type GroupDetailsHeaderProps = {
  group: GroupDetailsDto;
  onDelete: () => void;
};

type GroupDetailsHeaderState = {
  showDeleteModal: boolean;
};

export default class GroupDetailsHeader extends Component<GroupDetailsHeaderProps, GroupDetailsHeaderState> {
  constructor(props: GroupDetailsHeaderProps) {
    super(props);

    this.state = { showDeleteModal: false };

    autoBind(this);
  }

  proposeDeleteGroup(): void {
    this.setState({ showDeleteModal: true });
  }

  cancelDeleteGroup(): void {
    this.setState({ showDeleteModal: false });
  }

  confirmDeleteGroup(): void {
    this.setState({ showDeleteModal: false });
    this.props.onDelete();
  }

  render(): JSX.Element {
    const { group } = this.props;

    return (
      <div className="row">
        <div className="col-md-12 titlebar">
          <div className="float-left">
            <h3>Group: {group.name}</h3>
          </div>
          <div className="float-right btn-group">
            {/* edit, delete */}
            <OverlayButton to={`/groups/${group.id}/edit`} variant="outline-warning">
              <FontAwesomeIcon icon="edit" />
              <span>&nbsp;Edit</span>
            </OverlayButton>
            <OverlayButton onClick={this.proposeDeleteGroup} variant="outline-danger">
              <FontAwesomeIcon icon="trash" />
              <span>&nbsp;Delete</span>
            </OverlayButton>
          </div>
        </div>

        <DeleteModal
          show={this.state.showDeleteModal}
          onCancel={this.cancelDeleteGroup}
          onConfirm={this.confirmDeleteGroup}
          title="Delete group"
        >
          <div>
            Are you sure you would like to delete the group <b>{this.props.group.name}</b>?
          </div>
          <div>All associated affiliations will be deleted. This action is irreversible.</div>
        </DeleteModal>
      </div>
    );
  }
}
