import ms from 'ms';

/**
 * Convert numeric timestamp parts into 2-digits
 */
export function padZero(val: number): string {
  return val < 10 ? `0${val}` : `${val}`;
}

/**
 * Convert seconds into printable timestamp
 */
export function secondsToTimeText(sec: number): string {
  if (sec < 3600) {
    const minutes = Math.floor(sec / 60);
    const seconds = Math.floor(sec % 60);
    return `${padZero(minutes)}:${padZero(seconds)}`;
  }

  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor(sec / 60) % 60;
  const seconds = Math.floor(sec % 60);
  return `${hours}:${padZero(minutes)}:${padZero(seconds)}`;
}

/**
 * Convert milliseconds into printable timestamp
 */
export function millisecondsToTimeText(millis: number): string {
  const sec = Math.round(millis / 1000);
  return secondsToTimeText(sec);
}

/**
 * Convert seconds into human-readable timestamp
 */
export function secondsToHrText(sec: number, suffix: string) {
  let ret: string;

  if (sec < 60) {
    ret = `${sec} second${sec !== 1 ? 's' : ''}`;
  } else if (sec < 3600) {
    const minutes = Math.floor(sec / 60);
    const seconds = sec % 60;
    ret = `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    if (seconds > 0) {
      ret += ` ${seconds} second${seconds !== 1 ? 's' : ''}`;
    }
  } else {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor(sec / 60) % 60;
    const seconds = sec % 60;

    ret = `${hours} hour${hours !== 1 ? 's' : ''}`;
    if (minutes > 0) {
      ret += ` ${minutes} minute${minutes !== 1 ? 's' : ''}`;
    }
    if (seconds > 0) {
      ret += ` ${seconds} second${seconds !== 1 ? 's' : ''}`;
    }
  }

  if (suffix) {
    ret += suffix;
  }
  return ret;
}

/**
 * Convert seconds into human-readable short text
 */
export function secondsToHrShortText(sec: number) {
  if (sec < 60) {
    return `${sec}s`;
  }

  if (sec < 3600) {
    const minutes = Math.floor(sec / 60);
    const seconds = sec % 60;
    let ret = `${minutes}m`;
    if (seconds > 0) {
      ret += ` ${seconds}s`;
    }
    return ret;
  }

  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor(sec / 60) % 60;
  const seconds = sec % 60;

  let ret = `${hours}h`;
  if (minutes > 0) {
    ret += ` ${minutes}m`;
  }
  if (seconds > 0) {
    ret += ` ${seconds}s`;
  }
  return ret;
}

/**
 * Convert timestamp into numeric seconds
 */
export function timeTextToSeconds(timeText: string): number {
  const elements = timeText.split(':');
  if (elements.length === 1) {
    const ret = Number(elements[0]);
    if (Number.isNaN(ret)) {
      return ms(timeText) / 1000;
    }
    return ret;
  }
  if (elements.length === 2) {
    const [minutes, seconds] = elements.map((val: string) => Number(val));
    return minutes * 60 + seconds;
  }
  if (elements.length === 3) {
    const [hours, minutes, seconds] = elements.map((val: string) => Number(val));
    return hours * 3600 + minutes * 60 + seconds;
  }
  return 0;
}

// TODO rename file, not only date-related stringformat now
/**
 * Format bytes into human-readable text
 */
export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes < 1024) {
    return `${bytes}B`;
  }
  if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(decimals)}KB`;
  }
  if (bytes < 1024 * 1024 * 1024) {
    return `${(bytes / (1024 * 1024)).toFixed(decimals)}MB`;
  }
  return 'a lot...';
}
