import React from 'react';
import { withRouter } from 'react-router';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';

import AsyncComponent from './util/AsyncComponent';
import withErrorScreen from './util/withErrorScreen';
import { blocking } from './util/decorators';
import withBreadcrumbs from './util/withBreadcrumbs';
import { fetchExamById } from '../service/exam';
import { createBank, fetchBankById, updateBank } from '../service/questionbank';
import SecondsPicker from './util/SecondsPicker';
import ExamSittingsWarning from './util/ExamSittingsWarning';
import { answerStartPointMappings } from '../model';
import { parseBool } from '../util/queryparser';

class EditBank extends AsyncComponent {
  constructor(props) {
    super(props);

    this.state = {
      editMode: 'bankId' in props.match.params,
      exam: {},
      newBank: {
        name: 'New bank',
        timePerQuestion: 60,
        minTimePerQuestion: 5,
        pointsPerQuestion: 1.0,
        chooseQuantity: 1,
        resumable: Boolean(parseBool(localStorage.cvqQuestionBanksResumable)),
        answerStartPoint: 'available',
      },
      blocking: false,
    };

    autoBind(this);

    this.fetchExam = blocking(this.fetchExam, this, {
      autoClose: true,
      keepTrying: true,
    });
    this.fetchBank = blocking(this.fetchBank, this, {
      autoClose: true,
      keepTrying: true,
    });
    this.createBank = blocking(this.createBank, this);
    this.updateBank = blocking(this.updateBank, this);
  }

  componentDidMount() {
    if (this.state.editMode) {
      this.fetchBank();
    } else {
      this.fetchExam();
    }
  }

  async fetchExam() {
    const { examId } = this.props.match.params;
    const exam = await fetchExamById(examId);
    this.props.setBreadcrumbs([
      { path: '/exams/', text: 'Exams' },
      { path: `/exams/${exam.id}/`, text: exam.name },
      { path: `/exams/${exam.id}/`, text: 'Banks' },
      { path: `/exams/${exam.id}/banks/new`, text: 'New bank' },
    ]);

    await this.setStateAsync({
      exam,
    });
  }

  async fetchBank() {
    const { examId, bankId } = this.props.match.params;
    const exam = await fetchExamById(examId);
    const bank = await fetchBankById(examId, bankId);
    this.props.setBreadcrumbs([
      { path: '/exams/', text: 'Exams' },
      { path: `/exams/${exam.id}/`, text: exam.name },
      { path: `/exams/${exam.id}/`, text: 'Banks' },
      { path: `/exams/${exam.id}/banks/${bank.id}/`, text: bank.name },
      { path: `/exams/${exam.id}/banks/${bank.id}/edit`, text: 'Edit bank' },
    ]);

    await this.setStateAsync({
      exam,
      newBank: bank,
    });
  }

  async createBank() {
    const { examId } = this.props.match.params;
    const bank = await createBank(examId, this.state.newBank);
    this.props.history.push(`/exams/${examId}/banks/${bank.id}/`);
  }

  async updateBank() {
    const { examId, bankId } = this.props.match.params;
    const bank = await updateBank(examId, bankId, this.state.newBank);
    this.props.history.push(`/exams/${examId}/banks/${bank.id}/`);
  }

  onValueChange(event) {
    const { target } = event;
    const value = event.target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;

    this.setState({
      newBank: {
        ...this.state.newBank,
        [key]: value,
      },
    });
  }

  onResumableChange(event) {
    const value = event.target.checked;
    localStorage.cvqQuestionBanksResumable = value;
    this.onValueChange(event);
  }

  onTimePerQuestionChanged(timePerQuestion) {
    this.setState({
      newBank: {
        ...this.state.newBank,
        timePerQuestion,
      },
    });
  }

  onMinTimePerQuestionChanged(minTimePerQuestion) {
    this.setState({
      newBank: {
        ...this.state.newBank,
        minTimePerQuestion,
      },
    });
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.state.editMode) {
      this.updateBank();
    } else {
      this.createBank();
    }
  }

  render() {
    return (
      <BlockUi tag="form" onSubmit={this.onSubmit} blocking={this.state.blocking}>
        {/* header */}
        <div className="form-row">
          <div className="form-group col-md-12 titlebar">
            <h3>
              <label htmlFor="newBankName">{this.state.editMode ? 'Question bank name' : 'New question bank name'}</label>
              <input
                type="text"
                name="name"
                className="form-control"
                id="newBankName"
                maxLength="256"
                placeholder="Name"
                required
                value={this.state.newBank.name}
                onChange={this.onValueChange}
              />
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="jumbotron mini-jumbotron">
              <dl className="examdescription">
                <dt>Time given per question (mm:ss)</dt>
                <dd>
                  <SecondsPicker
                    value={this.state.newBank.timePerQuestion}
                    minValue={30}
                    faicon="clock"
                    onChange={this.onTimePerQuestionChanged}
                  />
                </dd>

                <dt>Minimum time to spend per question (mm:ss)</dt>
                <dd>
                  <SecondsPicker
                    value={this.state.newBank.minTimePerQuestion}
                    minValue={5}
                    faicon="clock"
                    onChange={this.onMinTimePerQuestionChanged}
                  />
                </dd>

                <dt>Points given per question</dt>
                <dd>
                  <FontAwesomeIcon icon="trophy" />
                  <span>
                    &nbsp;
                    <input
                      type="number"
                      name="pointsPerQuestion"
                      className="mini-input"
                      id="newBankPointsPerQuestion"
                      min="0"
                      max="100"
                      step="any"
                      value={this.state.newBank.pointsPerQuestion}
                      onChange={this.onValueChange}
                      required
                    />{' '}
                    point{this.state.newBank.pointsPerQuestion !== 1 ? 's' : ''}/question
                  </span>
                </dd>
                <dt>Choose quantity</dt>
                <dd>
                  <FontAwesomeIcon icon="hashtag" />
                  &nbsp;
                  <input
                    type="number"
                    name="chooseQuantity"
                    className="mini-input"
                    id="newBankChooseQuantity"
                    min="1"
                    max="100"
                    value={this.state.newBank.chooseQuantity}
                    onChange={this.onValueChange}
                    required
                  />
                  <span>&nbsp;question{this.state.newBank.chooseQuantity !== 1 ? 's' : ''}</span>
                </dd>

                <dt>Resumable without skipping</dt>
                <dd>
                  <FontAwesomeIcon icon="step-forward" />
                  &nbsp;
                  <input type="checkbox" name="resumable" checked={this.state.newBank.resumable} onChange={this.onResumableChange} />
                </dd>

                {this.state.exam.streaming && (
                  <>
                    <dt>Answer start point availability</dt>
                    <dd>
                      <FontAwesomeIcon icon="map-pin" />
                      &nbsp;
                      <select
                        className="mid-input"
                        id="newBankAnswerStartPoint"
                        name="answerStartPoint"
                        value={this.state.newBank.answerStartPoint}
                        onChange={this.onValueChange}
                        required
                      >
                        {Object.entries(answerStartPointMappings).map(([value, text]) => (
                          <option key={value} value={value}>
                            {text}
                          </option>
                        ))}
                      </select>
                    </dd>
                  </>
                )}
              </dl>
            </div>
          </div>
        </div>

        {/* warn if there are active sittings */}
        <ExamSittingsWarning examId={this.props.match.params.examId} />

        <div className="row">
          <div className="col-md-12">
            <div className="float-right">
              <Button className="float-right" variant="success" type="submit">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </BlockUi>
    );
  }
}

EditBank.propTypes = {
  history: PropTypes.any.isRequired,
};

export default withRouter(withErrorScreen(withBreadcrumbs(EditBank)));
