import React from 'react';
import autoBind from 'auto-bind';
import { withRouter } from 'react-router';

import { blocking } from '../util/decorators';
import QuestionListEntry from './QuestionListEntry';
import AsyncComponent from '../util/AsyncComponent';
import { parseQuery } from '../../util/queryparser';
import { fetchQuestions } from '../../service/question';
import { ExamDetailsDto, QuestionBankDto, QuestionDto } from '../../model';
import { QuestionListHeader } from './QuestionListHeader';

type QuestionListProps = {
  location: { [key: string]: string };
  exam: ExamDetailsDto;
  bank: QuestionBankDto;
};

type QuestionListState = {
  loaded: boolean;
  blocking: boolean;
  newQuestion: boolean;
  questions: QuestionDto[];
  count: number;
  page: number;
  perPage: number;
};

class QuestionList extends AsyncComponent<QuestionListProps, QuestionListState> {
  constructor(props) {
    super(props);

    const query = parseQuery(this.props.location.search);

    this.state = {
      loaded: false,
      questions: [],
      blocking: false,
      newQuestion: false,
      count: 0,
      page: Number(query.page) || 1,
      perPage: Number(query.perPage) || 25,
    };

    autoBind(this);

    this.fetchQuestions = blocking(this.fetchQuestions, this);
  }

  componentDidMount() {
    this.fetchQuestions();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const query = parseQuery(this.props.location.search);
      await this.setStateAsync({
        page: Number(query.page) || 1,
        perPage: Number(query.perPage) || 25,
      });
      await this.fetchQuestions();
    }
  }

  async fetchQuestions() {
    const { exam, bank } = this.props;
    const { count, entities } = await fetchQuestions(exam.id, bank.id, {
      page: this.state.page,
      perPage: this.state.perPage,
    });

    await this.setStateAsync({
      loaded: true,
      questions: entities,
      count,
    });
  }

  onNewQuestion(question) {
    this.setState({
      newQuestion: false,
      questions: [question, ...this.state.questions],
    });
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading questions...</div>;
    }

    const header = (
      <QuestionListHeader createDisabled={this.state.newQuestion} onNewQuestionRequest={() => this.setState({ newQuestion: true })} />
    );

    if (!this.state.questions.length && !this.state.newQuestion) {
      return (
        <div>
          {header}
          <div className="infomsg">No questions to show</div>
        </div>
      );
    }

    return (
      <div>
        {header}
        <div className="row">
          <div className="col-md-12">
            <table className="table table-hover">
              <tbody>
                {this.state.newQuestion && (
                  <tr>
                    <td>
                      <QuestionListEntry
                        new={true}
                        groupId={this.props.exam.group.id}
                        examId={this.props.exam.id}
                        bankId={this.props.bank.id}
                        examStreaming={this.props.exam.streaming}
                        onNewQuestion={this.onNewQuestion}
                        onDelete={() => this.setState({ newQuestion: false })}
                      />
                    </td>
                  </tr>
                )}
                {this.state.questions.map((question) => (
                  <tr key={question.id}>
                    <td>
                      <QuestionListEntry
                        groupId={this.props.exam.group.id}
                        examId={this.props.exam.id}
                        bankId={this.props.bank.id}
                        examStreaming={this.props.exam.streaming}
                        question={question}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(QuestionList);
