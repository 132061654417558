import React from 'react';
import autoBind from 'auto-bind';
import ms from 'ms';
import QWebSocket, { ConnectCallback } from 'qws-browser';

import AsyncComponent from './AsyncComponent';
import VolumeMeter from './VolumeMeter';
import withErrorScreen from './withErrorScreen';
import { longId } from '../../util/random';
import CredentialsContext, { CredentialsContextType } from '../../context/credentials';
import { StreamingQuality, streamingQualityMappings } from '../../model';
import { formatBytes } from '../../util/date';
import { fileStorageWsUrl, videoSendInterval } from '../../util/config';

/**
 * Extended media recorder object to stream to websocket
 */
export class WebSocketMediaRecorder {
  recorder: MediaRecorder;
  wsConnection: QWebSocket;
  recorderState: 'active' | 'prematureClose' | 'cleanClose';
  onStreamedBytes: (streamedBytes: number) => void;
  onInterrupt: (message: string) => void;

  // eslint-disable-next-line max-params
  constructor(
    wsConnection: QWebSocket,
    stream: MediaStream,
    streamingQuality: StreamingQuality,
    desktop: boolean,
    onStreamedBytes: (streamedBytes: number) => void,
    onInterrupt: (message: string) => void,
  ) {
    autoBind(this);

    this.wsConnection = wsConnection;
    this.recorderState = 'active';
    this.onStreamedBytes = onStreamedBytes;
    this.onInterrupt = onInterrupt;

    // if possible, use h.264 codec (currently only in Chrome)
    const recorderOpts: MediaRecorderOptions = {};
    if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
      recorderOpts.mimeType = 'video/webm;codecs=h264';
      if (desktop) {
        recorderOpts.videoBitsPerSecond = streamingQualityMappings[streamingQuality].h264desktopBitRate;
      } else {
        recorderOpts.videoBitsPerSecond = streamingQualityMappings[streamingQuality].h264webcamBitRate;
      }
    } else {
      recorderOpts.mimeType = 'video/webm';
      if (desktop) {
        recorderOpts.videoBitsPerSecond = streamingQualityMappings[streamingQuality].vp8desktopBitRate;
      } else {
        recorderOpts.videoBitsPerSecond = streamingQualityMappings[streamingQuality].vp8webcamBitRate;
      }
    }

    // Preparing the MediaRecorder
    console.log(
      `Using format "${recorderOpts.mimeType} with ${streamingQuality} quality (${recorderOpts.videoBitsPerSecond}bps), sending chunks every ${videoSendInterval}"`,
    );
    this.recorder = new MediaRecorder(stream, recorderOpts);
    this.recorder.ondataavailable = this.onDataAvailable;
    this.recorder.onerror = console.error;
  }

  start(): void {
    console.debug('Starting recording');
    this.recorder.start(ms(videoSendInterval as string));
  }

  stop(final = true): void {
    if (this.recorder.state === 'inactive') {
      console.error('Recorder already stopped');
    } else {
      console.debug('Recording stopped');
      this.recorderState = final ? 'cleanClose' : 'prematureClose';
      this.recorder.stop();
    }
  }

  onDataAvailable(event: BlobEvent): void {
    if (event.data.size > 0) {
      const finalChunk = this.recorder.state === 'inactive' && this.recorderState === 'cleanClose';

      // send video data
      const chunkIdx = this.wsConnection.send(event.data, { finalChunk });
      console.debug(chunkIdx);

      this.onStreamedBytes(event.data.size);

      if (finalChunk) {
        console.log('Closing ws after flush');
        this.wsConnection.close();
      }

      // sharing interrupted manually
      if (this.recorder.state === 'inactive' && this.recorderState === 'active') {
        console.error('Sharing interrupted manually');
        this.onInterrupt('Streaming has been interrupted manually');
      }

      // uncomment to also save chunk file while uploading - useful for reconnection debugging
      //
      // const blobUrl = window.URL.createObjectURL(event.data);
      // const tempLink = document.createElement('a');
      // tempLink.href = blobUrl;
      // const streamingType = this.wsConnection.streamingObjectId.includes('desktop') ? 'desktop' : 'webcam';
      // tempLink.setAttribute('download', `${streamingType}/chunk${chunkIdx.toString().padStart(6, '0')}`);
      // tempLink.click();
    }
  }
}

/**
 * Video recorder react component
 */

export type WebSocketMediaRecorderEvent = {
  streamedBytes: number;
};

type VideoRecorderProps = {
  stream: MediaStream;
  desktop: boolean;
  streaming: boolean;
  streamingQuality: StreamingQuality;
  streamingObjectId: string;
  streamingObjectPath: string;
  onStreamStart?: () => void;
  onStreamedBytes?: (event: WebSocketMediaRecorderEvent) => void;
  onConnect: ConnectCallback;
  onErroneousDisconnect: () => void;
  onInterrupt: (message: string) => void;
};

type VideoRecorderState = {
  id: string;
  monitorVolume: boolean;
  wsRecorder: WebSocketMediaRecorder;
  totalStreamedBytes: number;
};

class VideoRecorder extends AsyncComponent<VideoRecorderProps, VideoRecorderState> {
  context: CredentialsContextType;

  constructor(props: VideoRecorderProps) {
    super(props);

    this.state = {
      id: longId(),
      monitorVolume: false,
      wsRecorder: null,
      totalStreamedBytes: 0,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { desktop, stream } = this.props;
    if (!desktop) {
      const localVideo = document.getElementById(`local-video-${this.state.id}`) as HTMLVideoElement;
      localVideo.srcObject = stream;
    }
    this.startNewRecorder();
  }

  componentWillUnmount() {
    this.stopRecorder();
  }

  componentDidUpdate(prevProps: VideoRecorderProps) {
    // changing of streaming name
    if (prevProps.streamingObjectId !== this.props.streamingObjectId) {
      this.stopRecorder();
      this.startNewRecorder();
    } else if (prevProps.streaming !== this.props.streaming) {
      // start
      if (this.props.streaming) {
        this.state.wsRecorder.start();
      } else {
        this.stopRecorder(false);
      }
    }
  }

  startNewRecorder() {
    const connection = this.context.wsContainer.getConnection(this.props.streamingObjectId, {
      url: `${fileStorageWsUrl}/${this.props.streamingObjectPath}`,
      onConnect: this.props.onConnect,
      onErroneousDisconnect: this.props.onErroneousDisconnect,
    });

    const wsRecorder = new WebSocketMediaRecorder(
      connection,
      this.props.stream,
      this.props.streamingQuality,
      this.props.desktop,
      this.onStreamedBytes,
      this.props.onInterrupt,
    );

    if (this.props.streaming) {
      wsRecorder.start();
      if (this.props.onStreamStart) {
        this.props.onStreamStart();
      }
    }

    this.setState({ wsRecorder });
  }

  stopRecorder(final = true) {
    if (this.state.wsRecorder) {
      this.state.wsRecorder.stop(final);
    }
  }

  onStreamedBytes(streamedBytes: number) {
    this.setState({
      totalStreamedBytes: this.state.totalStreamedBytes + streamedBytes,
    });
    if (this.props.onStreamedBytes) {
      this.props.onStreamedBytes({
        streamedBytes,
      });
    }
  }

  render() {
    let backedUp: { messages: number; bytes: number };
    if (this.props.desktop) {
      backedUp = this.context.wsContainer.backedUp();
    }

    return (
      <div>
        {!this.props.desktop && (
          <>
            {/* monitor webcam feed */}
            <div>
              <video id={`local-video-${this.state.id}`} className="media-container" autoPlay muted></video>
            </div>
            {/* volume meter */}
            <div>
              {this.state.monitorVolume ? (
                <VolumeMeter stream={this.props.stream} onHide={() => this.setState({ monitorVolume: false })} />
              ) : (
                <i className="small-label likeALink" onClick={() => this.setState({ monitorVolume: true })}>
                  Show volume meter
                </i>
              )}
            </div>
          </>
        )}
        {/* report streamed amounts */}
        <div className="small-label stream-label">
          Successfully streamed <i>{formatBytes(this.state.totalStreamedBytes)}</i> of {this.props.desktop ? 'desktop' : 'video'} content
        </div>

        {this.props.desktop && (
          <>
            {/* backed up amount */}
            <div className="small-label stream-label">
              Network backup:{' '}
              <i>
                {backedUp.messages} messages / {formatBytes(backedUp.bytes)}
              </i>
            </div>
          </>
        )}
      </div>
    );
  }
}

VideoRecorder.contextType = CredentialsContext;

export default withErrorScreen(VideoRecorder);
