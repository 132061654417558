import autoBind from 'auto-bind';
import cloneDeep from 'lodash/cloneDeep';
import { SittingQuestionDto, UploadResourceDto } from '../../model';

/**
 * Editor model props
 */

export abstract class QuestionEditorModelProps<T extends SittingQuestionDto> {
  question: T;
  questionUpToDate: boolean;

  constructor(question: T, template?: SittingQuestionDto) {
    if (!question) {
      this.question = this.fromQuestionTemplate(template);
    } else {
      this.question = cloneDeep(question);
    }
    this.questionUpToDate = true;
    autoBind(this);
  }

  getQuestion(): T {
    if (!this.questionUpToDate) {
      this.refreshQuestion();
      this.questionUpToDate = true;
    }
    return cloneDeep(this.question);
  }

  abstract fromQuestionTemplate(template: SittingQuestionDto): T;

  abstract refreshQuestion(): void;
}

/**
 * Editor props
 */

export type QuestionEditorMode = 'view' | 'edit' | 'take' | 'response' | 'evaluate';

export interface QuestionEditorProps<T extends SittingQuestionDto, M extends QuestionEditorModelProps<T>> {
  mode: QuestionEditorMode;
  model: M;
  onChange: (model: M) => unknown;
  allowFileUpload?: boolean;
  onUpload?: (resource: UploadResourceDto) => void;
}
