import React, { createRef, RefObject } from 'react';
import autoBind from 'auto-bind';
import { RouteComponentProps, withRouter } from 'react-router';
import BlockUi from 'react-block-ui';

import GroupList from './groups/GroupList';
import GroupListHeader from './groups/GroupListHeader';
import AsyncComponent from './util/AsyncComponent';
import { blocking } from './util/decorators';
import withErrorScreen from './util/withErrorScreen';
import { addQueryParameters, parseQuery } from '../util/queryparser';
import Paginator from './util/Paginator';

import { defaultGroupOptions, GroupFilterOptions, fetchGroups } from '../service/group';
import { Collection, GroupReducedDto } from '../model';

type GroupsProps = RouteComponentProps;

type GroupsState = {
  loaded: boolean;
  blocking: false;
  groups: Collection<GroupReducedDto>;
  options: GroupFilterOptions;
  pageTopRef: RefObject<HTMLDivElement>;
};

class Groups extends AsyncComponent<GroupsProps, GroupsState> {
  constructor(props: GroupsProps) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      groups: {
        entities: [],
        count: 0,
      },
      options: {},
      pageTopRef: createRef(),
    };

    autoBind(this);

    this.fetchGroups = blocking(this.fetchGroups, this, {
      autoClose: true,
      keepTrying: true,
    });
  }

  async searchToOptions() {
    const query = parseQuery(this.props.location.search);

    await this.setStateAsync({
      options: {
        name: query.name || '',
        page: Number(query.page) || 1,
        perPage: Number(query.perPage) || 25,
        sortBy: query.sortBy || localStorage.cvqGroupsSortBy || 'name',
        sortDirection: query.sortDirection || localStorage.cvqGroupsSortDirection || 'asc',
      },
    });
  }

  async componentDidMount() {
    await this.searchToOptions();
    const search = addQueryParameters(this.props.location.search, this.state.options, defaultGroupOptions);
    if (search !== this.props.location.search) {
      this.props.history.replace(search);
    } else {
      await this.fetchGroups();
    }
  }

  async componentDidUpdate(prevProps: GroupsProps) {
    if (prevProps.location.search !== this.props.location.search) {
      localStorage.cvqGroupsSortBy = this.state.options.sortBy;
      localStorage.cvqGroupsSortDirection = this.state.options.sortDirection;

      await this.searchToOptions();
      await this.fetchGroups();
      if (this.state.pageTopRef.current) {
        this.state.pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onOptionsChange(newOptions: Partial<GroupFilterOptions>) {
    const options = {
      ...this.state.options,
      ...newOptions,
    };

    const search = addQueryParameters(this.props.location.search, options, defaultGroupOptions);
    if (search !== this.props.location.search) {
      this.props.history.push(search);
    }

    this.setState({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
  }

  async fetchGroups() {
    const groups = await fetchGroups(this.state.options);
    await this.setStateAsync({ loaded: true, groups });
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading groups...</div>;
    }

    const { options } = this.state;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <GroupListHeader options={options} onOptionsChange={this.onOptionsChange} />

        <GroupList groups={this.state.groups.entities} />

        <Paginator options={options} count={this.state.groups.count} onChange={this.onOptionsChange} />
      </BlockUi>
    );
  }
}

export default withRouter(withErrorScreen(Groups));
