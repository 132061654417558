import { useMemo } from 'react';

export default function useFormattedDate(isoDate: string) {
  return useMemo(() => {
    if (!isoDate) {
      return null;
    }
    return new Date(isoDate).toLocaleString();
  }, [isoDate]);
}
