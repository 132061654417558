import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { render as katexRender, __parse as katexParseUnsafe } from 'katex';

/**
 * Safe version of katex's parse function
 * Returns object with "invalid" flag.
 */
export function katexParse(text) {
  const texText = text;

  try {
    katexParseUnsafe(texText);
  } catch (e) {
    return { invalid: true, texText };
  }
  return { invalid: false, texText };
}

class KatexOutput extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.texText !== this.props.texText) {
      this.update();
    }
  }

  update() {
    katexRender(this.props.texText, this.container.current, { displayMode: !this.props.inline });
  }

  render() {
    return <div style={this.props.style} ref={this.container} onClick={this.props.onClick} />;
  }
}

KatexOutput.propTypes = {
  texText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  inline: PropTypes.bool,
};

export default KatexOutput;
