import React from 'react';
import { Prompt, RouteComponentProps, withRouter } from 'react-router';
import BlockUi from 'react-block-ui';
import { ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import { Link } from 'react-router-dom';
import QWebSocket from 'qws-browser';

import AsyncComponent from './util/AsyncComponent';
import withBreadcrumbs, { WithBreadcrumbsProps } from './util/withBreadcrumbs';
import withErrorScreen from './util/withErrorScreen';
import { blocking } from './util/decorators';
import { fetchExamById } from '../service/exam';
import { fetchSittings, startSitting, resumeSitting } from '../service/sitting';
import CredentialsContext, { CredentialsContextType } from '../context/credentials';
import CountdownWidget from './util/CountdownWidget';
import VideoRecorder, { WebSocketMediaRecorderEvent } from './util/VideoRecorder';
import { getVideoStream, getDesktopStream, isCodecAppropriate } from '../util/media';
import { ErrorModal } from './util/Modals';
import OverlayButton from './util/OverlayButton';
import LocalVideo from './util/LocalVideo';
import * as dto from '../model';
import QuestionEditor, { QuestionEditorModel } from './question/QuestionEditor';
import { longId } from '../util/random';
import { brokerRelayUrl, streamTurnoverBytes } from '../util/config';
import { formatBytes } from '../util/date';

/**
 * State machine possible states for exam
 */
type ExamState =
  | 'not-started-yet'
  | 'awaiting-stream'
  | 'awaiting-confirmation'
  | 'awaiting-start'
  | 'active-question'
  | 'waiting-for-next-question'
  | 'concluding-stream'
  | 'finished';

/**
 * Taken from URL
 */
type TakeExamSearchPathParams = {
  examId: string;
  sittingId: string;
};

/**
 * Current component props type definition
 */
type TakeExamProps = WithBreadcrumbsProps & RouteComponentProps<TakeExamSearchPathParams>;

/**
 * Current component state type definition
 */
type TakeExamState = {
  loaded: boolean;
  blocking: boolean;
  promptBeforeLeaving: boolean;
  state: ExamState;
  connected: boolean;
  brokerRelayConnected: boolean;
  webcamConnected: boolean;
  desktopConnected: boolean;
  disconnectTime: Date;
  questionReceivedRecently: boolean;
  answerStarted: boolean;
  answerChanged: boolean;
  streamingKey: string;
  relayConnection: QWebSocket;
  desktopStreamedBytes: number;
  streamStartTime: number;
  answerStartTime: number;
  questionStartTime: Date;
  questionEndTime: Date;
  waitStartTime?: Date;
  waitEndTime?: Date;
  backedUpBytes: number;
  exam: dto.ExamDetailsDto;
  sitting: dto.SittingWithEntryCodeDto;
  handout: dto.SittingHandoutQuestionDto;
  questionModel: QuestionEditorModel;
  submitButtonEnableTimeout: NodeJS.Timeout;
  redirectTimeout: NodeJS.Timeout;
  answerSyncInterval: NodeJS.Timeout;
  backUpCheckInterval: NodeJS.Timeout;
};

/**
 * Main component
 */
class TakeExam extends AsyncComponent<TakeExamProps, TakeExamState> {
  submittedSittingQuestionIds: number[] = [];

  videoStream: MediaStream;
  desktopStream: MediaStream;

  windowInFocus = true;
  questionReceivedTick = new Audio('/sound/questionreceived.mp3');
  errorTick = new Audio('/sound/error.mp3');

  context: CredentialsContextType;

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      promptBeforeLeaving: true,
      state: 'not-started-yet',
      connected: false,
      brokerRelayConnected: false,
      webcamConnected: false,
      desktopConnected: false,
      disconnectTime: null,
      questionReceivedRecently: false,
      answerStarted: false,
      answerChanged: false,
      streamingKey: '',
      relayConnection: null,
      desktopStreamedBytes: 0,
      streamStartTime: null,
      answerStartTime: null,
      questionStartTime: null,
      questionEndTime: null,
      backedUpBytes: 0,
      exam: null,
      sitting: null,
      handout: null,
      questionModel: null,
      submitButtonEnableTimeout: null,
      redirectTimeout: null,
      answerSyncInterval: null,
      backUpCheckInterval: null,
    };

    autoBind(this);

    this.fetchExam = blocking(this.fetchExam, this);
    this.onSubmitAnswers = blocking(this.onSubmitAnswers, this);
    this.getStreamSources = blocking(this.getStreamSources, this, {
      autoClose: true,
      keepTrying: true,
    });
    this.onInterrupt = blocking(this.onInterrupt, this, {
      autoClose: true,
    });

    window.addEventListener('blur', () => {
      this.windowInFocus = false;
    });
    window.addEventListener('focus', () => {
      this.windowInFocus = true;
    });
  }

  async componentDidMount() {
    window.onbeforeunload = this.onBeforeUnload;
    this.context.wsContainer.onError = (message: string) => this.onInterrupt(message);
    await this.fetchExam();
    if (this.state.state === 'awaiting-stream') {
      await this.getStreamSources();
    }
  }

  componentWillUnmount() {
    console.log('Unmounting exam taking component');

    if (this.state.answerSyncInterval) {
      clearInterval(this.state.answerSyncInterval);
    }
    if (this.state.redirectTimeout) {
      clearTimeout(this.state.redirectTimeout);
    }
    if (this.state.submitButtonEnableTimeout) {
      clearTimeout(this.state.submitButtonEnableTimeout);
    }
    if (this.state.backUpCheckInterval) {
      clearInterval(this.state.backUpCheckInterval);
    }

    if (this.state.state === 'active-question') {
      this.sendAnswerUpdate();
    }

    if (this.desktopStream) {
      this.desktopStream.getTracks().forEach((track) => track.stop());
      console.log('Desktop recording stopped');
    }
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => track.stop());
      console.log('Video recording stopped');
    }

    this.state.relayConnection?.close();
    this.context.wsContainer.onError = null;
    window.onbeforeunload = null;
  }

  onBeforeUnload() {
    console.log('Checking unload prompt necessity', this.state.promptBeforeLeaving);
    if (this.state.promptBeforeLeaving) {
      return true;
    }
    return null;
  }

  async fetchExam() {
    const { params } = this.props.match;
    const examId = Number(params.examId);
    const sittingId = Number(params.sittingId);

    const exam = await fetchExamById(examId);

    if (exam.streaming && !exam.maintainer) {
      isCodecAppropriate(true);
    }

    await this.setStateAsync({
      loaded: true,
      exam,
    });

    let sitting: dto.SittingWithEntryCodeDto;

    if (!sittingId) {
      // sitting start

      this.props.setBreadcrumbs([
        { path: '/exams/', text: 'Exams' },
        { path: `/exams/${exam.id}/`, text: exam.name },
        { path: `/exams/${exam.id}/take`, text: 'Take exam' },
      ]);

      // start
      try {
        sitting = await startSitting(exam.id);
      } catch (startError) {
        // we already have an active sitting, try to resume it
        if (startError?.message?.includes('You can only have one active sitting')) {
          const { entities } = await fetchSittings(exam.id, {
            studentName: this.context.userName,
            active: true,
            perPage: 1,
          });
          const activeSitting = entities[0];
          if (!activeSitting) {
            throw startError;
          }
          console.log('Switching to resume mode');
          try {
            sitting = await resumeSitting(exam.id, activeSitting.id);
          } catch (resumeError) {
            // check if this sitting is already finished
            if (resumeError?.message?.includes('is already finished')) {
              console.log('Already finished, redirecting');
              await this.onFinish();
              return;
            }
            throw resumeError;
          }
        } else {
          throw startError;
        }
      }
    } else {
      // sitting resume

      this.props.setBreadcrumbs([
        { path: '/exams/', text: 'Exams' },
        { path: `/exams/${exam.id}/`, text: exam.name },
        { path: `/exams/${exam.id}/`, text: 'Sittings' },
        { path: `/exams/${exam.id}/sittings/${sittingId}/`, text: `Sitting ${sittingId}` },
        { path: `/exams/${exam.id}/sittings/${sittingId}/resume`, text: 'Resumed' },
      ]);

      // resume
      try {
        sitting = await resumeSitting(exam.id, sittingId);
      } catch (resumeError) {
        // trying to resume already finished exam
        if (resumeError?.message?.includes('is already finished')) {
          console.log('Already finished, redirecting');
          await this.onFinish();
          return;
        }
        throw resumeError;
      }
    }

    await this.setStateAsync({
      state: sitting.streaming ? 'awaiting-stream' : 'awaiting-start',
      promptBeforeLeaving: !sitting.simulated,
      sitting,
      streamingKey: sitting.streaming ? longId(true) : undefined,
      desktopStreamedBytes: 0,
    });

    if (!sitting.streaming) {
      await this.connectToBrokerRelay();
    }
  }

  async getStreamSources(): Promise<void> {
    console.log('Need streaming sources, requesting...');
    const videoWidth = dto.streamingQualityMappings[this.state.sitting.streamingQuality].webcamWidth;
    this.videoStream = await getVideoStream(videoWidth);
    console.log('Successfully registered webcam video stream');
    this.desktopStream = await getDesktopStream();
    console.log('Successfully registered desktop stream');

    if (this.state.sitting.simulated) {
      await this.setStateAsync({ state: 'awaiting-start' });
      await this.connectToBrokerRelay();
    } else {
      await this.setStateAsync({ state: 'awaiting-confirmation' });
    }
  }

  async onConfirmStartStreamingExam(): Promise<void> {
    await this.setStateAsync({ state: 'awaiting-start' });
    await this.connectToBrokerRelay();
  }

  async connectToBrokerRelay(): Promise<void> {
    const { id, sittingEntryCode, sittingToken } = this.state.sitting;
    const { userId } = this.context;

    const relayId = `relay.s${id}.${sittingToken}`;
    const relayFullUrl = `${brokerRelayUrl}/users/${userId}/sittings/${id}?sittingEntryCode=${sittingEntryCode}`;

    const relayConnection = this.context.wsContainer.getConnection(relayId, {
      url: relayFullUrl,
      onConnect: this.onBrokerRelayConnect,
      onErroneousDisconnect: this.onBrokerRelayErroneousDisconnect,
    });

    relayConnection.onJsonRoute<dto.SittingHandoutQuestionDto>('handout', this.onQuestion);
    relayConnection.onJsonRoute<dto.SittingConcludeDto>('conclude', this.onConclude);

    await this.setStateAsync({ relayConnection });
  }

  async onQuestion(message: dto.SittingHandoutQuestionDto): Promise<void> {
    // check if sitting token changed (stale message)
    if (this.state.sitting.sittingToken !== message.sittingToken) {
      console.log(
        `Received question but tokens do not match (received ${message.sittingToken}, expecting ${this.state.sitting.sittingToken}), assuming old stale message...`,
      );
      return;
    }

    if (this.state.submitButtonEnableTimeout) {
      clearTimeout(this.state.submitButtonEnableTimeout);
    }

    const { currentQuestion } = message;

    await this.setStateAsync({
      handout: message,
      state: 'active-question',
      questionReceivedRecently: true,
      answerStarted: !this.state.sitting.streaming || Boolean(currentQuestion.answerStartTime),
      answerChanged: false,
      questionStartTime: new Date(),
      streamStartTime: null,
      desktopStreamedBytes: 0,
      answerStartTime: null,
      questionEndTime: new Date(Date.now() + currentQuestion.timeRemaining * 1000),
      questionModel: new QuestionEditorModel(currentQuestion),
    });

    // send question acknowledgment message
    const ackMessage: dto.SittingQuestionAcknowledgmentDto = {
      sittingId: this.state.sitting.id,
      sittingQuestionId: currentQuestion.id,
      messageType: 'questionack',
    };
    this.state.relayConnection.send(ackMessage);

    let submitEnableTimeout = 2000;
    if (!this.state.sitting.simulated) {
      // calculate minimum time based on how much was already spent on this question
      const minTimeTimeout = currentQuestion.minTime - (currentQuestion.totalTime - currentQuestion.timeRemaining);
      // take at least 2 seconds to enable submit button
      submitEnableTimeout = Math.max(minTimeTimeout * 1000, 2000);
    }

    await this.setStateAsync({
      answerSyncInterval: setInterval(this.sendAnswerUpdate, 30000),
      submitButtonEnableTimeout: setTimeout(() => this.setState({ questionReceivedRecently: false }), submitEnableTimeout),
    });

    if (!this.windowInFocus) {
      this.questionReceivedTick.play();
    }
  }

  onStreamStart() {
    this.setState({ streamStartTime: Date.now() });
  }

  onStreamedBytes(event: WebSocketMediaRecorderEvent) {
    const desktopStreamedBytes = this.state.desktopStreamedBytes + event.streamedBytes;
    if (desktopStreamedBytes >= streamTurnoverBytes) {
      console.log(`Streamed bytes over turnover, re-initializing video: ${desktopStreamedBytes} / ${streamTurnoverBytes}`);
      this.setState({
        // regenerate streaming key randomly
        streamingKey: longId(true),
        desktopStreamedBytes: 0,
      });
    } else {
      this.setState({ desktopStreamedBytes });
    }
  }

  async onInterrupt(message: string) {
    // set redirect timeout
    const { params } = this.props.match;
    const examId = Number(params.examId) || this.state.exam.id;

    // play error sound
    this.errorTick.play();

    // omit prompt before redirect
    window.onbeforeunload = null;
    await this.setStateAsync({
      connected: true,
      promptBeforeLeaving: false,
      redirectTimeout: setTimeout(() => this.props.history.push(`/exams/${examId}/`), 4000),
    });

    // actual error
    const finalMessage = message || 'Unknown error occured';
    throw Error(`${finalMessage}. You are being redirected to the exam page, please resume sitting to continue`);
  }

  async sendAnswerUpdate() {
    const { questionEndTime, questionModel, answerChanged, sitting, relayConnection } = this.state;
    const timeRemaining = Math.round(Math.max((questionEndTime.getTime() - Date.now()) / 1000.0, 0));
    const question = questionModel.getQuestion();

    const commonMessageProps = {
      sittingQuestionId: question.id,
      sittingId: sitting.id,
      timeRemaining,
    };

    if (answerChanged) {
      // send temp answer
      const answerMessage: dto.SittingQuestionResponseDto = {
        ...commonMessageProps,
        messageType: 'answer',
        questionType: question.questionType,
        response: question.response,
        finalAnswer: false,
      };
      relayConnection.send(answerMessage);
    } else {
      // send lifesign
      const lifesignMessage: dto.SittingQuestionLifesignDto = {
        ...commonMessageProps,
        messageType: 'lifesign',
      };
      relayConnection.send(lifesignMessage);
    }

    await this.setStateAsync({ answerChanged: false });
  }

  async onAnswerStart() {
    const answerStartTime = (Date.now() - this.state.streamStartTime) / 1000.0;
    const answerStartMessage: dto.SittingQuestionStartSignalDto = {
      sittingQuestionId: this.state.handout.currentQuestion.id,
      sittingId: this.state.sitting.id,
      messageType: 'answerstart',
      answerStartStreamingKey: this.state.streamingKey,
      answerStartTime,
    };

    // send answer start event
    this.state.relayConnection.send(answerStartMessage);

    await this.setStateAsync({
      answerStarted: true,
      answerStartTime,
    });
  }

  onQuestionModelChange(questionModel: QuestionEditorModel) {
    this.setState({
      questionModel,
      answerChanged: true,
    });
  }

  async onSubmitAnswers() {
    const question = this.state.questionModel.getQuestion();

    if (this.submittedSittingQuestionIds.includes(question.id)) {
      console.warn(`Question ${question.id} already submitted, assuming rogue button click`);
      return;
    }
    this.submittedSittingQuestionIds.push(question.id);

    const timeRemaining = Math.round(Math.max((this.state.questionEndTime.getTime() - Date.now()) / 1000.0, 0));
    const answerMessage: dto.SittingQuestionResponseDto = {
      sittingQuestionId: question.id,
      sittingId: this.state.sitting.id,
      messageType: 'answer',
      questionType: question.questionType,
      response: question.response,
      timeRemaining,
      finalAnswer: true,
    };

    this.state.relayConnection.send(answerMessage);

    if (this.state.answerSyncInterval) {
      clearInterval(this.state.answerSyncInterval);
    }

    const { accumulateRemainingTime, pauseBetweenQuestions } = this.state.exam;
    await this.setStateAsync({
      state: 'waiting-for-next-question',
      waitStartTime: new Date(),
      waitEndTime: accumulateRemainingTime ? new Date() : new Date(Date.now() + pauseBetweenQuestions * 1000),
      handout: null,
    });
  }

  async onFinish() {
    const { params } = this.props.match;
    const examId = Number(params.examId) || this.state.exam.id;
    const sittingId = Number(params.sittingId) || this.state.sitting?.id;

    let redirectUrl;
    if (sittingId) {
      redirectUrl = `/exams/${examId}/sittings/${sittingId}/`;
    } else {
      redirectUrl = `/exams/${examId}/sittings/`;
    }

    await this.setStateAsync({
      state: 'finished',
      promptBeforeLeaving: false,
      handout: null,
      redirectTimeout: setTimeout(() => this.props.history.push(redirectUrl), 3000),
    });
  }

  async onConclude(message: dto.SittingConcludeDto) {
    // check if sitting token changed (stale message)
    if (this.state.sitting.sittingToken !== message.sittingToken) {
      console.log(
        `Received question but tokens do not match (received ${message.sittingToken}, expecting ${this.state.sitting.sittingToken}), assuming old stale message...`,
      );
      return;
    }

    if (this.state.sitting.streaming) {
      const backedUp = this.context.wsContainer.backedUp() as { messages: number; bytes: number };
      if (backedUp.bytes > 0) {
        await this.setStateAsync({
          state: 'concluding-stream',
          backedUpBytes: backedUp.bytes,
          handout: null,
        });
        if (!this.state.backUpCheckInterval) {
          await this.setStateAsync({
            backUpCheckInterval: setInterval(() => this.onConclude(message), 2000),
          });
        }
      } else {
        if (this.state.backUpCheckInterval) {
          clearInterval(this.state.backUpCheckInterval);
          await this.setStateAsync({
            backUpCheckInterval: null,
          });
        }
        await this.onFinish();
      }
    } else {
      await this.onFinish();
    }
  }

  postConnect(): void {
    const { sitting, brokerRelayConnected, webcamConnected, desktopConnected } = this.state;
    const streaming = sitting && sitting.streaming;
    const connected = brokerRelayConnected && (!streaming || (webcamConnected && desktopConnected));

    if (connected) {
      const { state, questionEndTime, disconnectTime } = this.state;

      if (state === 'active-question' && questionEndTime && disconnectTime) {
        // shift end time so it is not lost because if disconnectedness
        const shift = Date.now() - disconnectTime.getTime();
        const newEndTime = new Date(questionEndTime.getTime() + shift);
        console.log(`Shifting end time with ${shift}ms so there is no loss`);
        this.setState({ connected: true, questionEndTime: newEndTime });
      } else {
        this.setState({ connected: true });
      }
    }
  }

  postErroneousDisconnect(): void {
    if (this.state.connected) {
      this.setState({
        connected: false,
        disconnectTime: new Date(),
      });
    }
  }

  onBrokerRelayConnect(): void {
    if (!this.state.brokerRelayConnected) {
      this.setState({ brokerRelayConnected: true }, this.postConnect);
    }
  }

  onBrokerRelayErroneousDisconnect(): void {
    if (this.state.brokerRelayConnected) {
      this.setState({ brokerRelayConnected: false }, this.postErroneousDisconnect);
    }
  }

  onWebcamConnect(): void {
    if (!this.state.webcamConnected) {
      this.setState({ webcamConnected: true }, this.postConnect);
    }
  }

  onWebcamErroneousDisconnect(): void {
    if (this.state.webcamConnected) {
      this.setState({ webcamConnected: false }, this.postErroneousDisconnect);
    }
  }

  onDesktopConnect(): void {
    if (!this.state.desktopConnected) {
      this.setState({ desktopConnected: true }, this.postConnect);
    }
  }

  onDesktopErroneousDisconnect(): void {
    if (this.state.desktopConnected) {
      this.setState({ desktopConnected: false }, this.postErroneousDisconnect);
    }
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading exam...</div>;
    }

    const { state } = this.state;

    const trackConnectionError = !['not-started-yet', 'awaiting-stream', 'awaiting-confirmation', 'awaiting-start', 'finished'].includes(
      state,
    );

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <Prompt
          when={this.state.promptBeforeLeaving}
          message="Are you sure you want to leave? Answers you provided MAY BE LOST! You should not leave exam unless specifically approved by instructor"
        />

        <div className="row">
          <div className="col-md-12 titlebar">
            <div className="float-left">
              <h4>
                {this.state.exam.name}
                {this.state.sitting && <i> - Sitting {this.state.sitting.id}</i>}
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="jumbotron" style={{ paddingTop: 30, paddingBottom: state === 'active-question' ? 8 : 30, marginBottom: 0 }}>
              {state === 'not-started-yet' && this.renderNotStartedYet()}
              {state === 'awaiting-stream' && this.renderAwaitingStream()}
              {state === 'awaiting-confirmation' && this.renderAwaitingConfirmation()}
              {state === 'awaiting-start' && this.renderAwaitingStart()}
              {state === 'active-question' && this.renderActiveQuestion()}
              {state === 'waiting-for-next-question' && this.renderWaitingForNextQuestion()}
              {state === 'concluding-stream' && this.renderConcludingStream()}
              {state === 'finished' && this.renderFinished()}
            </div>
          </div>
        </div>

        <ErrorModal show={trackConnectionError && !this.state.connected} title="Connection severed">
          <div style={{ marginBottom: 10 }}>
            The connection to the server <b>has been interrupted</b>.
          </div>
          <div style={{ marginBottom: 10 }}>
            Please wait while we try to re-connect you. Do not leave this page unless instructed to do so, or{' '}
            <b>the current question may be un-answerable.</b>.
          </div>
          <div>The countdown timer has been paused during this technical difficulty.</div>
        </ErrorModal>
      </BlockUi>
    );
  }

  renderNotStartedYet() {
    return <div>Starting exam...</div>;
  }

  renderAwaitingStream() {
    return (
      <div>
        <div style={{ marginBottom: 10 }}>You have successfully been enrolled in this exam from this browser.</div>
        <div>
          <FontAwesomeIcon icon="video" />
          &nbsp;<b>Streaming video</b> is mandatory during this exam, attempting to get ahold of camera input...
          <br />
          If nothing happens, please re-connect your camera, make sure other applications are not using it, and reload the page.
        </div>
      </div>
    );
  }

  renderAwaitingConfirmation() {
    return (
      <div>
        <h4>Stream check</h4>
        <LocalVideo stream={this.videoStream} />

        <div>You are about to enter a streaming exam.</div>
        <div className="attention">Please read the following carefully and make sure all are true before beginning:</div>

        <ul className="checklist">
          <li>You can see your entire face in the middle of the box above</li>
          <li>You are facing the camera in a straight angle</li>
          <li>Your microphone is picking up a signal (say something and see the volume meter above change)</li>
          <li>You are not wearing headphones or sunglasses unless specifically allowed by your instructors</li>
          <li>Your room has adecquate light</li>
          <li>You have a stable Internet connection for the duration of the exam</li>
          <li>You have shared your entire screen in the previous message and are only using 1 monitor</li>
        </ul>

        <div className="errormsg">Failure to meet any of the above may lead to automatic barring from the exam.</div>

        <div className="eventdatemsg">You can monitor your webcam and microphone levels at any time during the exam.</div>

        <div className="row">
          <div className="col-md-12">
            <div>&nbsp;</div>
            <div className="float-right btn-group">
              <OverlayButton variant="success" tooltip="I have acknowledged the above rules" onClick={this.onConfirmStartStreamingExam}>
                <FontAwesomeIcon icon="play-circle" />
                &nbsp;Begin exam
              </OverlayButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAwaitingStart() {
    const { exam } = this.state;

    return (
      <div>
        <FontAwesomeIcon icon="hourglass-half" />
        &nbsp; You have successfully been enrolled in this exam from this browser. The exam is set to start at{' '}
        <i>{new Date(exam.startTime).toLocaleString()}</i>. Please do not close your browser window.
        <CountdownWidget
          startTime={new Date()}
          endTime={new Date(exam.startTime)}
          completeText="The exam should start momentarily, waiting for questions..."
        />
        {exam.examType === 'continuous' && (
          <p>
            <b>
              If a question does not arrive within 2-3 minutes of the expected time, please&nbsp;
              <Link to={`/exams/${exam.id}/`}>exit the exam,</Link>&nbsp; refresh the page and try resuming.
            </b>
          </p>
        )}
      </div>
    );
  }

  renderActiveQuestion() {
    const {
      exam,
      sitting,
      handout,
      questionModel,
      questionEndTime,
      questionStartTime,
      streamingKey,
      questionReceivedRecently,
      answerStarted,
    } = this.state;
    const { currentQuestion } = handout;

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <b>
              <span>Question&nbsp;{currentQuestion.questionOrder + 1}&nbsp;</span>
              <span>of&nbsp;{exam.numQuestions}&nbsp;</span>
            </b>
            <i>(worth {currentQuestion.possiblePoints} points)</i>
          </div>
          <div className="custom-progress-bar" style={{ flexGrow: 1 }}>
            <ProgressBar variant="info" now={((currentQuestion.questionOrder + 1) / (exam.numQuestions + 1)) * 100} />
          </div>
        </div>

        <div style={{ overflowY: 'scroll', paddingRight: 12, minHeight: 250, height: 'calc(100vh - 345px)' }}>
          {/* editor with question content and response inputs */}
          <QuestionEditor model={questionModel} mode="take" onChange={this.onQuestionModelChange} />

          {/* streaming question streaming object */}
          {sitting.streaming && (
            <>
              <hr className="large-hr" />
              <div>
                <b>
                  Currently recording with <i>{sitting.streamingQuality}</i> quality:
                </b>
              </div>
              <div style={{ marginBottom: 10 }}>
                <VideoRecorder
                  id="video-recorder"
                  stream={this.videoStream}
                  streaming={true}
                  streamingQuality={sitting.streamingQuality}
                  streamingObjectId={`webcam.s${sitting.id}.q${currentQuestion.questionId}.${streamingKey}`}
                  streamingObjectPath={`${sitting.streamingPath}/question${currentQuestion.questionId}-${streamingKey}-webcam.webm`}
                  onConnect={this.onWebcamConnect}
                  onErroneousDisconnect={this.onWebcamErroneousDisconnect}
                  onInterrupt={this.onInterrupt}
                />
                <VideoRecorder
                  id="desktop-recorder"
                  stream={this.desktopStream}
                  desktop={true}
                  streaming={true}
                  streamingQuality={sitting.streamingQuality}
                  streamingObjectId={`desktop.s${sitting.id}.q${currentQuestion.questionId}.${streamingKey}`}
                  streamingObjectPath={`${sitting.streamingPath}/question${currentQuestion.questionId}-${streamingKey}-desktop.webm`}
                  onStreamStart={this.onStreamStart}
                  onStreamedBytes={this.onStreamedBytes}
                  onConnect={this.onDesktopConnect}
                  onErroneousDisconnect={this.onDesktopErroneousDisconnect}
                  onInterrupt={this.onInterrupt}
                />
              </div>
            </>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* countdown timer */}
          <div style={{ flexGrow: 1 }}>
            <CountdownWidget
              startTime={questionStartTime}
              endTime={questionEndTime}
              paused={!this.state.connected}
              refreshInterval={10000}
              playTick1={true}
              playTick2={currentQuestion.timeRemaining >= 120}
              completeText="Time is up, submitting..."
              onComplete={this.onSubmitAnswers}
            />
          </div>
          {/* submit button */}
          <div className="single-button-row">
            <div className="float-right btn-group">
              {/* answer start point button */}
              {currentQuestion.answerStartPoint !== 'unavailable' && (
                <>
                  <OverlayButton
                    variant="outline-info"
                    visible={sitting.streaming}
                    tooltip="Mark when you are starting your answer"
                    disabled={answerStarted}
                    disabledTooltip={
                      this.state.answerStartTime
                        ? `Answer marked started at ${this.state.answerStartTime} seconds`
                        : 'Answer marked started in previous iteration'
                    }
                    onClick={this.onAnswerStart}
                  >
                    <FontAwesomeIcon icon="comments" />
                    &nbsp;Start answer
                  </OverlayButton>
                </>
              )}

              {/* submit button */}
              {currentQuestion.answerStartPoint === 'mandatory' && (
                <>
                  <OverlayButton
                    variant="success"
                    disabled={!answerStarted || questionReceivedRecently}
                    tooltip="Submit your answer"
                    disabledTooltip={
                      answerStarted
                        ? `Minimum time of ${currentQuestion.minTime} seconds has not elapsed yet`
                        : 'Cannot submit until answer is started'
                    }
                    onClick={this.onSubmitAnswers}
                  >
                    <FontAwesomeIcon icon="upload" />
                    &nbsp;Submit
                  </OverlayButton>
                </>
              )}
              {currentQuestion.answerStartPoint === 'available' && (
                <>
                  <OverlayButton
                    variant={answerStarted ? 'success' : 'warning'}
                    disabled={questionReceivedRecently}
                    tooltip={answerStarted ? 'Submit your answer' : "Warning: You haven't marked an answer start point"}
                    disabledTooltip={`Minimum time of ${currentQuestion.minTime} seconds has not elapsed yet`}
                    onClick={this.onSubmitAnswers}
                  >
                    <FontAwesomeIcon icon="upload" />
                    &nbsp;Submit
                  </OverlayButton>
                </>
              )}
              {currentQuestion.answerStartPoint === 'unavailable' && (
                <>
                  <OverlayButton
                    variant="success"
                    disabled={questionReceivedRecently}
                    tooltip="Submit your answer"
                    disabledTooltip={`Minimum time of ${currentQuestion.minTime} seconds has not elapsed yet`}
                    onClick={this.onSubmitAnswers}
                  >
                    <FontAwesomeIcon icon="upload" />
                    &nbsp;Submit
                  </OverlayButton>
                </>
              )}
            </div>
            &nbsp;
          </div>
        </div>
      </div>
    );
  }

  renderWaitingForNextQuestion() {
    const { exam, waitStartTime, waitEndTime } = this.state;
    const { accumulateRemainingTime, pauseBetweenQuestions, examType } = exam;

    return (
      <div>
        <FontAwesomeIcon icon="mug-hot" />
        &nbsp;
        <p>Taking a breather between questions. Please do not close your browser window.</p>
        {examType === 'continuous' && !accumulateRemainingTime && (
          <>
            <p>
              The next question is set to arrive in&nbsp;
              <i>{pauseBetweenQuestions}</i>&nbsp;seconds.
            </p>

            <CountdownWidget
              startTime={waitStartTime}
              endTime={waitEndTime}
              completeText="The next question should arrive momentarily..."
            />
          </>
        )}
        {examType === 'continuous' && (
          <p>
            <b>
              If a question does not arrive within 2-3 minutes of the expected time, please&nbsp;
              <Link to={`/exams/${exam.id}/`}>exit the exam,</Link>&nbsp; refresh the page and try resuming.
            </b>
          </p>
        )}
      </div>
    );
  }

  renderConcludingStream() {
    return (
      <div>
        <FontAwesomeIcon icon="cloud-upload-alt" />
        &nbsp; The exam has finished, but <span style={{ color: ' #b02020' }}>please do not leave this page.</span>
        <br />
        We still have {formatBytes(this.state.backedUpBytes)} of backed up information to sync.
      </div>
    );
  }

  renderFinished() {
    return (
      <div>
        <FontAwesomeIcon icon="clipboard-check" />
        &nbsp; Congratulations, you have successfully finished this exam. Redirecting you to the details page...
      </div>
    );
  }
}

TakeExam.contextType = CredentialsContext;

export default withRouter(withErrorScreen(withBreadcrumbs(TakeExam)));
