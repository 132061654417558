import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import React from 'react';
import BlockUi from 'react-block-ui';
import { Button, Dropdown } from 'react-bootstrap';
import CredentialsContext, { CredentialsContextType } from '../../context/credentials';

import { Collection, IdentityType, UserDto, UserIdentityCreationDto, UserIdentityDto, UserIdentityUpdateDto } from '../../model';
import {
  createUserIdentity,
  deleteUserIdentity,
  fetchUserIdentities,
  updateUserIdentity,
  UserIdentityFilterOptions,
} from '../../service/useridentity';
import AsyncComponent from '../util/AsyncComponent';
import { blocking } from '../util/decorators';
import FilterBy from '../util/FilterBy';
import withErrorScreen from '../util/withErrorScreen';
import UserIdentityListEntry from './UserIdentityListEntry';

type UserIdentityListProps = {
  user: UserDto;
  editMode: boolean;
};

type UserIdentityListState = {
  loaded: boolean;
  blocking: boolean;
  identities: Collection<UserIdentityDto>;
  showDeleteModal: false;
  options: UserIdentityFilterOptions;
  newIdentityType: IdentityType | undefined;
};

class UserIdentityList extends AsyncComponent<UserIdentityListProps, UserIdentityListState> {
  context: CredentialsContextType;

  constructor(props: UserIdentityListProps) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      identities: {
        entities: [],
        count: 0,
      },
      showDeleteModal: false,
      options: {},
      newIdentityType: undefined,
    };

    autoBind(this);

    this.fetchUserIdentities = this.fetchUserIdentities.bind(this);

    this.fetchUserIdentities = blocking(this.fetchUserIdentities, this);
    this.createUserIdentity = blocking(this.createUserIdentity, this);
    this.cancelIdentityCreation = blocking(this.cancelIdentityCreation, this);
    this.updateUserIdentity = blocking(this.updateUserIdentity, this);
    this.deleteUserIdentity = blocking(this.deleteUserIdentity, this);
  }

  componentDidMount(): void {
    this.fetchUserIdentities();
  }

  async componentDidUpdate(prevProps: UserIdentityListProps): Promise<void> {
    if (prevProps.user?.id !== this.props.user?.id) {
      await this.fetchUserIdentities();
    }
  }

  async onOptionsChange(newOptions: Partial<UserIdentityFilterOptions>): Promise<void> {
    await this.setStateAsync({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
    await this.fetchUserIdentities();
  }

  async fetchUserIdentities(): Promise<void> {
    if (this.props.editMode) {
      const identities = await fetchUserIdentities(this.props.user.id, this.state.options);
      await this.setStateAsync({ loaded: true, identities });
    } else {
      await this.setStateAsync({ loaded: true });
    }
  }

  async createUserIdentity(identityCreation: UserIdentityCreationDto): Promise<void> {
    const identity = await createUserIdentity(this.props.user.id, identityCreation);
    await this.setStateAsync({
      identities: {
        count: this.state.identities.count + 1,
        entities: [identity, ...this.state.identities.entities],
      },
      newIdentityType: undefined,
    });
  }

  async updateUserIdentity(description: { identityId: number; identity: UserIdentityUpdateDto }): Promise<void> {
    const { identityId, identity: identityUpdate } = description;
    const updatedIdentity = await updateUserIdentity(this.props.user.id, identityId, identityUpdate);
    await this.setStateAsync({
      identities: {
        count: this.state.identities.count,
        entities: this.state.identities.entities.map((identity) => (identity.id === identityId ? updatedIdentity : identity)),
      },
    });
  }

  async deleteUserIdentity(identityId: number): Promise<void> {
    await deleteUserIdentity(this.props.user.id, identityId);
    await this.setStateAsync({
      identities: {
        count: this.state.identities.count - 1,
        entities: this.state.identities.entities.filter((identity) => identity.id !== identityId),
      },
    });
  }

  showNewUserIdentity(identityType: IdentityType): void {
    this.setState({
      newIdentityType: identityType,
    });
  }

  cancelIdentityCreation(): void {
    this.setState({
      newIdentityType: undefined,
    });
  }

  render(): JSX.Element {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading identities...</div>;
    }
    const { userId, admin, providers } = this.context;
    const { user, editMode } = this.props;
    const { identities, options, newIdentityType } = this.state;

    const providersAvailable = providers && Object.keys(providers).length > 0;

    const canCreateStandardByExternal =
      localStorage.cvqLoggedInProvider &&
      providersAvailable &&
      providers[localStorage.cvqLoggedInProvider].canCreateStandardIdentity &&
      identities.entities.some(
        (identity) => identity.identityType === 'external' && identity.providerName === localStorage.cvqLoggedInProvider,
      );

    const hasStandardIdentity = Boolean(identities.entities.find(({ identityType }) => identityType === 'standard'));
    const canCreateStandard = (admin || userId === user.id) && !hasStandardIdentity && canCreateStandardByExternal;

    return (
      <BlockUi blocking={this.state.blocking}>
        <FilterBy
          values={options}
          onChange={this.onOptionsChange}
          options={[
            {
              name: 'Identity type',
              queryKey: 'identityType',
              options: [
                { key: 'all', label: 'All', value: undefined, default: true },
                { key: 'standard', label: 'Standard', value: 'standard' },
                { key: 'external', label: 'External', value: 'external' },
              ],
            },
          ]}
        />
        {user && user.id && !newIdentityType && (canCreateStandard || admin) && (
          <>
            <div className="float-right btn-group">
              {providersAvailable ? (
                <>
                  <Dropdown>
                    <Dropdown.Toggle variant="success">
                      <FontAwesomeIcon icon="plus" />
                      &nbsp;Create
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {canCreateStandard && (
                        <>
                          <Dropdown.Item onClick={() => this.showNewUserIdentity('standard')}>Standard</Dropdown.Item>
                        </>
                      )}
                      {admin && (
                        <>
                          <Dropdown.Item onClick={() => this.showNewUserIdentity('external')}>External</Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <>
                  <Button variant="primary" onClick={() => this.showNewUserIdentity('standard')}>
                    <FontAwesomeIcon icon="plus" />
                    &nbsp;Create
                  </Button>
                </>
              )}
            </div>
          </>
        )}

        {!identities.entities.length && !newIdentityType ? (
          <>
            <div className="infomsg">No identities to show</div>
          </>
        ) : (
          <>
            <table className="table table-hover">
              <tbody>
                {identities.entities.map((identity) => (
                  <tr key={identity.id}>
                    <td>
                      <UserIdentityListEntry
                        identity={identity}
                        identityType={identity.identityType}
                        user={user}
                        editMode={editMode}
                        onUpdate={this.updateUserIdentity}
                        onDelete={this.deleteUserIdentity}
                      />
                    </td>
                  </tr>
                ))}
                {newIdentityType && (
                  <>
                    <tr key="new-identity">
                      <td>
                        <UserIdentityListEntry
                          identityType={newIdentityType}
                          user={user}
                          editMode={false}
                          onCreate={this.createUserIdentity}
                          onCancel={this.cancelIdentityCreation}
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </>
        )}
      </BlockUi>
    );
  }
}

UserIdentityList.contextType = CredentialsContext;

export default withErrorScreen(UserIdentityList);
