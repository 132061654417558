import { forceH264Codec } from './config';

/**
 * Workaround: include getDisplayMedia, since not part of MediaStreams TS API yet
 * https://github.com/microsoft/TypeScript/issues/33232
 */
declare global {
  interface MediaDevices {
    getDisplayMedia(constraints?: MediaStreamConstraints): Promise<MediaStream>;
  }

  interface MediaTrackConstraintSet {
    displaySurface?: 'application' | 'browser' | 'monitor' | 'window';
    logicalSurface?: boolean;
    cursor?: 'always' | 'motion' | 'never';
  }
}

/**
 * Make sure capable audio & video devices are available
 */
export const ensureHasStreamingDevices = async (): Promise<boolean> => {
  const devices = await navigator.mediaDevices.enumerateDevices();

  const audioDevices = devices.filter((device) => device.kind === 'audioinput');
  console.log(
    `Found ${audioDevices.length} audio device(s):`,
    audioDevices.map((device) => device.label),
  );

  const videoDevices = devices.filter((device) => device.kind === 'videoinput');
  console.log(
    `Found ${videoDevices.length} video device(s):`,
    videoDevices.map((device) => device.label),
  );

  if (audioDevices.length === 0) {
    throw Error('No audio devices found to stream. Please connect one or re-connect existing one, and retry.');
  }

  if (videoDevices.length === 0) {
    throw Error('No video devices found to stream. Please connect one or re-connect existing one, and retry.');
  }

  return true;
};

/**
 * Ask for webcam video stream
 */
export const getVideoStream = async (videoWidth: number): Promise<MediaStream> => {
  await ensureHasStreamingDevices();
  try {
    const videoStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: { width: videoWidth },
    });
    return videoStream;
  } catch (e) {
    console.error(e);
    if (e.message.includes('Permission denied')) {
      throw Error('Could not get permission to access audio/video devices. Please check you have not blocked access');
    } else if (e.message.includes('Could not start video source')) {
      throw Error('Could not start video streaming. Is another application using it?');
    } else {
      throw e;
    }
  }
};

/**
 * Ask for desktop video stream
 */
export const getDesktopStream = async (): Promise<MediaStream> => {
  try {
    const desktopStream = await navigator.mediaDevices.getDisplayMedia({
      video: {
        frameRate: 15,
        cursor: 'always',
        displaySurface: 'monitor',
      },
    });
    return desktopStream;
  } catch (e) {
    console.error(e);
    if (e.message.includes('Permission denied')) {
      throw Error('Could not get permission to stream desktop. Please allow sharing on prompt');
    } else {
      throw e;
    }
  }
};

/**
 * Ensure we meet streaming requirements (h264 codec is present if forced)
 */
export const isCodecAppropriate = (failIfNot = false): boolean => {
  if (!forceH264Codec || MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
    return true;
  }

  if (failIfNot) {
    throw Error('Your browser does not support the necessary video codec for recording. Please download a recent Chrome-based browser');
  }
  return false;
};
