import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import React, { Component } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import AceEditor from 'react-ace';

import { CodeSittingQuestionDto, SittingQuestionDto } from '../../model';
import { languages, themes } from '../../util/ace';
import { RichTeX, RichTeXEditor } from '../util/RichTeXEditor';
import { QuestionEditorModelProps, QuestionEditorProps } from './QuestionEditorModel';
import CredentialsContext from '../../context/credentials';

/**
 * Extended model props
 */

export class CodeQuestionEditorModelProps extends QuestionEditorModelProps<CodeSittingQuestionDto> {
  questionText: RichTeX;

  constructor(question: CodeSittingQuestionDto, template?: SittingQuestionDto) {
    super(question, template);
    this.questionText = new RichTeX(this.question.questionContent.text);
  }

  refreshQuestion(): void {
    this.question.questionContent.text = this.questionText.getMarkdown();
  }

  fromQuestionTemplate(template: SittingQuestionDto): CodeSittingQuestionDto {
    return {
      id: template.id,
      questionType: 'code',
      questionContent: {
        text: (template.questionContent as { text: string }).text || (template.questionContent as string),
        language: 'text',
      },
    };
  }

  setQuestionText(questionText: RichTeX): void {
    this.questionText = questionText;
    this.questionUpToDate = false;
  }

  setQuestionLanguage(language: string): void {
    this.question.questionContent.language = language;
  }

  setAnswerText(answerText: string): void {
    this.question.response = answerText;
  }
}

/**
 * Alias
 */

export type CodeQuestionEditorProps = QuestionEditorProps<CodeSittingQuestionDto, CodeQuestionEditorModelProps>;

/**
 * Associated components
 */

export default class CodeQuestionEditor extends Component<CodeQuestionEditorProps> {
  constructor(props: CodeQuestionEditorProps) {
    super(props);
    autoBind(this);
  }

  onQuestionTextChange(questionText: RichTeX): void {
    const { model, onChange } = this.props;
    model.setQuestionText(questionText);
    onChange(model);
  }

  onQuestionLanguageChange(language: string): void {
    const { model, onChange } = this.props;
    model.setQuestionLanguage(language);
    onChange(model);
  }

  onAnswerTextChange(answerText: string): void {
    const { model, onChange } = this.props;
    model.setAnswerText(answerText);
    onChange(model);
  }

  render(): JSX.Element {
    const { mode, model, allowFileUpload, onUpload } = this.props;
    const { questionContent, response } = model.question;
    const { language } = questionContent;

    return (
      <>
        <div className="float-right">
          Answer box syntax highlighting:&nbsp;
          {mode === 'edit' && (
            <>
              <DropdownButton
                className="btn-wrapper"
                variant="outline-info"
                as="span"
                title={
                  <>
                    <FontAwesomeIcon icon="language" size="xs" />
                    &nbsp;{languages[language]}
                  </>
                }
              >
                {Object.entries(languages).map(([lang, languagePretty]) => (
                  <Dropdown.Item
                    key={lang}
                    className="dropdown-item"
                    active={lang === language}
                    onClick={() => this.onQuestionLanguageChange(lang)}
                  >
                    {languagePretty}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </>
          )}
          {mode !== 'edit' && (
            <>
              <b>{languages[language]}</b>
            </>
          )}
        </div>

        <span className="question-text">
          <RichTeXEditor
            className="static-editor"
            size="medium"
            value={this.props.model.questionText}
            readOnly={mode !== 'edit'}
            onChange={this.onQuestionTextChange}
            allowFileUpload={allowFileUpload}
            onUpload={onUpload}
          />
        </span>

        {(mode === 'evaluate' || mode === 'response' || mode === 'take') && (
          <>
            <div style={{ paddingBottom: 8 }}>
              <i>Response:</i>
            </div>
            <span className="question-text">
              <AceEditor
                className="inline-code-editor"
                mode={language}
                theme={this.context.darkMode ? themes.dark : themes.light}
                fontSize={16}
                minLines={8}
                maxLines={16}
                placeholder={mode === 'take' ? 'Insert code here' : 'No response'}
                readOnly={mode !== 'take'}
                value={response}
                showPrintMargin={false}
                highlightActiveLine={true}
                onChange={this.onAnswerTextChange}
                editorProps={{ $blockScrolling: true }}
              />
            </span>
          </>
        )}
      </>
    );
  }
}

CodeQuestionEditor.contextType = CredentialsContext;
