import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shortId } from '../../util/random';

type HelpMarkerProps = {
  text: string | object;
  visible?: boolean;
};

export default function HelpMarker({ text, visible = true }: HelpMarkerProps) {
  if (!visible) {
    return null;
  }

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${shortId()}`}>{text}</Tooltip>}>
      <FontAwesomeIcon size="xs" icon="question-circle" />
    </OverlayTrigger>
  );
}
