import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import React, { Component, createRef, RefObject } from 'react';
import BlockUi from 'react-block-ui';
import { Button, ButtonGroup, Form, Modal } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';

import { GroupAffiliationDto, Collection, GroupAffiliationRole, GroupDetailsDto } from '../../model';
import { AffiliationFilterOptions, defaultAffiliationOptions } from '../../service/affiliation';
import { createGroupAffiliation, fetchGroupAffiliations } from '../../service/groupaffiliation';
import { addQueryParameters, parseQuery } from '../../util/queryparser';
import AsyncComponent from '../util/AsyncComponent';
import { blocking } from '../util/decorators';
import FilterBy from '../util/FilterBy';
import Paginator from '../util/Paginator';
import SearchBy from '../util/SearchBy';
import SortBy from '../util/SortBy';
import withErrorScreen from '../util/withErrorScreen';
import AffiliationListEntry from './AffiliationListEntry';

/**
 * E-mail list text modal
 */

type EmailsWithRole = {
  emails: string[];
  role: GroupAffiliationRole;
};

type EmailListModalProps = {
  show: boolean;
  onConfirm: (emails: EmailsWithRole) => void;
  onCancel: () => void;
};

type EmailListModalState = {
  content: string;
  role: GroupAffiliationRole;
};

export class EmailListModal extends Component<EmailListModalProps, EmailListModalState> {
  constructor(props: EmailListModalProps) {
    super(props);

    this.state = {
      content: '',
      role: 'student',
    };

    autoBind(this);
  }

  onConfirm(): void {
    this.props.onConfirm({
      role: this.state.role,
      emails: this.state.content
        .split('\n')
        .map((line) => line.trim())
        .filter(Boolean),
    });
    this.setState({
      content: '',
      role: 'student',
    });
  }

  render(): JSX.Element {
    return (
      <Modal show={this.props.show} onHide={this.props.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Add user(s) to group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Affiliation role</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => this.setState({ role: e.target.value as GroupAffiliationRole })}
              value={this.state.role}
            >
              <option>maintainer</option>
              <option>student</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>E-mail address list (one per line)</Form.Label>
            <Form.Control as="textarea" rows={5} onChange={(e) => this.setState({ content: e.target.value })} value={this.state.content} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button variant="outline-secondary" onClick={this.props.onCancel}>
              <FontAwesomeIcon icon="times" />
              &nbsp;Cancel
            </Button>
            <Button variant="success" onClick={this.onConfirm}>
              <FontAwesomeIcon icon="plus" />
              &nbsp;Add
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    );
  }
}

/**
 * Affiliation list component
 */

type AffiliationListProps = RouteComponentProps & {
  group: GroupDetailsDto;
};

type AffiliationListState = {
  loaded: boolean;
  blocking: boolean;
  affiliations: Collection<GroupAffiliationDto>;
  showAdd: boolean;
  options: AffiliationFilterOptions;
  pageTopRef: RefObject<HTMLDivElement>;
};

class AffiliationList extends AsyncComponent<AffiliationListProps, AffiliationListState> {
  constructor(props: AffiliationListProps) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      affiliations: {
        entities: [],
        count: 0,
      },
      showAdd: false,
      options: {},
      pageTopRef: createRef(),
    };

    autoBind(this);

    this.fetchAffiliations = blocking(this.fetchAffiliations, this);
    this.addUsers = blocking(this.addUsers, this);
  }

  async searchToOptions() {
    const query = parseQuery(this.props.location.search);
    await this.setStateAsync({
      options: {
        userName: query.userName || '',
        role: query.role as GroupAffiliationRole,
        page: Number(query.page) || 1,
        perPage: Number(query.perPage) || 25,
        sortBy: query.sortBy || localStorage.cvqAffiliationsSortBy || 'userName',
        sortDirection: query.sortDirection || localStorage.cvqAffiliationsSortDirection || 'asc',
      },
    });
  }

  async componentDidMount() {
    await this.searchToOptions();
    const search = addQueryParameters(this.props.location.search, this.state.options, defaultAffiliationOptions);
    if (search !== this.props.location.search) {
      this.props.history.replace(search);
    } else {
      await this.fetchAffiliations();
    }
  }

  async componentDidUpdate(prevProps: AffiliationListProps) {
    if (prevProps.location.search !== this.props.location.search) {
      localStorage.cvqAffiliationsSortBy = this.state.options.sortBy;
      localStorage.cvqAffiliationsSortDirection = this.state.options.sortDirection;

      await this.searchToOptions();
      await this.fetchAffiliations();
      if (this.state.pageTopRef.current) {
        this.state.pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onOptionsChange(newOptions: Partial<AffiliationFilterOptions>) {
    const options = {
      ...this.state.options,
      ...newOptions,
    };

    const search = addQueryParameters(this.props.location.search, options, defaultAffiliationOptions);
    if (search !== this.props.location.search) {
      this.props.history.push(search);
    }

    this.setState({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
  }

  async fetchAffiliations() {
    const affiliations = await fetchGroupAffiliations(this.props.group.id, this.state.options);
    await this.setStateAsync({ loaded: true, affiliations });
  }

  async addUsers(emailsWithRole: EmailsWithRole) {
    await this.setStateAsync({ showAdd: false });
    const { emails, role } = emailsWithRole;
    await Promise.all(emails.map((email) => createGroupAffiliation(this.props.group.id, { email, role })));
    await this.fetchAffiliations();
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading affiliations...</div>;
    }

    const { affiliations, options } = this.state;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div className="row">
          <div className="col-md-12 titlebar">
            <div className="float-left">
              <h4>Enrolled users</h4>
            </div>
            <div className="float-right btn-group">
              <Button variant="success" onClick={() => this.setState({ showAdd: true })}>
                <FontAwesomeIcon icon="plus" />
                &nbsp;Add user(s)
              </Button>
            </div>
          </div>
        </div>

        <div ref={this.state.pageTopRef}>
          <div className="row">
            <div className="col-md-12 titlebar">
              <div className="float-left">
                <FilterBy
                  values={options}
                  onChange={this.onOptionsChange}
                  resetPage={true}
                  options={[
                    {
                      name: 'Role',
                      queryKey: 'role',
                      options: [
                        { key: 'all', label: 'All', value: undefined, default: true },
                        { key: 'maintainer', label: 'Only maintainers', value: 'maintainer' },
                        { key: 'student', label: 'Only students', value: 'student' },
                      ],
                    },
                  ]}
                />
                <SortBy
                  options={options}
                  onChange={this.onOptionsChange}
                  keys={{
                    userName: 'User name',
                  }}
                />
                <SearchBy
                  queryKey="userName"
                  term={options.userName}
                  onChange={this.onOptionsChange}
                  placeholder="Search by user name..."
                  resetPage={true}
                />
              </div>
            </div>
          </div>

          {!affiliations.entities.length ? (
            <div className="infomsg">No affiliations to show</div>
          ) : (
            <div>
              <Paginator options={options} count={affiliations.count} onChange={this.onOptionsChange} />

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-hover">
                    <tbody>
                      {affiliations.entities.map((affiliation) => (
                        <tr key={affiliation.id}>
                          <td>
                            <AffiliationListEntry group={this.props.group} affiliation={affiliation} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <Paginator options={options} count={affiliations.count} onChange={this.onOptionsChange} />
            </div>
          )}
        </div>

        <EmailListModal show={this.state.showAdd} onConfirm={this.addUsers} onCancel={() => this.setState({ showAdd: false })} />
      </BlockUi>
    );
  }
}

export default withRouter(withErrorScreen(AffiliationList));
