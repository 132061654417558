import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BlockUi from 'react-block-ui';
import autoBind from 'auto-bind';

import QuestionBankListEntry from './QuestionBankListEntry';
import FormattedTime from '../util/FormattedTime';
import { blocking } from '../util/decorators';
import withErrorScreen from '../util/withErrorScreen';
import AsyncComponent from '../util/AsyncComponent';
import { addQueryParameters, parseQuery } from '../../util/queryparser';
import Paginator from '../util/Paginator';
import SortBy from '../util/SortBy';
import OverlayButton from '../util/OverlayButton';

import { fetchBanks, QuestionBankFilterOptions, updateBank, defaultQuestionBankOptions } from '../../service/questionbank';
import { Collection, ExamDetailsDto, QuestionBankDto } from '../../model';
import storage from '../../util/storage';

type QuestionBankListProps = RouteComponentProps & {
  exam: ExamDetailsDto;
  maintainer: boolean;
};

type QuestionBankListState = {
  loaded: boolean;
  blocking: boolean;
  questionBanks: Collection<QuestionBankDto>;
  options: QuestionBankFilterOptions;
};

class QuestionBankList extends AsyncComponent<QuestionBankListProps, QuestionBankListState> {
  constructor(props: QuestionBankListProps) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      questionBanks: {
        entities: [],
        count: 0,
      },
      options: {},
    };

    autoBind(this);

    this.fetchQuestionBanks = blocking(this.fetchQuestionBanks, this);
    this.onMoveBankUp = blocking(this.onMoveBankUp, this);
    this.onMoveBankDown = blocking(this.onMoveBankDown, this);
  }

  async searchToOptions() {
    const query = parseQuery(this.props.location.search);
    await this.setStateAsync({
      options: {
        page: Number(query.page) || 1,
        perPage: Number(query.perPage) || 25,
        sortBy: storage.questionBankSortBy.get(query.sortBy),
        sortDirection: storage.questionBankSortDirection.get(query.sortDirection),
      },
    });
  }

  async componentDidMount() {
    await this.searchToOptions();
    const search = addQueryParameters(this.props.location.search, this.state.options, defaultQuestionBankOptions);
    if (search !== this.props.location.search) {
      this.props.history.replace(search);
    } else {
      await this.fetchQuestionBanks();
    }
  }

  async componentDidUpdate(prevProps: QuestionBankListProps) {
    if (prevProps.location.search !== this.props.location.search) {
      storage.questionBankSortBy.set(this.state.options.sortBy);
      storage.questionBankSortDirection.set(this.state.options.sortDirection);

      await this.searchToOptions();
      await this.fetchQuestionBanks();
    }
  }

  onOptionsChange(newOptions: Partial<QuestionBankFilterOptions>) {
    const options = {
      ...this.state.options,
      ...newOptions,
    };

    const search = addQueryParameters(this.props.location.search, options, defaultQuestionBankOptions);
    if (search !== this.props.location.search) {
      this.props.history.push(search);
    }

    this.setState({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
  }

  async fetchQuestionBanks() {
    const questionBanks = await fetchBanks(this.props.exam.id, this.state.options);
    this.setState({ loaded: true, questionBanks });
  }

  async onMoveBankUp(bank) {
    await updateBank(this.props.exam.id, bank.id, {
      bankOrder: bank.bankOrder - 1,
    });
    await this.fetchQuestionBanks();
  }

  async onMoveBankDown(bank) {
    await updateBank(this.props.exam.id, bank.id, {
      bankOrder: bank.bankOrder + 1,
    });
    await this.fetchQuestionBanks();
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading question banks...</div>;
    }

    const { maintainer } = this.props;
    const { questionBanks, options } = this.state;

    const header = (
      <div className="row">
        <div className="col-md-12 titlebar">
          <div className="float-left">
            <SortBy
              options={options}
              onChange={this.onOptionsChange}
              keys={{
                bankOrder: 'Bank order',
                name: 'Name',
              }}
            />
          </div>

          <div className="float-right btn-group">
            <OverlayButton visible={maintainer} variant="success" to={`/exams/${this.props.exam.id}/banks/new`}>
              <FontAwesomeIcon icon="plus" />
              &nbsp;Create
            </OverlayButton>
          </div>
        </div>
      </div>
    );

    if (!questionBanks.count) {
      return (
        <div>
          {header}
          <div className="infomsg">No question banks to show</div>
        </div>
      );
    }

    return (
      <div>
        {header}
        <BlockUi tag="div" className="row" blocking={this.state.blocking}>
          <div className="col-md-12">
            <table className="table table-hover">
              <tbody>
                {questionBanks.entities.map((bank) => (
                  <tr key={bank.id}>
                    <td>
                      <QuestionBankListEntry
                        examId={this.props.exam.id}
                        examType={this.props.exam.examType}
                        maintainer={this.props.maintainer}
                        onMoveUp={this.onMoveBankUp}
                        onMoveDown={this.onMoveBankDown}
                        bank={bank}
                        bankCount={questionBanks.count}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <div className="float-left">&nbsp;</div>
                    <div className="float-right btn-group" style={{ textAlign: 'right' }}>
                      <h4>
                        Total points:&nbsp;<b>{this.props.exam.totalPoints}</b>
                        <br />
                        {this.props.exam.examType === 'continuous' && (
                          <>
                            Total time:&nbsp;
                            <b>
                              <FormattedTime value={this.props.exam.totalTime} />
                            </b>
                          </>
                        )}
                      </h4>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <Paginator options={options} count={questionBanks.count} onChange={this.onOptionsChange} />
          </div>
        </BlockUi>
      </div>
    );
  }
}

export default withRouter(withErrorScreen(QuestionBankList));
