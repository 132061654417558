import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

type ModalFooterProps = {
  onLogin?: () => void;
  onLogout: () => void;
};

// const ModalFooter: FunctionComponent<ModalFooterProps> = ({ onLogin, onLogout }: ModalFooterProps) => (
//   <ButtonGroup>
//     <Button variant="success" onClick={onLogin}>
//       <FontAwesomeIcon icon="check" />&nbsp;Re-login
//     </Button>
//     <Button variant="outline-secondary" onClick={onLogout}>
//       <FontAwesomeIcon icon="times" />&nbsp;Logout
//     </Button>
//   </ButtonGroup>
// );

export default class AuthError extends Error {
  loginUrl: unknown;

  constructor(loginUrl: string) {
    super('Authentication error');
    this.name = 'AuthError';
    this.loginUrl = loginUrl;
    this.message = 'Your external credentials have expired, you must re-login to continue';
  }

  getModalFooter(): FunctionComponent<ModalFooterProps> {
    const { loginUrl } = this;
    // eslint-disable-next-line react/prop-types
    return function ModalFooter({ onLogin, onLogout }: ModalFooterProps) {
      return (
        <ButtonGroup>
          <Button
            variant="success"
            onClick={() => {
              if (onLogin) {
                onLogin();
              } else {
                console.log(`Not logged in, redirecting to ${loginUrl}`);
                window.location = loginUrl as Location;
              }
            }}
          >
            <FontAwesomeIcon icon="check" />
            &nbsp;Re-login
          </Button>
          <Button variant="danger" onClick={onLogout}>
            <FontAwesomeIcon icon="times" />
            &nbsp;Logout
          </Button>
        </ButtonGroup>
      );
    };
  }
}
