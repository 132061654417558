import React, { createRef, RefObject } from 'react';
import autoBind from 'auto-bind';
import { RouteComponentProps, withRouter } from 'react-router';
import BlockUi from 'react-block-ui';

import UserList from './users/UserList';
import UserListHeader from './users/UserListHeader';
import AsyncComponent from './util/AsyncComponent';
import { blocking } from './util/decorators';
import withErrorScreen from './util/withErrorScreen';
import { addQueryParameters, parseBool, parseQuery } from '../util/queryparser';
import Paginator from './util/Paginator';
import { defaultUserOptions, UserFilterOptions, fetchUsers, createUser, updateUser, deleteUser } from '../service/user';
import { Collection, UserCreationDto, UserDto, UserUpdateDto } from '../model';
import EditUserModal from './users/EditUserModal';

type UsersProps = RouteComponentProps;

type UsersState = {
  loaded: boolean;
  blocking: boolean;
  users: Collection<UserDto>;
  showEditor: boolean;
  editedUser: UserDto;
  options: UserFilterOptions;
  pageTopRef: RefObject<HTMLDivElement>;
};

class Users extends AsyncComponent<UsersProps, UsersState> {
  constructor(props: UsersProps) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      users: {
        entities: [],
        count: 0,
      },
      showEditor: false,
      editedUser: null,
      options: {},
      pageTopRef: createRef(),
    };

    autoBind(this);

    this.fetchUsers = blocking(this.fetchUsers, this, {
      autoClose: true,
      keepTrying: true,
    });
    this.createUser = blocking(this.createUser, this);
    this.updateUser = blocking(this.updateUser, this);
  }

  async searchToOptions() {
    const query = parseQuery(this.props.location.search);

    await this.setStateAsync({
      options: {
        name: query.name || '',
        admin: parseBool(query.admin),
        page: Number(query.page) || 1,
        perPage: Number(query.perPage) || 25,
        sortBy: query.sortBy || localStorage.cvqUsersSortBy || 'name',
        sortDirection: query.sortDirection || localStorage.cvqUsersSortDirection || 'asc',
      },
    });
  }

  async componentDidMount() {
    await this.searchToOptions();
    const search = addQueryParameters(this.props.location.search, this.state.options, defaultUserOptions);
    if (search !== this.props.location.search) {
      this.props.history.replace(search);
    } else {
      await this.fetchUsers();
    }
  }

  async componentDidUpdate(prevProps: UsersProps) {
    if (prevProps.location.search !== this.props.location.search) {
      localStorage.cvqUsersSortBy = this.state.options.sortBy;
      localStorage.cvqUsersSortDirection = this.state.options.sortDirection;

      await this.searchToOptions();
      await this.fetchUsers();
      if (this.state.pageTopRef.current) {
        this.state.pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onOptionsChange(newOptions: Partial<UserFilterOptions>) {
    const options = {
      ...this.state.options,
      ...newOptions,
    };

    const search = addQueryParameters(this.props.location.search, options, defaultUserOptions);
    if (search !== this.props.location.search) {
      this.props.history.push(search);
    }

    this.setState({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
  }

  async fetchUsers() {
    const users = await fetchUsers(this.state.options);
    await this.setStateAsync({ loaded: true, users });
  }

  async createUser(user: UserCreationDto) {
    await this.setStateAsync({ showEditor: false });
    await createUser(user);
    await this.fetchUsers();
  }

  async updateUser(description: { userId: number; user: UserUpdateDto }) {
    const { userId, user } = description;
    await this.setStateAsync({ showEditor: false });
    await updateUser(userId, user);
    await this.fetchUsers();
  }

  async deleteUser(userId: number) {
    await this.setStateAsync({ showEditor: false });
    await deleteUser(userId);
    await this.fetchUsers();
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading users...</div>;
    }

    const { options, users, showEditor, editedUser } = this.state;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <UserListHeader
          options={options}
          onOptionsChange={this.onOptionsChange}
          onCreate={() => this.setState({ showEditor: true, editedUser: null })}
        />

        <UserList
          users={users.entities}
          onUpdate={(user: UserDto) => this.setState({ showEditor: true, editedUser: user })}
          onDelete={this.deleteUser}
        />

        <Paginator options={options} count={users.count} onChange={this.onOptionsChange} />

        <EditUserModal
          editMode={Boolean(editedUser)}
          show={showEditor}
          user={editedUser}
          onCreate={this.createUser}
          onUpdate={this.updateUser}
          onCancel={() => this.setState({ showEditor: false })}
        />
      </BlockUi>
    );
  }
}

export default withRouter(withErrorScreen(Users));
