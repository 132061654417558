import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import OverlayButton from './OverlayButton';

interface SearchByProps extends RouteComponentProps {
  queryKey: string;
  term?: string;
  onChange: (values: { [key: string]: unknown }) => void;
  placeholder?: string;
  resetPage?: boolean;
}

function SearchBy({ queryKey, term: initialTerm = '', onChange, placeholder = 'Search...', resetPage = false }: SearchByProps) {
  const [term, setTerm] = useState(initialTerm);

  useEffect(() => setTerm(initialTerm), [initialTerm]);

  const onSearchChange = useCallback((event) => setTerm(event.target.value), []);

  const onSearch = useCallback(() => {
    onChange({
      [queryKey]: term,
      page: resetPage ? 1 : undefined,
    });
  }, [onChange, queryKey, resetPage, term]);

  const onClear = useCallback(() => {
    onChange({
      [queryKey]: '',
      page: resetPage ? 1 : undefined,
    });
  }, [onChange, queryKey, resetPage]);

  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      // enter
      if (event.key === 'Enter' || event.keyCode === 13) {
        onSearch();
      }
      if (event.key === 'Escape' || event.keyCode === 27) {
        onClear();
      }
    },
    [onSearch, onClear],
  );

  return (
    <>
      <input className="dynamic-search" type="text" placeholder={placeholder} value={term} onChange={onSearchChange} onKeyUp={onKeyUp} />
      <OverlayButton tooltip="Search" className="close" onClick={onSearch}>
        <FontAwesomeIcon icon="search" size="xs" />
      </OverlayButton>
      &nbsp;
      <OverlayButton tooltip="Clear" className="close" onClick={onClear}>
        <FontAwesomeIcon icon="times" size="xs" />
      </OverlayButton>
      &nbsp;
    </>
  );
}

export default withRouter(SearchBy);
