import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import BlockUi from 'react-block-ui';
import autoBind from 'auto-bind';

import { blocking } from '../util/decorators';
import withErrorScreen from '../util/withErrorScreen';
import AsyncComponent from '../util/AsyncComponent';
import { fetchSittingQuestionReports, SittingQuestionReportFilterOptions } from '../../service/report';
import { ProctoringEntryReducedDto, Collection } from '../../model';
import ReportListEntry from './ReportListEntry';
import Paginator from '../util/Paginator';
import FilterBy from '../util/FilterBy';

type SittingQuestionReportListProps = RouteComponentProps & {
  examId: number;
  sittingId: number;
  sittingQuestionId: number;
  streamingObjectKey: string;
  questionId: number;
  maintainer: boolean;
};

type SittingQuestionReportListState = {
  loaded: boolean;
  blocking: boolean;
  reports: Collection<ProctoringEntryReducedDto>;
  options: SittingQuestionReportFilterOptions;
};

class SittingQuestionReportList extends AsyncComponent<SittingQuestionReportListProps, SittingQuestionReportListState> {
  constructor(props: SittingQuestionReportListProps) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      reports: {
        entities: [],
        count: 0,
      },
      options: {
        page: 1,
        perPage: 10,
        streamingObjectKey: props.streamingObjectKey,
      },
    };

    autoBind(this);

    this.fetchReports = blocking(this.fetchReports, this);
  }

  async componentDidMount() {
    await this.fetchReports();
  }

  async componentDidUpdate(prevProps: SittingQuestionReportListProps, prevState: SittingQuestionReportListState) {
    // props changed - will re-trigger
    if (prevProps.streamingObjectKey !== this.props.streamingObjectKey) {
      this.onOptionsChange({
        streamingObjectKey: this.props.streamingObjectKey,
      });
      return;
    }

    const prevOptions = prevState.options;
    const { options } = this.state;
    if (
      prevOptions.page !== options.page ||
      prevOptions.sortBy !== options.sortBy ||
      prevOptions.sortDirection !== options.sortDirection ||
      prevOptions.severity !== options.severity ||
      prevOptions.streamingObjectKey !== options.streamingObjectKey
    ) {
      await this.fetchReports();
    }
  }

  onOptionsChange(newOptions: Partial<SittingQuestionReportFilterOptions>) {
    this.setState({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
  }

  async fetchReports() {
    const reports = await fetchSittingQuestionReports(
      this.props.examId,
      this.props.sittingId,
      this.props.sittingQuestionId,
      this.state.options,
    );
    await this.setStateAsync({ loaded: true, reports });
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading reports...</div>;
    }

    const { reports, options } = this.state;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div>
          {this.props.maintainer && (
            <div className="row">
              <div className="col-md-12 titlebar">
                <div className="float-left">
                  <FilterBy
                    values={options}
                    onChange={this.onOptionsChange}
                    resetPage={true}
                    options={[
                      {
                        name: 'Severity',
                        queryKey: 'severity',
                        options: [
                          { key: 'all', label: 'All', value: undefined, default: true },
                          { key: 'low', label: 'Low', value: 'low' },
                          { key: 'medium', label: 'Medium', value: 'medium' },
                          { key: 'high', label: 'High', value: 'high' },
                        ],
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}

          {!reports.entities.length ? (
            <div className="infomsg">No reports to show</div>
          ) : (
            <div>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-hover">
                    <tbody>
                      {reports.entities.map((report) => (
                        <tr key={report.id}>
                          <td>
                            <ReportListEntry
                              examId={this.props.examId}
                              sittingId={this.props.sittingId}
                              questionId={this.props.questionId}
                              report={report}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <Paginator options={options} count={reports.count} onChange={this.onOptionsChange} />
            </div>
          )}
        </div>
      </BlockUi>
    );
  }
}

export default withRouter(withErrorScreen(SittingQuestionReportList));
