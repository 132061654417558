import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BlockUi from 'react-block-ui';
import autoBind from 'auto-bind';

import { GroupAffiliationDto, GroupAffiliationRole, GroupDetailsDto } from '../../model';
import OverlayButton from '../util/OverlayButton';
import { DeleteModal } from '../util/Modals';
import { blocking } from '../util/decorators';
import withErrorScreen from '../util/withErrorScreen';
import AsyncComponent from '../util/AsyncComponent';
import { deleteGroupAffiliation, updateGroupAffiliation } from '../../service/groupaffiliation';

type AffiliationListEntryProps = {
  group: GroupDetailsDto;
  affiliation: GroupAffiliationDto;
};

type AffiliationListEntryState = {
  blocking: boolean;
  deleted: boolean;
  showDeleteModal: boolean;
  role: GroupAffiliationRole;
};

class AffiliationListEntry extends AsyncComponent<AffiliationListEntryProps, AffiliationListEntryState> {
  constructor(props: AffiliationListEntryProps) {
    super(props);

    this.state = {
      showDeleteModal: false,
      deleted: false,
      blocking: false,
      role: this.props.affiliation.role,
    };

    autoBind(this);

    this.confirmDeleteAffiliation = blocking(this.confirmDeleteAffiliation, this);
    this.setRole = blocking(this.setRole, this);
  }

  componentDidUpdate(prevProps: AffiliationListEntryProps) {
    if (prevProps.affiliation.role !== this.props.affiliation.role) {
      this.setState({
        role: this.props.affiliation.role,
      });
    }
  }

  proposeDeleteAffiliation() {
    this.setState({ showDeleteModal: true });
  }

  cancelDeleteAffiliation() {
    this.setState({ showDeleteModal: false });
  }

  async confirmDeleteAffiliation() {
    await this.setStateAsync({ showDeleteModal: false });
    await deleteGroupAffiliation(this.props.group.id, this.props.affiliation.user.id);
    this.setState({ deleted: true });
  }

  async setRole(role: GroupAffiliationRole) {
    const affiliation = await updateGroupAffiliation(this.props.group.id, this.props.affiliation.user.id, { role });
    await this.setStateAsync({ role: affiliation.role });
  }

  render() {
    if (this.state.deleted) {
      return <div className="infomsg">This affiliation has been deleted.</div>;
    }

    const { affiliation } = this.props;
    const { role } = this.state;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div className="float-left mr-3">
          <dl>
            <dt>
              <FontAwesomeIcon icon="user-graduate" />
              &nbsp;
              {affiliation.user.name}&nbsp;
            </dt>
            <dd>&nbsp;</dd>
          </dl>
        </div>
        <div className="float-right btn-group">
          {/* change role */}
          <OverlayButton
            variant="outline-warning"
            visible={role === 'student'}
            onClick={() => this.setRole('maintainer')}
            tooltip="Promote to maintainer"
          >
            <FontAwesomeIcon icon="arrow-circle-up" />
          </OverlayButton>
          <OverlayButton
            variant="outline-info"
            visible={role === 'maintainer'}
            onClick={() => this.setRole('student')}
            tooltip="Demote to student"
          >
            <FontAwesomeIcon icon="arrow-circle-down" />
          </OverlayButton>

          {/* delete */}
          <OverlayButton variant="outline-danger" onClick={this.proposeDeleteAffiliation} tooltip="Delete">
            <FontAwesomeIcon icon="trash" />
          </OverlayButton>
        </div>

        <DeleteModal
          show={this.state.showDeleteModal}
          onCancel={this.cancelDeleteAffiliation}
          onConfirm={this.confirmDeleteAffiliation}
          title="Delete affiliation"
        >
          <div>Are you sure you would like to delete this affiliation? The user will not be deleted.</div>
        </DeleteModal>
      </BlockUi>
    );
  }
}

export default withErrorScreen(AffiliationListEntry);
