/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, DropdownButton, Dropdown, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AceEditor from 'react-ace';
import ImageWithFallback from './ImageWithFallback';
import KatexOutput, { katexParse } from './KatexOutput';

import { themes, languages } from '../../util/ace';

import 'katex/dist/katex.css';
import OverlayButton from './OverlayButton';
import CredentialsContext from '../../context/credentials';

/**
 * Delete modal
 */
export class ErrorModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={() => false}>
        <Modal.Header>
          <Modal.Title>{this.props.title || 'An error has occurred'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.children}</Modal.Body>
        <Modal.Footer>&nbsp;</Modal.Footer>
      </Modal>
    );
  }
}

ErrorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.any,
};

/**
 * Confirmation modal
 */
export class ConfirmModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title || 'Confirm'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.children}</Modal.Body>
        <Modal.Footer>
          <div className="btn-group">
            <Button variant="outline-secondary" onClick={this.props.onCancel}>
              <FontAwesomeIcon icon="times" />
              &nbsp;Cancel
            </Button>
            <Button variant="success" onClick={this.props.onConfirm}>
              <FontAwesomeIcon icon="check" />
              &nbsp;Confirm
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.any,
};

/**
 * Delete modal
 */
export class DeleteModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title || 'Delete'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.children}</Modal.Body>
        <Modal.Footer>
          <div className="btn-group">
            <Button variant="outline-secondary" onClick={this.props.onCancel}>
              <FontAwesomeIcon icon="times" />
              &nbsp;Cancel
            </Button>
            <Button variant="danger" onClick={this.props.onConfirm}>
              <FontAwesomeIcon icon="trash" />
              &nbsp;Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

DeleteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.any,
};

/**
 * Image upload
 */
export class ImageUploadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      upload: true,
      file: '',
      filename: '',
      url: '',
      altChanged: false,
    };

    this.onSwitchTab = this.onSwitchTab.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleReaderComplete = this.handleReaderComplete.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.onAltChange = this.onAltChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.reader = new FileReader();
    this.reader.addEventListener('loadend', this.handleReaderComplete);
  }

  onSwitchTab(upload) {
    this.setState({
      upload,
      file: '',
      url: '',
      filename: '',
      altChanged: false,
    });
  }

  handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        file,
        filename: file.name,
      });

      this.reader.readAsDataURL(file);
    }
  }

  handleReaderComplete() {
    this.setState({ url: this.reader.result });
  }

  onConfirm() {
    const { upload, file, filename, url } = this.state;

    if (upload) {
      this.props.onConfirmUpload({
        file,
        filename,
      });
    } else {
      this.props.onConfirmUrl({
        src: url,
        alt: filename,
      });
    }

    this.setState({
      file: '',
      filename: '',
      url: '',
      altChanged: false,
    });
  }

  onUrlChange(event) {
    const url = event.target.value;
    if (this.state.altChanged) {
      this.setState({ url });
    } else {
      const filename = url.split('/').pop().split('#')[0].split('?')[0];
      this.setState({ url, filename });
    }
  }

  onAltChange(event) {
    this.setState({
      filename: event.target.value,
      altChanged: true,
    });
  }

  onCancel() {
    this.setState({
      file: '',
      filename: '',
      url: '',
      altChanged: false,
    });
    this.props.onCancel();
  }

  render() {
    const { upload, filename, url } = this.state;
    const { show, title } = this.props;

    return (
      <Modal className="wide-modal-dialog" show={show} onHide={this.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{title || 'Upload image'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link active={upload} onClick={() => this.onSwitchTab(true)}>
                Upload
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={!upload} onClick={() => this.onSwitchTab(false)}>
                Existing URL
              </Nav.Link>
            </Nav.Item>
          </Nav>

          {url && <ImageWithFallback src={url} alt={filename} />}

          {upload && (
            <>
              <div>
                <input type="file" className="form-control file-input" onChange={this.handleFileChange} accept="image/*" />
              </div>
            </>
          )}

          {!upload && (
            <>
              <form className="modal-form">
                <div className="form-group">
                  <label>Source</label>&nbsp;
                  <input type="url" name="src" value={url} onChange={this.onUrlChange} />
                </div>
                <div className="form-group">
                  <label>Alt</label>&nbsp;
                  <input type="text" name="alt" value={filename} onChange={this.onAltChange} />
                </div>
              </form>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-group">
            <Button variant="outline-secondary" onClick={this.onCancel}>
              <FontAwesomeIcon icon="times" />
              &nbsp;Cancel
            </Button>
            <Button variant="success" onClick={this.onConfirm} disabled={!url || !filename}>
              <FontAwesomeIcon icon="file-import" />
              &nbsp;Upload
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

ImageUploadModal.propTypes = {
  show: PropTypes.bool,
  onConfirmUpload: PropTypes.func.isRequired,
  onConfirmUrl: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
};

/**
 * File upload
 */
export class FileLinkUploadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: '',
      filename: '',
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        file,
        filename: file.name,
      });
    }
  }

  onConfirm() {
    this.props.onConfirm({
      file: this.state.file,
      filename: this.state.filename,
    });
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title || 'Upload new file'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input type="file" className="form-control file-input" onChange={this.handleFileChange} accept="*/*" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-group">
            <Button variant="outline-secondary" onClick={this.props.onCancel}>
              <FontAwesomeIcon icon="times" />
              &nbsp;Cancel
            </Button>
            <Button variant="success" onClick={this.onConfirm} disabled={!this.state.file}>
              <FontAwesomeIcon icon="file-import" />
              &nbsp;Upload
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

FileLinkUploadModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
};

/**
 * URL editing
 */
export class LinkEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url || '',
      placeholder: this.props.placeholder || '',
      placeholderChanged: Boolean(this.props.placeholder),
    };

    this.onConfirm = this.onConfirm.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.onPlaceholderChange = this.onPlaceholderChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url || prevProps.placeholder !== this.props.placeholder) {
      this.setState({
        url: this.props.url || '',
        placeholder: this.props.placeholder || '',
        placeholderChanged: Boolean(this.props.placeholder),
      });
    }
  }

  onConfirm() {
    this.props.onConfirm({
      url: this.state.url,
      placeholder: this.state.placeholder,
    });
    this.setState({
      placeholderChanged: false,
    });
  }

  onUrlChange(event) {
    const url = event.target.value;
    if (this.state.placeholderChanged) {
      this.setState({ url });
    } else {
      const placeholder = url.split('/').pop().split('#')[0].split('?')[0];
      this.setState({ url, placeholder });
    }
  }

  onPlaceholderChange(event) {
    this.setState({
      placeholder: event.target.value,
      placeholderChanged: true,
    });
  }

  onCancel() {
    this.setState({
      url: this.props.url || '',
      placeholder: this.props.placeholder || '',
      placeholderChanged: false,
    });
    this.props.onCancel();
  }

  render() {
    const { url, placeholder } = this.state;
    const { show, onRemove, title } = this.props;

    return (
      <Modal show={show} onHide={this.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{title || 'URL'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="modal-form">
            <div className="form-group">
              <label>URL</label>&nbsp;
              <input type="url" name="url" value={url} onChange={this.onUrlChange} />
            </div>
            <div className="form-group">
              <label>Placeholder</label>&nbsp;
              <input type="text" name="placeholder" value={placeholder} onChange={this.onPlaceholderChange} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-group">
            <OverlayButton
              variant="success"
              onClick={this.onConfirm}
              disabled={!url || !placeholder}
              disabledTooltip="Fields must not be empty"
            >
              <FontAwesomeIcon icon="check" />
              &nbsp;Save
            </OverlayButton>
            <Button variant="outline-secondary" onClick={this.onCancel}>
              <FontAwesomeIcon icon="times" />
              &nbsp;Cancel
            </Button>
            {onRemove && (
              <>
                <Button variant="danger" onClick={onRemove}>
                  <FontAwesomeIcon icon="trash" />
                  &nbsp;Delete
                </Button>
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

LinkEditorModal.propTypes = {
  url: PropTypes.string,
  placeholder: PropTypes.string,
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  title: PropTypes.string,
};

/**
 * Image source URL editing
 */
export class ImageSourceEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: this.props.src || '',
      alt: this.props.alt || '',
    };

    this.onConfirm = this.onConfirm.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src || prevProps.alt !== this.props.alt) {
      this.setState({
        src: this.props.src || '',
        alt: this.props.alt || '',
      });
    }
  }

  onConfirm() {
    this.props.onConfirm({
      src: this.state.src,
      alt: this.state.alt,
    });
  }

  render() {
    const { src, alt } = this.state;
    const { show, onCancel, onRemove, title } = this.props;

    return (
      <Modal className="wide-modal-dialog" show={show} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{title || 'Image URL'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {src && <ImageWithFallback src={src} alt={alt} />}

          <form className="modal-form">
            <div className="form-group">
              <label>Source</label>&nbsp;
              <input type="url" name="src" value={src} onChange={(event) => this.setState({ src: event.target.value })} />
            </div>
            <div className="form-group">
              <label>Alt</label>&nbsp;
              <input type="text" name="alt" value={alt} onChange={(event) => this.setState({ alt: event.target.value })} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-group">
            <OverlayButton variant="success" onClick={this.onConfirm} disabled={!src} disabledTooltip="Source must not be empty">
              <FontAwesomeIcon icon="check" />
              &nbsp;Save
            </OverlayButton>
            <Button variant="outline-secondary" onClick={onCancel}>
              <FontAwesomeIcon icon="times" />
              &nbsp;Cancel
            </Button>
            {onRemove && (
              <>
                <Button variant="danger" onClick={onRemove}>
                  <FontAwesomeIcon icon="trash" />
                  &nbsp;Delete
                </Button>
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

ImageSourceEditorModal.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  title: PropTypes.string,
};

/**
 * Upload of JSON file with reading
 */
export class JsonUploadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: '',
      filename: '',
      content: '',
      error: '',
      enableUploadButton: false,
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleReaderComplete = this.handleReaderComplete.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onError = this.onError.bind(this);

    this.reader = new FileReader();
    this.reader.addEventListener('loadend', this.handleReaderComplete);
    this.reader.addEventListener('error', this.onError);
  }

  handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        file,
        filename: file.name,
        error: '',
      });

      this.reader.readAsText(file);
    }
  }

  handleReaderComplete() {
    const content = this.reader.result;

    try {
      this.setState({ content: JSON.parse(content), error: '', enableUploadButton: true });
    } catch (e) {
      this.setState({ error: 'Could not parse JSON', enableUploadButton: false });
    }
  }

  onConfirm() {
    this.props.onConfirm({
      file: this.state.file,
      filename: this.state.filename,
      content: this.state.content,
    });
  }

  onError(error) {
    console.error(error);
    this.setState({ error: error.toString(), enableUploadButton: false });
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title || 'Upload new file'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input type="file" className="form-control file-input" onChange={this.handleFileChange} accept="application/json" />
          </div>
          <div className="upload-error">{this.state.error}</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-group">
            <Button variant="outline-secondary" onClick={this.props.onCancel}>
              <FontAwesomeIcon icon="times" />
              &nbsp;Cancel
            </Button>
            <Button variant="success" onClick={this.onConfirm} disabled={!this.state.enableUploadButton}>
              <FontAwesomeIcon icon="file-import" />
              &nbsp;Upload
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

JsonUploadModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
};

/**
 * Code editor modal
 */
export class CodeEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: this.props.language || 'text',
      text: this.props.text || '',
    };

    this.onConfirm = this.onConfirm.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text || prevProps.language !== this.props.language) {
      this.setState({
        language: this.props.language || 'text',
        text: this.props.text || '',
      });
    }
  }

  onConfirm() {
    this.props.onConfirm({
      language: this.state.language,
      text: this.state.text,
    });
  }

  render() {
    const { language, text } = this.state;
    const { show, onCancel, onRemove, title } = this.props;

    return (
      <>
        <Modal className="wide-modal-dialog" show={show} onHide={onCancel}>
          <Modal.Header closeButton>
            <Modal.Title>{title || 'Code snippet'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AceEditor
              className="inline-code-editor"
              mode={language}
              theme={this.context.darkMode ? themes.dark : themes.light}
              fontSize={16}
              minLines={8}
              maxLines={16}
              placeholder="Insert code here"
              value={text}
              showPrintMargin={false}
              highlightActiveLine={true}
              onFocus={this.onClick}
              onChange={(newText) => this.setState({ text: newText })}
              editorProps={{ $blockScrolling: true }}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-group">
              <DropdownButton
                className="btn-wrapper"
                variant="outline-info"
                as="span"
                title={
                  <>
                    <FontAwesomeIcon icon="language" size="xs" />
                    &nbsp;{languages[language]}
                  </>
                }
              >
                {Object.entries(languages).map(([lang, languagePretty]) => (
                  <Dropdown.Item
                    key={lang}
                    className="dropdown-item"
                    active={lang === language}
                    onClick={() => this.setState({ language: lang })}
                  >
                    {languagePretty}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <OverlayButton variant="success" onClick={this.onConfirm} disabled={!text} disabledTooltip="Code must not be empty">
                <FontAwesomeIcon icon="check" />
                &nbsp;Save
              </OverlayButton>
              <Button variant="outline-secondary" onClick={onCancel}>
                <FontAwesomeIcon icon="times" />
                &nbsp;Cancel
              </Button>
              {onRemove && (
                <>
                  <Button variant="danger" onClick={onRemove}>
                    <FontAwesomeIcon icon="trash" />
                    &nbsp;Delete
                  </Button>
                </>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

CodeEditorModal.contextType = CredentialsContext;

CodeEditorModal.propTypes = {
  language: PropTypes.string,
  text: PropTypes.string,
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  title: PropTypes.string,
};

/**
 * Code editor modal
 */
export class TeXEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      texText: this.props.texText || '',
      invalid: false,
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.texText !== this.props.texText) {
      this.onValueChange(this.props.texText);
    }
  }

  onValueChange(text) {
    if (!text) {
      this.setState({ invalid: true, texText: '' });
    } else {
      const { invalid, texText } = katexParse(text);
      this.setState({ invalid, texText });
    }
  }

  onConfirm() {
    this.props.onConfirm({
      texText: this.state.texText,
    });
  }

  render() {
    const { texText, invalid } = this.state;
    const { show, onCancel, onRemove, title } = this.props;

    return (
      <>
        <Modal className="wide-modal-dialog" show={show} onHide={onCancel}>
          <Modal.Header closeButton>
            <Modal.Title>{title || 'TeX code'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!invalid && <KatexOutput texText={texText} />}
            <AceEditor
              className="inline-code-editor"
              mode="latex"
              theme={this.context.darkMode ? themes.dark : themes.light}
              fontSize={16}
              minLines={8}
              maxLines={16}
              placeholder="Insert LaTeX script here"
              value={texText}
              showPrintMargin={false}
              highlightActiveLine={true}
              editorProps={{ $blockScrolling: true }}
              onChange={this.onValueChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-group">
              <OverlayButton variant="success" onClick={this.onConfirm} disabled={invalid} disabledTooltip="TeX code invalid">
                <FontAwesomeIcon icon="check" />
                &nbsp;Save
              </OverlayButton>
              <Button variant="outline-secondary" onClick={onCancel}>
                <FontAwesomeIcon icon="times" />
                &nbsp;Cancel
              </Button>
              {onRemove && (
                <>
                  <Button variant="danger" onClick={onRemove}>
                    <FontAwesomeIcon icon="trash" />
                    &nbsp;Delete
                  </Button>
                </>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

TeXEditorModal.contextType = CredentialsContext;

TeXEditorModal.propTypes = {
  texText: PropTypes.string,
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  title: PropTypes.string,
};
