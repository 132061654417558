import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BlockUi from 'react-block-ui';

class SittingDetailsPanel extends Component {
  render() {
    const { sitting } = this.props;
    const finished = Boolean(sitting.finishTime);

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="jumbotron mini-jumbotron">
            <dl className="examdescription">
              <dt>Sitting state</dt>
              <dd>
                <FontAwesomeIcon icon="question" />
                <span>&nbsp;{sitting.state}</span>
              </dd>
              <dt>Start time</dt>
              <dd>
                <FontAwesomeIcon icon="calendar" />
                <span>&nbsp;{new Date(sitting.startTime).toLocaleString()}</span>
              </dd>
              <dt>Finish time</dt>
              <dd>
                <FontAwesomeIcon icon="calendar" />
                &nbsp;
                {finished ? <span>{new Date(sitting.finishTime).toLocaleString()}</span> : <i>Not finished yet...</i>}
              </dd>
              <dt>Student</dt>
              <dd>
                <FontAwesomeIcon icon="user-graduate" />
                <span>
                  &nbsp;<span className="likeALink">{sitting.studentName || <i>Deleted User</i>}</span>
                </span>
              </dd>
              <dt>Recorded questions</dt>
              <dd>
                <FontAwesomeIcon icon="hashtag" />
                <span>&nbsp;{sitting.numQuestions}</span>
                <span>&nbsp;/&nbsp;{sitting.totalQuestions}</span>
              </dd>
              {!sitting.simulated && (
                <>
                  <dt>Points released to student</dt>
                  <dd>
                    <FontAwesomeIcon icon="eye" />
                    &nbsp;
                    <input type="checkbox" checked={sitting.pointsReleased} disabled />
                    &nbsp;
                  </dd>
                </>
              )}
              <dt>Total points</dt>
              {sitting.maintainer ? (
                <BlockUi tag="dd" blocking={this.props.blocking}>
                  <FontAwesomeIcon icon="trophy" />
                  <span>&nbsp;{sitting.totalPoints}</span>
                  <span>&nbsp;/&nbsp;{sitting.totalPossiblePoints}</span>
                </BlockUi>
              ) : (
                <dd>
                  <FontAwesomeIcon icon="trophy" />
                  <span>
                    &nbsp;
                    {sitting.pointsReleased ? sitting.totalPoints : <i>Not graded yet...</i>}
                  </span>
                  <span>&nbsp;/&nbsp;{sitting.totalPossiblePoints}</span>
                </dd>
              )}
            </dl>
          </div>
        </div>
      </div>
    );
  }
}

SittingDetailsPanel.propTypes = {
  sitting: PropTypes.object.isRequired,
  blocking: PropTypes.bool.isRequired,
};

export default SittingDetailsPanel;
