import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import CredentialsContext, { CredentialsContextType } from '../context/credentials';
import OverlayButton from './util/OverlayButton';
import Account from './Account';

type NavBarProps = {
  darkMode: boolean;
  onToggleDarkMode: () => Promise<void>;
  onLogout: () => Promise<void>;
};

export default function NavBar({ darkMode, onToggleDarkMode, onLogout }: NavBarProps) {
  const { userName, admin, canCreateExam } = useContext<CredentialsContextType>(CredentialsContext);

  const isAdmin = userName && admin;
  const isMaintainer = userName && canCreateExam;

  return (
    <div className="row">
      <div className="col-md-12">
        <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
          <Navbar.Brand>
            <Link className="navbar-brand" to="/">
              <FontAwesomeIcon icon="file-video" />
              &nbsp;NRand
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {(isAdmin || isMaintainer) && (
                <>
                  <LinkContainer to="/exams/">
                    <Nav.Link active={false}>Exams</Nav.Link>
                  </LinkContainer>
                </>
              )}
              {(isAdmin || isMaintainer) && (
                <>
                  <LinkContainer to="/groups/">
                    <Nav.Link active={false}>Groups</Nav.Link>
                  </LinkContainer>
                </>
              )}
              {isAdmin && (
                <>
                  <LinkContainer to="/users/">
                    <Nav.Link active={false}>Users</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
            <Nav>
              <Nav.Item>
                <OverlayButton
                  variant="secondary"
                  tooltipPlacement="bottom"
                  tooltip="Toggle dark mode"
                  onClick={onToggleDarkMode}
                  buttonStyle={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
                >
                  <FontAwesomeIcon icon={darkMode ? 'sun' : 'moon'} />
                </OverlayButton>
              </Nav.Item>
              {userName && (
                <>
                  <Nav.Item>
                    <Account></Account>
                  </Nav.Item>
                  <Nav.Item>
                    <Button variant="danger" onClick={onLogout}>
                      <FontAwesomeIcon icon="sign-out-alt" />
                      &nbsp;Logout
                    </Button>
                  </Nav.Item>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}
