import autoBind from 'auto-bind';
import React from 'react';
import BlockUi from 'react-block-ui';
import { Button } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';

import CredentialsContext from '../context/credentials';
import { GroupCreationDto, SyncFileUploadResult } from '../model';
import { moveToGroupResource, uploadGroupResource, uploadUserResource } from '../service/filestorage';
import { createGroup, fetchGroupById, updateGroup } from '../service/group';
import AsyncComponent from './util/AsyncComponent';
import { blocking } from './util/decorators';
import { RichTeX, RichTeXEditor } from './util/RichTeXEditor';
import withBreadcrumbs, { WithBreadcrumbsProps } from './util/withBreadcrumbs';
import withErrorScreen from './util/withErrorScreen';

type EditGroupPathParams = {
  groupId: string;
};

type EditGroupProps = WithBreadcrumbsProps & RouteComponentProps<EditGroupPathParams>;

type EditGroupState = {
  editMode: boolean;
  newGroup: GroupCreationDto;
  pendingResources: SyncFileUploadResult[];
  descriptionEditor: RichTeX;
  blocking: boolean;
};

class EditGroup extends AsyncComponent<EditGroupProps, EditGroupState> {
  constructor(props: EditGroupProps) {
    super(props);

    this.state = {
      editMode: 'groupId' in props.match.params,
      newGroup: {
        name: 'New group',
        description: 'Enter description here',
      },
      pendingResources: [],
      descriptionEditor: new RichTeX('Enter description here'),
      blocking: false,
    };

    autoBind(this);

    this.fetchGroup = blocking(this.fetchGroup, this, {
      autoClose: true,
      keepTrying: true,
    });
    this.createGroup = blocking(this.createGroup, this);
    this.updateGroup = blocking(this.updateGroup, this);
    this.onUpload = blocking(this.onUpload, this);
  }

  async componentDidMount() {
    if (this.state.editMode) {
      await this.fetchGroup();
    } else {
      this.props.setBreadcrumbs([
        { path: '/groups/', text: 'Groups' },
        { path: '/groups/new', text: 'New group' },
      ]);
    }
  }

  async fetchGroup() {
    const groupId = Number(this.props.match.params.groupId);
    const group = await fetchGroupById(groupId);

    await this.setStateAsync({
      newGroup: group,
      descriptionEditor: new RichTeX(group.description),
    });

    this.props.setBreadcrumbs([
      { path: '/groups/', text: 'Groups' },
      { path: `/groups/${group.id}/`, text: group.name },
      { path: `/groups/${group.id}/edit`, text: 'Edit' },
    ]);
  }

  async createGroup() {
    await this.setStateAsync({
      newGroup: {
        ...this.state.newGroup,
        description: this.state.descriptionEditor.getMarkdown(),
      },
    });
    const group = await createGroup(this.state.newGroup);

    // check if any temp files were created, and move them to group space
    if (this.state.pendingResources.length > 0) {
      // move files on storage
      const moveUrlMappings = await Promise.all(
        this.state.pendingResources.map(async (upload) => {
          const newUpload = await moveToGroupResource(upload.location, group.id);
          return [upload.url, newUpload.url];
        }),
      );

      // change description text to reflect new URLs
      this.state.descriptionEditor.setLinksByMapping(moveUrlMappings);

      // update on backend
      await updateGroup(group.id, {
        description: this.state.descriptionEditor.getMarkdown(),
      });

      // clear pending resources
      await this.setStateAsync({
        pendingResources: [],
      });
    }

    // redirect to newly created group page
    this.props.history.push(`/groups/${group.id}/`);
  }

  async updateGroup() {
    const groupId = Number(this.props.match.params.groupId);
    await this.setStateAsync({
      newGroup: {
        ...this.state.newGroup,
        description: this.state.descriptionEditor.getMarkdown(),
      },
    });
    await updateGroup(groupId, this.state.newGroup);
    this.props.history.push(`/groups/${groupId}/`);
  }

  onValueChange(event) {
    const { target } = event;
    const value = event.target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;

    this.setState({
      newGroup: {
        ...this.state.newGroup,
        [key]: value,
      },
    });
  }

  onDescriptionChange(descriptionEditor) {
    this.setState({ descriptionEditor });
  }

  onValuesChanged(newValues) {
    this.setState({
      newGroup: {
        ...this.state.newGroup,
        ...newValues,
      },
    });
  }

  async onUpload({ filename, file }) {
    if (this.state.editMode) {
      const groupId = Number(this.props.match.params.groupId);
      return uploadGroupResource(groupId, filename, file);
    }

    // group not created yet, cache created URLs and move upon create
    const { userId, userName } = this.context;
    const upload = await uploadUserResource(userId, userName, filename, file);
    await this.setStateAsync({
      pendingResources: [...this.state.pendingResources, upload],
    });
    return upload;
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.state.editMode) {
      this.updateGroup();
    } else {
      this.createGroup();
    }
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    const { newGroup } = this.state;

    return (
      <BlockUi tag="form" onSubmit={this.onSubmit} blocking={this.state.blocking}>
        {/* header */}
        <div className="form-row">
          <div className="form-group col-md-12 titlebar">
            <h3>
              <label htmlFor="newGroupName">{this.state.editMode ? 'Group name' : 'New group name'}</label>
              <input
                type="text"
                name="name"
                className="form-control"
                id="newGroupName"
                maxLength={256}
                placeholder="Name"
                required
                value={newGroup.name}
                onChange={this.onValueChange}
              />
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="jumbotron">
              <div className="description">
                <dt>
                  <label htmlFor="newGroupDescription">Description</label>
                </dt>
                <RichTeXEditor
                  value={this.state.descriptionEditor}
                  onChange={this.onDescriptionChange}
                  allowFileUpload={true}
                  onUpload={this.onUpload}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="float-right">
              <Button className="float-right" variant="success" type="submit">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </BlockUi>
    );
  }
}

EditGroup.contextType = CredentialsContext;

export default withRouter(withErrorScreen(withBreadcrumbs(EditGroup)));
