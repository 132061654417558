/* eslint-disable no-param-reassign */
import React, { createRef, RefObject, SyntheticEvent } from 'react';
import autoBind from 'auto-bind';
import { isEqual } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fileStorageHttpUrl } from '../../util/config';
import AsyncComponent from './AsyncComponent';
import { secondsToTimeText } from '../../util/date';
import { shortId } from '../../util/random';
import { resourceExists } from '../../service/filestorage';
import { AnswerStartPoint } from '../../model';
import { StreamDescription } from '../../model/filestorage';

type VideoPlayerProps = {
  streamDescription: StreamDescription;
  streamingPath: string;
  answerStartPoint?: AnswerStartPoint;
  answerStartTime?: number;
  skipTime?: number;
  onVideoLoaded?: () => void;
};

type VideoPlayerState = {
  id: string;
  widthRatio: number;
  videoLoaded: boolean;
  desktopLoaded: boolean;
  videoMissing: boolean;
  loadingError: boolean;
  playbackRate: number;
  componentRef: RefObject<HTMLDivElement>;
};

class VideoPlayer extends AsyncComponent<VideoPlayerProps, VideoPlayerState> {
  cameraVideo: HTMLVideoElement;
  desktopVideo: HTMLVideoElement;

  constructor(props: VideoPlayerProps) {
    super(props);

    this.state = {
      id: shortId(),
      widthRatio: Number(localStorage.cvqVideoWidthRatio || 0.5),
      videoLoaded: false,
      desktopLoaded: false,
      videoMissing: false,
      loadingError: false,
      playbackRate: 1.0,
      componentRef: createRef(),
    };

    autoBind(this);
  }

  componentDidMount(): void {
    this.checkVideosExist();
  }

  componentDidUpdate(prevProps: VideoPlayerProps): void {
    if (!isEqual(prevProps.streamDescription, this.props.streamDescription)) {
      this.checkVideosExist();
    }
    if (this.state.videoLoaded && prevProps.skipTime !== this.props.skipTime) {
      this.jumpToSkipTime();
    }
  }

  async checkVideosExist(): Promise<void> {
    const { streamDescription, streamingPath } = this.props;
    const webcamExists =
      Boolean(streamDescription.webcam) &&
      Boolean(streamDescription.webcam.fileName) &&
      (await resourceExists(`${streamingPath}/${streamDescription.webcam.fileName}`));
    const desktopExists =
      Boolean(streamDescription.webcam) &&
      Boolean(streamDescription.webcam.fileName) &&
      (await resourceExists(`${streamingPath}/${streamDescription.desktop.fileName}`));

    this.setState({ videoMissing: !webcamExists || !desktopExists });
  }

  connectPlayers(): void {
    [
      [this.cameraVideo, this.desktopVideo],
      [this.desktopVideo, this.cameraVideo],
    ].forEach(([elemA, elemB]) => {
      elemA.onplay = () => {
        if (elemB.paused) {
          elemB.play();
        }
      };
      elemA.onpause = () => {
        if (!elemB.paused) {
          elemB.pause();
        }
      };
      elemA.onratechange = () => {
        if (Math.abs(elemB.playbackRate - elemA.playbackRate) > 0.0001) {
          elemB.playbackRate = elemA.playbackRate;
        }
      };
      elemA.onseeked = () => {
        if (Math.abs(elemB.currentTime - elemA.currentTime) > 0.5) {
          elemB.currentTime = elemA.currentTime;
        }
      };
    });
  }

  async ensureDuration(video: HTMLVideoElement): Promise<void> {
    if (Number.isFinite(video.duration)) {
      await video.play();
      return;
    }

    const originalTime = video.currentTime;
    const originalMuted = video.muted;
    video.muted = true;
    video.controls = false;
    video.style.opacity = '0.25';

    while (!Number.isFinite(video.duration) && video.currentTime < 1000000) {
      video.currentTime += 10000;
      try {
        // eslint-disable-next-line no-await-in-loop
        await video.play();
        // eslint-disable-next-line no-await-in-loop
        await video.pause();
      } catch (e) {
        // expected exception
      }
    }
    console.log(`Deduced dynamic video length ${video.duration}`);

    video.currentTime = originalTime;

    await video.play();

    video.style.opacity = '1';
    video.controls = true;
    video.muted = originalMuted;
  }

  async configureVideo(event: SyntheticEvent<HTMLVideoElement>): Promise<void> {
    this.cameraVideo = document.getElementById(`camera-video-${this.state.id}`) as HTMLVideoElement;
    this.desktopVideo = document.getElementById(`desktop-video-${this.state.id}`) as HTMLVideoElement;

    const video = event.target as HTMLVideoElement;
    if (video.id.startsWith('camera-video')) {
      await this.setStateAsync({ videoLoaded: true });
    } else {
      await this.setStateAsync({ desktopLoaded: true });
    }

    if (this.state.videoLoaded && this.state.desktopLoaded) {
      if (this.props.onVideoLoaded) {
        this.props.onVideoLoaded();
      }

      const onfirstplay = async () => {
        this.cameraVideo.onplay = null;
        this.desktopVideo.onplay = null;
        await Promise.all([this.ensureDuration(this.cameraVideo), this.ensureDuration(this.desktopVideo)]);
        this.connectPlayers();
        if (this.props.skipTime >= 0) {
          this.cameraVideo.currentTime = this.props.skipTime;
          this.desktopVideo.currentTime = this.props.skipTime;
        }
      };
      this.cameraVideo.onplay = onfirstplay;
      this.desktopVideo.onplay = onfirstplay;

      if (this.props.skipTime >= 0) {
        this.cameraVideo.play();
      }
    }
  }

  jumpToAnswerStart(): void {
    this.onPlaybackRateChange(1.0);
    this.cameraVideo.currentTime = this.props.answerStartTime;
    this.desktopVideo.currentTime = this.props.answerStartTime;
    this.cameraVideo.play();
  }

  jumpToSkipTime(): void {
    if (this.props.skipTime !== undefined) {
      this.onPlaybackRateChange(1.0);
      this.cameraVideo.currentTime = this.props.skipTime;
      this.desktopVideo.currentTime = this.props.skipTime;
      this.cameraVideo.play();
    }
  }

  onPlaybackRateChange(playbackRate: number): void {
    this.setState({ playbackRate });
    this.cameraVideo.playbackRate = playbackRate;
    this.desktopVideo.playbackRate = playbackRate;
  }

  onWidthRatioChange(widthRatio: number): void {
    this.setState({ widthRatio });
    localStorage.cvqVideoWidthRatio = widthRatio;
  }

  scrollIntoView(): void {
    if (this.state.componentRef.current) {
      this.state.componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render(): JSX.Element {
    const { streamDescription, streamingPath, answerStartPoint, answerStartTime } = this.props;
    const { videoMissing, loadingError, id, videoLoaded, desktopLoaded, playbackRate, widthRatio } = this.state;

    const webcamSrc = streamDescription.webcam && `${fileStorageHttpUrl}/${streamingPath}/${streamDescription.webcam.fileName}`;
    const desktopSrc = streamDescription.desktop && `${fileStorageHttpUrl}/${streamingPath}/${streamDescription.desktop.fileName}`;

    let webcamWidth = '100%';
    let desktopWidth = '0%';
    if (streamDescription.desktop && streamDescription.desktop.fileName) {
      webcamWidth = `${widthRatio * 100}%`;
      desktopWidth = `${(1 - widthRatio) * 100}%`;
    }

    return (
      <div className="video-container" ref={this.state.componentRef}>
        <div>
          {!loadingError && streamDescription.desktop && streamDescription.desktop.fileName && (
            <>
              <span>
                <label htmlFor={`video-width-ratio-${id}`}>Width ratio</label>
                <input
                  type="range"
                  className="custom-range"
                  id={`playback-rate-${id}`}
                  min={0.2}
                  max={0.8}
                  step={0.01}
                  value={widthRatio}
                  onChange={(event) => this.onWidthRatioChange(Number(event.target.value))}
                  onDoubleClick={() => this.onWidthRatioChange(0.5)}
                />
              </span>
            </>
          )}

          {/* video not found */}
          {videoMissing && (
            <>
              <span style={{ color: '#a0a010', display: 'inline-block', fontStyle: 'italic', fontSize: '90%', margin: 12 }}>
                One or both recorded videos not found.
              </span>
            </>
          )}

          {/* video wrong encoding */}
          {!videoMissing && loadingError && (
            <>
              <span style={{ color: '#a01010', display: 'inline-block', fontStyle: 'italic', fontSize: '90%', margin: 12 }}>
                Videos exist but <b>could not be decoded</b>, might be under processing. Please wait, or use download links below.
                <br />
                <a href={webcamSrc} download>
                  <FontAwesomeIcon icon="download" />
                  &nbsp;Download webcam video
                </a>
                <br />
                <a href={desktopSrc} download>
                  <FontAwesomeIcon icon="download" />
                  &nbsp;Download desktop file
                </a>
              </span>
            </>
          )}

          {/* actual video */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              margin: 0,
              flexFlow: 'row wrap',
              justifyContent: 'space-around',
              alignItems: 'stretch',
            }}
          >
            <div style={{ flexBasis: webcamWidth, alignSelf: 'center', padding: 5, minWidth: 480 * widthRatio }}>
              <video
                id={`camera-video-${id}`}
                onLoadedMetadata={this.configureVideo}
                onError={() => this.setState({ loadingError: true })}
                controls
                style={{ display: videoLoaded ? 'inline-block' : 'none', cursor: 'pointer', width: '100%' }}
              >
                <source src={webcamSrc} />
                Your browser does not support HTML5 media elements.
              </video>
            </div>
            {streamDescription.desktop && streamDescription.desktop.fileName && (
              <>
                <div style={{ flexBasis: desktopWidth, alignSelf: 'center', padding: 5, minWidth: 480 * (1 - widthRatio) }}>
                  <video
                    id={`desktop-video-${id}`}
                    onLoadedMetadata={this.configureVideo}
                    onError={() => this.setState({ loadingError: true })}
                    controls
                    style={{ display: desktopLoaded ? 'inline-block' : 'none', cursor: 'pointer', width: '100%' }}
                  >
                    <source src={desktopSrc} />
                    Your browser does not support HTML5 media elements.
                  </video>
                </div>
              </>
            )}
          </div>
        </div>

        {!loadingError && (
          <div>
            {/* playback rate setting */}
            <span>
              <label htmlFor={`playback-rate-${id}`}>
                Playback rate (<i>{playbackRate.toFixed(1)}</i>)
              </label>
              <input
                type="range"
                className="custom-range"
                id={`playback-rate-${id}`}
                min={0.5}
                max={4.0}
                step={0.5}
                value={playbackRate}
                onChange={(event) => this.onPlaybackRateChange(Number(event.target.value))}
                onDoubleClick={() => this.onPlaybackRateChange(1.0)}
              />
            </span>

            {/* start time label */}
            {answerStartPoint !== 'unavailable' && (
              <>
                {answerStartTime ? (
                  <span>
                    Answer starts at{' '}
                    <i>
                      <span className="likeALink" onClick={this.jumpToAnswerStart}>
                        {secondsToTimeText(answerStartTime)} (skip there)
                      </span>
                    </i>
                  </span>
                ) : (
                  <span>
                    <i>Answer start point not set</i>
                  </span>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default VideoPlayer;
