import React, { createRef, RefObject } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import BlockUi from 'react-block-ui';
import autoBind from 'auto-bind';

import { blocking } from '../util/decorators';
import withErrorScreen from '../util/withErrorScreen';
import AsyncComponent from '../util/AsyncComponent';
import { defaultExamReportFilterOptions, ExamReportFilterOptions, fetchExamReports } from '../../service/report';
import { ProctoringEntryDetailsDto, Collection } from '../../model';
import ReportListEntry from './ReportListEntry';
import Paginator from '../util/Paginator';
import { addQueryParameters, parseQuery } from '../../util/queryparser';
import SortBy from '../util/SortBy';
import SearchBy from '../util/SearchBy';
import FilterBy from '../util/FilterBy';

type ExamReportListProps = RouteComponentProps & {
  examId: number;
  maintainer: boolean;
};

type ExamReportListState = {
  loaded: boolean;
  blocking: boolean;
  reports: Collection<ProctoringEntryDetailsDto>;
  options: ExamReportFilterOptions;
  pageTopRef: RefObject<HTMLDivElement>;
};

class ExamReportList extends AsyncComponent<ExamReportListProps, ExamReportListState> {
  constructor(props: ExamReportListProps) {
    super(props);

    this.state = {
      loaded: false,
      blocking: false,
      reports: {
        entities: [],
        count: 0,
      },
      options: {},
      pageTopRef: createRef(),
    };

    autoBind(this);

    this.fetchReports = blocking(this.fetchReports, this);
  }

  async searchToOptions() {
    const query = parseQuery(this.props.location.search);
    await this.setStateAsync({
      options: {
        severity: query.severity,
        studentName: query.studentName || '',
        page: Number(query.page) || 1,
        perPage: Number(query.perPage) || 25,
        sortBy: query.sortBy || localStorage.cvqExamReportsSortBy || 'sittingQuestionSittingStartTime',
        sortDirection: query.sortDirection || localStorage.cvqExamReportsSortDirection || 'asc',
      } as ExamReportFilterOptions,
    });
  }

  async componentDidMount() {
    await this.searchToOptions();
    const search = addQueryParameters(this.props.location.search, this.state.options, defaultExamReportFilterOptions);
    if (search !== this.props.location.search) {
      this.props.history.replace(search);
    } else {
      await this.fetchReports();
    }
  }

  async componentDidUpdate(prevProps: ExamReportListProps) {
    if (prevProps.location.search !== this.props.location.search) {
      localStorage.cvqExamReportsSortBy = this.state.options.sortBy;
      localStorage.cvqExamReportsSortDirection = this.state.options.sortDirection;

      await this.searchToOptions();
      await this.fetchReports();
      if (this.state.pageTopRef.current) {
        this.state.pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onOptionsChange(newOptions: Partial<ExamReportFilterOptions>) {
    const options = {
      ...this.state.options,
      ...newOptions,
    };

    const search = addQueryParameters(this.props.location.search, options, defaultExamReportFilterOptions);
    if (search !== this.props.location.search) {
      this.props.history.push(search);
    }

    this.setState({
      options: {
        ...this.state.options,
        ...newOptions,
      },
    });
  }

  async fetchReports() {
    const reports = await fetchExamReports(this.props.examId, this.state.options);
    await this.setStateAsync({ loaded: true, reports });
  }

  render() {
    if (!this.state.loaded) {
      return <div className="infomsg">Loading reports...</div>;
    }

    const { reports, options } = this.state;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div ref={this.state.pageTopRef}>
          {this.props.maintainer && (
            <div className="row">
              <div className="col-md-12 titlebar">
                <div className="float-left">
                  <FilterBy
                    values={options}
                    onChange={this.onOptionsChange}
                    resetPage={true}
                    options={[
                      {
                        name: 'Severity',
                        queryKey: 'severity',
                        options: [
                          { key: 'all', label: 'All', value: undefined, default: true },
                          { key: 'low', label: 'Low', value: 'low' },
                          { key: 'medium', label: 'Medium', value: 'medium' },
                          { key: 'high', label: 'High', value: 'high' },
                        ],
                      },
                    ]}
                  />
                  <SortBy
                    options={options}
                    onChange={this.onOptionsChange}
                    keys={{
                      sittingQuestionSittingStartTime: 'Sitting start time',
                      sittingQuestionQuestionOrder: 'Question index',
                      sittingQuestionSittingStudentName: 'Student name',
                    }}
                  />
                  <SearchBy
                    queryKey="studentName"
                    term={options.studentName}
                    onChange={this.onOptionsChange}
                    placeholder="Search by student name..."
                    resetPage={true}
                  />
                </div>
              </div>
            </div>
          )}

          {!reports.entities.length ? (
            <div className="infomsg">No reports to show</div>
          ) : (
            <div>
              <Paginator options={options} count={reports.count} onChange={this.onOptionsChange} />

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-hover">
                    <tbody>
                      {reports.entities.map((report) => (
                        <tr key={report.id}>
                          <td>
                            <ReportListEntry examId={this.props.examId} report={report} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <Paginator options={options} count={reports.count} onChange={this.onOptionsChange} />
            </div>
          )}
        </div>
      </BlockUi>
    );
  }
}

export default withRouter(withErrorScreen(ExamReportList));
