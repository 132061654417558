import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useId from '../../hooks/useId';
import { secondsToHrShortText, secondsToHrText } from '../../util/date';
import { If } from './conditional';

type FormattedTimeProps = {
  value: number;
  short?: boolean;
  faicon?: IconProp;
  suffix?: string;
};

export default function FormattedTime({ value, short = false, faicon = null, suffix = '' }: FormattedTimeProps) {
  const tooltipHrText = useMemo(() => {
    return secondsToHrText(value, suffix);
  }, [value, suffix]);
  const hrText = useMemo(() => {
    return short ? secondsToHrShortText(value) : tooltipHrText;
  }, [short, value, tooltipHrText]);

  const tooltipId = useId();

  return (
    <OverlayTrigger trigger={short ? undefined : []} placement="top" overlay={<Tooltip id={tooltipId}>{tooltipHrText}</Tooltip>}>
      <span>
        <If c={faicon}>
          <FontAwesomeIcon icon={faicon} />
          &nbsp;
        </If>
        {hrText}
      </span>
    </OverlayTrigger>
  );
}
