import fetch from '../util/fetch';
import { PagingAndSortingOptions } from '../model/options';
import { Collection, GroupCreationDto, GroupDetailsDto, GroupReducedDto, GroupUpdateDto } from '../model';
import { toUrlParams } from '../util/queryparser';

export type GroupFilterOptions = PagingAndSortingOptions & {
  sortBy?: 'name';
  name?: string;
};

export const defaultGroupOptions: Readonly<GroupFilterOptions> = {
  page: 1,
  perPage: 25,
  sortBy: 'name',
  sortDirection: 'asc',
};

export const fetchGroups = async (options: GroupFilterOptions = {}): Promise<Collection<GroupReducedDto>> => {
  const path = `/api/groups?${toUrlParams(options, defaultGroupOptions)}`;
  const response = await fetch<Array<GroupReducedDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const fetchGroupById = async (groupId: number): Promise<GroupDetailsDto> => {
  const path = `/api/groups/${groupId}`;
  const response = await fetch<GroupDetailsDto>(path);
  return response.body;
};

export const createGroup = async (group: GroupCreationDto): Promise<GroupDetailsDto> => {
  const path = '/api/groups';
  const response = await fetch<GroupDetailsDto, GroupCreationDto>(path, {
    method: 'POST',
    body: group,
  });
  return response.body;
};

export const updateGroup = async (groupId: number, group: GroupUpdateDto): Promise<GroupDetailsDto> => {
  const path = `/api/groups/${groupId}`;
  const response = await fetch<GroupDetailsDto, GroupUpdateDto>(path, {
    method: 'PATCH',
    body: group,
  });
  return response.body;
};

export const deleteGroup = async (groupId: number): Promise<void> => {
  const path = `/api/groups/${groupId}`;
  await fetch<void>(path, {
    method: 'DELETE',
  });
};
