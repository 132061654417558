import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { secondsToTimeText, timeTextToSeconds } from '../../util/date';
import { If } from './conditional';

type SecondsPickerProps = {
  value?: number;
  minValue?: number;
  maxValue?: number;
  delta?: number;
  onChange?: (value: number) => unknown;
  disabled?: boolean;
  faicon?: IconProp;
};

export default function SecondsPicker({
  value: initialValue = 0,
  minValue = 0,
  maxValue = 86400,
  delta = 15,
  onChange = null,
  disabled = false,
  faicon = null,
}: SecondsPickerProps) {
  const [value, setValue] = useState(initialValue);
  const [shownValue, setShownValue] = useState(() => secondsToTimeText(value));

  useEffect(() => {
    const newValue = initialValue || minValue;
    if (value !== newValue) {
      setValue(newValue);
      setShownValue(secondsToTimeText(newValue));
    }
  }, [initialValue, minValue]);

  const addToSeconds = useCallback(
    (multiplier) => {
      let newValue = timeTextToSeconds(shownValue) + delta * multiplier;
      newValue = Math.min(Math.max(newValue, minValue), maxValue);

      if (onChange) {
        onChange(newValue);
      } else {
        setValue(newValue);
        setShownValue(secondsToTimeText(newValue));
      }
    },
    [shownValue, delta, minValue, maxValue],
  );

  const onWheel = useCallback(
    (event) => {
      event.preventDefault();
      if (!disabled) {
        const multiplier = -event.deltaY / Math.abs(event.deltaY);
        addToSeconds(multiplier);
      }
    },
    [disabled, addToSeconds],
  );

  const onKeyDown = useCallback(
    (event) => {
      if (!disabled) {
        if (event.keyCode === 38) {
          addToSeconds(+1);
        }
        if (event.keyCode === 40) {
          addToSeconds(-1);
        }
      }
    },
    [disabled, addToSeconds],
  );

  const onBlur = useCallback(() => {
    const newValue = timeTextToSeconds(shownValue);
    if (onChange) {
      onChange(newValue);
    } else {
      setValue(newValue);
    }
  }, [shownValue]);

  return (
    <>
      <If c={faicon}>
        <FontAwesomeIcon icon={faicon} />
        &nbsp;
      </If>
      <input
        type="text"
        className="mid-input"
        value={shownValue}
        onChange={(event) => setShownValue(event.target.value)}
        onBlur={onBlur}
        onWheel={onWheel}
        onKeyDown={onKeyDown}
        disabled={disabled}
        required
      />
    </>
  );
}
