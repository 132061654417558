import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CompositeDecorator } from 'draft-js';

/**
 * Custom link decorator
 */
export class LinkDecorator extends Component {
  render() {
    const { contentState, decoratedText, entityKey, offsetKey } = this.props;
    const { readOnly, onClick } = LinkDecorator.decoratorProps;
    const { url } = contentState.getEntity(this.props.entityKey).getData();

    if (readOnly) {
      return (
        <a href={url} target="_blank" rel="noreferrer noopener">
          {decoratedText}
        </a>
      );
    }

    const blockKey = offsetKey.split('-').slice(0, -2).join('-');

    return (
      <span style={{ cursor: 'pointer' }} onClick={() => onClick(blockKey, entityKey, url, decoratedText)}>
        <a href={url}>{decoratedText}</a>
      </span>
    );
  }
}

LinkDecorator.component = LinkDecorator;
LinkDecorator.strategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (entityKey != null) {
      const entity = contentState ? contentState.getEntity(entityKey) : null;
      return entity != null && entity.getType() === 'LINK';
    }
    return false;
  }, callback);
};

LinkDecorator.propTypes = {
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired,
  decoratedText: PropTypes.string,
  offsetKey: PropTypes.string.isRequired,
};

// export composite decorator
export default new CompositeDecorator([LinkDecorator]);
