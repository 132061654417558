import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import React, { Component, CSSProperties, KeyboardEvent } from 'react';
import OverlayButton from '../util/OverlayButton';
import { RichTeX, RichTeXEditor } from '../util/RichTeXEditor';
import { QuestionEditorMode } from './QuestionEditorModel';

interface AnswerEditorProps {
  mode: QuestionEditorMode;
  variant: 'simple' | 'singlecorrect' | 'multicorrect';
  allowDynamic: boolean;
  token: string;
  new?: boolean;
  answerId?: string;
  dynamic?: boolean;
  text?: RichTeX | string;
  correct?: boolean;
  selected?: boolean;

  onTextChange: (answerText: RichTeX | string, dynamic: boolean) => unknown;
  onToggleCorrect?: () => unknown;
  onToggleSelected?: () => unknown;
  onDelete?: () => unknown;
}

interface AnswerEditorState {
  editing: boolean;
  text?: RichTeX | string;
  dynamic?: boolean;
  selected?: boolean;
  correct?: boolean;
}

export default class AnswerEditor extends Component<AnswerEditorProps, AnswerEditorState> {
  constructor(props: AnswerEditorProps) {
    super(props);

    this.state = {
      editing: props.new,
      text: '',
      dynamic: false,
      selected: this.props.selected,
      correct: this.props.correct,
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps: AnswerEditorProps): void {
    if (prevProps.selected !== this.props.selected && this.props.selected !== this.state.selected) {
      this.setState({ selected: this.props.selected });
    }
    if (prevProps.correct !== this.props.correct && this.props.correct !== this.state.correct) {
      this.setState({ correct: this.props.correct });
    }
  }

  startEditing(): void {
    this.setState({ editing: true, text: this.props.text, dynamic: this.props.dynamic });
  }

  finishEditing(): void {
    if (!this.state.text) {
      return;
    }

    this.props.onTextChange(this.state.text, this.state.dynamic);

    if (this.props.new) {
      this.setState({ text: '', dynamic: false });
    } else {
      this.setState({ editing: false, text: '', dynamic: false });
    }
  }

  cancelEditing(): void {
    this.setState({ editing: false, text: '', dynamic: false });
  }

  onTextChange(text: RichTeX | string): void {
    this.setState({ text });
  }

  onDynamicToggle(): void {
    const { text, dynamic } = this.state;
    if (dynamic) {
      // dynamic => non-dynamic
      this.setState({
        text: (text as RichTeX).getMarkdown(),
        dynamic: false,
      });
    } else {
      // non-dynamic => dynamic
      this.setState({
        text: new RichTeX(this.state.text),
        dynamic: true,
      });
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    // enter
    if (event.key === 'Enter' || event.keyCode === 13) {
      this.finishEditing();
    }
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.cancelEditing();
    }
  }

  onToggleSelected(): void {
    this.setState({ selected: !this.state.selected }, this.props.onToggleSelected);
  }

  onToggleCorrect(): void {
    this.setState({ correct: !this.state.correct }, this.props.onToggleCorrect);
  }

  // eslint-disable-next-line complexity
  render(): JSX.Element {
    const { props, state } = this;
    const { mode, variant } = props;
    const { editing } = state;

    const editingAnswer = mode === 'edit' && editing;
    const text = editingAnswer ? this.state.text : this.props.text;
    const dynamic = editingAnswer ? this.state.dynamic : this.props.dynamic;

    const style: CSSProperties = { display: 'flex' };

    if (mode === 'evaluate' && props.selected) {
      style.color = props.correct ? '#009900' : '#990000';
      style.backgroundColor = props.correct ? '#00ff0010' : '#ff000010';
    }

    const inputId = `answer-${props.token}-${props.answerId}`;
    const inputName = `question-${props.token}`;

    return (
      <div style={style}>
        {/* checkbox */}
        {(variant === 'singlecorrect' || variant === 'multicorrect') && (
          <>
            <span style={{ display: 'inherit' }}>
              {(mode === 'evaluate' || mode === 'response' || mode === 'take') && (
                <>
                  <input
                    type={variant === 'multicorrect' ? 'checkbox' : 'radio'}
                    style={{ height: '100%' }}
                    id={inputId}
                    name={inputName}
                    checked={state.selected}
                    disabled={mode !== 'take'}
                    onChange={this.onToggleSelected}
                  />
                </>
              )}
              {(mode === 'view' || mode === 'edit') && (
                <>
                  <input
                    type={variant === 'multicorrect' ? 'checkbox' : 'radio'}
                    style={{ height: '100%' }}
                    id={inputId}
                    name={inputName}
                    checked={state.correct}
                    disabled={mode === 'view' || props.new}
                    onChange={this.onToggleCorrect}
                  />
                </>
              )}
            </span>
          </>
        )}
        {variant === 'simple' && <>&#10004;</>}
        &nbsp;
        {/* static text or editor */}
        <div style={{ flexGrow: 1 }}>
          {dynamic && !editingAnswer && (
            <>
              <label htmlFor={inputId} className="uncolored" style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1 }}>
                  <RichTeXEditor className="answer-inline-viewer" size="small" value={text as RichTeX} readOnly={true} inline={true} />
                </div>
              </label>
            </>
          )}

          {dynamic && editingAnswer && (
            <>
              <RichTeXEditor className="answer-editor" size="small" value={text as RichTeX} onChange={this.onTextChange} />
            </>
          )}

          {!dynamic && !editingAnswer && (
            <>
              <label htmlFor={inputId} className="uncolored" style={{ flexGrow: 1 }}>
                {text}
              </label>
            </>
          )}

          {!dynamic && editingAnswer && (
            <>
              <input
                type="text"
                placeholder={props.new ? 'New answer' : ''}
                value={text as string}
                onChange={(event) => this.onTextChange(event.target.value)}
                onKeyUp={this.onKeyUp}
                style={{ flexGrow: 1, width: '100%' }}
                required
              />
            </>
          )}
        </div>
        {/* buttons to start/finish editing */}
        {mode === 'edit' && (
          <>
            {editing && (
              <>
                &nbsp;
                <OverlayButton
                  visible={this.props.allowDynamic}
                  style={{ display: 'inherit' }}
                  className="close"
                  tooltip="Toggle rich text"
                  onClick={this.onDynamicToggle}
                >
                  <FontAwesomeIcon icon="comment-dollar" size="xs" />
                  &nbsp;
                </OverlayButton>
                <OverlayButton
                  style={{ display: 'inherit' }}
                  className="close"
                  tooltip="Save answer"
                  onClick={this.finishEditing}
                  disabled={!text}
                  disabledTooltip="Question text must not be empty"
                >
                  <FontAwesomeIcon icon="check" size="xs" />
                </OverlayButton>
                <OverlayButton
                  visible={!this.props.new}
                  style={{ display: 'inherit' }}
                  className="close"
                  tooltip="Cancel editing"
                  onClick={this.cancelEditing}
                >
                  &nbsp;
                  <FontAwesomeIcon icon="times" size="xs" />
                </OverlayButton>
              </>
            )}

            {!editing && (
              <>
                &nbsp;
                <OverlayButton style={{ display: 'inherit' }} className="close" tooltip="Edit answer text" onClick={this.startEditing}>
                  <FontAwesomeIcon icon="edit" size="xs" />
                </OverlayButton>
                &nbsp;
                <OverlayButton
                  style={{ display: 'inherit' }}
                  className="close"
                  visible={!props.new}
                  tooltip="Delete answer"
                  onClick={props.onDelete}
                >
                  <FontAwesomeIcon icon="trash" size="xs" />
                </OverlayButton>
              </>
            )}
          </>
        )}
        {/* evaluation */}
        {variant !== 'simple' && mode === 'evaluate' && (
          <>
            <span>
              &nbsp;(<b>{props.correct ? 'correct' : 'incorrect'}</b>)
            </span>
          </>
        )}
      </div>
    );
  }
}
