import autoBind from 'auto-bind';
import React, { Component } from 'react';
import { EssaySittingQuestionDto, SittingQuestionDto } from '../../model';
import { RichTeX, RichTeXEditor } from '../util/RichTeXEditor';
import { QuestionEditorModelProps, QuestionEditorProps } from './QuestionEditorModel';

/**
 * Extended model props
 */

export class EssayQuestionEditorModelProps extends QuestionEditorModelProps<EssaySittingQuestionDto> {
  questionText: RichTeX;
  answerText: RichTeX;

  constructor(question: EssaySittingQuestionDto, template?: SittingQuestionDto) {
    super(question, template);
    this.questionText = new RichTeX(this.question.questionContent);
    this.answerText = new RichTeX(this.question.response);
  }

  refreshQuestion(): void {
    this.question.questionContent = this.questionText.getMarkdown();
    this.question.response = this.answerText.getMarkdown();
  }

  fromQuestionTemplate(template: SittingQuestionDto): EssaySittingQuestionDto {
    return {
      id: template.id,
      questionType: 'essay',
      questionContent: (template.questionContent as { text: string }).text || (template.questionContent as string),
    };
  }

  setQuestionText(questionText: RichTeX): void {
    this.questionText = questionText;
    this.questionUpToDate = false;
  }

  setAnswerText(answerText: RichTeX): void {
    this.answerText = answerText;
    this.questionUpToDate = false;
  }
}

/**
 * Alias
 */

export type EssayQuestionEditorProps = QuestionEditorProps<EssaySittingQuestionDto, EssayQuestionEditorModelProps>;

/**
 * Associated components
 */

export default class EssayQuestionEditor extends Component<EssayQuestionEditorProps> {
  constructor(props: EssayQuestionEditorProps) {
    super(props);
    autoBind(this);
  }

  onQuestionTextChange(questionText: RichTeX): void {
    const { model, onChange } = this.props;
    model.setQuestionText(questionText);
    onChange(model);
  }

  onAnswerTextChange(answerText: RichTeX): void {
    const { model, onChange } = this.props;
    model.setAnswerText(answerText);
    onChange(model);
  }

  render(): JSX.Element {
    const { mode, allowFileUpload, onUpload } = this.props;
    return (
      <>
        <span className="question-text">
          <RichTeXEditor
            className="static-editor"
            size="medium"
            value={this.props.model.questionText}
            readOnly={mode !== 'edit'}
            onChange={this.onQuestionTextChange}
            allowFileUpload={allowFileUpload}
            onUpload={onUpload}
          />
        </span>
        {(mode === 'evaluate' || mode === 'response' || mode === 'take') && (
          <>
            <i>Response:</i>
            <span className="question-text">
              <RichTeXEditor
                className="static-editor"
                size="medium"
                value={this.props.model.answerText}
                readOnly={mode !== 'take'}
                hideMarkdown={true}
                onChange={this.onAnswerTextChange}
              />
            </span>
          </>
        )}
      </>
    );
  }
}
