import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import React from 'react';
import BlockUi from 'react-block-ui';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ExamReducedDto } from '../../model';
import { deleteExam, publishExam, starExam, unpublishExam, unstarExam } from '../../service/exam';
import AsyncComponent from '../util/AsyncComponent';
import { blocking } from '../util/decorators';
import { DeleteModal } from '../util/Modals';
import OverlayButton from '../util/OverlayButton';
import withErrorScreen from '../util/withErrorScreen';

type ExamListEntryProps = {
  exam: ExamReducedDto;
};

type ExamListEntryState = {
  showDeleteModal: boolean;
  deleted: boolean;
  blocking: boolean;
  starred: boolean;
  published: boolean;
};

class ExamListEntry extends AsyncComponent<ExamListEntryProps, ExamListEntryState> {
  constructor(props: ExamListEntryProps) {
    super(props);

    this.state = {
      starred: this.props.exam.starred,
      published: this.props.exam.published,
      showDeleteModal: false,
      deleted: false,
      blocking: false,
    };

    autoBind(this);

    this.starExam = blocking(this.starExam, this);
    this.unstarExam = blocking(this.unstarExam, this);
    this.publishExam = blocking(this.publishExam, this);
    this.unpublishExam = blocking(this.unpublishExam, this);
    this.confirmDeleteExam = blocking(this.confirmDeleteExam, this);
  }

  async starExam() {
    const exam = await starExam(this.props.exam.id);
    this.setState({ starred: exam.starred });
  }

  async unstarExam() {
    const exam = await unstarExam(this.props.exam.id);
    this.setState({ starred: exam.starred });
  }

  async publishExam() {
    const exam = await publishExam(this.props.exam.id);
    this.setState({ published: exam.published });
  }

  async unpublishExam() {
    const exam = await unpublishExam(this.props.exam.id);
    this.setState({ published: exam.published });
  }

  proposeDeleteExam() {
    this.setState({ showDeleteModal: true });
  }

  cancelDeleteExam() {
    this.setState({ showDeleteModal: false });
  }

  async confirmDeleteExam() {
    await this.setStateAsync({ showDeleteModal: false });
    await deleteExam(this.props.exam.id);
    this.setState({ deleted: true });
  }

  render() {
    if (this.state.deleted) {
      return <div className="infomsg">This exam has been deleted.</div>;
    }

    const { exam } = this.props;
    const { maintainer } = exam;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div className="float-left mr-3">
          <Link to={`/exams/${exam.id}/`}>
            <dl>
              <dt>
                {exam.name}&nbsp;
                <span className="objectstreams">
                  {exam.examType === 'surveillance' && (
                    <>
                      <OverlayTrigger placement="top" overlay={<Tooltip id={`surveillance-${exam.id}`}>Surveillance</Tooltip>}>
                        <FontAwesomeIcon icon="eye" />
                      </OverlayTrigger>
                      &nbsp;
                    </>
                  )}
                  {exam.examType === 'interactive' && (
                    <>
                      <OverlayTrigger placement="top" overlay={<Tooltip id={`interactive-${exam.id}`}>Interactive</Tooltip>}>
                        <FontAwesomeIcon icon="sign-language" />
                      </OverlayTrigger>
                      &nbsp;
                    </>
                  )}
                  {exam.streaming && (
                    <>
                      <OverlayTrigger placement="top" overlay={<Tooltip id={`video-${exam.id}`}>Contains video questions</Tooltip>}>
                        <FontAwesomeIcon icon="video" />
                      </OverlayTrigger>
                      &nbsp;
                    </>
                  )}
                </span>
              </dt>
              <dd>
                <FontAwesomeIcon icon="university" />
                &nbsp;
                {exam.group.name}&nbsp;·&nbsp;
                {new Date() > new Date(exam.hardStartTime) ? (
                  <OverlayTrigger placement="top" overlay={<Tooltip id={`expired-${exam.id}`}>Expired</Tooltip>}>
                    <span className="expired">
                      <FontAwesomeIcon icon="calendar" />
                      &nbsp;{new Date(exam.startTime).toLocaleString()}
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span>
                    <FontAwesomeIcon icon="calendar" />
                    &nbsp;{new Date(exam.startTime).toLocaleString()}
                  </span>
                )}
              </dd>
            </dl>
          </Link>
        </div>
        <div className="float-right btn-group">
          {/* star */}
          <OverlayButton
            visible={this.state.starred}
            tooltip="Starred. Click to unstar"
            onClick={this.unstarExam}
            variant="outline-warning"
          >
            <FontAwesomeIcon icon="star" />
          </OverlayButton>
          <OverlayButton
            visible={!this.state.starred}
            tooltip="Not starred. Click to star"
            onClick={this.starExam}
            variant="outline-secondary"
          >
            <FontAwesomeIcon icon={['far', 'star']} />
          </OverlayButton>

          {/* publish, edit, delete */}
          <OverlayButton
            visible={maintainer && this.state.published}
            tooltip="Published. Click to unpublish"
            onClick={this.unpublishExam}
            variant="outline-info"
          >
            <FontAwesomeIcon icon="check-circle" />
          </OverlayButton>
          <OverlayButton
            visible={maintainer && !this.state.published}
            tooltip="Not published. Click to publish"
            onClick={this.publishExam}
            variant="outline-secondary"
          >
            <FontAwesomeIcon icon="times-circle" />
          </OverlayButton>
          <OverlayButton visible={maintainer} tooltip="Edit" to={`/exams/${exam.id}/edit`} variant="outline-warning">
            <FontAwesomeIcon icon="edit" />
          </OverlayButton>
          <OverlayButton visible={maintainer} tooltip="Delete" onClick={this.proposeDeleteExam} variant="outline-danger">
            <FontAwesomeIcon icon="trash" />
          </OverlayButton>
        </div>

        <DeleteModal
          show={this.state.showDeleteModal}
          onCancel={this.cancelDeleteExam}
          onConfirm={this.confirmDeleteExam}
          title="Delete exam"
        >
          <div>
            Are you sure you would like to delete the exam <b>{exam.name}</b>?
          </div>
          <div>All associated questions will be deleted. This action is irreversible.</div>
        </DeleteModal>
      </BlockUi>
    );
  }
}

export default withErrorScreen(ExamListEntry);
