import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import BlockUi from 'react-block-ui';

import { DeleteModal } from '../util/Modals';
import AsyncComponent from '../util/AsyncComponent';
import { deleteBank } from '../../service/questionbank';
import OverlayButton from '../util/OverlayButton';

import { blocking } from '../util/decorators';
import withErrorScreen from '../util/withErrorScreen';
import FormattedTime from '../util/FormattedTime';

class QuestionBankListEntry extends AsyncComponent {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal: false,
      deleted: false,
      blocking: false,
    };

    this.proposeDeleteBank = this.proposeDeleteBank.bind(this);
    this.cancelDeleteBank = this.cancelDeleteBank.bind(this);
    this.confirmDeleteBank = this.confirmDeleteBank.bind(this);

    this.confirmDeleteBank = blocking(this.confirmDeleteBank, this);
  }

  proposeDeleteBank() {
    this.setState({ showDeleteModal: true });
  }

  cancelDeleteBank() {
    this.setState({ showDeleteModal: false });
  }

  async confirmDeleteBank() {
    await this.setStateAsync({ showDeleteModal: false });
    await deleteBank(this.props.examId, this.props.bank.id);
    this.setState({ deleted: true });
  }

  render() {
    if (this.state.deleted) {
      return <div className="infomsg">This question bank has been deleted.</div>;
    }

    const { bank, maintainer, examId, onMoveUp, onMoveDown } = this.props;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div className="float-left mr-3">
          <dl>
            <dt>
              {maintainer ? (
                <>
                  <Link to={`/exams/${examId}/banks/${bank.id}/`}>
                    {bank.bankOrder + 1}. {bank.name}
                  </Link>
                  {bank.resumable && (
                    <span className="objectstreams">
                      &nbsp;·&nbsp;
                      <OverlayTrigger placement="top" overlay={<Tooltip>Resumable without skipping</Tooltip>}>
                        <FontAwesomeIcon icon="step-forward" />
                      </OverlayTrigger>
                    </span>
                  )}
                </>
              ) : (
                <span>
                  {bank.bankOrder + 1}. {bank.name}
                </span>
              )}
            </dt>
            <dd>
              {/* time per question */}
              <FormattedTime faicon="clock" value={bank.timePerQuestion} short suffix="/question" />
              &nbsp;·&nbsp;
              {/* points per question */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{`${bank.pointsPerQuestion} point${bank.pointsPerQuestion !== 1 ? 's' : ''}/question`}</Tooltip>}
              >
                <span>
                  <FontAwesomeIcon icon="trophy" />
                  &nbsp;{bank.pointsPerQuestion}p
                </span>
              </OverlayTrigger>
              &nbsp;·&nbsp;
              {/* choose quantity */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{`${bank.chooseQuantity} question${bank.chooseQuantity !== 1 ? 's' : ''}`}</Tooltip>}
              >
                <span>
                  <FontAwesomeIcon icon="hashtag" />
                  &nbsp;{bank.chooseQuantity}
                </span>
              </OverlayTrigger>
            </dd>
          </dl>
        </div>
        <div className="float-right btn-group">
          <OverlayButton
            visible={maintainer}
            tooltip="Sitting questions"
            variant="outline-info"
            to={`/exams/${examId}/banks/${bank.id}/sittingQuestions/`}
          >
            <FontAwesomeIcon icon="question-circle" />
          </OverlayButton>
          <OverlayButton
            visible={maintainer}
            tooltip="Move up"
            variant="outline-secondary"
            disabled={bank.bankOrder === 0}
            onClick={() => onMoveUp(bank)}
          >
            <FontAwesomeIcon icon="arrow-up" />
          </OverlayButton>
          <OverlayButton
            visible={maintainer}
            tooltip="Move down"
            variant="outline-secondary"
            disabled={bank.bankOrder === this.props.bankCount - 1}
            onClick={() => onMoveDown(bank)}
          >
            <FontAwesomeIcon icon="arrow-down" />
          </OverlayButton>
          <OverlayButton visible={maintainer} tooltip="Edit" variant="outline-warning" to={`/exams/${examId}/banks/${bank.id}/edit`}>
            <FontAwesomeIcon icon="edit" />
          </OverlayButton>
          <OverlayButton visible={maintainer} tooltip="Delete" variant="outline-danger" onClick={this.proposeDeleteBank}>
            <FontAwesomeIcon icon="trash" />
          </OverlayButton>
        </div>

        <DeleteModal
          show={this.state.showDeleteModal}
          onCancel={this.cancelDeleteBank}
          onConfirm={this.confirmDeleteBank}
          title="Delete question bank"
        >
          <div>
            Are you sure you would like to delete the exam question bank&nbsp;
            <b>{bank.name}</b>?
          </div>
          <div>All associated questions will be deleted. This action is irreversible.</div>
        </DeleteModal>
      </BlockUi>
    );
  }
}

QuestionBankListEntry.propTypes = {
  examId: PropTypes.number.isRequired,
  examType: PropTypes.string.isRequired,
  maintainer: PropTypes.bool.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  bank: PropTypes.object.isRequired,
  bankCount: PropTypes.number.isRequired,
};

export default withErrorScreen(QuestionBankListEntry);
