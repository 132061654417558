import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { If } from '../components/util/conditional';
import BreadcrumbsSetterContext, { Breadcrumbs } from '../context/breadcrumbs';

export type BreadcrumbsProviderProps = {
  children: ReactNode;
};

export default function BreadcrumbsProvider({ children }: BreadcrumbsProviderProps) {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs>([]);

  return (
    <BreadcrumbsSetterContext.Provider value={setBreadcrumbs}>
      <If c={breadcrumbs?.length > 0}>
        <div className="row">
          <div className="col-md-12">
            <ol className="breadcrumb">
              &nbsp;
              {breadcrumbs?.map(({ text, path }, key) =>
                key + 1 === breadcrumbs.length ? (
                  <li key={`${text}-${path}`} className="breadcrumb-item active">
                    {text}
                  </li>
                ) : (
                  <li key={`${text}-${path}`} className="breadcrumb-item">
                    <Link to={path}>{text}</Link>
                  </li>
                ),
              )}
            </ol>
          </div>
        </div>
      </If>

      {children}
    </BreadcrumbsSetterContext.Provider>
  );
}
