import React, { Component } from 'react';

import { RichTeX, RichTeXEditor } from '../util/RichTeXEditor';
import { GroupDetailsDto } from '../../model';

type GroupDetailsPanelProps = {
  group: GroupDetailsDto;
};

type GroupDetailsPanelState = {
  description: RichTeX;
};

export default class GroupDetailsPanel extends Component<GroupDetailsPanelProps, GroupDetailsPanelState> {
  constructor(props: GroupDetailsPanelProps) {
    super(props);
    this.state = {
      description: new RichTeX(props.group.description),
    };
  }

  render(): JSX.Element {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="jumbotron mini-jumbotron">
            <div className="description">
              <RichTeXEditor value={this.state.description} readOnly={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
