import autoBind from 'auto-bind';
import React, { Component } from 'react';
import { ScaSittingQuestionDto, ShortTextSittingQuestionDto, SittingQuestionDto } from '../../model';
import { shortId } from '../../util/random';
import { RichTeX, RichTeXEditor } from '../util/RichTeXEditor';
import AnswerEditor from './AnswerEditor';
import { QuestionEditorModelProps, QuestionEditorProps } from './QuestionEditorModel';

/**
 * Extended model props
 */

export class ShortTextQuestionEditorModelProps extends QuestionEditorModelProps<ShortTextSittingQuestionDto> {
  questionText: RichTeX;
  correctAnswers: {
    [key: string]: string;
  };

  constructor(question: ShortTextSittingQuestionDto, template?: SittingQuestionDto) {
    super(question, template);

    this.questionText = new RichTeX(this.question.questionContent.text);
    this.correctAnswers = {};
    if (!this.question.response) {
      this.question.response = '';
    }

    if (this.question.evaluationKey) {
      this.question.evaluationKey.forEach((answer) => {
        this.correctAnswers[shortId()] = answer;
      });
    }
  }

  refreshQuestion(): void {
    this.question.questionContent.text = this.questionText.getMarkdown();
    this.question.evaluationKey = [...Object.values(this.correctAnswers)];
  }

  fromQuestionTemplate(template: SittingQuestionDto): ShortTextSittingQuestionDto {
    // special case
    if (template.questionType === 'singlecorrect' || template.questionType === 'multiplecorrect') {
      const castQuestion = template as ScaSittingQuestionDto;
      return {
        id: template.id,
        questionType: 'shorttext',
        questionContent: {
          text: castQuestion.questionContent.text,
          caseSensitive: false,
        },
        evaluationKey: Object.values(castQuestion.questionContent.answers).map((answer) => answer.text),
      };
    }

    // general case
    return {
      id: template.id,
      questionType: 'shorttext',
      questionContent: {
        text: (template.questionContent as { text: string }).text || (template.questionContent as string),
        caseSensitive: false,
      },
      evaluationKey: [],
    };
  }

  setQuestionText(questionText: RichTeX): void {
    this.questionText = questionText;
    this.questionUpToDate = false;
  }

  toggleQuestionCaseSensitive(): void {
    this.question.questionContent.caseSensitive = !this.question.questionContent.caseSensitive;
  }

  setCorrectAnswerText(answerId: string, answerText: string): void {
    this.correctAnswers[answerId] = answerText;
    this.questionUpToDate = false;
  }

  addNewCorrectAnswer(answerText: string): void {
    this.correctAnswers[shortId()] = answerText;
    this.questionUpToDate = false;
  }

  deleteCorrectAnswer(answerId: string): void {
    delete this.correctAnswers[answerId];
    this.questionUpToDate = false;
  }

  setResponse(answerText: string): void {
    this.question.response = answerText;
  }
}

/**
 * Alias
 */

type ShortTextQuestionEditorProps = QuestionEditorProps<ShortTextSittingQuestionDto, ShortTextQuestionEditorModelProps>;

/**
 * Associated components
 */

export default class ShortTextQuestionEditor extends Component<ShortTextQuestionEditorProps> {
  token = shortId();

  constructor(props: ShortTextQuestionEditorProps) {
    super(props);
    autoBind(this);
  }

  onQuestionTextChange(questionText: RichTeX): void {
    const { model, onChange } = this.props;
    model.setQuestionText(questionText);
    onChange(model);
  }

  onQuestionToggleCaseSensitive(): void {
    const { model, onChange } = this.props;
    model.toggleQuestionCaseSensitive();
    onChange(model);
  }

  onCorrectAnswerTextChange(answerId: string, answerText: string): void {
    const { model, onChange } = this.props;
    model.setCorrectAnswerText(answerId, answerText);
    onChange(model);
  }

  onNewCorrectAnswer(answerText: string): void {
    const { model, onChange } = this.props;
    model.addNewCorrectAnswer(answerText);
    onChange(model);
  }

  onDeleteCorrectAnswer(answerId: string): void {
    const { model, onChange } = this.props;
    model.deleteCorrectAnswer(answerId);
    onChange(model);
  }

  onResponseChange(answerText: string): void {
    const { model, onChange } = this.props;
    model.setResponse(answerText);
    onChange(model);
  }

  render(): JSX.Element {
    const { mode, model, allowFileUpload, onUpload } = this.props;
    const { caseSensitive } = model.question.questionContent;

    const isAnswerSelected = (answer: string): boolean => {
      if (!model.question.response) {
        return false;
      }
      if (model.question.questionContent.caseSensitive) {
        return answer.trim() === model.question.response.trim();
      }
      return answer.trim().toLowerCase() === model.question.response.trim().toLowerCase();
    };

    return (
      <>
        {mode === 'edit' && (
          <>
            <div className="float-right">
              <input
                type="checkbox"
                id={`caseSensitive-${model.question.id}`}
                checked={caseSensitive}
                onChange={this.onQuestionToggleCaseSensitive}
              />
              &nbsp;
              <label htmlFor={`caseSensitive-${model.question.id}`} className="uncolored">
                Case sensitive
              </label>
            </div>
          </>
        )}

        {mode !== 'edit' && (
          <>
            <div className="float-right">
              This question <b>{caseSensitive ? 'is' : 'is not'}</b> case sensitive
            </div>
          </>
        )}

        {/* question editor */}
        <span className="question-text">
          <RichTeXEditor
            className="static-editor"
            size="medium"
            value={model.questionText}
            readOnly={mode !== 'edit'}
            onChange={this.onQuestionTextChange}
            allowFileUpload={allowFileUpload}
            onUpload={onUpload}
          />
        </span>
        <br />

        {/* response */}
        {(mode === 'response' || mode === 'evaluate' || mode === 'take') && (
          <>
            <div style={{ paddingBottom: 4, paddingTop: 12 }}>
              <b className="eventmsg">Response:</b>&nbsp;
              <input
                type="text"
                placeholder={mode === 'take' ? 'Your response goes here' : 'No response'}
                value={model.question.response}
                onChange={(event) => this.onResponseChange(event.target.value)}
                style={{ flexGrow: 1, width: '100%' }}
                disabled={mode !== 'take'}
                required
              />
            </div>
          </>
        )}

        {/* list of correct answers */}
        {(mode === 'edit' || mode === 'view' || mode === 'evaluate') && (
          <>
            <div className="eventmsg" style={{ paddingBottom: 4 }}>
              <b>Accepted answers:</b>
            </div>

            {Object.entries(model.correctAnswers).map(([answerId, answer]) => (
              <AnswerEditor
                key={answerId}
                mode={mode}
                variant="simple"
                allowDynamic={false}
                selected={isAnswerSelected(answer)}
                correct={true}
                token={this.token}
                answerId={answerId}
                text={answer}
                onTextChange={(answerText: string) => this.onCorrectAnswerTextChange(answerId, answerText)}
                onDelete={() => this.onDeleteCorrectAnswer(answerId)}
              />
            ))}
          </>
        )}

        {mode === 'edit' && (
          <>
            <AnswerEditor
              mode={mode}
              variant="simple"
              allowDynamic={false}
              token={this.token}
              new={true}
              onTextChange={this.onNewCorrectAnswer}
            />
          </>
        )}
      </>
    );
  }
}
