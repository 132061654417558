import { useCallback, useContext, useEffect, useState } from 'react';
import ErrorModalContext, { ErrorDisplayOptions } from '../context/error';

export function useWithErrorHandling<T>(method: () => Promise<T>, options: ErrorDisplayOptions = {}) {
  const showError = useContext(ErrorModalContext);

  const decorated = useCallback(async () => {
    let ret: T;
    try {
      ret = await method();
    } catch (error) {
      console.error(error);
      const onClose = options.keepTrying ? decorated : undefined;
      showError({
        error,
        options,
        onClose,
      });
    }
    return ret;
  }, [showError, method, options]);

  return decorated;
}

export function useWithErrorHandlingEffect<T>(method: () => Promise<T>, options: ErrorDisplayOptions = {}, dependents: unknown[] = []) {
  const withErrorHandling = useWithErrorHandling(method, options);
  useEffect(() => {
    withErrorHandling();
  }, dependents);
}

export function useBlocking<T>(method: () => Promise<T>, options: ErrorDisplayOptions = {}) {
  const [blocking, setBlocking] = useState(false);
  const withErrorHandling = useWithErrorHandling(method, options);

  const decorated = useCallback(async () => {
    setBlocking(true);
    const ret = await withErrorHandling();
    setBlocking(false);
    return ret;
  }, []);

  return {
    blocking,
    decorated,
  };
}
