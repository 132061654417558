import React from 'react';
import autoBind from 'auto-bind';

import AsyncComponent from './AsyncComponent';
import VolumeMeter from './VolumeMeter';
import withErrorScreen from './withErrorScreen';
import { longId } from '../../util/random';

/**
 * Video recorder react component
 */

type LocalVideoProps = {
  stream: MediaStream;
};

type LocalVideoState = {
  id: string;
};

class LocalVideo extends AsyncComponent<LocalVideoProps, LocalVideoState> {
  constructor(props: LocalVideoProps) {
    super(props);

    this.state = {
      id: longId(),
    };

    autoBind(this);
  }

  componentDidMount() {
    const localVideo = document.getElementById(`local-video-${this.state.id}`) as HTMLVideoElement;
    localVideo.srcObject = this.props.stream;
  }

  render() {
    return (
      <div>
        {/* monitor webcam feed */}
        <div>
          <video id={`local-video-${this.state.id}`} className="media-container" autoPlay muted></video>
        </div>

        {/* volume meter */}
        <div>
          <VolumeMeter stream={this.props.stream} />
        </div>
      </div>
    );
  }
}

export default withErrorScreen(LocalVideo);
