import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { countSittings } from '../../service/sitting';

type ExamSittingsWarningProps = {
  examId: number;
};

export default function ExamSittingsWarning({ examId }: ExamSittingsWarningProps) {
  const [numSittings, setNumSittings] = useState(0);

  useEffect(() => {
    (async () => {
      const newNumSittings = await countSittings(examId, { simulated: false });
      setNumSittings(newNumSittings);
    })();
  }, [examId]);

  if (!numSittings) {
    return null;
  }

  return (
    <div className="errormsg" style={{ paddingBottom: 30 }}>
      <b>Warning:</b> There are already student sittings for this exam. Any modification on this page may have unwanted side effects.
      Consider removing them.&nbsp;
      <Link to={`/exams/${examId}/sittings/`}>See all {numSittings} sittings</Link>
    </div>
  );
}
