import React, { ChangeEvent, KeyboardEvent } from 'react';
import autoBind from 'auto-bind';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import withErrorScreen from './util/withErrorScreen';
import OverlayButton from './util/OverlayButton';

import { PasswordResetDto } from '../model';
import { resetPassword } from '../service/auth';
import { blocking } from './util/decorators';
import AsyncComponent from './util/AsyncComponent';

type PasswordResetProps = RouteComponentProps & {
  onPasswordReset: () => void;
};

type PasswordResetState = {
  oldPassword: string;
  newPassword: string;
  newPasswordAgain: string;
  valid: boolean;
};

class PasswordReset extends AsyncComponent<PasswordResetProps, PasswordResetState> {
  constructor(props: PasswordResetProps) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      newPasswordAgain: '',
      valid: false,
    };

    autoBind(this);

    this.onPasswordReset = blocking(this.onPasswordReset, this);
  }

  onValueChange(event: ChangeEvent<HTMLInputElement>): void {
    const { target } = event;
    const value = event.target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;

    const passwords = {
      ...this.state,
      [key]: value,
    };
    const { oldPassword, newPassword, newPasswordAgain } = passwords;

    this.setState({
      oldPassword,
      newPassword,
      newPasswordAgain,
      valid: oldPassword.length > 0 && newPassword.length > 0 && newPassword === newPasswordAgain,
    });
  }

  onKeyUp(event: KeyboardEvent): void {
    const { oldPassword, newPassword, valid } = this.state;

    if (!valid) {
      return;
    }
    if (event.key === 'Enter' || event.keyCode === 13) {
      this.onPasswordReset({ oldPassword, newPassword });
    }
  }

  onSubmit(): void {
    const { oldPassword, newPassword, valid } = this.state;

    if (!valid) {
      return;
    }
    this.onPasswordReset({ oldPassword, newPassword });
  }

  async onPasswordReset(passwordResetDto: PasswordResetDto) {
    await resetPassword(passwordResetDto);
    if (this.props.onPasswordReset) {
      this.props.onPasswordReset();
    } else {
      this.props.history.push('/');
    }
  }

  render(): JSX.Element {
    const { oldPassword, newPassword, newPasswordAgain, valid } = this.state;

    return (
      <>
        {/* header */}
        <div className="form-row">
          <div className="form-group col-md-12 titlebar">
            <h3>Password reset</h3>
          </div>
        </div>

        {/* form */}
        <div className="row">
          <div className="col-md-12">
            <div className="jumbotron">
              <Form.Group>
                <Form.Label>Old password</Form.Label>
                <Form.Control type="password" name="oldPassword" onChange={this.onValueChange} onKeyUp={this.onKeyUp} value={oldPassword} />
              </Form.Group>

              <Form.Group>
                <Form.Label>New password</Form.Label>
                <Form.Control type="password" name="newPassword" onChange={this.onValueChange} onKeyUp={this.onKeyUp} value={newPassword} />
              </Form.Group>

              <Form.Group>
                <Form.Label>New password again</Form.Label>
                <Form.Control
                  type="password"
                  name="newPasswordAgain"
                  onChange={this.onValueChange}
                  onKeyUp={this.onKeyUp}
                  value={newPasswordAgain}
                />
              </Form.Group>
            </div>
          </div>
        </div>

        {/* submit button */}
        <div className="row">
          <div className="col-md-12">
            <div className="float-right">
              <OverlayButton variant="success" disabled={!valid} disabledTooltip="Please fill out form correctly" onClick={this.onSubmit}>
                <FontAwesomeIcon icon="check" />
                &nbsp;Confirm
              </OverlayButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withErrorScreen(PasswordReset));
