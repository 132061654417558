import { ReactNode } from 'react';

export type IfProps = {
  c: unknown;
  children: ReactNode;
};

export function If({ c, children }: IfProps): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return c ? <>{children}</> : null;
}
