import React from 'react';
import { Pagination } from 'react-bootstrap';
import { PagingOptions } from '../../model/options';

interface PaginatorProps {
  options: PagingOptions;
  count: number;
  onChange: (options: Partial<PagingOptions>) => void;
}

export default function Paginator({ options, count, onChange }: PaginatorProps) {
  const { page, perPage } = options;

  if (!count) {
    return (
      <div>
        <div className="pagination-row pagination-subtext">No objects to show</div>
      </div>
    );
  }
  const numPages = Math.ceil(count / perPage);
  const start = (page - 1) * perPage + 1;
  const end = Math.min(count, page * perPage);

  return (
    <div className="pagination-container">
      {perPage < count && (
        <div className="pagination-row">
          <Pagination size="sm">
            {/* first & previous */}
            <Pagination.First onClick={() => onChange({ page: 1 })} disabled={page === 1} />
            <Pagination.Prev onClick={() => onChange({ page: page - 1 })} disabled={page === 1} />

            {page > 3 && <Pagination.Ellipsis disabled />}

            {/* previous 2 */}
            {page > 2 && <Pagination.Item onClick={() => onChange({ page: page - 2 })}>{page - 2}</Pagination.Item>}
            {page > 1 && <Pagination.Item onClick={() => onChange({ page: page - 1 })}>{page - 1}</Pagination.Item>}

            {/* current */}
            <Pagination.Item active>{page}</Pagination.Item>

            {/* next 2 */}
            {page <= numPages - 1 && <Pagination.Item onClick={() => onChange({ page: page + 1 })}>{page + 1}</Pagination.Item>}
            {page <= numPages - 2 && <Pagination.Item onClick={() => onChange({ page: page + 2 })}>{page + 2}</Pagination.Item>}

            {page <= numPages - 3 && <Pagination.Ellipsis disabled />}

            {/* next & last */}
            <Pagination.Next onClick={() => onChange({ page: page + 1 })} disabled={page === numPages} />
            <Pagination.Last onClick={() => onChange({ page: numPages })} disabled={page === numPages} />
          </Pagination>
        </div>
      )}
      <div className="pagination-row pagination-subtext">
        Showing items {start}-{end} of a total of {count}
        <br />
      </div>
    </div>
  );
}
