import { QuestionDto } from './question';

/**
 * Incoming
 */

export type AnswerStartPoint = 'unavailable' | 'available' | 'mandatory';

export const answerStartPointMappings = {
  unavailable: 'Unavailable',
  available: 'Available',
  mandatory: 'Mandatory',
};

export interface QuestionBankDto {
  id: number;
  name: string;
  bankOrder: number;
  pointsPerQuestion: number;
  chooseQuantity: number;
  timePerQuestion: number;
  minTimePerQuestion: number;
  resumable: boolean;
  answerStartPoint: AnswerStartPoint;
  questions?: Array<QuestionDto>;
}

/**
 * Outgoing
 */

export interface QuestionBankCreationDto {
  name: string;
  timePerQuestion?: number;
  minTimePerQuestion?: number;
  pointsPerQuestion?: number;
  chooseQuantity?: number;
  resumable?: boolean;
  answerStartPoint?: AnswerStartPoint;
}

export interface QuestionBankUpdateDto {
  bankOrder?: number;
  name?: string;
  timePerQuestion?: number;
  minTimePerQuestion?: number;
  pointsPerQuestion?: number;
  chooseQuantity?: number;
  resumable?: boolean;
  answerStartPoint?: AnswerStartPoint;
}
