export const withErrorHandling = (method, context, options = {}) => {
  const decorated = async (args) => {
    let ret;
    try {
      ret = await method(args);
    } catch (e) {
      console.error(e);
      // TODO: remove options
      // const displayMessage = options.message || e.message;
      const onClose = options.keepTrying ? decorated : undefined;
      context.props.showError(e, options.autoClose, options.keepTrying, onClose);
    }
    return ret;
  };
  return decorated;
};

export const blocking = (method, context, options = {}) => {
  let beforeState;
  let afterState;

  const stateName = options.stateName || 'blocking';

  if (stateName instanceof Array) {
    beforeState = stateName.reduce((arr, key) => ({ ...arr, [key]: true }), {});
    afterState = stateName.reduce((arr, key) => ({ ...arr, [key]: false }), {});
  } else {
    beforeState = { [stateName]: true };
    afterState = { [stateName]: false };
  }

  const decorated = async (args) => {
    await context.setStateAsync(beforeState);
    const ret = await withErrorHandling(method, context, options)(args);
    await context.setStateAsync(afterState);
    return ret;
  };
  return decorated;
};
