import { Collection, SittingQuestionDto, SittingQuestionState } from '../model';
import { PagingAndSortingOptions } from '../model/options';
import fetch from '../util/fetch';
import { toUrlParams } from '../util/queryparser';

export type SittingQuestionSortOptions = 'questionOrder' | 'sittingStartTime' | 'sittingStudentName' | 'points';

export type SittingQuestionFilterOptions = PagingAndSortingOptions & {
  // sort
  sortBy?: SittingQuestionSortOptions;
  // search
  studentName?: string;
  // filter
  graded?: boolean;
  state?: SittingQuestionState;
};

export const defaultSittingQuestionOptions: Readonly<SittingQuestionFilterOptions> = {
  page: 1,
  perPage: 25,
  sortBy: 'questionOrder',
  sortDirection: 'asc',
};

export const fetchSittingQuestions = async (
  examId: number,
  sittingId: number,
  options: SittingQuestionFilterOptions = {},
): Promise<Collection<SittingQuestionDto>> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}/questions?${toUrlParams(options)}`;
  const response = await fetch<Array<SittingQuestionDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const fetchSittingQuestionById = async (examId: number, sittingId: number, questionId: number): Promise<SittingQuestionDto> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}/questions/${questionId}`;
  const response = await fetch<SittingQuestionDto>(path);
  return response.body;
};

export const fetchSittingQuestionsByQuestionId = async (
  examId: number,
  questionId: number,
  options: SittingQuestionFilterOptions = {},
): Promise<Collection<SittingQuestionDto>> => {
  const path = `/api/exams/${examId}/sittingQuestions?questionId=${questionId}&${toUrlParams(options)}`;
  const response = await fetch<Array<SittingQuestionDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const fetchSittingQuestionsByBankId = async (
  examId: number,
  bankId: number,
  options: SittingQuestionFilterOptions = {},
): Promise<Collection<SittingQuestionDto>> => {
  const path = `/api/exams/${examId}/sittingQuestions?bankId=${bankId}&${toUrlParams(options)}`;
  const response = await fetch<Array<SittingQuestionDto>>(path);
  return {
    count: Number(response.headers['x-total-count']),
    entities: response.body,
  };
};

export const updateSittingQuestionComment = async (
  examId: number,
  sittingId: number,
  questionId: number,
  comment: string,
): Promise<SittingQuestionDto> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}/questions/${questionId}`;
  const response = await fetch<SittingQuestionDto>(path, {
    method: 'PATCH',
    body: {
      comment,
    },
  });
  return response.body;
};

export const updateSittingQuestionPoints = async (
  examId: number,
  sittingId: number,
  questionId: number,
  points: number,
): Promise<SittingQuestionDto> => {
  const path = `/api/exams/${examId}/sittings/${sittingId}/questions/${questionId}`;
  const response = await fetch<SittingQuestionDto>(path, {
    method: 'PATCH',
    body: {
      points,
    },
  });
  return response.body;
};
