import store from 'store2';
import fetch from './fetch';
import version from './version';

export default async function cacheBust(): Promise<void> {
  console.log('Cache busting application');

  // add current timestamp as query param
  const timestamp = encodeURIComponent(new Date().toISOString());

  // fetch actual version from server - never cached
  const response = await fetch<Blob>(`${window.origin}/VERSION?timestamp=${timestamp}`, {
    blob: true,
  });
  const actualVersion = response.bodyText;

  // trim both versions and compare
  if (`${version}`.trim() === `${actualVersion}`.trim()) {
    // save number of reloads
    store.set('reloaded', 0);
    // all good
    console.log(`Version ${version} confirmed`);
    return;
  }

  // at this point, a cache bust is necessary!

  // check if we are not in an infinite reload loop
  const reloadedTimes = store.get('reloaded');
  if (reloadedTimes >= 3) {
    console.error('Cache busting would be necessary, but infinite reload detected');
    throw new Error(
      'You are running an old cached version of this app, please hard reload the page (Ctrl+F5 or Ctrl+Shift+R). If that fails, please contact technical support.',
    );
  }
  store.set('reloaded', reloadedTimes + 1);

  // do cache bust
  console.error(`Cache busting necessary, versions don't match: ${version} != ${actualVersion}`);

  // clear caches
  if (caches) {
    const names = await caches.keys();
    await Promise.all(names.map((name) => caches.delete(name)));
  } else {
    console.warn('Caches not available, cannot bust fully');
  }

  // reload window - forcedReload is deprecated only on certain browsers
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window.location as any).reload(true);
}
