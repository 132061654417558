import React, { ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import { Button, ButtonGroup, Form } from 'react-bootstrap';

import {
  ExternalUserIdentityCreationDto,
  ExternalUserIdentityDto,
  ExternalUserIdentityUpdateDto,
  IdentityType,
  StandardUserIdentityCreationDto,
  StandardUserIdentityDto,
  StandardUserIdentityUpdateDto,
  UserDto,
  UserIdentityCreationDto,
  UserIdentityDto,
  UserIdentityUpdateDto,
} from '../../model';
import AsyncComponent from '../util/AsyncComponent';
import CredentialsContext, { CredentialsContextType } from '../../context/credentials';
import OverlayButton from '../util/OverlayButton';

/**
 * standard
 */

type StandardUserIdentityListEntryProps = {
  identity?: StandardUserIdentityDto;
  user: UserDto;
  editMode: boolean;
  onCreate?: (user: StandardUserIdentityCreationDto) => void;
  onCancel?: () => void;
  onUpdate?: (desc: { identityId: number; identity: StandardUserIdentityUpdateDto }) => void;
  onDelete?: (userIdentityId: number) => void;
};

type StandardUserIdentityListEntryState = {
  expanded: boolean;
  identity: StandardUserIdentityCreationDto;
};

export class StandardUserIdentityListEntry extends AsyncComponent<StandardUserIdentityListEntryProps, StandardUserIdentityListEntryState> {
  constructor(props: StandardUserIdentityListEntryProps) {
    super(props);

    this.state = {
      expanded: !this.props.editMode,
      identity: this.props.editMode
        ? {
            ...this.props.identity,
            password: '',
          }
        : this.defaultIdentityValues(),
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps: StandardUserIdentityListEntryProps): void {
    if (prevProps.user !== this.props.user || prevProps.identity !== this.props.identity) {
      this.setState({
        expanded: false,
        identity: this.props.editMode
          ? {
              ...this.props.identity,
              password: null,
            }
          : this.defaultIdentityValues(),
      });
    }
  }

  onValueChange(event: ChangeEvent<HTMLInputElement>): void {
    const { target } = event;
    const value = event.target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;

    this.setState({
      identity: {
        ...this.state.identity,
        [key]: value,
      },
    });
  }

  onConfirm(): void {
    if (this.props.editMode) {
      this.props.onUpdate({
        identityId: this.props.identity.id,
        identity: this.state.identity,
      });
    } else {
      this.props.onCreate(this.state.identity);
    }
  }

  onCancel(): void {
    if (this.props.editMode) {
      this.setState({
        expanded: false,
        identity: {
          ...this.props.identity,
          password: '',
        },
      });
    } else {
      this.props.onCancel();
    }
  }

  private defaultIdentityValues(): StandardUserIdentityCreationDto {
    return {
      identityType: 'standard',
      email: '',
      password: '',
      canChangePassword: false,
      mustChangePassword: false,
    };
  }

  render(): JSX.Element {
    const { editMode } = this.props;
    const { identity, expanded } = this.state;

    const isCurrentUser = this.context.userId === this.props.user.id;
    const isAdmin = this.context.admin;
    const canChangePassword = isAdmin || (isCurrentUser && (!editMode || identity.canChangePassword || identity.mustChangePassword));
    const canDelete = isAdmin && !isCurrentUser && editMode && Boolean(this.props.onDelete);

    return (
      <>
        <div style={{ overflow: 'auto' }}>
          <div className="float-left">
            <dl className="likeALink" onClick={() => this.setState({ expanded: !expanded })}>
              <dt>
                <FontAwesomeIcon icon="user" />
                &nbsp;
                {identity.email}
              </dt>
              <dd className="eventmsg">standard identity</dd>
            </dl>
          </div>
          <div className="float-right">
            <OverlayButton
              visible={expanded && canDelete}
              tooltip="Delete"
              onClick={() => this.props.onDelete(this.props.identity.id)}
              variant="outline-danger"
            >
              <FontAwesomeIcon icon="trash" />
            </OverlayButton>
          </div>
        </div>
        {expanded && (
          <>
            <Form.Group>
              <Form.Label>E-mail address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={this.onValueChange}
                value={identity.email}
                placeholder="user.email@example.com"
                disabled={editMode}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                onChange={this.onValueChange}
                value={identity.password}
                disabled={!canChangePassword}
              />
            </Form.Group>
            <Form.Check
              type="checkbox"
              id="canChangePassword"
              name="canChangePassword"
              checked={identity.canChangePassword}
              onChange={this.onValueChange}
              disabled={!isAdmin && isCurrentUser}
              label={<>This user can change their password</>}
            />
            <Form.Check
              type="checkbox"
              id="mustChangePassword"
              name="mustChangePassword"
              checked={identity.mustChangePassword}
              onChange={this.onValueChange}
              disabled={isCurrentUser}
              label={<>This user must change their password on next login</>}
            />

            <div className="row">
              <div className="col-md-12">
                <div className="float-right">
                  <ButtonGroup>
                    <Button variant="outline-secondary" onClick={this.onCancel}>
                      <FontAwesomeIcon icon="times" />
                      &nbsp;Cancel
                    </Button>
                    <Button variant="success" onClick={this.onConfirm}>
                      <FontAwesomeIcon icon="check" />
                      &nbsp;Confirm
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

StandardUserIdentityListEntry.contextType = CredentialsContext;

/**
 * external
 */

type ExternalUserIdentityListEntryProps = {
  identity?: ExternalUserIdentityDto;
  user: UserDto;
  editMode: boolean;
  onCreate?: (user: ExternalUserIdentityCreationDto) => void;
  onCancel?: () => void;
  onUpdate?: (desc: { identityId: number; identity: ExternalUserIdentityUpdateDto }) => void;
  onDelete?: (userIdentityId: number) => void;
};

type ExternalUserIdentityListEntryState = {
  expanded: boolean;
  identity: ExternalUserIdentityCreationDto;
};

export class ExternalUserIdentityListEntry extends AsyncComponent<ExternalUserIdentityListEntryProps, ExternalUserIdentityListEntryState> {
  context: CredentialsContextType;

  constructor(props: ExternalUserIdentityListEntryProps) {
    super(props);

    this.state = {
      expanded: !this.props.editMode,
      identity: this.props.editMode
        ? {
            ...this.props.identity,
          }
        : this.defaultIdentityValues(),
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps: ExternalUserIdentityListEntryProps): void {
    if (prevProps.user !== this.props.user || prevProps.identity !== this.props.identity) {
      this.setState({
        expanded: false,
        identity: this.props.editMode
          ? {
              ...this.props.identity,
            }
          : this.defaultIdentityValues(),
      });
    }
  }

  onValueChange(event: ChangeEvent<HTMLInputElement>): void {
    const { target } = event;
    const value = event.target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;

    this.setState({
      identity: {
        ...this.state.identity,
        [key]: value,
      },
    });
  }

  onConfirm(): void {
    if (this.props.editMode) {
      this.props.onUpdate({
        identityId: this.props.identity.id,
        identity: this.state.identity,
      });
    } else {
      this.props.onCreate(this.state.identity);
    }
  }

  onCancel(): void {
    if (this.props.editMode) {
      this.setState({
        expanded: false,
        identity: {
          ...this.props.identity,
        },
      });
    } else {
      this.props.onCancel();
    }
  }

  private defaultIdentityValues(): ExternalUserIdentityCreationDto {
    return {
      identityType: 'external',
      providerName: '',
      providerSpecificId: 0,
    };
  }

  render(): JSX.Element {
    const { providers } = this.context;
    const { editMode } = this.props;
    const { identity, expanded } = this.state;

    const isCurrentUser = this.context.userId === this.props.user.id;
    const isAdmin = this.context.admin;
    const canDelete = isAdmin && !isCurrentUser && editMode && Boolean(this.props.onDelete);

    return (
      <>
        <div style={{ overflow: 'auto' }}>
          <div className="float-left">
            <dl className="likeALink" onClick={() => this.setState({ expanded: !expanded })}>
              <dt>
                <FontAwesomeIcon icon="user" />
                &nbsp;
                {identity.providerName}&nbsp;
                <i>ID {identity.providerSpecificId}</i>
              </dt>
              <dd className="eventmsg">external identity</dd>
            </dl>
          </div>
          <div className="float-right">
            <OverlayButton
              visible={expanded && canDelete}
              tooltip="Delete"
              onClick={() => this.props.onDelete(this.props.identity.id)}
              variant="outline-danger"
            >
              <FontAwesomeIcon icon="trash" />
            </OverlayButton>
          </div>
        </div>
        {expanded && (
          <>
            <Form.Group>
              <Form.Label>Provider name</Form.Label>
              <Form.Control as="select" name="providerName" onChange={this.onValueChange} value={identity.providerName} disabled={!isAdmin}>
                <option hidden disabled value={''}></option>
                {Object.values(providers).map(({ name }) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Provider Specific ID</Form.Label>
              <Form.Control
                type="number"
                name="providerSpecificId"
                onChange={this.onValueChange}
                value={identity.providerSpecificId}
                disabled={!isAdmin}
              />
            </Form.Group>

            <div className="row">
              <div className="col-md-12">
                <div className="float-right">
                  <ButtonGroup>
                    <Button variant="outline-secondary" onClick={this.onCancel}>
                      <FontAwesomeIcon icon="times" />
                      &nbsp;Cancel
                    </Button>
                    <Button variant="success" onClick={this.onConfirm}>
                      <FontAwesomeIcon icon="check" />
                      &nbsp;Confirm
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

ExternalUserIdentityListEntry.contextType = CredentialsContext;

/**
 * switcher
 */

type UserIdentityListEntryProps = {
  identity?: Partial<UserIdentityDto>;
  identityType: IdentityType;
  user: UserDto;
  editMode: boolean;
  onCreate?: (user: UserIdentityCreationDto) => void;
  onCancel?: () => void;
  onUpdate?: (desc: { identityId: number; identity: UserIdentityUpdateDto }) => void;
  onDelete?: (userIdentityId: number) => void;
};

export default class UserIdentityListEntry extends AsyncComponent<UserIdentityListEntryProps> {
  render(): JSX.Element {
    const { identity, identityType, user, editMode } = this.props;
    const { onCreate, onCancel, onUpdate, onDelete } = this.props;

    if (identityType === 'standard') {
      return (
        <StandardUserIdentityListEntry
          identity={identity as StandardUserIdentityDto}
          user={user}
          editMode={editMode}
          onCreate={onCreate}
          onCancel={onCancel}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      );
    }

    return (
      <ExternalUserIdentityListEntry
        identity={identity as ExternalUserIdentityDto}
        user={user}
        editMode={editMode}
        onCreate={onCreate}
        onCancel={onCancel}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />
    );
  }
}
