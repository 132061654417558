export const shortId = (urlsafe = false): string => {
  const randVal = crypto.getRandomValues(new Uint8Array(2));
  const randStr = String.fromCharCode.apply(null, randVal);
  return urlsafe ? btoa(randStr).replace(/\+/g, '-').replace(/\//g, '_') : btoa(randStr);
};

export const longId = (urlsafe = false): string => {
  const randVal = crypto.getRandomValues(new Uint8Array(8));
  const randStr = String.fromCharCode.apply(null, randVal);
  return urlsafe ? btoa(randStr).replace(/\+/g, '-').replace(/\//g, '_') : btoa(randStr);
};
