import { useAnswerStartPoint, useNumQuestions, usePoints } from '../../hooks/useFormatted';
import { ExamDetailsDto, QuestionBankDto } from '../../model';
import { If } from '../util/conditional';
import { CheckboxPanelEntry, IntervalPanelEntry, PanelEntry } from '../util/Panel';

type BankDetailsPanelProps = {
  bank: QuestionBankDto;
  exam: ExamDetailsDto;
};

export default function BankDetailsPanel({ bank, exam }: BankDetailsPanelProps) {
  const pointsPerQuestion = usePoints(bank.pointsPerQuestion, '/question');
  const chooseQuantity = useNumQuestions(bank.chooseQuantity);
  const answerStartPoint = useAnswerStartPoint(bank.answerStartPoint);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="jumbotron mini-jumbotron">
          <dl className="examdescription">
            <IntervalPanelEntry name="Time given per question" icon="clock" suffix="/question" value={bank.timePerQuestion} />
            <IntervalPanelEntry name="Minimum time to spend per question" icon="clock" suffix="/question" value={bank.minTimePerQuestion} />
            <PanelEntry name="Points given per question" icon="trophy" value={pointsPerQuestion} />
            <PanelEntry name="Choose quantity" icon="hashtag" value={chooseQuantity} />
            <CheckboxPanelEntry name="Resumable without skipping" icon="step-forward" value={bank.resumable} />
            <If c={exam.streaming}>
              <PanelEntry name="Answer start point availability" icon="map-pin" value={answerStartPoint} />
            </If>
          </dl>
        </div>
      </div>
    </div>
  );
}
