import { CSSProperties } from 'react';
import { Button, ButtonProps, OverlayTrigger, OverlayTriggerProps, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useId from '../../hooks/useId';

type OverlayButtonProps = {
  children?: ButtonProps['children'];
  style?: CSSProperties;
  buttonStyle?: CSSProperties;
  visible?: boolean;
  disabled?: boolean;
  variant?: ButtonProps['variant'];
  tooltip?: string;
  disabledTooltip?: string;
  tooltipPlacement?: OverlayTriggerProps['placement'];
  size?: ButtonProps['size'];
  to?: string;
  onClick?: ButtonProps['onClick'];
  className?: ButtonProps['className'];
};

export default function OverlayButton({
  children = null,
  style = {},
  buttonStyle = {},
  visible = true,
  disabled = false,
  variant = null,
  tooltip = null,
  disabledTooltip = null,
  tooltipPlacement = 'top',
  size = null,
  to = null,
  onClick = null,
  className = null,
}: OverlayButtonProps) {
  const tooltipId = useId();

  if (!visible) {
    return null;
  }

  const finalStyle = style;
  const finalButtonStyle = buttonStyle;
  if (disabled) {
    finalStyle.cursor = 'not-allowed';
    finalButtonStyle.pointerEvents = 'none';
  }

  let ret = (
    <span className="btn-wrapper" style={finalStyle}>
      <Button variant={variant} onClick={onClick} disabled={disabled} style={finalButtonStyle} className={className} size={size}>
        {children}
      </Button>
    </span>
  );

  const tooltipText = disabled ? disabledTooltip || tooltip : tooltip;

  if (tooltipText) {
    ret = (
      <OverlayTrigger placement={tooltipPlacement} overlay={<Tooltip id={tooltipId}>{tooltipText}</Tooltip>}>
        {ret}
      </OverlayTrigger>
    );
  }

  if (to) {
    ret = <Link to={to}>{ret}</Link>;
  }

  return ret;
}
