import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ErrorModalContext, { ErrorDisplay } from '../context/error';

export type ErrorModalProviderProps = {
  children: ReactNode;
};

export default function ErrorModalProvider({ children }: ErrorModalProviderProps) {
  const [show, setShow] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState<ErrorDisplay>({
    error: null,
    options: {
      autoClose: false,
      keepTrying: false,
    },
    onClose: null,
  });

  const message = useMemo(() => errorDisplay?.error?.message || 'Unknown error', [errorDisplay?.error]);

  const close = useCallback(() => {
    setShow(false);
    errorDisplay?.onClose?.();
  }, [setShow, errorDisplay?.onClose]);

  const showError = useCallback(
    (newErrorDisplay: ErrorDisplay) => {
      setShow(true);
      setErrorDisplay(newErrorDisplay);

      if (newErrorDisplay.options?.autoClose) {
        setTimeout(() => close(), 5000);
      }
    },
    [setShow, setErrorDisplay, close],
  );

  const { autoClose, keepTrying } = errorDisplay.options;

  return (
    <ErrorModalContext.Provider value={showError}>
      {children}

      <Modal show={show} onHide={close}>
        <Modal.Header closeButton={!autoClose}>
          <Modal.Title>An error occurred</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          {/* close button */}
          {!autoClose && (
            <div className="btn-group">
              <Button variant="danger" onClick={close}>
                <FontAwesomeIcon icon="times" />
                &nbsp;Close
              </Button>
            </div>
          )}
          {/* retying message */}
          {keepTrying && (
            <div className="btn-group">
              <FontAwesomeIcon icon="stopwatch" />
              &nbsp;
              <i>Retrying in a few seconds...</i>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </ErrorModalContext.Provider>
  );
}
