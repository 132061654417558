import autoBind from 'auto-bind';
import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import {
  instanceOfProctoringEntryDetailsDto,
  instanceOfProctoringEntryReducedDto,
  instanceOfProctoringEntryWithSittingQuestionDto,
  ProctoringEntryDetailsDto,
  ProctoringEntryReducedDto,
  ProctoringEntryWithSittingQuestionDto,
} from '../../model';
import { secondsToTimeText } from '../../util/date';
import { addQueryParameters } from '../../util/queryparser';

type ReportListEntryProps = RouteComponentProps & {
  report: ProctoringEntryDetailsDto | ProctoringEntryWithSittingQuestionDto | ProctoringEntryReducedDto;
  examId: number;
  sittingId?: number;
  questionId?: number;
};

class ReportListEntry extends Component<ReportListEntryProps> {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  addSkip() {
    const search = addQueryParameters(this.props.location.search, {
      skip: this.props.report.timestampStart,
      questionId: this.props.questionId,
    });
    if (search !== this.props.location.search) {
      this.props.history.push(search);
    }
  }

  render(): JSX.Element {
    const { report, examId } = this.props;
    let { sittingId } = this.props;
    const timestampsContainNull =
      !(report.timestampStart === null || report.timestampStart === undefined) &&
      !(report.timestampEnd === null || report.timestampEnd === undefined);

    let sitting;
    let questionInfo;
    let studentName;
    let timeInterval;

    const streamType = report.filename.includes('webcam') ? ', webcam' : ', desktop';
    const severity = (
      <>
        severity <span className={`severity${report.severity}`}>{report.severity}</span>
      </>
    );
    const timeIntervalText = `${secondsToTimeText(report.timestampStart)}-${secondsToTimeText(report.timestampEnd)}`;

    if (instanceOfProctoringEntryDetailsDto(report) || instanceOfProctoringEntryWithSittingQuestionDto(report)) {
      if (instanceOfProctoringEntryDetailsDto(report)) {
        sittingId = report.sittingId;
        studentName = (
          <>
            , <span className="likeALink">{report.studentName}</span>
          </>
        );
      }

      const perPage = 5;
      const page = Math.ceil((report.questionOrder + 1) / perPage);
      const queryParams = `questionId=${report.questionId}&skip=${report.timestampStart}&perPage=${perPage}&page=${page}`;

      if (timestampsContainNull) {
        timeInterval = (
          <>
            , between <Link to={`/exams/${examId}/sittings/${sittingId}/?${queryParams}`}>{timeIntervalText}</Link>
          </>
        );
      }

      sitting = <Link to={`/exams/${examId}/sittings/${sittingId}/`}>Sitting {sittingId}</Link>;
      questionInfo = `, question ID ${report.questionId}, question order ${report.questionOrder + 1}, `;
    } else if (instanceOfProctoringEntryReducedDto(report)) {
      if (timestampsContainNull) {
        timeInterval = (
          <>
            , between{' '}
            <span onClick={this.addSkip} className="likeALink">
              {timeIntervalText}
            </span>
          </>
        );
      }
    }

    return (
      <span className="reportmsg">
        {sitting}
        {questionInfo}
        {severity}
        {streamType}
        {timeInterval}
        {studentName}: {report.message}
      </span>
    );
  }
}

export default withRouter(ReportListEntry);
