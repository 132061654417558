import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind from 'auto-bind';
import React from 'react';
import BlockUi from 'react-block-ui';
import { Link } from 'react-router-dom';

import { GroupReducedDto } from '../../model';
import { deleteGroup } from '../../service/group';
import AsyncComponent from '../util/AsyncComponent';
import { blocking } from '../util/decorators';
import { DeleteModal } from '../util/Modals';
import OverlayButton from '../util/OverlayButton';
import withErrorScreen from '../util/withErrorScreen';

type GroupListEntryProps = {
  group: GroupReducedDto;
};

type GroupListEntryState = {
  showDeleteModal: boolean;
  deleted: boolean;
  blocking: boolean;
};

class GroupListEntry extends AsyncComponent<GroupListEntryProps, GroupListEntryState> {
  constructor(props: GroupListEntryProps) {
    super(props);

    this.state = {
      showDeleteModal: false,
      deleted: false,
      blocking: false,
    };

    autoBind(this);

    this.confirmDeleteGroup = blocking(this.confirmDeleteGroup, this);
  }

  proposeDeleteGroup() {
    this.setState({ showDeleteModal: true });
  }

  cancelDeleteGroup() {
    this.setState({ showDeleteModal: false });
  }

  async confirmDeleteGroup() {
    await this.setStateAsync({ showDeleteModal: false });
    await deleteGroup(this.props.group.id);
    this.setState({ deleted: true });
  }

  render() {
    if (this.state.deleted) {
      return <div className="infomsg">This group has been deleted.</div>;
    }

    const { group } = this.props;

    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div className="float-left mr-3">
          <Link to={`/groups/${group.id}/`}>
            <b>{group.name}</b>
          </Link>
        </div>
        <div className="float-right btn-group">
          {/* edit, delete */}
          <OverlayButton tooltip="Edit" to={`/groups/${group.id}/edit`} variant="outline-warning">
            <FontAwesomeIcon icon="edit" />
          </OverlayButton>
          <OverlayButton tooltip="Delete" onClick={this.proposeDeleteGroup} variant="outline-danger">
            <FontAwesomeIcon icon="trash" />
          </OverlayButton>
        </div>

        <DeleteModal
          show={this.state.showDeleteModal}
          onCancel={this.cancelDeleteGroup}
          onConfirm={this.confirmDeleteGroup}
          title="Delete group"
        >
          <div>
            Are you sure you would like to delete the group <b>{group.name}</b>?
          </div>
          <div>All affiliated users will be deleted. This action is irreversible.</div>
        </DeleteModal>
      </BlockUi>
    );
  }
}

export default withErrorScreen(GroupListEntry);
