import autoBind from 'auto-bind';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import QWebSocket, { QwsMessageExtraHeaders } from 'qws-browser';
import { MpQuestionEntryType, MpSittingQuestionDto, QuestionType } from '../../model';
import QuestionEditor, { QuestionEditorModel } from '../question/QuestionEditor';
import withErrorScreen from '../util/withErrorScreen';

const startingQuestions = [
  // no answer (only for streaming)
  {
    id: 0,
    questionType: 'noanswer' as QuestionType,
    questionContent: 'What is **the answer** to this question?',
  },
  // essay type question
  {
    id: 1,
    questionType: 'essay' as QuestionType,
    questionContent: 'What is **the answer** to this question?',
    response: 'This is a **response** string',
  },
  // code type question
  {
    id: 2,
    questionType: 'code' as QuestionType,
    questionContent: {
      text: 'What is **the answer** to this question?',
      language: 'javascript',
    },
    response: 'console.log("This is a **response** string");',
  },
  // file type question
  {
    id: 3,
    questionType: 'file' as QuestionType,
    questionContent: {
      text: 'What is **the answer** to this question?',
      files: {
        pdfFile: {
          entryKey: 'PDF file',
          maxFileSize: 1048576,
          acceptedExtensions: 'pDf',
          uploadObjectPrefix: 'course0297/exam0069/sitting8099_student47_sulyok_csaba/resources/question003-pdfFile_',
        },
        jsonFile: {
          entryKey: 'JSON file',
          maxFileSize: 8192,
          acceptedExtensions: 'jSon',
          uploadObjectPrefix: 'course0297/exam0069/sitting8099_student47_sulyok_csaba/resources/question003-jsonFile_',
        },
      },
    },
    response: {
      pdfFile: {
        name: 'question003-pdfFile_abcd.pdf',
        size: 5000,
        location: 'course0297/exam0069/sitting8099_student47_sulyok_csaba/resources/question003-pdfFile_abcd.pdf',
      },
    },
  },
  // multiple correct answers (original)
  {
    id: 4,
    questionType: 'multiplecorrect' as QuestionType,
    questionContent: {
      text: 'What is **the answer** to this question?',
      shuffleAnswers: true,
      answers: {
        vsuive: { dynamic: true, text: '$$ \\sum_{i=1}^{n} i = \\frac{n(n+1)}{2} $$' },
        veseve: { dynamic: false, text: 'Answer 2' },
        vb32df: { dynamic: true, text: '```java\nSystem.out.println("Hello world");\n```' },
      },
    },
    evaluationKey: {
      vsuive: true,
      veseve: true,
      vb32df: false,
    },
    response: {
      vsuive: false,
      veseve: true,
      vb32df: false,
    },
  },
  // single correct answer
  {
    id: 5,
    questionType: 'singlecorrect' as QuestionType,
    questionContent: {
      text: 'What is **the answer** to **this** question?',
      shuffleAnswers: true,
      answers: {
        vsuive: { dynamic: true, text: '**Bold answer** with `some code`' },
        veseve: { dynamic: false, text: 'Answer 2' },
        vb32df: { dynamic: false, text: 'Answer 3' },
      },
    },
    evaluationKey: 'veseve', // which ID answer is correct
    response: 'vb32df', // which ID answer the student selected
  },
  // short text answer
  {
    id: 6,
    questionType: 'shorttext' as QuestionType,
    questionContent: {
      text: 'What is **the answer** to **this** question?',
      caseSensitive: true,
    },
    evaluationKey: ['abc', 'abcd'],
    response: 'abc',
  },
  // multiple short text answers
  {
    id: 7,
    questionType: 'multipart' as QuestionType,
    questionContent: {
      text: 'Here is a problem.\n\n1. What is the numeric solution?\n2. Why is it the solution?',
      entries: {
        what: {
          entryKey: '1. What?',
          type: 'numeric' as MpQuestionEntryType,
          weight: 0.25,
        },
        why: {
          entryKey: '2. Why?',
          type: 'shorttext' as MpQuestionEntryType,
          weight: 0.25,
          caseSensitive: false,
        },
        which: {
          entryKey: '3. Which?',
          type: 'singlecorrect' as MpQuestionEntryType,
          weight: 0.25,
          shuffleAnswers: true,
          answers: {
            vsuive: { dynamic: true, text: '**Bold answer** with `some code`' },
            veseve: { dynamic: false, text: 'Answer 2' },
            vb32df: { dynamic: false, text: 'Answer 3' },
          },
        },
        whiches: {
          entryKey: '4. Whiches?',
          type: 'multiplecorrect' as MpQuestionEntryType,
          weight: 0.25,
          answers: {
            vsuive: { dynamic: true, text: '$$ \\sum_{i=1}^{n} i = \\frac{n(n+1)}{2} $$' },
            veseve: { dynamic: false, text: 'Answer 2' },
            vb32df: { dynamic: true, text: '```java\nSystem.out.println("Hello world");\n```' },
          },
        },
      },
    },
    evaluationKey: {
      what: {
        type: 'numeric' as MpQuestionEntryType,
        min: 41.5,
        max: 42.5,
      },
      why: {
        type: 'shorttext' as MpQuestionEntryType,
        acceptedAnswers: ['cause', 'because', 'just because'],
      },
      which: {
        type: 'singlecorrect' as MpQuestionEntryType,
        correctKey: 'vb32df',
      },
      whiches: {
        type: 'multiplecorrect' as MpQuestionEntryType,
        correctKeys: {
          vsuive: false,
          veseve: true,
          vb32df: true,
        },
      },
    },
    response: {
      what: {
        type: 'numeric' as MpQuestionEntryType,
        response: 42,
      },
      why: {
        type: 'shorttext' as MpQuestionEntryType,
        response: 'dunno',
      },
      which: {
        type: 'singlecorrect' as MpQuestionEntryType,
        selectedKey: 'vsuive',
      },
      whiches: {
        type: 'multiplecorrect' as MpQuestionEntryType,
        selectedKeys: {
          vsuive: false,
          veseve: true,
          vb32df: true,
        },
      },
    },
  } as MpSittingQuestionDto,
  // numeric answer
  {
    id: 8,
    questionType: 'numeric' as QuestionType,
    questionContent: 'What is **the answer** to **this** question?',
    evaluationKey: {
      min: 4,
      max: 8,
    },
    response: 5,
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class Sandbox extends Component<any, any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = {
      questionModel: new QuestionEditorModel(startingQuestions[7]),
    };
    autoBind(this);
  }

  onQuestionModelChange(questionModel) {
    this.setState({ questionModel });
  }

  onClick() {
    const qws = new QWebSocket('ws://localhost:8888/ws');

    qws.send(new Blob(['1234567890-1234567890']), {
      key1: 'wgj3480 tj29[[[úó23űűűíí',
      key2: 42,
      key3: false,
    });

    qws.onBin(async (body: Blob, headers: QwsMessageExtraHeaders) => {
      console.log('Headers', headers);
      console.log('Body as string', await body.text());
    });
  }

  render() {
    const { questionModel } = this.state;

    return (
      <>
        <h4 style={{ paddingTop: '50px' }}>View mode</h4>
        <QuestionEditor mode="view" model={questionModel} />
        <div style={{ paddingBottom: 100 }} />
        <hr />
        <h4>Edit mode</h4>
        <QuestionEditor mode="edit" model={questionModel} onChange={this.onQuestionModelChange} />
        <div style={{ paddingBottom: 100 }} />
        <hr />
        <h4>Take mode</h4>
        <QuestionEditor mode="take" model={questionModel} onChange={this.onQuestionModelChange} />
        <div style={{ paddingBottom: 100 }} />
        <hr />
        <h4>Response mode</h4>
        <QuestionEditor mode="response" model={questionModel} />
        <div style={{ paddingBottom: 100 }} />
        <hr />
        <h4>Evaluate mode</h4>
        <QuestionEditor mode="evaluate" model={questionModel} />
        <div style={{ paddingBottom: 100 }} />
        {/* <Button onClick={this.onClick}>Send message</Button> */}
      </>
    );
  }
}

export default withRouter(withErrorScreen(Sandbox));
